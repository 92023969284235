<div class="asset-customer-table">
  <div class="chipContainer">
    <span
      class="chipCls"
      *ngFor="let option of tabsList; let i = index"
      [cc-chip]="option.value"
      type="check"
      [radio]="false"
      [state]="radioStates[i]"
      [ngClass]="radioStates[i] === 'active' ? 'home-chip-field' : ''"
      (selected)="changeSelected($event, option.label, i)"
    ></span>
  </div>

  <div
    class="table-content"
    id="home-assets-table-container"
    (scroll)="onScrollAssets($event)"
    *ngIf="selectedTab === 'Assets'"
  >
    <div *ngIf="isAssetLoading && !isVirtualScrollTriggered">
      <dsp-next-gen-ui-home-skeleton-loader></dsp-next-gen-ui-home-skeleton-loader>
    </div>

    <div *ngIf="assetsData?.assets && assetsData?.assets?.length && !isAssetLoading">
      <div *ngFor="let item of assetsData?.assets">
        <div class="nested-row-container">
          <div class="left-assetcard-container">
            <div class="firstrow-data" *ngIf="item.serialNumber">
              <span class="code-title-name">{{ item.serialNumber }} </span>
            </div>
            <div class="firstrow-data" *ngIf="item.engineSerialNo">
              <span class="code-title-name"> | {{ item.engineSerialNo }} </span>
            </div>
            <div class="firstrow-data" *ngIf="item.make">
              <span class="code-title-name"> | {{ item.make }} </span>
            </div>
            <div class="firstrow-data" *ngIf="item.model">
              <span class="code-title-name"> | {{ item.model }} </span>
            </div>
            <div class="firstrow-data" *ngIf="item.manufacturingYear">
              <span class="code-title-name"> | {{ item.manufacturingYear }} </span>
            </div>
            <div class="firstrow-data" *ngIf="item.deviceType && (item.deviceType !== '' || item.deviceType !== null)">
              <span class="code-title-name"> | {{ item.deviceType }}</span>
            </div>
            <div *ngIf="isSecondRowLoading">
              <cc-skeleton-loader [height]="12" [animation]="true" class="row-skeleton-loader">
                <svg:rect x="2" y="6" rx="2" ry="2" width="35" height="5" />
                <svg:rect x="50" y="6" rx="2" ry="2" width="35" height="5" />
                <svg:rect x="100" y="6" rx="2" ry="2" width="35" height="5" />
                <svg:rect x="150" y="6" rx="2" ry="2" width="35" height="5" />
                <svg:rect x="200" y="6" rx="2" ry="2" width="35" height="5" />
              </cc-skeleton-loader>
            </div>
            <div *ngIf="!isSecondRowLoading">
              <div class="secondrow-data"><a class="click-here-link" (click)="openAssetDrawer(item)"> Details </a></div>
              <div class="secondrow-data" *ngIf="item.showManagePlan">
                <a class="click-here-link" (click)="openManagePlan(item)"> Manage Plan </a>
              </div>
              <div class="secondrow-data" *ngIf="item.showUpdateRegistration">
                <a class="click-here-link" (click)="openEditAssetSerialNumber(item)"> Update Registration </a>
              </div>
              <div class="secondrow-data" *ngIf="item.showDeviceStatusIcon && isCustomAccessEnabled">
                <a class="click-here-link" (click)="openDeviceStatus(item)"> Device Status </a>
              </div>
              <div class="secondrow-data" *ngIf="item.showConfigureWifiIcon">
                <a class="click-here-link" (click)="openWifiConfiguration(item)"> Configure Wi-Fi </a>
              </div>
            </div>
          </div>
          <div class="right-assetcard-container" *ngIf="item.serialNumber">
            <span class="home-asset-icon-width">
              <span *ngIf="item.reportingState === dspWorklistConstants.SUBSCRIBED">
                <cc-icon class="reportingStateIcons subscribed">subscribed</cc-icon
                ><span class="reporting-title">{{ dspWorklistConstants.SUBSCRIBED }}</span>
              </span>
              <span *ngIf="item.reportingState === dspWorklistConstants.UNSUBSCRIBED">
                <cc-icon class="reportingStateIcons unsubscribed">unsubscribed</cc-icon
                ><span class="reporting-title">{{ dspWorklistConstants.UNSUBSCRIBED }}</span>
              </span>
              <span *ngIf="item.reportingState === dspWorklistConstants.SUSPENDED">
                <cc-icon class="reportingStateIcons suspended">suspended</cc-icon
                ><span class="reporting-title">{{ dspWorklistConstants.SUSPENDED }}</span>
              </span></span
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!this.isAssetLoading && assetsData?.assets?.length === 0" class="no-results-found">
      <img src="../../../assets/images/no_results_found.png" alt="no results found" />
      <span>No Results Found</span>
    </div>
  </div>
  <div
    class="table-content"
    id="home-customers-table-container"
    (scroll)="onScrollCustomers($event)"
    *ngIf="selectedTab === 'Customers'"
  >
    <div *ngIf="isCustomerLoading && !isVirtualScrollTriggeredCustomer">
      <dsp-next-gen-ui-home-skeleton-loader></dsp-next-gen-ui-home-skeleton-loader>
    </div>
    <div *ngIf="customerData?.customers && customerData?.customers?.length && !isCustomerLoading">
      <div *ngFor="let item of customerData?.customers">
        <div class="nested-customer-row-container">
          <div class="left-assetcard-container">
            <div class="secondrow-data">
              <span class="code-row-name">Customer : </span>
              <span class="code-title-name">
                {{ getCustomerData(item) }}
              </span>
            </div>
            <div class="secondrow-data">
              <span class="code-row-name">Dealer Customer : </span>
              <span class="code-title-name">
                {{ getDealerCustomerData(item) }}
              </span>
            </div>
            <div class="secondrow-data">
              <a class="click-here-link" (click)="goToAssetsPage(item)"> Assets </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isCustomerLoading && customerData?.customers?.length === 0" class="no-results-found">
      <img src="../../../assets/images/no_results_found.png" alt="no results found" />
      <span>No Results Found</span>
    </div>
  </div>
</div>
