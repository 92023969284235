import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AssetData } from '../../asset-interface';
import { AssetService } from '../../services/asset.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { DspCommonService } from '../../../../services/dsp-common.service';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { ConfigureWifiComponent } from '../../asset-drawer/configure-wifi/configure-wifi.component';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { EditAssetSerialNumberComponent } from '../../asset-drawer/edit-asset-serial-number/edit-asset-serial-number.component';
import { ManageAssetSubscriptionComponent } from '../../manage-asset-subscription/manage-asset-subscription.component';
import { isEmpty } from 'lodash-es';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { Subject, Subscription } from 'rxjs';
import { DeviceStatusComponent } from '../../../device-status/device-status.component';
import { ManagePlanComponent } from '../../manage-plan/manage-plan.component';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { ManagePendingRequestComponent } from '../../../manage-pending-request/manage-pending-request.component';
import { Router } from '@angular/router';

@Component({
  selector: 'dsp-next-gen-ui-asset-action-menus',
  templateUrl: './asset-action-menus.component.html',
  styleUrls: ['./asset-action-menus.component.scss'],
})
export class AssetActionMenusComponent implements OnInit {
  @Input() assetInfo!: AssetData;
  @Output() refreshTableOnPID = new EventEmitter();
  @Output() refreshTableOnManagePlan = new EventEmitter();
  queueReasonSubscription!: Subscription;
  selectedQueueReason: any;
  queueReasonNewCount: any;
  assetData: any;
  userActiveFeatures: any;
  showConfigureWifiIcon = false;
  showDeviceStatusIcon = false;
  rowData: any;
  dealer: any;
  wificonfig!: any;
  deviceFeaturesInfo: any;
  dspConfig: any;
  dspData: any;
  wifiConfigurationRef: any;
  featureSupportMapping: any;
  dspWorklistConstants = dspConstants.Worklist;
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  editAssetSerialNumber: any;
  customData: any;
  editAssetSNNumber: any = false;
  manageAssetPlanDrawerRef: any;
  queueReasonId: any;
  isEnabled: boolean = false;
  disableMenu = false;
  managePendingRequestRef: any;
  isCustRequestedSubscription: any = false;
  isSelfServeSubscription: any;
  queueReasonList: any;
  showManageIcon = false;
  isCustomAccessEnabled: any;
  constructor(
    private assetService: AssetService,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private drawerService: DrawerService,
    private assetDrawerService: AssetDrawerService,
    private needsReviewService: NeedsReviewService,
    private messageBar: MessageBar,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dspCommonService.isCustomAccessEnabled?.subscribe((res: any) => {
      this.isCustomAccessEnabled = res;
    });
    if (
      (this.assetInfo?.status !== this.dspNeedsReviewConstants.Active &&
        this.assetInfo?.status !== this.dspNeedsReviewConstants.New &&
        this.assetInfo?.reportingState === this.dspWorklistConstants.SUSPENDED) ||
      this.assetInfo?.isMaskRequired ||
      !this.isCustomAccessEnabled
    ) {
      this.isEnabled = false;
    } else {
      this.isEnabled = true;
    }

    this.dealer = fetchLoggedInDealerDetails();
    this.assetData = this.assetInfo;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspData = dsp;
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.dspConfig = dsp.dspConfig;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.featureSupportMapping = dsp.featureSupportMapping;
      this.isSelfServeSubscription = this.dspData?.featureFlagInfo?.isSelfServeSubscription
        ? this.dspData?.featureFlagInfo?.isSelfServeSubscription
        : false;
      this.queueReasonList = JSON.parse(this.dspConfig.Queue_Reason_List);
    });
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
      this.queueReasonNewCount = data.newCount;
      this.queueReasonId = data.dealerQueueReasonId;
    });
    this.isDeviceStatusNeeded();
    this.getSupportedServices();
    this.showManagePlanIcon();
    this.disableActionMenu();
    this.checkQueueReasonList();
  }

  checkQueueReasonList() {
    this.isCustRequestedSubscription = this.queueReasonList?.indexOf(this.selectedQueueReason) > -1 ? true : false;
  }
  checkPIDIsApplicable(assetInfo: any) {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Product ID') !== -1 &&
      assetInfo &&
      assetInfo.deviceType &&
      this.deviceFeaturesInfo[assetInfo.deviceType] &&
      !this.deviceFeaturesInfo[assetInfo.deviceType].Disable_Product_ID &&
      assetInfo.reportingState === this.dspWorklistConstants.UNSUBSCRIBED &&
      assetInfo.deviceType !== 'PRINTUP' &&
      assetInfo.deviceType !== 'NO_DEVICE' &&
      !this.assetData.isMaskRequired
    ) {
      this.editAssetSNNumber = true;
      return this.editAssetSNNumber;
    }
  }
  isDeviceStatusNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Device Status') > -1 &&
      (isEmpty(this.assetData?.dealerCode) ||
        this.assetData?.dealerCode == '-' ||
        this.assetData?.dealerCode == this.dealer?.dealerCode) && // enableSubscriptionFlag
      this.assetData?.deviceType !== 'NO_DEVICE' &&
      !isEmpty(this.assetData?.deviceType) &&
      this.assetData?.deviceType !== '-' && //isTelematicsRegistered Flag
      this.deviceFeaturesInfo &&
      !this.deviceFeaturesInfo[this.assetData?.deviceType]?.Disable_Device_Status &&
      this.assetData?.reportingState !== dspConstants.Worklist.SUSPENDED &&
      !this.assetData.isMaskRequired
    ) {
      this.showDeviceStatusIcon = true;
    }
  }
  openDeviceStatus() {
    if (this.assetInfo?.status && this.assetInfo.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.assetInfo, this.queueReasonId);
    }
    this.drawerService.openDrawer<DeviceStatusComponent, CcDrawerConfig>(DeviceStatusComponent, {
      data: { type: 'LANDING', assetInfo: this.assetInfo },
      enableBlackBackDrop: true,
      id: 'deviceStatusDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }
  openDrawerInMultiSize(assetInfo: any) {
    if (this.assetInfo?.status && this.assetInfo.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.assetInfo, this.queueReasonId);
    }
    this.editAssetSerialNumber = this.drawerService.openDrawer<EditAssetSerialNumberComponent, CcDrawerConfig>(
      EditAssetSerialNumberComponent,
      {
        data: {
          rowData: assetInfo,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.editAssetSerialNumber.afterClosed().subscribe((res: any) => {
      if (res) {
        if (['/needsreview'].includes(this.router.url)) {
          //to refresh needs review left tab
          this.needsReviewService.refreshNeedsReviewCategories.next(res);
          // to refresh table
          this.needsReviewService.refreshNeedsReviewTable.next(res);
        }

        if (['/asset'].includes(this.router.url)) {
          this.refreshTableOnPID.emit();
        }
      }
    });
  }
  openWifiConfiguration() {
    if (this.assetInfo?.status && this.assetInfo.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.assetInfo, this.queueReasonId);
    }
    this.wifiConfigurationRef = this.drawerService.openDrawer<ConfigureWifiComponent, CcDrawerConfig>(
      ConfigureWifiComponent,
      {
        data: {
          titleNames: 'Configure Wifi',
          data: this.assetData,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  setDataLayer(selectedOption: any, status: any, failedReason: any) {
    let isNeedsReview =
      this.assetInfo?.status === this.dspNeedsReviewConstants.Active ||
      this.assetInfo?.status === this.dspNeedsReviewConstants.New;
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: selectedOption,
      formContent: this.assetData?.serialNumber + ' | ' + this.assetData?.deviceType,
      formContent2: isNeedsReview ? this.selectedQueueReason : '',
      formAdditionalContent: this.dealer?.dealerCode + ' | ' + 'Asset Action Menu Clicked',
      formStatus: status,
      formFieldCausingError: failedReason,
      formLocation: isNeedsReview ? 'Needs Review Page' : '',
    };
    this.sharedService.updateDataLayer(object);
  }

  openManagePlan() {
    if (this.assetInfo?.status && this.assetInfo.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.assetInfo, this.queueReasonId);
    }
    if (this.dspConfig.isMultiProductEnabled === 'true') {
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePlanComponent, CcDrawerConfig>(
        ManagePlanComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: this.assetData ? this.assetData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: true,
          id: 'multiSizeDrawer',
          disableBackdropClick: false,
          drawerSize: CcDrawerSize.LARGE,
        }
      );
    } else {
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManageAssetSubscriptionComponent, CcDrawerConfig>(
        ManageAssetSubscriptionComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: this.assetData ? this.assetData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: false,
          id: 'multiSizeDrawer',
          disableBackdropClick: true,
          drawerSize: CcDrawerSize.LARGE,
        }
      );
      this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
        if (response) {
          this.refreshTableOnManagePlan.emit();
        }
      });
    }
  }

  isWifiConfigurationNeeded() {
    if (
      this.assetData?.reportingState != dspConstants.Worklist.SUSPENDED &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Wifi') > -1 &&
      this.getWifiConfigurationType(this.assetData) !== '' &&
      !this.assetData.isMaskRequired
    ) {
      this.showConfigureWifiIcon = true;
      this.assetData.showConfigureWifiIcon = true;
    } else {
      if (this.assetData) this.showConfigureWifiIcon = this.assetData.showConfigureWifiIcon = false;
    }
  }
  getWifiConfigurationType(assetData: any) {
    const featureSupportMsg = assetData.getSupportedServicesList ? assetData.getSupportedServicesList : [];
    const wifiSimultaneous = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_SIMULTANEOUS) > -1;
    const wifiHotSpot = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_HOTSPOT) > -1;
    const wifiInfrastructure = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_INFRASTRUCTURE) > -1;

    if (wifiSimultaneous && wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_AND_HOTSPOT;
    } else if (wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_OR_HOTSPOT;
    } else if (wifiInfrastructure && !wifiHotSpot && !wifiSimultaneous) {
      return dspConstants.Worklist.WIFI_CLIENT;
    }

    return '';
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  getSupportedServices() {
    const supportedServicesPayload = { guiID: this.assetData?.appId || '' };
    this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
      next: (result: any) => {
        let data: any[] = [];
        if (result?.length > 0) {
          this.assetData.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
          result?.forEach((val: any) => {
            if (this.featureSupportMapping && this.featureSupportMapping[val]) {
              data = data.concat(this.featureSupportMapping[val]);
            }
          });
        }
        this.assetData.getSupportedServicesList = data;
        this.isWifiConfigurationNeeded();
      },
      error: (err: any) => {
        this.isWifiConfigurationNeeded();
      },
    });
  }
  dismissAsset() {
    const dismissAssetPayload = {
      radioSharingConfirmation: null,
      dealer: this.dealer?.dealerCode,
      id: this.assetData.make + '|' + this.assetData.serialNumber,
      Selected_QueueReason: this.selectedQueueReason,
      version: 'v2',
    };

    this.needsReviewService.dismissAsset(dismissAssetPayload).subscribe({
      next: (result: any) => {
        this.setDataLayer('Dismiss', 'success', '');
        if (result?.status == 'Success') {
          const messageText = 'Asset dismissed';
          this.showToastMessage(messageText, 'success');
          setTimeout(() => {
            const evt = { status: true, newCount: this.queueReasonNewCount };
            this.needsReviewService.dismissStatus.next(evt);
          }, 500);
        }
      },
      error: (err: any) => {
        const messageText = 'There was an error processing your request.Please contact Cat Digital Support';
        this.setDataLayer('Dismiss', 'failed', messageText);
        this.showToastMessage(messageText, 'error');
      },
    });
  }
  approveRequest() {
    if (this.assetInfo?.status && this.assetInfo?.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.assetInfo, this.queueReasonId);
    }

    this.managePendingRequestRef = this.drawerService.openDrawer<ManagePendingRequestComponent, CcDrawerConfig>(
      ManagePendingRequestComponent,
      {
        data: {
          type: 'LANDING',
          selectedAssetDetails: this.assetData ? this.assetData : {},
          isApproved: true,
        },
        enableBlackBackDrop: true,
        closeOnEsc: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );

    this.managePendingRequestRef?.afterClosed()?.subscribe((response: any) => {
      if (response) {
        //to refresh needs review left tab
        this.needsReviewService.refreshNeedsReviewCategories.next(response);
        // to refresh table
        this.needsReviewService.refreshNeedsReviewTable.next(response);
      }
    });
  }

  showManagePlanIcon() {
    if (
      this.assetInfo?.reportingState !== this.dspWorklistConstants.SUSPENDED &&
      !this.assetInfo?.isMaskRequired &&
      this.userActiveFeatures?.features?.indexOf('Subscription Management') > -1
    ) {
      if (
        this.userActiveFeatures?.features?.indexOf('View Only') === -1 &&
        this.userActiveFeatures?.features?.indexOf('Read Only') === -1 &&
        this.userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') === -1
      ) {
        this.showManageIcon = true;
      } else if (
        this.userActiveFeatures?.features?.indexOf('View Only') > -1 ||
        this.userActiveFeatures?.features?.indexOf('Read Only') > -1 ||
        this.userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1
      ) {
        this.showManageIcon = false;
      }
    }
  }

  disableActionMenu() {
    if (
      !this.checkPIDIsApplicable(this.assetInfo) &&
      !this.showDeviceStatusIcon &&
      !this.showConfigureWifiIcon &&
      this.assetInfo?.status !== this.dspNeedsReviewConstants.Active &&
      this.assetInfo?.status !== this.dspNeedsReviewConstants.New &&
      this.showManageIcon
    ) {
      this.disableMenu = true;
    }
  }
}
