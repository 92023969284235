/* eslint-disable no-case-declarations */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  isObject,
  isEmpty,
  cloneDeep,
  isArray,
  map,
  groupBy,
  first,
  size,
  hasIn,
  findIndex,
  isEqual,
  debounce,
  uniqueId,
  includes,
} from 'lodash-es';
import { DSPAppState } from '../../../../store/state/dsp.state';
import * as dspConstants from '../../../../shared/dspConstants';
import { DspCommonService } from '../../../../services/dsp-common.service';
import { AssetDrawerService } from '../../../..//services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { DateFormattingService } from '../../../../services/date-formatting.service';
import { CommunicationModeService } from '../../../../services/communication-mode.service';
import { SubscriptionChangeService } from '../../../../services/subscription-change.service';
import { CatBillingService } from '../../../../services/catBillingService';
import { LoaderService } from '../../../../services/loader.service';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import {
  ApplicablePromoParam,
  BasePlanList,
  OptionalPlanList,
  MultiLevelPlans,
  ProductFamilyList,
  ApplicableSubscriptionsType,
  MineStarSiteSearchResultsType,
  AssetSubscriptionFormDataType,
  ProfileBasedAdditionalServices,
  SubscriptionData,
  MineStarSiteData,
  AssetSubscription,
} from '../../../../models/assets.interface';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { Subscription } from 'rxjs';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { resolve } from 'path';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-select-plan-menu',
  templateUrl: './select-plan-menu.component.html',
  styleUrls: ['./select-plan-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectPlanMenuComponent implements OnDestroy {
  @ViewChild('b2cCustWarningTemplate') b2cCustWarningTemplate!: TemplateRef<any>;
  @ViewChild('catGradeConnectivityPopup') catGradeConnectivityPopup!: TemplateRef<any>;
  @ViewChild('selectPlanpopUp') selectPlanpopUps!: TemplateRef<any>;
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: any;
  @Output() redirectToSite = new EventEmitter();
  @Output() loadCancelScreen = new EventEmitter();
  @Output() updateTrackerIndexFor = new EventEmitter();
  @Output() updateTrackerIndex = new EventEmitter();
  @Input() productData: any;
  @Input() productOrderList: any;
  infoMessageManageAsset = `Telematics hardware must have current firmware and configurations to function properly. <a href="https://catdealer.com/en/bt/digital-business-strategy/digital-vision/dealer-services-portal/service-technicians-toolbox.html#tabs-ff82e23862-item-612755e470-tab" target="_blank" style="color:#2595FF; text-decoration:none; font-weight:400">More info</a>`;
  catGradeConnectivityMessage =
    'Grade Connectivity enables a cellular data stream for certain Grade functionality. Be aware that other subscriptions or registrations may be necessary for desired functionality. Grade Connectivity enables wireless streaming of Virtual Reference Station (VRS) Global Connectivity Satellite System (GNSS) corrections from a credentialed IP or web address for achieving a Real Time Kinematic fixed position, streaming of GNSS corrections from an Internet Based Satellite System (IBSS) associated with a registered Trimble Connected Community (TCC) account and asset, or transfer of files to and from a registered TCC asset.';
  manageAssetSubscriptionForm: FormGroup = this.formBuilder.group({
    products: this.formBuilder.array([]),
  });
  currentAssetSubscription!: FormGroup;
  selectSiteForm!: FormGroup;
  listOfService: any;
  catalogMapping: any;
  addMorePlanClicked = false;
  singleLevelServicesList: any = [];
  multiLevelServiceList: any = [];
  isSiteFound: any = [];
  list!: any[];
  response1: any = [];
  response2: any = [];
  editAccordianCountWithoutChanges = 0;
  isCancelView = false;
  listOfProductFamily: Array<ProductFamilyList> = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;
  multiLevelPlans: MultiLevelPlans = {
    customer: [],
    dealer: [],
    cat: [],
  };
  subs: AssetSubscription = {
    subsTitle: '',
    catLevel: {},
    dealerLevel: {},
    selectedCategory: '',
    dealerCancelReason: '',
    siteInfo: {},
    promoInfo: {
      promoValue: [],
      showPromoValue: true,
      showPromoLoad: false,
    },
    templateCheck: {
      custSubs: {
        disableServiceCheck: false,
      },
      dealerSubs: {
        disableServiceCheck: false,
      },
      catSubs: {
        disableServiceCheck: false,
      },
      addOns: {
        disableBtnCheck: false,
        disableAddOnListCheck: false,
      },

      prepayDisableCheck: false,
      prepayDisplayCheck: false,
      promotionDisplayCheck: false,
      contractDisplayCheck: true,
      showCatRfvWarning: false,
      serviceName: '',
    },
    prepayEligible: false,
    prepaySelected: false,
    activeContract: false,
  };
  lastSelectedBaseAndOption: any = {
    planName: '',
    basePlan: '',
    applicationName: '',
    type: '',
    optionalPlanList: {},
    optionalPlanChecked: '',
    isReset: false,
    optionalPlan: '',
  };
  customerPlan = 'None';
  dealerPlan = 'None';
  catPlan = 'None';
  lastOpenedAccordian: any;
  isPrepay: any;
  selectedAddOnOptions: Array<any> = [];
  subsInfo: any;
  profileBasedAdditionalServices: ProfileBasedAdditionalServices = {
    optionList: {},
    selectedCount: 0,
    optionListPrev: {},
    chkbxValChng: false,
  };
  addOnOptions: any;
  selectedService: any;
  productFamily: any = {
    selectedProductFamily: 'None',
    productFamilyList: [{ subscription: '', productFamily: [] }],
    selectedAddOnProductFamilyList: [],
  };
  modifiedProductFamily = true;
  inheritanceFlag = {
    dealerInherited: false,
    catInherited: false,
  };
  initialAddonSubscribed = [];
  isRatePlanExist = true;
  subsGradeChangeStatus = {
    Upgraded: 'Upgraded',
    Downgraded: 'Downgraded',
    NoChange: '',
  };
  subscriptionLevelCategory = {
    selectedCategory: '',
  };
  subsCategory: any;
  addmorePlanSuccessOrFailureMessage: any = [];
  featureSupportedMapping: any;
  serviceMapping: any;
  dspConfig: any;
  userActiveFeatures: any;
  userPreferences: any;
  cvaEnabledList: any;
  isBackPrevious = false;
  featureSupportMsgEnabledList: any;
  applicableAddlSubscriptions: any;
  mockApplicableAddlSubscription: any;
  isNonBillableCust: any;
  subsPricingEligible!: boolean;
  RFEligibleList = [
    'Software Update Recommended',
    'Remote Software Download Failed',
    'Software Restore Failed',
    'Remote Flash Failed',
    'Telematics Update Recommended',
    'Equipment Updates Recommended',
    'Remote Flash Recommended for Equipment',
    'Remote Flash Recommended for Telematics',
  ];
  applicableSubscriptions: ApplicableSubscriptionsType = { level1: [], level2: [], level3: [] };
  primarySubscriptionService: any;
  telUserType: any;
  dealer: any;
  b2cCustomers: any;
  isContractPeriodChanged!: boolean;
  supportedServicesForAsset: any;
  isFsmSupportService: any;
  isFsmsupporteBaseSub: any;
  greyOutPlan: any = {
    addon: [],
    base: [],
  };
  showOtoMessage = false;
  otoMessage: any;
  isDspAppMock: any;
  promo: any = { promoParams: {}, promoRetrieved: true };
  deviceFeaturesInfo: any;
  languageTranslations: any;
  changedServices: any;
  featuresToggleMapping: any;
  isNoticeOk: any = false;
  ccsEnabled: any;
  lastselectedAddmorePlan: any;
  makeCodeSrch: any;
  ccsSubscription: any;
  ccsAlertFlag: any;
  selectedBillingAccount: any;
  getDynamicTrueAddon: any = {};
  commModes = ['Dual Mode', 'Satellite - 4 Hours', 'Cellular- Hourly'];
  dualModeInfo: any = {
    isDualModeSub: false,
    commModeSelected: this.commModes[0],
    isCommModeDisabledForDealer: true,
  };
  orderByFld = 'grade';
  selectedDate = new Date();
  newApplicationIntroduceByOtherAppication: any = {};
  currentYear = this.selectedDate.getFullYear();
  currentMonth = this.selectedDate.getMonth();
  currentDate = this.selectedDate.getDate();
  apllicationNameAddMorePlan: any;
  dateFormat = { value: 'mmm dd, yyyy', label: 'mmm dd, yyyy' };
  dateFieldConfig = {
    disabled: false,
    labelValue: '',
    width: '170px',
    isLeading: true,
    isTrailing: false,
    ariaDescribedBy: 'Select Date',
    required: true,
  };
  minDate = new Date(this.currentYear, this.currentMonth, this.currentDate - 9);
  maxDate = new Date(this.currentYear + 1, this.currentMonth, this.currentDate);
  datepickerStatus = { isValidDate: true, isValidFormat: true };
  contractStartDate = new Date();
  contractEndDate = new Date();
  setPrepay = false;
  addmorePlanPayload: any;
  siteName = '';
  siteValue = '';
  enableSearchOverlay = true;
  listOfDynamicPricingArray: any = {};
  siteResults: MineStarSiteSearchResultsType[] = [];
  isListItemSelected = false;
  siteNotFound = false;
  showPromotion = false;
  isWarningMsgDisplayed!: any;
  isSuperAdmin!: any;
  featuresToggleMap!: any;
  previousCompatible: any = {
    addmoreButtonRetain: '',
    manageAssetForm: '',
    listOfProducts: '',
    listOfBasePlan: '',
    selectedAssetFormData: '',
    listOfOptionalPlan: '',
    siteName: '',
    optinalPlanList: [],
    minstarSiteNameDisabled: {},
  };
  billingAccounts: any[] = [];
  pFamilyList: any;
  PFamilyListData: any;
  cwsId!: any;
  listOfPlans: any;
  selectedBasePlanValues: any;
  selecedPlanId: any;
  minstarSiteNameDisabled: any = {};
  Id: any;
  selectPlanpopUpRef: any;
  setZeroPricingDetails: any = {
    isBasePlan: false,
    product: '',
  };
  Name: any;
  planId: any[] = [];
  dspConstants!: any;
  loadingPaymentPreview: any;
  isResetService = false;
  startDateFieldConfig!: any;
  endDateFieldConfig!: any;
  disableForm: boolean = false;
  multiYearConfig = { yearsPerPage: 9, previousYearCount: 3 };
  currentServices: any[] = [];
  isOpen = true;
  catGradeModalRef: any;
  isSiteNameLoading = false;
  previousSiteSearch = '';
  isProductFamilyActive = false;
  promotionAPICallData!: Subscription;
  isPromotionAPICallCompleted = false;
  showCATGradePopup = false;
  enableAutoSelectAddon: any = [];
  autoSelectItem!: OptionalPlanList[];
  listOfOptionalPlansToShow: any;
  fetchedAutoAddons = false;
  showB2CWarningPopupInPaymentPreview = false;
  listOfProducts: any;
  listOfSubscribed: any[] = [];
  listOfBasePlans!: any;
  enableAddMoreBtn = true;
  listOfOptionalPlans: any = {};
  planInfo: any;
  selectedBasePlan: any = {};
  applicablePlansDetails: any[] = [];
  applicableAddOnPlansDetails: any[] = [];
  isAddMorePlansEnable = false;
  subsData: any = {};
  dataAddMore: any = {};
  promoLoader: any = {};
  selectedBaseInfo: any;
  planNum: any = [];
  value: any;
  isEditFlowFirstCall = false;
  onLoadApplicablePlanForEditToLoadList: any = [];
  compatipleMessage: any;
  successOrFailureMessages: any;
  addmoreButtonRetain: any = {
    selectedProduct: [],
    selectedBasePlanID: {},
    selectedOptionalPlanID: {},
  };
  isCurrentAddOnEmpty = false;
  selectPlanpopUpRefs: any[] = [];
  catOnlyAddons: any;
  isNumberConventionEnabled: any;
  dspStoreData: any;
  showCompatiblePopUp = false;
  seeMoreResponse: any[] = [];
  dspHelpUrl = '';
  isChinaDealer = true;
  datUrl = '';
  datExcludedProducts: any = [];
  isdatExcludedProductCheck = false;
  selectedDigitalAuthDetails: any;
  showPlans = false;

  constructor(
    public formBuilder: FormBuilder,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private assetDrawerService: AssetDrawerService,
    private dateFormattingService: DateFormattingService,
    private assetService: AssetService,
    private commModeFactoryService: CommunicationModeService,
    public subscriptionChangeService: SubscriptionChangeService,
    private messageBar: MessageBar,
    private catBillingService: CatBillingService,
    private router: Router,
    private modal: CcModal,
    private loaderService: LoaderService,
    public changeDetector: ChangeDetectorRef,
    public dspUtilsService: DspUtilsCommonService,
    private cdr: ChangeDetectorRef
  ) {
    this.startDateFieldConfig = {
      disabled: true,
      labelValue: 'Select Date',
      width: '180px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Select Date',
      required: false,
    };
    this.endDateFieldConfig = {
      disabled: false,
      labelValue: 'Select Date',
      width: '180px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Select Date',
      required: false,
    };
  }
  /**
   *
   */
  ngOnInit(): void {
    this.assetDrawerService.datExcludedProducts.subscribe((data: any) => {
      this.datExcludedProducts = data;
    });
    this.updateTime();
    this.dspHelpUrl =
      environment.redirectURL.helpDocUrl +
      'article/How-do-I-subscribe-multiple-products-on-the-same-asset?language=en_US';
    this.selectedAssetSubscriptionFormData.isValidForm = false; // Need to update based on Next and Previous actions.
    const { reportingState } = this.rowData;
    this.rowData.cvaInfo = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails;
    const { customer } = this.selectedAssetSubscriptionFormData;
    const selectedOwnership = this.selectedAssetSubscriptionFormData.customer.ownershipId;
    this.assetService.isCancelAll.subscribe((response: any) => {
      if (response) {
        // this.setNoneOnCancel();
        this.updateMultiProductTriggerVal();
      }
    });
    if (reportingState === 'Subscribed' && !isEmpty(customer)) {
      this.updateTrackerIndex.emit();
      this.checkDatStatus();
    }
    this.selectedDigitalAuthDetails = this.getDigitalAUthorizationDetails(
      this.rowData?.ownershipDetails?.length > 0 && this.rowData?.ownershipDetails,
      selectedOwnership
    );
    if (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE') {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
      this.showDATToastMessage(messageText, 'error', linkDetail);
    }
    // if (
    //   this.rowData?.ownershipDetails?.length === 1 &&
    //   this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE'
    // ) {
    //   const linkDetail = [
    //     {
    //       label: 'Digital Authorization Tool',
    //       fn: () => {
    //         window.open(this.datUrl);
    //       },
    //     },
    //   ];
    //   const messageText =
    //     'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
    //   this.showDATToastMessage(messageText, 'error', linkDetail);
    // }
    this.updateTrackerIndexFor.emit(1);
    this.manageAssetSubscriptionForm = this.formBuilder.group({
      products: this.formBuilder.array([]),
    });
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
      this.cwsId = user.user?.cwsId || '';
      this.isSuperAdmin = user.user?.superAdministratorUser || '';
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.catOnlyAddons = JSON.parse(dsp.dspConfig?.['CAT_Only_Addon']) || [];
      this.singleLevelServicesList = JSON.parse(dsp.dspConfig?.['Single_Level_Profile_AddOn_FSM']) || [];
      this.multiLevelServiceList = [];
      this.catalogMapping = dsp.deviceCatalog || {};
      this.featureSupportedMapping = dsp.featureSupportMapping || {};
      this.serviceMapping = dsp.serviceMapping || {};
      this.dspConfig = dsp.dspConfig || {};
      this.userActiveFeatures = dsp.userActiveFeatures?.features || {};
      this.enableAutoSelectAddon = JSON.parse(dsp.dspConfig?.['enableAutoAddon']) || [];
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo || {};
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.languageTranslations = dsp.languageTranslations || {};
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.billingAccounts = dsp.billingAccounts || [];
      this.userPreferences = dsp.userActiveFeatures?.userPreferences;
      this.pFamilyList = dsp.productFamily || {};
      delete this.pFamilyList.type;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.PFamilyListData = Object.keys(this.pFamilyList).map((key) => this.pFamilyList[key]);
      this.showB2CWarningPopupInPaymentPreview = dsp?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
        ? dsp?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
        : false;
      this.isdatExcludedProductCheck = dsp?.featureFlagInfo?.datExcludedProductCheck
        ? dsp?.featureFlagInfo?.datExcludedProductCheck
        : false;
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.dspConstants = dspConstants;
    this.subsCategory = {
      //for subscription levels/categories
      Single_Level_Minestar: [dspConstants.Worklist.MINESTAR],
      Single_Level_Ecalegacy: [dspConstants.Worklist.ECA_LEGACY],
      Subscription_Remoteflash: [dspConstants.Worklist.REMOTE_FLASH],
      Subscription_Remotetroubleshoot: [dspConstants.Worklist.REMOTE_TROUBLESHOOT],
      Single_Level_Profile_AddOn_FSM: this.singleLevelServicesList,
      Multi_Level_Profile_AddOn_FSM: this.multiLevelServiceList,
    };
    this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    // if (!isEmpty(this.rowData.deviceType)) {
    //   const validateOnRes = () => {
    //     this.validateServiceOnLoad();
    //   };
    //   this.getSupportedServicesFn(validateOnRes);
    // }
    // this.setAssetFormData();
    // this.assetSubscriptionFormChange();
    // this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.setformData();
    const data = this.assetService.getListOfSubsData();
    if (
      (this.assetService.getListOfSubsData() || this.assetService.getListOfProduct()) &&
      this.assetService.listOfAddmorePlanDetails
    ) {
      for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
        let valueData: any = value;
        if (valueData.subscription.currentSubscription.customerLevel) {
          this.selectedBasePlan[key] = valueData.subscription.currentSubscription.customerLevel;
        }
      }
      this.listOfProducts = this.assetService.getListOfProduct().listOfProducts;
      this.listOfBasePlans = this.assetService.getListOfProduct().listOfBasePlans;
      this.listOfOptionalPlans = this.assetService.getListOfProduct().listOfOptionalPlans;
      this.listOfSubscribed = this.assetService.getListOfProduct().listOfSubscribed;
      this.greyOutPlan = this.assetService.getListOfProduct().listOfGreyOut;
      this.subsData = this.assetService.getListOfSubsData();
      this.minstarSiteNameDisabled = this.assetService.minstarSiteNameDisabled;
      this.listOfDynamicPricingArray = this.assetService.listOfPricingDetails.priceDetails;
      this.onLoadApplicablePlanForEditToLoadList = this.assetService.listOfPricingDetails.onloadData;
      this.manageAssetSubscriptionForm = this.assetService.getManageAssetForm();
      this.newApplicationIntroduceByOtherAppication = this.assetService.listOfPricingDetails
        .newApplicationIntroduceByOtherAppication
        ? this.assetService.listOfPricingDetails.newApplicationIntroduceByOtherAppication
        : {};
      this.selectedAssetSubscriptionFormData.isValidForm = true;
      this.addmoreButtonRetain = this.assetService.listOfAddmorePlanDetails;
      this.siteName = this.subsData.MineStar.siteInfo?.siteVal?.name;
      this.showOtoMessage =
        this.subsData[dspConstants.Worklist.NEW_VISION_LINK]?.isOtoMsgEnabled ??
        this.subsData[dspConstants.Worklist.VISION_LINK]?.isOtoMsgEnabled ??
        false;
      this.otoMessage = this.showOtoMessage
        ? this.subsData[dspConstants.Worklist.NEW_VISION_LINK]?.otoMessage
        : (this.subsData[dspConstants.Worklist.VISION_LINK]?.otoMessage ?? '');
      if (this.assetService.selectPlanMenuDetails?.addMorePlanClicked) {
        this.addMorePlanClicked = this.assetService.selectPlanMenuDetails.addMorePlanClicked;
      }
      this.getPlanId();
      this.assignSiteName();
      this.isBackPrevious = true;
    } else {
      this.getApplicableSubscriptions();
    }
    this.setFormAllValue();
    this.getDealerParentAndChildfeatures();
  }

  getDigitalAUthorizationDetails(ownershipArr: any, selectedOwnershipId: any) {
    if (ownershipArr && ownershipArr.length > 0) {
      const selectedItem = ownershipArr?.find((item: any) => item.ownershipId === selectedOwnershipId);
      return selectedItem ? selectedItem?.digitalAuthorizationDetails : null;
    }
  }
  /**
   * Fetches and updates the last updated timestamp for the asset.
   *
   * This method calls the assetService to retrieve the latest timestamp
   * for the asset identified by rowData.assetId.
   */
  updateTime() {
    this.assetService.getUpdatedTimeStamp(this.rowData?.assetId).subscribe((res: any) => {
      this.assetService.lastUpdatedTime = res.updatedTimeStampForUi;
    });
  }
  /**
   * Assigns the site name from the selected asset subscription form data.
   *
   * This method iterates through the products in selectedAssetSubscriptionFormData.
   * If a product's key matches the MineStar constant, it checks for a valid
   * siteName in the selectedSiteInfo object. If found, it assigns the siteName
   * to the class property and calls setMineStarSiteNameOnInit to initialize it.
   */
  assignSiteName() {
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let valueData: any = value;
      if (key == dspConstants.Worklist.MINESTAR && valueData?.selectedSiteInfo?.siteName) {
        this.siteName = valueData?.selectedSiteInfo?.siteName;
        this.setMineStarSiteNameOnInit();
      }
    }
  }
  /**
   * Iterates through the selected asset subscription form data and populates the form array.
   *
   * This method retrieves products from the selectedAssetSubscriptionFormData object.
   * For each product, it calls the pushFormArray method, passing the product's key
   * to create a corresponding form group in the product array.
   */
  setformData() {
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let valueData: any = value;
      this.pushFormArray(key);
    }
  }
  /**
   * Adds a new form group to the product array.
   *
   * @param {any} key - The application name to be associated with the new form group.
   *
   * This method creates a form group with the following controls:
   * - customerPlan: An empty string field.
   * - dealerPlan: An empty string field.
   * - catPlan: An empty string field.
   * - applicationName: The key provided as a parameter.
   * - addOnOptions: An empty string field.
   * - selectedProductFamily: An empty string field.
   * - mineStarSiteName: A string field that requires a minimum length of 3 characters.
   */
  pushFormArray(key: any) {
    this.product?.push(
      this.formBuilder.group({
        customerPlan: [''],
        dealerPlan: [''],
        catPlan: [''],
        applicationName: key,
        addOnOptions: [''],
        selectedProductFamily: [''],
        mineStarSiteName: ['', [Validators.minLength(3)]],
      })
    );
  }
  /**
   * Retrieves the 'products' form array from the manageAssetSubscriptionForm.
   *
   * This getter method returns the 'products' control as a FormArray, allowing
   * access to the array of form groups associated with the asset subscription.
   * It uses optional chaining to safely handle cases where the form may not be defined.
   */
  get product() {
    return this.manageAssetSubscriptionForm?.get('products') as FormArray;
  }
  /**
   * Calls the getApplicableSubscriptions API to fetch applicable subscription plans for the asset.
   *
   * This method constructs an assetData object containing relevant details such as make,
   * serial number, device type, dealer code, and various asset identifiers. It then makes
   * an API call to fetch applicable subscriptions. The response is processed to update
   * the local state, including the list of applicable plans and products.
   *
   */
  getApplicableSubscriptions() {
    this.showPlans = true;
    let assetData = {
      make: this.rowData.make,
      serialNumber: this.rowData.serialNumber,
      planIds: [],
      includePricing: true,
      deviceType: this.rowData?.deviceType === null ? 'NO_DEVICE' : this.rowData?.deviceType,
      dealerCode: this.dealer?.dealerCode,
      assetId: this.rowData?.assetId,
      assetGuiId: this.rowData.appId,
      isCVAAvailable:
        this.selectedAssetSubscriptionFormData.customer?.cvaDetails?.cvaStatus == 'Not Available' ? false : true,
    };
    this.assetDrawerService.getApplicableSubscriptions(assetData).subscribe({
      next: (result: any) => {
        this.onLoadApplicablePlanForEditToLoadList = cloneDeep(result);
        this.assetService.listOfPricingDetails['onloadData'] = this.onLoadApplicablePlanForEditToLoadList;
        this.listOfProducts = [];
        this.planInfo = result;
        let updatedObjectOld: any = {};
        for (const [key, value] of Object.entries(this.planInfo?.applicableProductsDTOMap)) {
          let valueData: any = value;
          if (this.selectedAssetSubscriptionFormData.products.hasOwnProperty(valueData.applicationName)) {
            updatedObjectOld[valueData.applicationName] =
              this.selectedAssetSubscriptionFormData.products[valueData.applicationName];
          }
        }
        this.selectedAssetSubscriptionFormData.products = updatedObjectOld;
        const dataValue = this.manageAssetSubscriptionForm?.get('products') as FormArray;
        for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
          let valueData: any = value;
          if (!dataValue.controls.some((item: any) => item.value.applicationName === key)) {
            this.pushFormArray(key);
          }
        }
        for (const [key, value] of Object.entries(this.planInfo?.applicableProductsDTOMap)) {
          let valueData: any = value;
          if (valueData.applicablePlansDTOs) {
            this.listOfProducts.push(value);
            this.listOfProducts.forEach((item: any) => {
              item['isSubscribed'] = false;
              item['expand'] = false;
            });

            if (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE') {
              this.listOfProducts.forEach((item: any) => {
                if (this.datExcludedProducts.includes(item.applicationName)) {
                  item['disabled'] = false;
                } else {
                  item['disabled'] = true;
                }
              });
            }
            this.selectedAssetSubscriptionFormData.products[valueData.applicationName].appId = valueData.applicationId;
            this.subsData[valueData['applicationName']] = cloneDeep(this.subs);
            this.subsData[valueData['applicationName']]['serviceName'] = valueData.applicationName;
            this.promoLoader[valueData['applicationName']] = false;
          }
        }
        this.removeCatOnlyAddon();
        if (this.rowData.reportingState !== 'Subscribed') {
          this.addNoneInEditFlow();
          this.showPlans = false;
        } else {
          this.editFlowSubscriptions();
        }
        if (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE') {
          this.ListOfproductOrder();
        }
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  /**
   * Removes CAT-only add-ons from the list of applicable plans for non-CAT users.
   *
   * This method checks the user type against the CAT and CATLEVEL constants. If the user
   * is not a CAT user, it iterates through the list of products. For each product, it
   * examines the applicable plans and filters out any add-on plans that are specific to
   * CAT users, ensuring that only relevant add-ons remain.
   *
   * Key operations include:
   * - Checking if the user type is not CAT or CATLEVEL.
   * - Iterating through each product's applicable plans.
   * - Filtering out unwanted CAT-only add-on plans from each base plan's add-on list.
   */
  removeCatOnlyAddon() {
    if (this.telUserType !== dspConstants.Common.CAT && this.telUserType !== dspConstants.Worklist.CATLEVEL) {
      this.listOfProducts?.forEach((productData: any) => {
        if (productData?.applicablePlansDTOs?.length > 0) {
          productData.applicablePlansDTOs.forEach((basePlan: any) => {
            if (basePlan?.addonApplicablePlansDTOs?.length > 0) {
              basePlan.addonApplicablePlansDTOs = basePlan.addonApplicablePlansDTOs.filter((optionalPlan: any) => {
                return !this.catOnlyAddons.includes(optionalPlan.planName);
              });
            }
          });
        }
      });
    }
  }
  /**
   * Edits and updates the subscription plans for the asset based on the current data.
   *
   * This method retrieves the subscribed plans for the asset using the asset's make
   * and serial number. It processes the response to update the local state and
   * manage various properties related to the subscriptions.
   *
   * Key operations include:
   * - Fetching the subscribed plans from the asset service.
   * - Populating the list of subscribed services and marking them as subscribed.
   * - Updating the selected asset subscription form data with previous and current
   *   subscription details, including optional plans and prepay information.
   * - Handling specific conditions for various application names, such as NEW_VISION_LINK
   *   and MINESTAR, to store additional site and contract information.
   * - Updating the asset service with the latest subscription data and managing
   *   the list of products and plans.
   *
   * On completion, it calls addMorePlan to potentially add more plans to the subscription
   * and updates the asset service with the latest state.
   */
  editFlowSubscriptions() {
    const object = {
      make: this.rowData.make,
      serialNumber: this.rowData.serialNumber,
    };
    this.assetService.getAssetDataSubscribedPlansEdit(object).subscribe((data: any) => {
      this.seeMoreResponse = data.servicesSubscribedDTO;
      data.servicesSubscribedDTO.forEach((item: any) => {
        let subscribedPlan = this.telUserType == 'CAT' ? item?.catLevel : item?.dealerLevel;
        subscribedPlan.forEach((subscribedPlanItem: any) => {
          let basePlan: any;
          basePlan = subscribedPlan.filter((basePlan: any) => basePlan?.type == 'Base')[0]?.planName;
          const isApplicable = this.listOfProducts.some(
            (product: { applicationName: any }) => product.applicationName === item.appName
          );

          if (!isApplicable) {
            this.appendGreyedOutProduct(
              item,
              subscribedPlan.filter((basePlan: any) => basePlan?.type == 'Base')[0],
              this.listOfProducts
            );
          }
          this.listOfProducts.filter((product: any) => {
            if (item?.appName == product?.applicationName) {
              let isBasePlanExist: any;
              product?.applicablePlansDTOs.filter((applicablePlansDTO: any) => {
                if (applicablePlansDTO.planName == basePlan) {
                  isBasePlanExist = true;
                }
              });
              if (!isBasePlanExist) {
                this.appendGreyedOutPlan(
                  subscribedPlan.filter((basePlan: any) => basePlan?.type == 'Base')[0],
                  product?.applicablePlansDTOs
                );
              }
            }
          });
          if (
            subscribedPlanItem?.type === 'AddOn' ||
            subscribedPlanItem?.type === 'Addon' ||
            (subscribedPlanItem?.planName && !subscribedPlanItem?.type)
          ) {
            let isExists = 0;
            this.listOfProducts.filter((product: any) => {
              if (item?.appName == product?.applicationName) {
                product?.applicablePlansDTOs.filter((plan: any, index: any) => {
                  if (basePlan && plan?.planName == basePlan && plan?.addonApplicablePlansDTOs?.length > 0) {
                    plan?.addonApplicablePlansDTOs?.filter((addon: any, index: any) => {
                      if (addon?.planName === subscribedPlanItem?.planName) {
                        isExists++;
                        if (addon?.productDisabled) {
                          this.greyOutPlan?.addon?.push(subscribedPlanItem?.planName);
                        }
                      }
                      if (index == plan?.addonApplicablePlansDTOs?.length - 1 && isExists == 0) {
                        this.appendGreyedOutPlan(subscribedPlanItem, plan);
                      }
                    });
                  } else if (basePlan && plan?.planName == basePlan && plan?.addonApplicablePlansDTOs?.length == 0) {
                    this.appendGreyedOutPlan(subscribedPlanItem, plan);
                  }
                });
              }
            });
          }
        });
        this.listOfSubscribed.push(
          ...this.listOfProducts.filter((object: any) => object?.applicationName === item?.appName)
        );
      });

      this.listOfProducts.forEach((item: any) => {
        if (this.listOfSubscribed.some((object: any) => object.applicationName === item.applicationName)) {
          item.isSubscribed = true;
          this.selectedAssetSubscriptionFormData.products[item.applicationName].reportingState = 'Subscribed';
        } else {
          item.isSubscribed = false;
          this.selectedAssetSubscriptionFormData.products[item.applicationName].reportingState = '';
        }
      });
      this.selectedAssetSubscriptionFormData.greyOutPlan = this.greyOutPlan;
      data.servicesSubscribedDTO.forEach((item: any) => {
        if (item.appName) {
          let CatOrDealerArray = this.telUserType == 'CAT' ? item.catLevel : item.dealerLevel;
          if (!this.selectedAssetSubscriptionFormData.products.hasOwnProperty(item.appName)) {
            this.selectedAssetSubscriptionFormData.products[item?.appName] = cloneDeep(this.productData);
            this.subsData[item?.appName] = cloneDeep(this.productData);
          }
          this.addmoreButtonRetain.selectedProduct.push(item?.appName);
          this.addmoreButtonRetain.selectedBasePlanID[item?.appName] = [
            {
              id: CatOrDealerArray.filter((item: any) => item?.type === 'Base')[0]?.planId,
              type: CatOrDealerArray.filter((item: any) => item?.type === 'Base')[0]?.type,
              planName: CatOrDealerArray.filter((item: any) => item?.type === 'Base')[0]?.planName,
            },
          ];
          this.addmoreButtonRetain.selectedOptionalPlanID[item.appName] = CatOrDealerArray.filter(
            (item: any) => item?.type === 'AddOn' || item?.type === 'Addon' || (item?.planName && !item?.type)
          ).map((item1: any) => ({ id: item1?.planId, type: item1?.type, planName: item1?.planName }));
          this.selectedAssetSubscriptionFormData.products[item?.appName].service.previousService = item?.appName;
          this.selectedAssetSubscriptionFormData.products[item?.appName].service.currentService = item?.appName;

          this.selectedAssetSubscriptionFormData.products[item.appName].subscription.previousSubscription.catLevel =
            CatOrDealerArray.filter((item: any) => item.type === 'Base')[0]?.planName;
          this.selectedAssetSubscriptionFormData.products[item.appName].subscription.previousSubscription.dealerLevel =
            CatOrDealerArray.filter((item: any) => item.type === 'Base')[0]?.planName;
          this.selectedAssetSubscriptionFormData.products[
            item.appName
          ].subscription.previousSubscription.customerLevel = CatOrDealerArray.filter(
            (item: any) => item.type === 'Base'
          )[0]?.planName;
          this.selectedAssetSubscriptionFormData.products[item.appName].addOns.previousddons = CatOrDealerArray.filter(
            (item: any) => item?.type === 'AddOn' || item?.type === 'Addon' || (item?.planName && !item?.type)
          ).map((item: any) => item.planName);
          if (
            this.selectedAssetSubscriptionFormData.products[item.appName].addOns.previousddons.some(
              (item: any) => item === dspConstants.Worklist.CAT_GRADE_CONNECTIVITY
            )
          ) {
            this.showCATGradePopup = true;
          }
          let optionalPlan: any = [];
          this.selectedAssetSubscriptionFormData.products[item.appName].addOns.previousddons.forEach((item: any) => {
            const data = {
              autoSelect: false,
              isSubscribable: true,
              label: item,
              prices: null,
              serviceName: item,
              value: item,
            };
            optionalPlan.push(data);
          });
          this.lastSelectedBaseAndOption.optionalPlanList[item.appName] = optionalPlan;

          if (
            item.appName === dspConstants.Worklist.NEW_VISION_LINK ||
            item.appName === dspConstants.Worklist.VISION_LINK
          ) {
            let startDate = item?.contractStartDate
              ? this.assetService.convertTimeStampToData(item.contractStartDate, '', true)
              : '';
            let endDate = item?.contractEndDate
              ? this.assetService.convertTimeStampToData(item.contractEndDate, '', true)
              : '';
            let prepay = item?.prepay == true ? true : false;
            this.selectedAssetSubscriptionFormData.products[item.appName].prepay.previousPrepayData = {
              contractEndDate: endDate,
              contractStartDate: startDate,
              prepaySelected: prepay,
            };
            this.selectedAssetSubscriptionFormData.products[item.appName].prepay.currentPrepayData = {
              contractEndDate: endDate,
              contractStartDate: startDate,
              prepaySelected: prepay,
            };
            this.subsData[item.appName].prepaySelected = prepay;
            this.subsData[item.appName].contractPeriod = {
              startDate: startDate,
              endDate: endDate,
              isDisabled: prepay === true ? true : false,
            };
            this.subsData[item.appName].prepayEligible = prepay === true ? true : false;
            this.subsData[item.appName].templateCheck.contractDisplayCheck = prepay === true ? true : false;
            this.subsData[item.appName].templateCheck.prepayDisableCheck = prepay === true ? true : false;
            this.subsData[item.appName].templateCheck.prepayDisplayCheck = prepay === true ? true : false;
          }

          if (item.appName === dspConstants.Worklist.MINESTAR) {
            this.selectedAssetSubscriptionFormData.products[item.appName].previousSiteInfo = {
              siteName: data?.siteName ? data?.siteName : '',
              siteId: data?.siteId ? data?.siteId : '',
            };
            this.selectedAssetSubscriptionFormData.products[item.appName].siteInfo = {
              siteId: data?.siteId ? data?.siteId : '',
              siteName: data?.siteName ? data?.siteName : '',
            };
            this.selectedAssetSubscriptionFormData.products[item.appName].productFamily.previousProductFamily =
              data?.productFamily ? data?.productFamily : '';
            this.rowData.productFamily = data?.productFamily ? data?.productFamily : '';
            this.subsData[item.appName].siteInfo.isValidSite = true;
            this.subsData[item.appName].siteInfo.siteId = data?.siteId ? data?.siteId : '';
            this.subsData[item.appName].siteInfo.siteVal = {
              name: data?.siteName ? data?.siteName : '',
            };
          }

          this.selectedBasePlan[item.appName] =
            this.selectedAssetSubscriptionFormData.products[
              item.appName
            ].subscription.previousSubscription.customerLevel;
          this.minstarSiteNameDisabled[item.appName] =
            this.selectedAssetSubscriptionFormData.products[
              item.appName
            ].subscription.previousSubscription.customerLevel;
        }
      });
      // this.addMorePlan(true);
      this.assetService.minstarSiteNameDisabled = this.minstarSiteNameDisabled;
      this.assetService.setListOfSubsData(this.subsData);
      this.assetService.setManageAssetForm(this.manageAssetSubscriptionForm);
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      this.assetService.setListOfProduct({
        listOfProducts: this.listOfProducts,
        listOfBasePlans: this.listOfBasePlans,
        listOfOptionalPlans: this.listOfOptionalPlans,
        listOfSubscribed: this.listOfSubscribed,
        listOfGreyOut: this.greyOutPlan,
      });
      this.assetService.listOfAddmorePlanDetails = this.addmoreButtonRetain;
      this.ListOfproductOrder();
      this.showPlans = false;
    });
  }

  appendGreyedOutProduct(subscribedProduct: any, subscribedPlan: any, products: any) {
    let productObj = {
      applicablePlansDTOs: [],
      applicationId: subscribedProduct.appId,
      applicationName: subscribedProduct.appName,
      expand: false,
      isSubscribed: true,
    };
    this.appendGreyedOutPlan(subscribedPlan, productObj.applicablePlansDTOs);
    products?.push(productObj);
    this.selectedAssetSubscriptionFormData.products[subscribedProduct.appName] = cloneDeep(this.productData);
    this.pushFormArray(subscribedProduct.appName);
    this.selectedAssetSubscriptionFormData.products[subscribedProduct.appName].appId = subscribedProduct.appId;
    this.subsData[subscribedProduct.appName] = cloneDeep(this.subs);
    this.subsData[subscribedProduct.appName]['serviceName'] = subscribedProduct.appName;
  }

  appendGreyedOutPlan(subscribedPlanItem: any, plan: any) {
    let baseAndOptinoalPlanObj = {
      addonApplicablePlansDTOs: [],
      addonPlanIds: null,
      hasDynamicPricing: false,
      isFeatureMsgSupported: false,
      isSubscribable: false,
      orderId: null,
      planId: subscribedPlanItem?.planId,
      planName: subscribedPlanItem?.planName,
      prices: null,
      productName: null,
      type: subscribedPlanItem?.type,
      disabled: true,
      productDisabled: true,
      retirePlan: !subscribedPlanItem?.isPlanSubscribable ? true : false,
    };
    if (subscribedPlanItem.type === 'Base') {
      this.greyOutPlan?.base?.push(subscribedPlanItem?.planName);
      plan?.push(baseAndOptinoalPlanObj);
    } else {
      this.greyOutPlan?.addon?.push(subscribedPlanItem?.planName);
      plan?.addonApplicablePlansDTOs?.push(baseAndOptinoalPlanObj);
    }
  }
  /**
   * Fetches dynamic pricing details for a specified application and plan type.
   *
   * @param {any} dynArray - An array of dynamic pricing subscription data.
   * @param {any} planType - The type of plan, either 'BasePlan' or 'OptionalPlan'.
   * @param {any} applicationName - The name of the application for which pricing is being fetched.
   * @returns {Promise<void>} A promise that resolves when the pricing details are fetched and processed.
   *
   * This method constructs a request to obtain dynamic pricing based on the provided
   * dynamic array and application details. It checks for valid customer information
   * and includes relevant parameters such as dealer code, UCID, product name,
   * make, serial number, and device type.
   *
   * If the CVA feature flag is enabled, it adds the CVA status to the request. It also
   * handles cases where a new application is introduced by another application,
   * adjusting the product name accordingly.
   *
   * Upon receiving a successful response, the method updates the pricing details
   * for the specified plan type:
   * - For 'BasePlan', it updates the prices of applicable base plans.
   * - For 'OptionalPlan', it updates the prices of add-on plans associated with the selected base plan.
   *
   * In case of an error during the fetch, it shows an error message and resolves the promise.
   */
  getDynamicPricing(dynArray: any, planType: any, applicationName: any) {
    const promise: Promise<void> = new Promise<void>((resolve, reject) => {
      if (dynArray?.length > 0) {
        const validCustomer = this.getValidCustomerForAsset();
        const servInputParams: any = {
          dealerCode: validCustomer?.length == 1 ? validCustomer[0].dealerCode : this.dealer?.dealerCode,
          ucid: validCustomer?.length == 1 ? validCustomer[0].ucid : '',
          productName: applicationName,
          make: this.rowData.make,
          serialNumber: this.rowData.serialNumber,
          deviceType: this.rowData.deviceType,
        };
        if (this.dspStoreData?.featureFlagInfo?.CVAEnabled === true) {
          servInputParams.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
        }
        servInputParams.subscriptions = dynArray;
        let isnewApplicationIntroByOther = false;
        if (this.newApplicationIntroduceByOtherAppication) {
          for (const [key, value] of Object.entries(this.newApplicationIntroduceByOtherAppication)) {
            let valueData: any = value;
            if (valueData?.length > 0 && valueData?.indexOf(applicationName) !== -1) {
              servInputParams.productName = key;
              isnewApplicationIntroByOther = true;
              break;
            }
          }
        }

        this.assetService.getDynamicPricingDetails(servInputParams).subscribe({
          next: (result: any) => {
            let value = result;
            if (isnewApplicationIntroByOther) {
              this.listOfDynamicPricingArray[applicationName] = result;
            }
            if (planType === 'BasePlan') {
              this.listOfProducts
                .filter((item: any) => item.applicationName === applicationName)[0]
                .applicablePlansDTOs.forEach((item1: any) => {
                  for (const [key, value] of Object.entries(result)) {
                    let valueData: any = value;
                    if (key === item1.planName) {
                      item1.prices = valueData.price + ' ' + valueData.currency;
                    }
                  }
                });
            } else if (planType === 'OptionalPlan') {
              this.listOfProducts
                .filter((item: any) => item.applicationName === applicationName)[0]
                .applicablePlansDTOs.filter(
                  (item1: any) => item1.planName === this.selectedBasePlan[applicationName]
                )[0]
                .addonApplicablePlansDTOs.forEach((item2: any) => {
                  for (const [key, value] of Object.entries(result)) {
                    let valueData: any = value;
                    if (key === item2.planName) {
                      item2.prices = valueData.price + ' ' + valueData.currency;
                    }
                  }
                });
            }
            resolve();
          },
          error: (err: any) => {
            this.showToastMessage(this.systemError, 'error');
            resolve();
          },
        });
      } else {
        resolve();
      }
    });

    return promise;
  }
  /**
   * Sets the base plan values for a specified application.
   *
   * This method retrieves and processes applicable base plans for the given application name,
   * particularly those with dynamic pricing enabled and without current prices.
   *
   * @param {any} applicationName - The name of the application for which base plans are being set.
   * @returns {Promise<void>} A promise that resolves when the base plans are successfully set.
   *
   * Key operations include:
   * - Filtering the list of products to find applicable plans with dynamic pricing.
   * - Calling `getDynamicPricing` to fetch pricing details for these plans.
   * - Based on the selected category in `subscriptionLevelCategory`, it initializes `listOfBasePlans`.
   * - For each product, it constructs an array of base plan objects, including labels, values,
   *   and pricing details. The plans are sorted based on their grade if there are multiple options.
   * - Finally, it sets the values in the form and resolves the promise.
   */
  setBasePlans(applicationName?: any) {
    const promise: Promise<void> = new Promise<void>((resolve, reject) => {
      let dynamicPriceEnabledArray = this.listOfProducts
        ?.filter((item: any) => item.applicationName === applicationName)[0]
        ?.applicablePlansDTOs?.filter((item: any) => item?.hasDynamicPricing === true && item?.prices === null)
        ?.map((item1: any) => item1?.planName);
      this.getDynamicPricing(dynamicPriceEnabledArray, 'BasePlan', applicationName).then(() => {
        const { selectedCategory } = this.subscriptionLevelCategory;
        if (
          selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR ||
          selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
        ) {
          this.listOfBasePlans = {};
          this.applicablePlansDetails = this.listOfProducts?.forEach((product: any, index: any) => {
            this.checkPriceForB2C(product.applicationName);
            let listOfBasePlanArray: any = [];
            product.applicablePlansDTOs.forEach((plan: any) => {
              listOfBasePlanArray.push({
                label: this.showBaseRatePlan(plan, product.applicationName)
                  ? plan?.planName + ' - ' + plan?.prices
                  : plan?.planName,
                value: this.showBaseRatePlan(plan, product.applicationName)
                  ? plan?.planName + ' - ' + plan?.prices
                  : plan?.planName,
                grade: plan?.order,
                isSubscribable: true,
                prices: plan?.prices,
                baseSubscription: plan?.planName,
                disabled: plan?.retirePlan ? true : false,
              });
            });
            if (listOfBasePlanArray?.length > 1) {
              listOfBasePlanArray = this.sortPlansBasedOnGrade(listOfBasePlanArray);
            }
            this.listOfBasePlans[product.applicationName] = listOfBasePlanArray;
            if (index == this.listOfProducts.length - 1) {
              this.setFormAllValue();
            }
          });

          resolve();
        }
        resolve();
      });
    });
    return promise;
  }
  /**
   * Determines whether to display the plan name along with its price or only the plan name.
   *
   * This method checks the eligibility criteria for displaying the pricing of a given plan
   * based on various conditions, such as customer level, pricing availability, and subscription
   * status.
   *
   * @param {any} plan - The details of the plan, including its name and pricing information.
   * @param {any} [product] - Optional parameter to specify the product associated with the plan.
   * @returns {boolean} Returns true if the plan name should be displayed with its price; otherwise, false.
   *
   * Key conditions for displaying the price:
   * - The subscription pricing must be eligible (`subsPricingEligible`).
   * - The customer must be non-billable (`isNonBillableCust`).
   * - The plan must have a price associated with it.
   * - The plan name must not be 'None' or match the customer's previous subscription level.
   */
  showBaseRatePlan(plan: any, product?: any) {
    let planName = plan.planName ? plan.planName : plan.baseSubscription;
    const { customerLevel } =
      this.selectedAssetSubscriptionFormData.products[product].subscription.previousSubscription;
    return this.subsPricingEligible && this.isNonBillableCust && plan?.prices && planName != 'None' ? true : false;
  }

  /**
   * Checks and sets the eligibility for subscription pricing based on user features.
   *
   * This method evaluates whether the current user is eligible for subscription pricing
   * based on their active features. It updates the `subsPricingEligible` property accordingly.
   *
   * @param {any} usrOptedService - The name of the currently expanded product/service
   *                                 (not used in the eligibility check but included for context).
   *
   * The eligibility criteria are as follows:
   * - The user must have both 'Contract Visualization' and 'Subscription Management' features active.
   * - The user must not have 'View Only', 'Read Only', or 'Manage - Limited Plans (DSP Mobile Only)' features active.
   */
  setSubsPricingEligibility(usrOptedService: any) {
    this.subsPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
      (this.userActiveFeatures?.indexOf('View Only') == -1 ||
        this.userActiveFeatures?.indexOf('Read Only') == -1 ||
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1);
  }
  /**
   * Performs a validation check for B2C pricing eligibility.
   *
   * This method checks whether the current customer qualifies as a non-billable
   * customer for a specified application. The result is stored in the `isNonBillableCust`
   * property.
   *
   * @param {any} appName - The name of the application for which B2C pricing validation is performed.
   *
   * The method retrieves valid customer information and uses the utility service
   * (`dspUtilsService`) to determine if the customer falls under the B2C category
   * based on the provided application name.
   */
  checkPriceForB2C(appName: any) {
    const customerInfo = this.getValidCustomerForAsset();
    this.isNonBillableCust = this.dspUtilsService.checkPriceForB2C(this.b2cCustomers, customerInfo, appName);
  }
  /**
   * Retrieves valid customer information for the current asset subscription.
   *
   * This method extracts the customer data from the `selectedAssetSubscriptionFormData`
   * and uses the utility service to validate and return the appropriate customer details.
   *
   * @returns {any} The valid customer information related to the current asset subscription.
   */
  getValidCustomerForAsset() {
    const { customer } = this.selectedAssetSubscriptionFormData;
    return this.dspUtilsService.getValidCustomerForAsset(customer);
  }

  /**
   * Sorts a list of base plans based on their grade values.
   *
   * This method takes an array of base plans and sorts them in ascending order
   * according to their grade property. The sorted array is then returned.
   *
   * @param {any} arr - An array containing the list of base plans to be sorted.
   * @returns {any} The sorted array of base plans.
   *
   * The sorting is performed using the `Array.prototype.sort` method, which
   * compares the `grade` property of each plan.
   */
  sortPlansBasedOnGrade(arr: any) {
    //sorting plans based on grade.
    arr?.sort((a: BasePlanList, b: BasePlanList) => {
      return a.grade - b.grade;
    });

    return arr;
  }

  /**
   * Toggles the prepay option for a specified application, performing necessary checks
   * and updates based on the user's selection.
   *
   * This method handles the logic for enabling or disabling prepay functionality.
   * It updates the subscription data and manages any associated promotions based
   * on whether the prepay option is checked or unchecked.
   *
   * @param {any} event - The event object containing details about the prepay toggle action.
   * @param {any} applicationName - The name of the application for which prepay functionality is toggled.
   *
   * Key operations include:
   * - Checking if a rate plan exists for the application.
   * - Setting default contract values.
   * - If prepay is checked:
   *   - Updating the prepay selection state and contract end date.
   *   - Fetching applicable promotions.
   * - If prepay is unchecked:
   *   - Restoring the previous selection state and managing contract period changes.
   *   - Fetching applicable promotions based on the previous state.
   */
  togglePrepay(event: any, applicationName?: any) {
    const prepayChecked = event?.checked;
    this.isRatePlanExist = this.checkIfRatePlanExists(applicationName);
    this.setContractDefaults(applicationName);
    if (prepayChecked) {
      const { contractEndDate } =
        this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData;
      this.subsData[applicationName].prepaySelected = true;
      if (contractEndDate === '') {
        this.setPrepayFormData(applicationName);
      } else {
        this.subsData[applicationName].contractPeriod.endDate = contractEndDate;
        this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData.prepaySelected = true;
        this.selectedAssetSubscriptionFormData.products[applicationName].prepay.selectedPrepayData.prepaySelected =
          true;
        this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
      this.subsData[applicationName].contractPeriod.shadowStartDate =
        this.subsData[applicationName].contractPeriod.startDate;
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(applicationName, '', '', true); // refer
      return;
    }
    if (!prepayChecked) {
      const { prepaySelected: previousPrepaySelected } =
        this.selectedAssetSubscriptionFormData.products[applicationName].prepay.previousPrepayData;
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData.prepaySelected = false;
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.selectedPrepayData.prepaySelected = false;
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      if (previousPrepaySelected) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(applicationName);
      } else {
        this.isContractPeriodChanged = false;
        this.subsData[applicationName].contractPeriod.isDisabled = false;
        this.setTemplateCondt(applicationName);
        if (this.subsData[applicationName].templateCheck.promotionDisplayCheck) {
          if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
            this.promotionAPICallData.unsubscribe();
          }
          this.getApplicablePromo(applicationName);
        } else {
          // this.triggerValChange();
        }
      }
      this.subsData[applicationName].prepaySelected = false;
      this.assetService.setListOfSubsData(this.subsData);
      this.updateMultiProductTriggerVal(applicationName);
      return;
    }
  }
  /**
   * Stores or updates the selected prepay details for a specified application.
   *
   * This method checks if the contract start and end dates are available in the
   * subscription data. If they are, it updates the current and selected prepay data
   * for the specified application in the asset subscription form.
   *
   * @param {any} applicationName - The name of the application for which prepay details are being set.
   *
   * The method performs the following actions:
   * - If both start and end dates for the contract exist, it updates the
   *   `currentPrepayData` and `selectedPrepayData` with the respective dates
   *   and marks `prepaySelected` as true.
   * - Finally, it calls `setAssetSubscriptionFormData` to persist the changes
   *   in the main subscription form data.
   */
  setPrepayFormData(applicationName?: any) {
    if (
      this.subsData[applicationName]?.contractPeriod?.endDate &&
      this.subsData[applicationName]?.contractPeriod?.startDate
    ) {
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData = {
        contractEndDate: this.subsData[applicationName].contractPeriod.endDate,
        contractStartDate: this.subsData[applicationName].contractPeriod.startDate,
        prepaySelected: true,
      };
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.selectedPrepayData = {
        contractEndDate: this.subsData[applicationName].contractPeriod.endDate,
        contractStartDate: this.subsData[applicationName].contractPeriod.startDate,
        prepaySelected: true,
      };
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Retrieves dynamic pricing details for a specified application and stores them
   * in the dynamic pricing array if they are not already present.
   *
   * This method checks if dynamic pricing details for the given application name
   * are already stored. If not, it gathers applicable plans with dynamic pricing
   * and requests their details from the asset service.
   *
   * @param {any} applicationName - The name of the application for which dynamic
   *                                 pricing details are to be retrieved.
   *
   * The method performs the following steps:
   * 1. Checks if dynamic pricing details for the application already exist.
   * 2. If not, it clones the applicable plans list and extracts plans with dynamic
   *    pricing that currently lack pricing information.
   * 3. It constructs an input parameter object containing necessary customer and
   *    product information.
   * 4. Makes a request to fetch dynamic pricing details using the constructed parameters.
   * 5. Upon receiving a successful response, it stores the results in the
   *    `listOfDynamicPricingArray`.
   */
  getStoreDynmaicPricingDetails(applicationName: any) {
    let onLoadData = [];
    // cloneDeep(this.onLoadApplicablePlanForEditToLoadList);
    if (!this.listOfDynamicPricingArray.hasOwnProperty(applicationName)) {
      for (const [key, value] of Object.entries(
        cloneDeep(this.onLoadApplicablePlanForEditToLoadList.applicableProductsDTOMap)
      )) {
        let valueData: any = value;
        onLoadData.push(valueData);
      }
      let dynamicPriceEnabledArray = [];
      dynamicPriceEnabledArray = onLoadData
        ?.filter((item: any) => item.applicationName === applicationName)[0]
        ?.applicablePlansDTOs?.filter((item: any) => item?.hasDynamicPricing === true && item?.prices === null)
        ?.map((item1: any) => item1?.planName);
      let dynamicPriceEnabledArrayOpt = [];
      dynamicPriceEnabledArrayOpt = onLoadData
        ?.filter((item: any) => item.applicationName === applicationName)[0]
        ?.applicablePlansDTOs?.filter((item1: any) => item1)[0]
        ?.addonApplicablePlansDTOs?.filter((item2: any) => item2.hasDynamicPricing === true && item2.prices === null)
        ?.map((item3: any) => item3.planName);
      let data: any = [];
      if (
        dynamicPriceEnabledArray &&
        dynamicPriceEnabledArray.length > 0 &&
        dynamicPriceEnabledArrayOpt &&
        dynamicPriceEnabledArrayOpt.length > 0
      ) {
        data = [...dynamicPriceEnabledArray, ...dynamicPriceEnabledArrayOpt];
      } else if (
        dynamicPriceEnabledArray &&
        dynamicPriceEnabledArray.length > 0 &&
        dynamicPriceEnabledArrayOpt?.length == 0
      ) {
        data = dynamicPriceEnabledArray;
      } else if (dynamicPriceEnabledArray?.length == 0 && dynamicPriceEnabledArrayOpt?.length > 0) {
        data = dynamicPriceEnabledArrayOpt;
      }

      if (data?.length > 0) {
        const validCustomer = this.getValidCustomerForAsset();
        const servInputParams: any = {
          dealerCode: validCustomer?.length == 1 ? validCustomer[0].dealerCode : this.dealer?.dealerCode,
          ucid: validCustomer?.length == 1 ? validCustomer[0].ucid : '',
          productName: applicationName,
          make: this.rowData.make,
          serialNumber: this.rowData.serialNumber,
          deviceType: this.rowData.deviceType,
        };
        if (this.dspStoreData?.featureFlagInfo?.CVAEnabled === true) {
          servInputParams.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
        }
        servInputParams.subscriptions = data;
        this.assetService.getDynamicPricingDetails(servInputParams).subscribe({
          next: (result: any) => {
            this.listOfDynamicPricingArray[applicationName] = result;
          },
        });

        // getStoreDynmaicPricingDetails
      }
    }
    this.assetService.listOfPricingDetails['priceDetails'] = this.listOfDynamicPricingArray;
  }
  /**
   * Handles changes when a service (product) is expanded in the UI, performing necessary checks
   * and updates related to the selected service.
   *
   * This method is triggered when a user expands a product. It manages UI interactions,
   * updates subscription data, and performs validations based on the selected service.
   *
   * @param {any} serviceSelected - The name of the currently expanded product/service.
   * @param {string} [id] - Optional ID of the HTML element to scroll into view.
   *
   * Key operations include:
   * - Scrolling the specified element into view if an ID is provided.
   * - Dismissing any existing messages and setting the last opened accordion to the selected service.
   * - Fetching dynamic pricing details for the selected service.
   * - Updating the current service state in the asset subscription form data.
   * - Checking for active contracts based on prepay and contract dates.
   * - Resetting various related data structures, including dynamic pricing lists and add-on plans.
   * - Validating customer eligibility for subscription pricing and B2C checks.
   * - Configuring fields based on the prepay selection status and previous subscription details.
   * - Setting base plans and handling various contract-related configurations.
   * - Displaying warnings if certain conditions are met regarding feature support.
   */
  handleServiceChange(serviceSelected: any, id?: any) {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
    this.lastOpenedAccordian = serviceSelected;
    this.messageBar.dismiss();

    this.getStoreDynmaicPricingDetails(serviceSelected);
    this.selectedService = serviceSelected;
    this.selectedAssetSubscriptionFormData.products[serviceSelected].expandedService = serviceSelected;
    this.selectedAssetSubscriptionFormData.products[serviceSelected].service.currentService = serviceSelected;
    this.selectedAssetSubscriptionFormData.products[serviceSelected].service.selectedService = serviceSelected;
    this.subsData[serviceSelected].activeContract =
      !this.rowData?.prepay && this.rowData?.contractStartDate && this.rowData?.contractEndDate ? true : false;
    this.rowData.dynamicPricingList = [];
    this.applicableAddOnPlansDetails = [];
    this.mockApplicableAddlSubscription = [];
    this.isNonBillableCust = true;
    this.isRatePlanExist = this.checkIfRatePlanExists(serviceSelected);
    this.setSubscriptionLevelCategory(serviceSelected);
    this.setSubsPricingEligibility(serviceSelected);
    this.b2cValidationCheck(false, serviceSelected);

    if (
      this.selectedAssetSubscriptionFormData.products[serviceSelected].subscription.previousSubscription
        .customerLevel === this.selectedBasePlan[serviceSelected] &&
      this.subsData[serviceSelected].prepaySelected === true &&
      this.selectedAssetSubscriptionFormData.products[serviceSelected].prepay.selectedPrepayData.prepaySelected ===
        false
    ) {
      this.endDateFieldConfig = {
        ...this.endDateFieldConfig,
        disabled: true,
      };
    }
    this.setBasePlans();
    this.setBasePlans(serviceSelected).then(() => {
      this.setContractDefaults(serviceSelected);
      this.setIfPrepayIsEligible(serviceSelected);
      this.handlePrepayFormData(serviceSelected);
      this.setTemplateCondt(serviceSelected);
      // if (this.addmoreButtonRetain.selectedProduct.indexOf(serviceSelected) != -1) {
      this.editFlowSetFormAndNone(serviceSelected);
      // }
      this.enableMutiProductFlow(serviceSelected);
      this.setExpandValue(serviceSelected, true, false);
      // this.updateMultiProductTriggerVal(serviceSelected);
      if (this.subsData[serviceSelected]?.templateCheck?.showCatRfvWarning) {
        const message = this.languageTranslations['Worklist']?.WL_NO_FATURE_SUPPORT_IN_ONBOARD;
        this.showToastMessage(message, 'error');
      }
    });
  }
  /**
   * Sets the expand state for a specified service (product) in the list of products.
   *
   * This method updates the `expand` property of the specified product based on
   * whether it should be opened or closed. It checks the list of products and
   * applies the appropriate state based on the provided parameters.
   *
   * @param {any} serviceSelected - The name of the service (product) whose expand state is being set.
   * @param {boolean} [opened] - Optional flag indicating whether to expand the service.
   * @param {boolean} [closed] - Optional flag indicating whether to collapse the service.
   *
   * The method performs the following actions:
   * - Iterates through the list of products.
   * - If the product's application name matches the selected service, it sets the
   *   `expand` property to true if `opened` is provided or to false if `closed` is provided.
   */
  setExpandValue(serviceSelected: any, opened?: any, closed?: any) {
    if (this.listOfProducts?.length > 0) {
      this.listOfProducts?.forEach((product: any) => {
        if (product.applicationName == serviceSelected && opened) {
          product.expand = true;
        }
        if (product.applicationName == serviceSelected && closed) {
          product.expand = false;
        }
      });
    }
  }
  /**
   * Enables the multi-product flow by adding a new plan if certain conditions are met.
   *
   * This method checks if the user has not clicked to add more plans, if there are
   * existing base plans selected, and if the specified service is not already included
   * in the selected base plans. If all conditions are satisfied, it sets the base plan
   * flag to false and initiates the process to add more plans.
   *
   * @param {any} serviceSelected - The name of the service (product) that is being evaluated
   *                                for inclusion in the multi-product flow.
   *
   * Key operations performed:
   * - Checks if the `addMorePlanClicked` flag is false.
   * - Validates that there are existing base plans and that the selected service
   *   is not already part of the selected base plans.
   * - Calls `addMorePlan()` to initiate adding another plan.
   */
  enableMutiProductFlow(serviceSelected: any) {
    if (
      !this.addMorePlanClicked &&
      Object.keys(this.selectedBasePlan)?.length > 0 &&
      !this.selectedBasePlan.hasOwnProperty(serviceSelected)
    ) {
      this.setZeroPricingDetails.isBasePlan = false;
      this.addMorePlan();
    }
  }
  /**
   * Manages the editing flow for a specified application, updating applicable
   * plans and the subscription form based on the current state.
   *
   * This method checks various conditions to determine whether to set selected
   * services, update applicable plans, and add a "None" option in the edit flow.
   * It also handles cases when the user navigates back in the flow.
   *
   * @param {any} appName - The name of the application being edited.
   *
   * Key operations performed:
   * - If certain conditions are met (e.g., no changes in the edit accordion,
   *   the reporting state is 'Subscribed', and the user is not navigating back),
   *   it iterates through selected products to set the current service and updates
   *   applicable plans for those products.
   * - Adds a "None" option in the edit flow and sets base plans.
   * - If the user is navigating back and the next button can be checked, or if
   *   the reporting state is not 'Subscribed', it sets the add-on form data for the application.
   */
  editFlowSetFormAndNone(appName: any) {
    if (
      this.editAccordianCountWithoutChanges == 0 &&
      this.rowData.reportingState === 'Subscribed' &&
      !this.isBackPrevious &&
      !this.checkNextBtnForEditFlow()
    ) {
      this.addmoreButtonRetain.selectedProduct.forEach((item: any, index: any) => {
        this.setSelectedService(item);
        if (index == this.addmoreButtonRetain.selectedProduct.length - 1) {
          this.listOfProducts.forEach((product: any, index1: any) => {
            let matchingProduct: any = Object?.values(
              this.onLoadApplicablePlanForEditToLoadList?.applicableProductsDTOMap
            )?.find((item: any) => item?.applicationName === product?.applicationName);
            if (matchingProduct) {
              this.pushGreyOutPlan(matchingProduct, product);
              product.applicablePlansDTOs = matchingProduct.applicablePlansDTOs;
            }
            if (index1 == this.listOfProducts.length - 1) {
              this.removeCatOnlyAddon();
              this.addNoneInEditFlow();
              this.setBasePlans(appName);
            }
          });
        }
      });
      this.editAccordianCountWithoutChanges++;
    } else if (
      (this.isBackPrevious && this.checkNextBtnForEditFlow()) ||
      (this.isBackPrevious && this.rowData.reportingState !== 'Subscribed')
    ) {
      this.setAddonFormData(appName);
    }
  }
  pushGreyOutPlan(matchingProduct: any, product: any) {
    product?.applicablePlansDTOs?.filter((basePlan: any) => {
      if (basePlan.productDisabled) {
        const exists = matchingProduct?.applicablePlansDTOs?.find((plan: any) => plan?.planName === basePlan?.planName);
        if (!exists) {
          matchingProduct?.applicablePlansDTOs?.push(basePlan);
        }
      }
      basePlan?.addonApplicablePlansDTOs?.filter((addon: any) => {
        if (addon?.productDisabled) {
          matchingProduct?.applicablePlansDTOs?.filter((matchingProductBasePlan: any) => {
            if (basePlan?.planName == matchingProductBasePlan?.planName) {
              const exists = matchingProductBasePlan?.addonApplicablePlansDTOs?.find(
                (plan: any) => plan?.planName === addon?.planName
              );
              if (!exists) {
                matchingProductBasePlan?.addonApplicablePlansDTOs?.push(addon);
              }
            }
          });
        }
      });
    });
  }
  /**
   * Sets the values for the selected (expanded) product in the subscription form.
   *
   * This method updates the site information and base plan details for the
   * specified service. It handles specific cases for the "MINESTAR" service
   * and updates various form fields accordingly.
   *
   * @param {any} serviceOpted - The name of the selected product/service.
   *
   * Key operations performed:
   * - Retrieves the site name and ID from the previous site information of the
   *   selected product.
   * - If the selected service is "MINESTAR" and a site name is provided,
   *   it updates the site information in the subscription data and patches
   *   the form with the site name.
   * - Resets the overlay box after updating the site information.
   * - If a customer level is present, it sets the form values for the base plan,
   *   retrieves optional plans, and updates the add-on form data and template conditions.
   */
  setSelectedService(serviceOpted: any) {
    const { siteName, siteId } = this.selectedAssetSubscriptionFormData.products[serviceOpted].previousSiteInfo;
    const { customerLevel } =
      this.selectedAssetSubscriptionFormData.products[serviceOpted].subscription.previousSubscription;

    if (serviceOpted === dspConstants.Worklist.MINESTAR && siteName !== '') {
      this.siteName = siteName;
      this.subsData[serviceOpted].siteInfo.isValidSite = true;
      this.subsData[serviceOpted].siteInfo.siteVal = { name: siteName };
      this.subsData[serviceOpted].siteInfo.siteId = siteId;
      this.previousSiteSearch = siteName;
      setTimeout(() => {
        this.resetOverlayBox();
      });
      const data = this.manageAssetSubscriptionForm.get('products') as FormArray;
      if (siteName !== '') {
        Object.keys(data.controls).forEach((key) => {
          if (serviceOpted == data.controls[parseInt(key)].value.applicationName) {
            (data.controls[parseInt(key)] as FormGroup).get('mineStarSiteName')?.patchValue(siteName);
          }
        });
      }
    }

    if (customerLevel !== '') {
      this.setFormValueForBasePlan(serviceOpted);
      this.setOptionalPlans({ applicationName: serviceOpted }).then(() => {
        this.setAddonFormData(serviceOpted);
        this.setFormAllValue();
        this.setTemplateCondt(serviceOpted);
      });
    }
  }

  /**
   * Updates the base plan information for the selected service in the subscription form.
   *
   * This method retrieves the customer level for the specified service and filters
   * the list of base plans to find those that match the customer level. It then
   * updates the current subscription's customer level and sets the filtered base
   * subscription data in the form.
   *
   * @param {any} serviceOpted - The name of the selected service for which the
   *                             base plan is being updated.
   *
   * Key operations performed:
   * - Retrieves the customer level from the previous subscription data of the
   *   selected service.
   * - Filters the list of base plans to find those that correspond to the
   *   customer level.
   * - Maps the filtered base plans to create an array of objects containing
   *   relevant details (label, value, grade, etc.).
   * - Updates the form's `customerPlan` field with the filtered subscription data
   *   for the selected service.
   */
  setFormValueForBasePlan(serviceOpted: any) {
    const { customerLevel } =
      this.selectedAssetSubscriptionFormData.products[serviceOpted].subscription.previousSubscription;
    this.selectedAssetSubscriptionFormData.products[serviceOpted].subscription.currentSubscription.customerLevel =
      customerLevel;
    let selectedLevelData = [];
    let filteredsubscriptionData: SubscriptionData[] = [];
    if (!isEmpty(this.listOfBasePlans[serviceOpted])) {
      selectedLevelData = this.listOfBasePlans[serviceOpted]?.filter(
        (subscription: any) => subscription.baseSubscription === customerLevel
      );

      filteredsubscriptionData = selectedLevelData.map((plan: any) => ({
        label: this.showBaseRatePlan(plan, serviceOpted)
          ? plan?.baseSubscription + ' - ' + plan?.prices
          : plan?.baseSubscription,
        value: this.showBaseRatePlan(plan, serviceOpted)
          ? plan?.baseSubscription + ' - ' + plan?.prices
          : plan?.baseSubscription,
        grade: plan?.grade,
        isSubscribable: plan?.isSubscribable,
        prices: plan?.prices,
        baseSubscription: plan?.baseSubscription,
      }));

      const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
      Object.keys(data.controls).forEach((key) => {
        if (serviceOpted == data.controls[parseInt(key)].value.applicationName) {
          (data.controls[parseInt(key)] as FormGroup).get('customerPlan')?.patchValue(filteredsubscriptionData);
        }
      });
    }
  }

  /**
   * Updates the optional plans (add-ons) for the selected service in the subscription form.
   *
   * This method retrieves the current and previous add-ons based on the navigation state
   * (backward navigation or not) and updates the add-on options in the subscription form
   * for the specified service. It also handles specific logic for the "MINESTAR" service.
   *
   * @param {any} serviceOpted - The name of the selected service for which the
   *                             add-on data is being updated.
   *
   * Key operations performed:
   * - Determines which set of add-ons to use (current or previous) based on navigation state.
   * - Updates the current add-ons in the subscription form data for the selected service.
   * - Filters the list of optional plans to find those that are subscribed and prepares
   *   them for display in the form.
   * - If the selected service is "MINESTAR" and there are add-ons, it updates the
   *   profile-based additional services to reflect the selected add-ons.
   * - Patches the form with the subscribed add-ons for the selected service.
   * - Calls an additional function to manage asset product family data if the selected
   *   service is "MINESTAR".
   */
  setAddonFormData(serviceOpted: any) {
    const { previousddons, currentAddons } = this.selectedAssetSubscriptionFormData?.products[serviceOpted]?.addOns;
    const addOns = this.isBackPrevious ? currentAddons : previousddons;
    this.selectedAssetSubscriptionFormData.products[serviceOpted].addOns.currentAddons = addOns;
    if (!isEmpty(this.listOfOptionalPlans[serviceOpted])) {
      const subscribedAddons: any = [];
      this.listOfOptionalPlans[serviceOpted]?.forEach((plan: any) => {
        if (addOns.includes(plan.serviceName)) {
          subscribedAddons.push({
            label: this.showOptionalRatePlan(plan, serviceOpted)
              ? plan?.serviceName + ' - ' + plan?.prices
              : plan?.serviceName,
            value: this.showOptionalRatePlan(plan, serviceOpted)
              ? plan?.serviceName + ' - ' + plan?.prices
              : plan?.serviceName,
            isSubscribable: plan?.isSubscribable,
            prices: plan?.prices,
            serviceName: plan?.serviceName,
            autoSelect: false,
          });
        }
      });
      if (serviceOpted === dspConstants.Worklist.MINESTAR && addOns?.length > 0) {
        for (const addOn of addOns) {
          this.profileBasedAdditionalServices.optionListPrev[addOn] = true;
          this.profileBasedAdditionalServices.optionList[addOn] = true;
          this.profileBasedAdditionalServices.chkbxValChng = true;
        }
      }

      const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
      Object.keys(data?.controls).forEach((key) => {
        if (serviceOpted == data.controls[parseInt(key)].value.applicationName) {
          (data.controls[parseInt(key)] as FormGroup).get('addOnOptions')?.patchValue(subscribedAddons);
        }
      });
    }
    if (serviceOpted === dspConstants.Worklist.MINESTAR) {
      this.listAssetProductFamilyFn(serviceOpted);
    }
  }

  /**
   * Handles the prepayment form data based on the selected service.
   *
   * @param {any} serviceSelected - The application name details for the selected service.
   *
   * This function checks if the prepayment option is available and whether the contract start
   * and end dates are defined. If conditions are met, it can proceed to add prepayment dates.
   */
  handlePrepayFormData(serviceSelected?: any) {
    const { prepay, contractStartDate, contractEndDate } = this.rowData;
    const { contractEndDate: currentContractEndDate } =
      this.selectedAssetSubscriptionFormData.products[serviceSelected].prepay.currentPrepayData;
    if (prepay && !isEmpty(contractStartDate) && !isEmpty(contractEndDate) && currentContractEndDate === '') {
      // this.addPrepayDates();
    }
  }
  /**
   * Sets the eligibility of prepayment for the selected service.
   *
   * @param {any} serviceSelected - The application name details for the selected service.
   *
   * This function checks various conditions to determine if prepayment is eligible based on user features,
   * supported applications, and subscription pricing. If all conditions are met and the service is eligible,
   * it updates the `prepayEligible` status in `subsData`.
   */
  setIfPrepayIsEligible(serviceSelected?: any) {
    this.subsData[serviceSelected].prepayEligible = false;
    !this.rowData?.prepay && this.rowData?.contractStartDate && this.rowData?.contractEndDate ? true : false;
    if (
      this.userActiveFeatures?.indexOf(dspConstants.Worklist.PREPAY) != -1 &&
      this.featuresToggleMap?.prepaySupportedApplications?.indexOf(serviceSelected) != -1 &&
      this.featuresToggleMap?.contractPeriodSupportedApplications?.indexOf(serviceSelected) != -1 &&
      this.subsPricingEligible &&
      this.isNonBillableCust
    ) {
      this.subsData[serviceSelected].prepayEligible = true;
      // this.subsData[serviceSelected].prepaySelected = !!this.rowData?.prepay;
    }
  }

  /**
   * Sets the default contract period for a selected service based on prepayment status.
   *
   * @param {any} serviceSelected - The details of the application name for which the contract defaults are being set.
   *
   * This function initializes the contract period parameters for the selected service in `subsData`.
   * It checks if prepayment is selected and adjusts the contract period configuration accordingly.
   *
   * The following key properties are set:
   * - **startDate**: The current date formatted as 'YYYY/MM/DD'.
   * - **endDate**: The date one year from now, formatted as 'YYYY/MM/DD'.
   * - **minEndDate**: The minimum end date set to one year from now.
   * - **maxEndDate**: The maximum end date set to five years from now.
   * - **datepickerMode**: Set to 'day'.
   * - **isDisabled**: A flag indicating whether the contract period is disabled based on various conditions, including subscription enablement and asset subscribability.
   * - **promoParams**: An object for promotional parameters, initialized as empty.
   * - **shadowStartDate**: A placeholder string for the start date.
   * - **showContractForReadOnlyApp**: Determines eligibility for read-only applications.
   *
   * Additionally, if certain conditions related to previous subscriptions are met, the contract period's `isDisabled` flag is set to true.
   *
   * The function also updates the `billInfoHeight` based on subscription level eligibility and modifies the `endDateFieldConfig` to ensure it is enabled.
   * Finally, it updates the asset service with the modified subscription data.
   *
   * @returns {Promise<void>} A promise that resolves when the contract defaults have been set.
   */
  setContractDefaults(serviceSelected?: any) {
    const promise: Promise<void> = new Promise<void>((resolve, reject) => {
      const { prepaySelected, contractEndDate, contractStartDate } =
        this.selectedAssetSubscriptionFormData.products[serviceSelected].prepay.currentPrepayData;
      if (this.subsData[serviceSelected].prepaySelected === false) {
        this.subsData[serviceSelected].contractPeriod = {
          startDate: this.dateFormattingService.formatDate(new Date(), 'YYYY/MM/DD'),
          endDate: this.dateFormattingService.formatDate(
            new Date(new Date().setDate(new Date().getDate() + 364)),
            'YYYY/MM/DD'
          ),
          minEndDate: new Date(new Date().setDate(new Date().getDate() + 364)),
          maxEndDate: new Date(new Date().setDate(new Date().getDate() + 1824)),
          datepickerMode: 'day',
          isDisabled:
            !this.rowData?.enableSubscription ||
            this.rowData?.suspended ||
            this.CheckIfNotSubscribableAsset(serviceSelected),
          promoParams: {},
          shadowStartDate: '-',
          showContractForReadOnlyApp: this.setContractEligibilityForReadOnlyApps('-'),
        };
      } else {
        this.subsData[serviceSelected].contractPeriod.datepickerMode = 'day';
        this.subsData[serviceSelected].contractPeriod.isDisabled =
          !this.rowData?.enableSubscription ||
          this.rowData?.suspended ||
          this.CheckIfNotSubscribableAsset(serviceSelected);
        this.subsData[serviceSelected].contractPeriod.promoParams = {};
        this.subsData[serviceSelected].contractPeriod.shadowStartDate = '-';
        this.subsData[serviceSelected].contractPeriod.showContractForReadOnlyApp =
          this.setContractEligibilityForReadOnlyApps('-');
      }
      if (
        this.selectedAssetSubscriptionFormData.products[serviceSelected].subscription.previousSubscription
          .customerLevel === this.selectedBasePlan[serviceSelected] &&
        this.subsData[serviceSelected].prepaySelected === true &&
        this.selectedAssetSubscriptionFormData.products[serviceSelected].prepay.selectedPrepayData.prepaySelected ===
          false
      ) {
        this.subsData[serviceSelected].contractPeriod.isDisabled = true;
      }
      this.rowData.billInfoHeight =
        this.subsPricingEligible &&
        (this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.LEVEL3_ADDON_SELECTION ||
          this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.LEVEL3_NO_ADDON_SELECTION)
          ? true
          : false;
      this.endDateFieldConfig.disabled = false;
      // this.setPrepayFormData();
      this.assetService.setListOfSubsData(this.subsData);
      resolve();
    });
    return promise;
  }
  /**
   * Resets the selection for a specified application.
   *
   * @param {any} event - The event object related to the reset action.
   * @param {any} applicationName - The name of the application to reset.
   *
   * Updates the last selected base and option, marks it as reset,
   * clears retained data, and triggers the addition of new plan options.
   */
  resetCall(event: any, applicationName: any) {
    this.lastSelectedBaseAndOption.apllicationName = applicationName;
    this.lastSelectedBaseAndOption.type = 'Base';
    this.lastSelectedBaseAndOption.planName =
      this.selectedAssetSubscriptionFormData.products[applicationName].subscription?.currentSubscription?.catLevel;
    this.lastSelectedBaseAndOption.basePlan =
      this.selectedAssetSubscriptionFormData.products[applicationName].subscription?.currentSubscription?.catLevel;
    this.lastSelectedBaseAndOption.isReset = true;
    this.removeDataFromAddmoreRetain(applicationName);
    this.addMorePlan();
  }
  /**
   * Removes data related to the specified application from retained selections.
   *
   * @param {any} applicationName - The name of the application to remove from retention.
   *
   * Deletes the application from selected base and optional plan IDs if they exist in the retain objects.
   */
  removeDataFromAddmoreRetain(applicationName: any) {
    if (this.addmoreButtonRetain.selectedBasePlanID.hasOwnProperty(applicationName)) {
      delete this.addmoreButtonRetain.selectedBasePlanID[applicationName];
    }
    if (this.addmoreButtonRetain.selectedOptionalPlanID.hasOwnProperty(applicationName)) {
      delete this.addmoreButtonRetain.selectedOptionalPlanID[applicationName];
    }
    if (this.selectedBasePlan.hasOwnProperty(applicationName)) {
      delete this.selectedBasePlan[applicationName];
    }
  }
  /**
   * Resets the state for a specific application.
   *
   * @param {any} applicationName - The name of the application to reset.
   * @param {any} isNone - Optional flag indicating if no data should be retained.
   *
   * This function removes retained data, either resetting the application to default values
   * or removing selected form data based on the `isNone` flag. It updates the list of products,
   * clears specific application details (like site name for MINESTAR), and sets contract defaults.
   * Finally, it updates the form array and retains the selected product state.
   */
  Reset(applicationName: any, isNone?: any) {
    this.removeDataFromAddmoreRetain(applicationName);
    if (!isNone) {
      this.selectedAssetSubscriptionFormData.products[applicationName] = cloneDeep(this.productData);
    } else {
      this.removeSelectedFormData(applicationName);
    }
    if (this.selectedBasePlan.hasOwnProperty(applicationName)) {
      delete this.selectedBasePlan[applicationName];
    }

    this.listOfProducts.map((item: any) => {
      if (item.applicationName == applicationName) {
        item.reset = false;
        item.expand = false;
      }
      // if (CheckaddmoreButtonDisableCount < 1) {
      //   item.disabled = false;
      // }
    });
    if (applicationName == dspConstants.Worklist.MINESTAR) {
      this.siteName = '';
      this.productFamily.selectedAddOnProductFamilyList = [];
      this.setMineStarSiteNameOnInit();
    }
    // if (this.listOfProducts.every((item: any) => item.disabled == false)) {
    //   this.addMorePlanClicked = false;
    // }
    this.subsData[applicationName] = cloneDeep(this.subs);
    this.selectedAssetSubscriptionFormData.products[applicationName];
    this.setContractDefaults(applicationName);
    const data = this.manageAssetSubscriptionForm.get('products') as FormArray;
    Object.keys(data.controls).forEach((key) => {
      if (applicationName == data.controls[parseInt(key)].value.applicationName) {
        data.removeAt(parseInt(key));
        this.pushFormArray(applicationName);
      }
    });
    this.addmoreButtonRetain?.selectedProduct?.splice(
      this.addmoreButtonRetain?.selectedProduct?.indexOf(applicationName),
      1
    );
    this.ListOfproductOrder();
  }
  /**
   * Opens a modal popup for selecting a plan.
   *
   * @param {any} content - The content to be displayed in the modal.
   *
   * This function initializes and opens a semi-modal with specified dimensions and options,
   * such as disabling backdrop click and closing on escape. The modal reference is stored
   * for later management.
   */
  selectPlanPopUp(content: any) {
    const selectPlanpopUpRef = this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.selectPlanpopUpRefs.push(selectPlanpopUpRef);
  }

  /**
   * Confirms compatibility and updates the application state.
   *
   * This function removes existing compatible data and resets the application state
   * based on the last selected base option. If the plan name is 'None', it performs
   * a specific reset. It then calls functions to add more plans, detect changes,
   * set form values, and manage product order. Finally, it updates multi-product values
   * and closes any open selection popups, while maintaining the disabled state of the
   * MINSTAR site name.
   */
  CompatibleConfirm() {
    this.removeExistingCompatibleData();
    if (this.lastSelectedBaseAndOption.isReset) {
      this.Reset(this.lastSelectedBaseAndOption.apllicationName, false);
    }
    if (this.lastSelectedBaseAndOption.planName == 'None') {
      this.Reset(this.lastSelectedBaseAndOption.apllicationName, true);
    }
    // if (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) == -1) {
    this.addMorePlan();
    // }
    this.cdr.detectChanges();
    this.setFormAllValue();
    this.ListOfproductOrder();
    this.updateMultiProductTriggerVal(this.lastSelectedBaseAndOption.apllicationName);
    this.closeAllSelectPlanPopUps();
    this.assetService.minstarSiteNameDisabled = this.minstarSiteNameDisabled;
    if (this.lastSelectedBaseAndOption.type === 'Addon') {
      this.additionalServicesChngFn(this.lastSelectedBaseAndOption?.apllicationName);
    }
  }
  /**
   * Cancels the compatibility selection and resets relevant state.
   *
   * This function checks if the last selected plan name is among the auto-select addons.
   * If so, it clears the auto-select items and updates the pricing details. If the
   * last option is not reset, it restores the previous form data. Finally, it closes
   * any open plan selection popups, updates the product order, and maintains the
   * disabled state of the MINSTAR site name.
   */
  CompatibleCancel() {
    if (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1) {
      this.autoSelectItem = [];
      this.setZeroPricingDetails.isBasePlan = false;
    }
    if (!this.lastSelectedBaseAndOption.isReset) {
      this.setCompatiblePreviousFormData();
    }
    this.closeAllSelectPlanPopUps();
    this.ListOfproductOrder();
    this.updateMultiProductTriggerVal(this.lastSelectedBaseAndOption.apllicationName);
    this.assetService.minstarSiteNameDisabled = this.minstarSiteNameDisabled;
  }
  /**
   * Closes all open plan selection popups and resets the reference list.
   *
   * This function iterates over the references stored in `selectPlanpopUpRefs`,
   * calling the `close` method on each reference to close the associated modal.
   * After closing all popups, it clears the `selectPlanpopUpRefs` array to
   * ensure that no stale references remain.
   *
   * This is useful for maintaining a clean user interface and preventing
   * interactions with modals that are no longer relevant.
   */
  closeAllSelectPlanPopUps() {
    this.selectPlanpopUpRefs.forEach((ref) => ref.close());
    this.selectPlanpopUpRefs = [];
  }
  /**
   * Updates the form values without emitting events or affecting other controls.
   * This method first ensures change detection is triggered, then retrieves the
   * current form data and patches the form with the same values.
   *
   * @async
   * @returns {Promise<void>}
   */
  async setFormAllValue() {
    await this.cdr.detectChanges();
    const currentFormData = this.manageAssetSubscriptionForm.value;
    this.manageAssetSubscriptionForm.patchValue(currentFormData, { onlySelf: true, emitEvent: false });
  }
  /**
   * Retrieves the plan ID based on the selected base and optional plan IDs,
   * as well as the type and name of the last selected plan. The retrieved
   * plan ID is then assigned to the `planNum` property.
   */
  getPlanId() {
    this.planNum = this.getPlanListId(
      this.addmoreButtonRetain.selectedBasePlanID,
      this.addmoreButtonRetain.selectedOptionalPlanID,
      this.lastSelectedBaseAndOption.type,
      this.lastSelectedBaseAndOption.planName
    );
  }
  /**
   * Initiates the process of adding more plans to the asset.
   *
   * This method retrieves the current plan ID, updates the state to indicate
   * that the "Add More Plan" button has been clicked, and prepares the payload
   * with relevant asset details for the API call. It also triggers the loader
   * service to indicate loading status.
   *
   * @param {boolean} [isEditFlowFirstCall] - Optional parameter to determine
   *                                           if this is the first call in an edit flow.
   */
  addMorePlan(isEditFlowFirstCall?: any) {
    this.getPlanId();
    this.addMorePlanClicked = true;
    this.assetService.selectPlanMenuDetails.addMorePlanClicked = this.addMorePlanClicked;
    this.loaderService.show();
    this.addmorePlanPayload = {
      make: this.rowData.make,
      serialNumber: this.rowData.serialNumber,
      planIds: this.planNum,
      includePricing: true,
      deviceType: this.rowData?.deviceType,
      dealerCode: this.dealer?.dealerCode,
      assetId: this.rowData?.assetId,
      assetGuiId: this.rowData.appId,
      isCVAAvailable:
        this.selectedAssetSubscriptionFormData.customer?.cvaDetails?.cvaStatus == 'Not Available' ? false : true,
    };
    this.lastselectedAddmorePlan = this.lastSelectedBaseAndOption.apllicationName;
    this.addmorePlanApiTrigger(isEditFlowFirstCall);
  }
  /**
   * Triggers an API call to fetch applicable subscriptions based on the provided
   * payload (addmorePlanPayload). It processes the API response to update
   * the UI state, manage product listings, and display relevant messages.
   *
   * The method performs the following key actions:
   *
   * 1. **Show Loader**: Displays a loading indicator while fetching data.
   *
   * 2. **Fetch Applicable Subscriptions**: Calls the `getApplicableSubscriptions` method
   *    from the asset drawer service with the payload. This returns a list of products
   *    that are compatible with the current selections.
   *
   * 3. **Handle API Response**:
   *    - If applicable products are found, it displays a popup to show compatible products
   *      and updates the internal state to reflect the new product information.
   *    - If no products are found, it displays an error message indicating that no
   *      alternatives are available.
   *
   * 4. **Product Processing**:
   *    - Iterates over the current list of products to match them with the received
   *      applicable products. It updates each product's state (enabled/disabled) based
   *      on whether they have applicable plans.
   *    - Adds new products introduced by the API response to the internal product list
   *      and manages their subscription statuses.
   *
   * 5. **Subscription and Addon Management**:
   *    - Checks for existing subscriptions and updates the UI with any relevant messages
   *      regarding addons or subscriptions.
   *    - Handles cases where additional API calls are needed based on the current product
   *      selections.
   *
   * 6. **Display Messages**:
   *    - Generates and displays messages based on the success or failure of the addition
   *      of new plans. It manages the visibility of popups and success/failure messages.
   *
   * 7. **Error Handling**:
   *    - If the API call fails, it hides the loader and displays a system error message.
   *
   * @param {boolean} [isEditFlowFirstCall] - Optional flag indicating if this is the
   *                                           first call in an edit flow. This can affect
   *                                           how subsequent API calls are managed.
   */
  addmorePlanApiTrigger(isEditFlowFirstCall?: any) {
    this.showCompatiblePopUp = false;
    this.loaderService.show();
    this.assetDrawerService.getApplicableSubscriptions(this.addmorePlanPayload).subscribe({
      next: (result: any) => {
        this.loaderService.hide();
        if (!isEmpty(result.applicableProductsDTOMap)) {
          this.showCompatiblePopUp = true;
          this.orderListOfProducts();
          this.planInfo = result;
          this.listOfProducts.forEach((product: any) => {
            let matchingProduct: any = Object.values(result.applicableProductsDTOMap).find(
              (item: any) => item.applicationName === product.applicationName
            );

            if (this.rowData.reportingState !== 'Subscribed') {
              if (matchingProduct) {
                product.applicablePlansDTOs = matchingProduct.applicablePlansDTOs;
              }
              if (matchingProduct && matchingProduct.applicablePlansDTOs.length >= 0) {
                product.disabled = false;
                // product.expand = true;
              } else {
                product.disabled = true;
              }

              if (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE') {
                if (this.datExcludedProducts?.includes(product.applicationName)) {
                  product.disabled = false;
                } else {
                  product.disabled = true;
                }
              }
            }
          });
          let newApplicationIntroduceByOtherAppicationArray = [];
          for (const [key, value] of Object.entries(result.applicableProductsDTOMap)) {
            let productData: any = value;
            if (!this.listOfProducts.some((item: any) => item.applicationName == productData.applicationName)) {
              if (productData.applicablePlansDTOs && productData.applicablePlansDTOs?.length > 0) {
                productData['isSubscribed'] = false;
                productData['disabled'] = false;
                productData['expand'] = false;
                if (this.seeMoreResponse && this.seeMoreResponse.length > 0) {
                  if (this.seeMoreResponse.some((item: any) => item.appName === productData.applicationName)) {
                    productData['isSubscribed'] = true;
                    this.listOfSubscribed.push(productData);
                    this.selectedAssetSubscriptionFormData.products[productData.applicationName].reportingState =
                      'Subscribed';
                  }
                }
                // this.getDynamicTrueAddon[this.lastSelectedBaseAndOption.apllicationName] =
                this.listOfProducts.push(productData);
                newApplicationIntroduceByOtherAppicationArray.push(productData.applicationName);

                let application = dspConstants.Worklist.NEW_VISION_LINK;
                if (this.lastSelectedBaseAndOption?.apllicationName) {
                  application = cloneDeep(this.lastSelectedBaseAndOption.apllicationName);
                }
                this.newApplicationIntroduceByOtherAppication[application] =
                  newApplicationIntroduceByOtherAppicationArray;
                if (!this.selectedAssetSubscriptionFormData.products.hasOwnProperty(productData.applicationName)) {
                  this.selectedAssetSubscriptionFormData.products[productData.applicationName] = this.productData;
                  this.subsData[productData.applicationName] = this.subs;
                }
              }
            }
          }
          this.listOfProducts = this.listOfProducts;
          this.addNoneInEditFlow();
          this.setBasePlans('');
          this.orderBySelectedProductForFormData(
            this.addmoreButtonRetain.selectedProduct,
            this.selectedAssetSubscriptionFormData
          );
          this.assetService.listOfPricingDetails.newApplicationIntroduceByOtherAppication =
            this.newApplicationIntroduceByOtherAppication;
          for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
            let productObj: any = value;
            this.listOfProducts.forEach((service: any) => {
              if (service?.applicationName === key && service.disabled) {
                service.expand = false;
              }
            });
            if (
              productObj.subscription?.currentSubscription?.catLevel ||
              productObj.subscription?.previousSubscription?.catLevel
            ) {
              const data: any = this.checkAddonsAndSubscription(productObj, result);
              if (data?.messages && data.messages.length > 0) {
                if (this.addmorePlanSuccessOrFailureMessage.length == 0) {
                  this.addmorePlanSuccessOrFailureMessage = data?.messages;
                } else {
                  if (!this.addmorePlanSuccessOrFailureMessage.some((item: any) => item.applicationName == key)) {
                    this.addmorePlanSuccessOrFailureMessage = [
                      ...this.addmorePlanSuccessOrFailureMessage,
                      ...data?.messages,
                    ];
                  }
                }
              }
              let indexDetail = this.addmoreButtonRetain.selectedProduct.indexOf(this.lastselectedAddmorePlan);
              let nextKeyName = this.addmoreButtonRetain.selectedProduct[indexDetail + 1];
              if (nextKeyName && nextKeyName == key) {
                let isFind = this.addmorePlanSuccessOrFailureMessage.find((item: any) => {
                  if (item.applicationName == nextKeyName) {
                    return item;
                  }
                });
                if (isFind) {
                  this.lastselectedAddmorePlan = key;
                } else {
                  if (!isEditFlowFirstCall) {
                    this.planNum = this.getPlanListId(
                      this.addmoreButtonRetain.selectedBasePlanID,
                      this.addmoreButtonRetain.selectedOptionalPlanID,
                      this.nextCallPlanDetails(true, key),
                      this.nextCallPlanDetails('', key),
                      key
                    );
                    this.lastselectedAddmorePlan = key;
                    this.addmorePlanPayload.planIds = this.planNum;
                    this.addmorePlanApiTrigger();
                    break;
                  }
                }
              }
            }
          }
          let returnMessage: any;
          if (this.addmorePlanSuccessOrFailureMessage?.length > 0) {
            returnMessage = this.generateOutput(
              this.addmorePlanSuccessOrFailureMessage,
              this.lastSelectedBaseAndOption.apllicationName
            );
          }
          this.appendPricingDetailsToPlan();
          if (returnMessage && this.showCompatiblePopUp) {
            this.successOrFailureMessages = this.addmorePlanSuccessOrFailureMessage;
            this.compatipleMessage = returnMessage;
            this.selectPlanPopUp(this.selectPlanpopUps);
            this.addmorePlanSuccessOrFailureMessage = [];
          } else {
            if (this.lastSelectedBaseAndOption.isReset) {
              this.Reset(this.lastSelectedBaseAndOption.apllicationName, false);
              this.addmorePlanSuccessOrFailureMessage = [];
            }
            if (this.lastSelectedBaseAndOption.type === 'Addon') {
              this.additionalServicesChngFn(this.lastSelectedBaseAndOption?.apllicationName);
            }
            if (
              this.setZeroPricingDetails.isBasePlan &&
              (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1 ||
                (this.autoSelectItem?.length > 0 &&
                  this.autoSelectItem.some((item) => item.serviceName == this.lastSelectedBaseAndOption.planName)))
            ) {
              this.setZeroPricingAddon();
              if (this.autoSelectItem && this.autoSelectItem.length > 0) {
                this.autoSelectItem.filter((item: any, index: any) => {
                  if (
                    this.autoSelectItem?.length - 1 == index &&
                    item.serviceName == this.lastSelectedBaseAndOption.planName
                  ) {
                    this.setZeroPricingDetails.isBasePlan = false;
                  }
                });
              }
            }
          }
          this.removeCatOnlyAddon();
          this.enableAddMoreBtn = true;
          this.ListOfproductOrder();
          this.orderBySelectedProductForFormData(
            this.addmoreButtonRetain.selectedProduct,
            this.selectedAssetSubscriptionFormData
          );
          if (this.showCompatiblePopUp) {
            this.loaderService.hide();
          }
        } else {
          const messageText = '​No other products are available for selection with the current selected product.';
          this.showToastMessage(messageText, 'error');
          this.ListOfproductOrder();
          this.orderBySelectedProductForFormData(
            this.addmoreButtonRetain.selectedProduct,
            this.selectedAssetSubscriptionFormData
          );
          this.loaderService.hide();
        }

        error: (err: any) => {
          this.loaderService.hide();
          this.showToastMessage(this.systemError, 'error');
        };
      },
      error: (_err: any) => {},
    });
  }
  /**
   * Appends pricing details to the applicable plans for each product in the list.
   *
   * This method updates the pricing information for both base plans and addon plans
   * associated with each product in the `listOfProducts`. It checks for dynamic
   * pricing availability and assigns the corresponding price and currency values.
   *
   * The method performs the following actions:
   * - Iterates through the `listOfProducts`.
   * - For each product, checks if it has applicable plans (`applicablePlansDTOs`).
   * - If applicable plans exist, it further checks each base plan for dynamic pricing.
   * - If dynamic pricing is applicable, retrieves the price and currency from
   *   `listOfDynamicPricingArray` and assigns it to the `prices` property of the base plan.
   * - If the base plan has addon plans, it similarly updates their pricing if dynamic
   *   pricing is available.
   * - Calls the `setBasePlans` method to ensure base plans are correctly set in the state.
   * - Loops through the applicable plans again to call `setOptionalPlans` for each
   *   base plan's name.
   */
  appendPricingDetailsToPlan() {
    this.listOfProducts.forEach((element: any) => {
      if (element?.applicablePlansDTOs?.length > 0) {
        element.applicablePlansDTOs.forEach((basePlan: any) => {
          if (basePlan.hasDynamicPricing && this.listOfDynamicPricingArray.hasOwnProperty(element.applicationName)) {
            if (this.listOfDynamicPricingArray[element.applicationName][basePlan.planName]) {
              basePlan.prices =
                this.listOfDynamicPricingArray[element.applicationName][basePlan.planName].price +
                ' ' +
                this.listOfDynamicPricingArray[element.applicationName][basePlan.planName].currency;
            }
          }
          if (basePlan.addonApplicablePlansDTOs?.length > 0) {
            basePlan.addonApplicablePlansDTOs.forEach((addonPlan: any) => {
              if (
                addonPlan.hasDynamicPricing &&
                this.listOfDynamicPricingArray.hasOwnProperty(element.applicationName)
              ) {
                if (this.listOfDynamicPricingArray[element.applicationName][addonPlan.planName]) {
                  addonPlan.prices =
                    this.listOfDynamicPricingArray[element.applicationName][addonPlan.planName].price +
                    ' ' +
                    this.listOfDynamicPricingArray[element.applicationName][addonPlan.planName].currency;
                }
              }
            });
          }
        });
      }
    });
    this.setBasePlans();
    this.listOfProducts.forEach((element: any) => {
      if (
        element?.applicablePlansDTOs?.length > 0 &&
        ((this.lastSelectedBaseAndOption.type === 'Addon' &&
          element.applicationName !== this.lastSelectedBaseAndOption.apllicationName) ||
          this.lastSelectedBaseAndOption.type === 'Base')
      ) {
        this.setOptionalPlans({ applicationName: element.applicationName });
      }
    });
  }
  /**
   * Checks whether the accordion for a specified application is open and
   * updates its state if necessary.
   *
   * This method iterates through the list of products to determine if the
   * accordion for the given application name should be closed based on
   * specific conditions. If the accordion is open and certain subscription
   * criteria are met, it will be closed.
   *
   * The method performs the following actions:
   * - Iterates through the `listOfProducts`.
   * - For each product, checks if the product's `applicationName` matches the
   *   provided `applicationName`.
   * - If the product is expanded (`expand` is true), it checks the following conditions:
   *   - The last opened accordion is not the current product's application name.
   *   - There is no current subscription cat level associated with the application.
   *   - There are no previous subscription cat levels or customer levels associated.
   * - If all conditions are met, the product's `expand` property is set to false
   *   and the method returns true, indicating that the accordion was closed.
   * - If no products meet the criteria, the method returns false.
   *
   * @param {any} applicationName - The name of the application whose accordion
   *                                 state is being checked.
   * @returns {boolean} - Returns true if the accordion was closed; otherwise, false.
   */
  checkOpenedAccordian(applicationName: any): boolean {
    if (this.listOfProducts?.length > 0) {
      for (const product of this.listOfProducts) {
        if (
          product?.applicationName === applicationName &&
          product?.expand &&
          this.lastOpenedAccordian !== product?.applicationName &&
          !this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.currentSubscription
            ?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.previousSubscription
            ?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.previousSubscription
            ?.customerLevel
        ) {
          product.expand = false;
          return true;
        }
      }
    }
    return false;
  }
  /**
   * Determines the next plan details based on the provided type and the
   * specified plan details.
   *
   * This method evaluates the current addons and subscription levels for the
   * specified plan and returns the appropriate value based on the conditions
   * specified by the `type` parameter.
   *
   * The method performs the following actions:
   * - Retrieves the current addons for the specified plan. If there are current
   *   addons, it uses them; otherwise, it falls back to previous addons.
   * - Checks the current subscription level. If a current cat level exists, it uses
   *   that; otherwise, it retrieves the previous subscription cat level.
   * - If there are current addons:
   *   - If `type` is truthy, it returns the string 'Addon'.
   *   - If `type` is falsy, it returns the last current addon in the list.
   * - If there are no current addons:
   *   - If `type` is truthy, it returns the string 'Base'.
   *   - If `type` is falsy, it returns the current subscription level.
   *
   * @param {any} type - A flag that determines the return type (addon or base).
   * @param {any} planDetails - The details of the plan used to lookup
   *                            current addons and subscription levels.
   * @returns {any} - Returns the last addon, subscription level, or a string
   *                  indicating the type ('Addon' or 'Base').
   */
  nextCallPlanDetails(type: any, planDetails: any) {
    const currentAddons =
      this.selectedAssetSubscriptionFormData.products[planDetails].addOns.currentAddons?.length > 0
        ? this.selectedAssetSubscriptionFormData.products[planDetails].addOns.currentAddons
        : this.selectedAssetSubscriptionFormData.products[planDetails].addOns.previousddons;
    const currentSubscription = this.selectedAssetSubscriptionFormData.products[planDetails].subscription
      .currentSubscription.catLevel
      ? this.selectedAssetSubscriptionFormData.products[planDetails].subscription.currentSubscription.catLevel
      : this.selectedAssetSubscriptionFormData.products[planDetails].subscription.previousSubscription.catLevel;
    if (currentAddons?.length > 0) {
      if (type) {
        return 'Addon';
      } else {
        return currentAddons[currentAddons?.length - 1];
      }
    } else {
      if (type) {
        return 'Base';
      } else {
        return currentSubscription;
      }
    }
  }
  /**
   * Generates a formatted output string of subscriptions and addons
   * for applications, excluding the specified target application.
   *
   * This method processes the provided data to compile a summary of
   * subscriptions and addons associated with each application.
   *
   * The method performs the following actions:
   * - Initializes an object to group subscriptions and addons by application name.
   * - Iterates over the `data` array, skipping the `targetApplication`.
   * - Populates the object with subscriptions and addons for each application.
   * - Constructs the output string, using 'and' to separate applications.
   *
   * @param {any[]} data - An array of subscription objects containing
   *                       application names, subscriptions, and addons.
   * @param {any} targetApplication - The name of the application to exclude
   *                                  from the output.
   * @returns {string} - A formatted string summarizing subscriptions and
   *                     addons for all applications except the target application.
   */
  generateOutput(data: any, targetApplication: any) {
    let applicationSubscriptions: any = {};

    for (const item of data) {
      const { applicationName, subscription, addon } = item;

      if (applicationName === targetApplication) {
        continue;
      }

      if (!applicationSubscriptions.hasOwnProperty(applicationName)) {
        applicationSubscriptions[applicationName] = [];
      }

      if (subscription) {
        applicationSubscriptions[applicationName].push(subscription);
      }
      if (addon) {
        applicationSubscriptions[applicationName].push(addon);
      }
    }

    let output = '';
    let isFirstApplication = true;

    for (const applicationName in applicationSubscriptions) {
      if (applicationSubscriptions.hasOwnProperty(applicationName)) {
        const subscriptions = applicationSubscriptions[applicationName];

        if (!isFirstApplication) {
          output += ' and ';
        }
        // output += `${applicationName} - ${subscriptions.join(', ')}`;
        output += `${subscriptions.join(', ')}`;
        isFirstApplication = false;
      }
    }

    return output;
  }
  /**
   * Checks if a specified plan name exists within the applicable plans
   * of a target application in the provided list of products.
   *
   * This method iterates through the `listOfProduct` to find the target
   * application and checks its applicable plans and their addon plans for
   * the specified plan name.
   *
   * The method performs the following actions:
   * - Loops through each product in `listOfProduct`.
   * - If the product's application name matches `targetApplicationName`,
   *   it checks the applicable plans.
   * - If the target plan name is found, it returns true.
   * - Additionally checks addon plans associated with the applicable plans
   *   and their conditions.
   *
   * @param {any[]} listOfProduct - An array of product objects containing
   *                                application names and applicable plans.
   * @param {string} targetPlanName - The name of the plan to check for.
   * @param {string} targetApplicationName - The name of the application to target.
   * @returns {boolean} - Returns true if the plan name exists; otherwise, false.
   */
  checkPlanName(listOfProduct: any, targetPlanName: any, targetApplicationName: any) {
    for (const data of listOfProduct) {
      const applicationName = data.applicationName;
      if (applicationName === targetApplicationName) {
        const applicablePlans = data?.applicablePlansDTOs;
        for (const plan of applicablePlans) {
          if (plan.planName === targetPlanName) {
            return true;
          }
          const addonPlans = plan.addonApplicablePlansDTOs;
          if (addonPlans) {
            for (const addonPlan of addonPlans) {
              if (
                addonPlan.planName === targetPlanName &&
                this.addmoreButtonRetain.selectedBasePlanID &&
                this.addmoreButtonRetain.selectedBasePlanID[targetApplicationName]?.length > 0 &&
                this.addmoreButtonRetain.selectedBasePlanID[targetApplicationName][0].planName == plan.planName
              ) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
  /**
   * Checks the validity of current addons and subscriptions against the
   * applicable products in the response.
   *
   * This method evaluates whether the current subscription and addons
   * exist within the list of applicable products for a specified service.
   * If any issues are found, it generates appropriate failure messages.
   *
   * The method performs the following actions:
   * - Retrieves current addons and the current or previous subscription level.
   * - Constructs a list of applicable products from the response.
   * - Checks if the current subscription exists and validates it against
   *   the last selected base option.
   * - Validates each current addon, generating failure messages if any are
   *   not found.
   *
   * @param {any} data - The data containing subscription and addon details.
   * @param {any} response - The response containing applicable products.
   * @returns {object} - Returns an object indicating success and any failure
   *                     messages if applicable.
   */
  checkAddonsAndSubscription(data: any, response: any) {
    const currentAddons = data.addOns.currentAddons?.length > 0 ? data.addOns.currentAddons : [];
    const currentSubscription = data.subscription.currentSubscription.catLevel
      ? data.subscription.currentSubscription.catLevel
      : data.subscription.previousSubscription.catLevel;
    const applicationName = data.service.currentService ? data.service.currentService : data.service.previousService;
    const listOfProduct: any = [];
    for (const [key, value] of Object.entries(response?.applicableProductsDTOMap)) {
      listOfProduct.push(value);
    }
    let failureMessages: any = [];
    if (currentSubscription != 'None') {
      const subscriptionExists = this.checkPlanName(listOfProduct, currentSubscription, applicationName);
      if (
        (!subscriptionExists &&
          this.lastSelectedBaseAndOption.planName !== currentSubscription &&
          this.checkPlanName(
            listOfProduct,
            this.lastSelectedBaseAndOption.planName,
            this.lastSelectedBaseAndOption.apllicationName
          )) ||
        (subscriptionExists &&
          this.lastSelectedBaseAndOption.planName &&
          !this.checkPlanName(
            listOfProduct,
            this.lastSelectedBaseAndOption.planName,
            this.lastSelectedBaseAndOption.apllicationName
          ) &&
          this.lastSelectedBaseAndOption.planName != 'None')
      ) {
        if (!this.greyOutPlan?.base?.includes(currentSubscription)) {
          failureMessages.push({
            success: false,
            type: 'Base',
            subscription: currentSubscription,
            message: `subscription ${currentSubscription} not found.`,
            applicationName: applicationName,
          });
        }
      }
      if (currentAddons?.length > 0 && Array.isArray(currentAddons)) {
        for (const addon of currentAddons) {
          const addonExists = this.checkPlanName(listOfProduct, addon, applicationName);
          if (
            !addonExists ||
            (addonExists &&
              this.lastSelectedBaseAndOption.planName &&
              (this.lastSelectedBaseAndOption.planName == this.lastSelectedBaseAndOption.basePlan ||
                (this.lastSelectedBaseAndOption.planName == this.lastSelectedBaseAndOption.optionalPlan &&
                  this.lastSelectedBaseAndOption.apllicationName != applicationName)) &&
              !this.checkPlanName(
                listOfProduct,
                this.lastSelectedBaseAndOption.planName,
                this.lastSelectedBaseAndOption.apllicationName
              ) &&
              this.lastSelectedBaseAndOption.planName != 'None')
          ) {
            if (!this.greyOutPlan?.addon?.includes(addon)) {
              failureMessages.push({
                success: false,
                addon: addon,
                type: 'Addon',
                message: `Addon ${addon} not found.`,
                applicationName: applicationName,
              });
            }
          }
        }
      }
    }

    if (failureMessages.length > 0) {
      return { success: false, messages: failureMessages };
    }

    return { success: true, applicationName };
  }
  /**
   * Orders the list of selected products by adding the current application
   * name if it is not already included.
   *
   * This method checks if the `apllicationNameAddMorePlan` is not already in
   * the `selectedProduct` list. If it is not, it adds the application name to
   * the list.
   */
  orderListOfProducts() {
    if (
      this.addmoreButtonRetain.selectedProduct.indexOf(this.apllicationNameAddMorePlan) === -1 &&
      this.apllicationNameAddMorePlan
    ) {
      this.addmoreButtonRetain.selectedProduct.push(this.apllicationNameAddMorePlan);
    }
  }
  /**
   * Orders the list of products based on specified criteria.
   *
   * This method sorts `listOfProducts` using multiple criteria:
   * - First, it sorts by the order defined in `productOrderList`, using a
   *   mapping of product names and IDs to their respective indices.
   * - Next, it sorts by the presence of application names in the
   *   `selectedProduct` list, prioritizing selected products.
   * - Finally, if the `reportingState` is not 'Subscribed', it sorts the
   *   products by their disabled state, ensuring disabled products are
   *   moved to the end of the list and updates their expand state accordingly.
   */
  ListOfproductOrder() {
    const orderMap = new Map(this.productOrderList?.map((obj: any, index: any) => [`${obj.name}_${obj.id}`, index]));
    this.listOfProducts?.sort((a: any, b: any) => {
      const aKey = `${a.applicationName}_${a.applicationId}`;
      const bKey = `${b.applicationName}_${b.applicationId}`;
      let aIndex: any = orderMap.get(aKey) ?? Infinity;
      let bIndex: any = orderMap.get(bKey) ?? Infinity;
      return aIndex - bIndex;
    });

    this.listOfProducts?.sort((a: any, b: any) => {
      const indexA = this.addmoreButtonRetain.selectedProduct.indexOf(a.applicationName);
      const indexB = this.addmoreButtonRetain.selectedProduct.indexOf(b.applicationName);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    if (
      this.rowData.reportingState !== 'Subscribed' ||
      (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE' &&
        this.rowData.reportingState == 'Subscribed')
    ) {
      this.listOfProducts
        .sort((a: any, b: any) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1))
        .map((product: any) => ({ ...product, expand: !product.disabled }));
    }
  }
  /**
   * onBasePlanChange()--> on Selection of base plan if we need to peform some check or need to add some functionalities based on the selected base plan then we can perform all such here.
   * @param {any} basePlanEvent - This param has the selected base plan details.
   * @param {any} product - This param has the products details fetched from repsonse.
   * @constant event - used to store the selected basePlan Details.
   * @constant level - used to store the selected basePlan Details.
   */
  onBasePlanChange(basePlanEvent: any, product: any) {
    this.assetDrawerService.getapplicableList = '';
    this.setSubscriptionLevelCategory(product.applicationName);
    this.resetNextBtnValidForm(null);
    this.selectedBaseInfo = basePlanEvent;
    this.apllicationNameAddMorePlan = product.applicationName;
    this.selectedBaseInfo = basePlanEvent;
    this.lastSelectedBaseAndOption.apllicationName = product.applicationName;
    this.lastSelectedBaseAndOption.basePlan = this.selectedBaseInfo?.event?.currentSelection[0]?.baseSubscription;
    this.lastSelectedBaseAndOption.planName = this.selectedBaseInfo?.event?.currentSelection[0]?.baseSubscription;
    this.lastSelectedBaseAndOption.type = 'Base';
    this.lastSelectedBaseAndOption.isReset = false;
    if (
      this.selectedBaseInfo?.event?.currentSelection[0]?.baseSubscription == 'None' &&
      this.rowData.reportingState !== 'Subscribed'
    ) {
      this.removeApplicationFromListOfProducts(product.applicationName);
      this.lastSelectedBaseAndOption.isReset = true;
    }
    if (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1) {
      this.setZeroPricingDetails.isBasePlan = true;
    } else {
      this.setZeroPricingDetails.isBasePlan = false;
    }
    this.compatiblePreviousFormData();
    this.minstarSiteNameDisabled[product.applicationName] = this.lastSelectedBaseAndOption.basePlan;
    this.removeOptionalPlanFromForm(product.applicationName);
    this.returnBaseAndOptionalId(basePlanEvent, product);
    this.selectedBasePlan[product.applicationName] =
      this.selectedBaseInfo?.event?.currentSelection[0]?.baseSubscription;
    this.isRatePlanExist = this.checkIfRatePlanExists(product.applicationName);
    this.setBasePlanFormData(this.selectedBasePlan[product.applicationName], product);
    this.setOptionalPlans(product).then(() => {
      this.profileBasedAdditionalServices = {
        optionList: {},
        selectedCount: 0,
        optionListPrev: {},
        chkbxValChng: false,
      };
      this.settingPromotions(product);
      this.settingMultiplePlan(product);
      this.resetCurrentPrepayPlans(product);
      this.isCurrentAddOnEmpty =
        this.selectedAssetSubscriptionFormData.products[product?.applicationName]?.addOns?.currentAddons?.length === 0;
      if (this.lastSelectedBaseAndOption.basePlan == 'None') {
        this.updateMultiProductTriggerVal(product.applicationName);
      }
      if (product.applicationName === dspConstants.Worklist.MINESTAR) {
        this.listAssetProductFamilyFn(product.applicationName);
      }
      this.assetService.setListOfSubsData(this.subsData);
      this.assetService.setListOfProduct({
        listOfProducts: this.listOfProducts,
        listOfBasePlans: this.listOfBasePlans,
        listOfOptionalPlans: this.listOfOptionalPlans,
        listOfSubscribed: this.listOfSubscribed,
        listOfGreyOut: this.greyOutPlan,
      });
      this.assetService.setManageAssetForm(this.manageAssetSubscriptionForm);
      this.assetService.minstarSiteNameDisabled = this.minstarSiteNameDisabled;
      this.addmorePlanTrigger(true, '');
      if (!this.addMorePlanClicked && this.selectedBaseInfo?.event?.currentSelection[0]?.baseSubscription == 'None') {
        this.addMorePlan();
      }
    });
  }
  /**
   * Removes an application and its associated data from the list of products.
   *
   * This method checks if the specified application name exists in
   * `newApplicationIntroduceByOtherAppication`. If it does, the method
   * iterates through its associated values, removing each application
   * from `listOfProducts`, deleting related entries from
   * `listOfDynamicPricingArray`, and calling the `Reset` method for
   * each removed item. Finally, it removes the application name from
   * `newApplicationIntroduceByOtherAppication`.
   *
   * @param {any} applicationName - The name of the application to be removed.
   */
  removeApplicationFromListOfProducts(applicationName: any) {
    if (this.newApplicationIntroduceByOtherAppication.hasOwnProperty(applicationName)) {
      for (const [key, value] of Object.entries(this.newApplicationIntroduceByOtherAppication)) {
        let valueData: any = value;
        if (valueData?.length > 0) {
          valueData.forEach((item: any) => {
            this.listOfProducts.splice(
              this.listOfProducts.findIndex((elem: any) => elem.applicationName == item),
              1
            );
            delete this.listOfDynamicPricingArray[item];
            this.Reset(item, true);
          });
          delete this.newApplicationIntroduceByOtherAppication[applicationName];
        }
      }
    }
  }
  /**
   * Resets the current prepay plans for the specified product.
   *
   * This method updates the selected asset subscription form data by
   * setting the `prepaySelected` flag to false and clearing the
   * `contractStartDate` and `contractEndDate` for the given
   * application's prepay data. It then updates the form data with
   * the modified state.
   *
   * @param {any} product - The product for which the prepay plans are being reset.
   */
  resetCurrentPrepayPlans(product: any) {
    this.selectedAssetSubscriptionFormData.products[product.applicationName].prepay.currentPrepayData.prepaySelected =
      false;
    this.selectedAssetSubscriptionFormData.products[
      product.applicationName
    ].prepay.currentPrepayData.contractStartDate = '';
    this.selectedAssetSubscriptionFormData.products[product.applicationName].prepay.currentPrepayData.contractEndDate =
      '';
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Removes optional plans associated with the specified application.
   *
   * This method resets the form values for add-ons and clears the
   * optional plan list, current add-ons, selected add-ons, and
   * selected optional plan IDs for the given application.
   *
   * @param {any} applicationName - The name of the application from which
   *                                 optional plans are being removed.
   */
  removeOptionalPlanFromForm(applicationName: any) {
    this.setFormValueOnCancel('Addon', '', 'addOnOptions');
    this.lastSelectedBaseAndOption.optionalPlanList[applicationName] = [];
    this.selectedAssetSubscriptionFormData.products[applicationName].addOns.currentAddons = [];
    this.selectedAssetSubscriptionFormData.products[applicationName].addOns.selectedAddons = [];
    this.addmoreButtonRetain.selectedOptionalPlanID[applicationName] = [];
  }
  /**
   * Orders the products in the asset subscription form data based on
   * the selected product order.
   *
   * This method sorts the `products` in `selectedAssetSubscriptionFormData`
   * according to their indices in the `selectedProduct` list. Products
   * not present in the `selectedProduct` list are moved to the end of the
   * list.
   *
   * @param {any} selectedProduct - The list of selected products used for ordering.
   * @param {any} selectedAssetFormData - The asset form data containing the products.
   */
  orderBySelectedProductForFormData(selectedProduct: any, selectedAssetFormData: any) {
    this.selectedAssetSubscriptionFormData.products = Object.entries(this.selectedAssetSubscriptionFormData.products)
      .sort(([keyA, valueA], [keyB, valueB]) => {
        const indexA = this.addmoreButtonRetain.selectedProduct.indexOf(keyA);
        const indexB = this.addmoreButtonRetain.selectedProduct.indexOf(keyB);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      })
      .reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  }
  /**
   * Triggers the addition of more plans based on the current subscription
   * and add-on counts.
   *
   * This method evaluates the number of current subscriptions and add-ons
   * for each product. It determines whether to enable the "Add More" button
   * and triggers the addition of more plans based on specific conditions,
   * including the reporting state and user actions.
   *
   * @param {any} basePlan - The base plan to be added (optional).
   * @param {any} OptionalPlan - The optional plan to be added (optional).
   */
  addmorePlanTrigger(basePlan?: any, OptionalPlan?: any) {
    let count = 0;
    let optionalCount = 0;
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData?.products)) {
      let productObj: any = value;
      if (productObj?.subscription?.currentSubscription?.catLevel) {
        count++;
      }
      if (productObj?.addOns?.currentAddons && productObj?.addOns?.currentAddons?.length > 0) {
        optionalCount++;
      }
    }
    // if (
    //   ((count < 2 && optionalCount < 2) || (count < 2 && optionalCount < 1)) &&
    //   this.rowData.reportingState !== 'Subscribed'
    // ) {
    //   if (!this.addMorePlanClicked) {
    //     this.enableAddMoreBtn = false;
    //   } else {
    //     this.enableAddMoreBtn = true;
    //     if (this.addMorePlanClicked && count == 1) {
    //       this.addMorePlan();
    //     }
    //   }
    // }
    if (this.addMorePlanClicked && count >= 1 && this.rowData.reportingState !== 'Subscribed') {
      this.addMorePlan();
    } else if (this.rowData.reportingState == 'Subscribed') {
      this.enableAddMoreBtn = true;
      if (basePlan || OptionalPlan) {
        this.addMorePlan();
      }
    }
  }
  /**
   * Cancels all plans and emits an event to load the cancel screen.
   *
   * This method triggers the loading of the cancel screen and updates
   * the asset subscription form data with the current selection.
   */
  cancelAllPlans() {
    this.loadCancelScreen.emit(true);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Configures the display state of products based on their subscription
   * status and the specified product.
   *
   * This method iterates over the products in the selected asset subscription
   * form data. It updates the expansion and reset states of the corresponding
   * services in `listOfProducts` based on whether the current subscription
   * level exists and the reporting state.
   *
   * @param {any} product - The product for which settings are being applied.
   */
  settingMultiplePlan(product: any) {
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (!productObj?.subscription?.currentSubscription?.catLevel) {
        this.listOfProducts?.forEach((service: any) => {
          if (service?.applicationName === key) {
            // if (this.rowData.reportingState !== 'Subscribed') {
            //   service.disabled = true;
            // }
            service.expand = false;
          }
          if (product?.applicationName == service?.applicationName) {
            service.expand = true;
          }
        });
      }
      if (productObj?.subscription?.currentSubscription?.catLevel && this.rowData?.reportingState !== 'Subscribed') {
        this.listOfProducts?.forEach((service: any) => {
          if (service?.applicationName === key) {
            service.reset = true;
          }
        });
      }
    }
  }
  /**
   * Sets the base plan form data based on the selected subscription
   * and the specified product.
   *
   * This method updates the current and selected subscriptions
   * according to the subscription level category. It handles specific
   * categories by invoking relevant update methods and then
   * updates the asset subscription form data.
   *
   * @param {any} selectedSubscription - The subscription to set as the base plan.
   * @param {any} product - The product associated with the subscription.
   */
  setBasePlanFormData(selectedSubscription: any, product: any) {
    switch (this.subscriptionLevelCategory.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        this.updateCurrentSubscription(selectedSubscription, product.applicationName);
        this.updateSelectedSubscription(selectedSubscription, product.applicationName);
        break;
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
        this.updateCurrentSubscription(selectedSubscription, product.applicationName);
        this.updateSelectedSubscription(selectedSubscription, product.applicationName);
        break;
      default:
        break;
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Updates the current subscription levels for a specified application.
   *
   * This method sets the current subscription, dealer level, and customer
   * level to the provided `selectedSubscription` for the specified
   * application. It also updates the feature message support status based
   * on the applicable plans for the application.
   *
   * @param {any} selectedSubscription - The subscription level to be set.
   * @param {any} applicationName - The name of the application being updated.
   */
  updateCurrentSubscription(selectedSubscription: any, applicationName: any) {
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.catLevel =
      selectedSubscription;
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.dealerLevel =
      selectedSubscription;
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.customerLevel =
      selectedSubscription;
    this.selectedAssetSubscriptionFormData.products[applicationName].isFeatureMsgSupported = this.listOfProducts
      ?.filter((item: any) => item.applicationName === applicationName)[0]
      ?.applicablePlansDTOs?.filter((item: any) => item.planName === selectedSubscription)[0]?.isFeatureMsgSupported;
  }
  /**
   * Updates the selected subscription levels for a specified application.
   *
   * This method sets the selected subscription, dealer level, and customer
   * level to the provided `selectedSubscription` for the specified
   * application.
   *
   * @param {any} selectedSubscription - The subscription level to be set.
   * @param {any} applicationName - The name of the application being updated.
   */
  updateSelectedSubscription(selectedSubscription: any, applicationName: any) {
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.selectedSubscription.catLevel =
      selectedSubscription;
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.selectedSubscription.dealerLevel =
      selectedSubscription;
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.selectedSubscription.customerLevel =
      selectedSubscription;
  }

  /**
   * settingPromotions()--> on Selection of base plan if we need to load the promotions and it's promotions details.
   * @param {any} product - This param has the selected product details.
   */
  settingPromotions(product: any) {
    const customerInfo = this.getValidCustomerForAsset();
    if (
      !this.showB2CWarningPopupInPaymentPreview &&
      this.telUserType == dspConstants.Common.DEALER &&
      !this.isNonBillableCust &&
      this.selectedBasePlan[product.applicationName] != 'None'
    ) {
      this.b2cCustWarningMsg(this.b2cCustWarningTemplate);
    }
    this.subsData[product.applicationName].prepaySelected = false;
    this.setContractDefaults(product.applicationName).then(() => {
      if (
        this.subsPricingEligible &&
        (customerInfo?.length === 0 ||
          !customerInfo[0].ucid ||
          findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo[0].ucid]) < 0 ||
          this.b2cCustomers?.b2cSupportedApps?.indexOf(product.applicationName) < 0)
      ) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(product.applicationName, '', '', '', '');
        this.setIsNewSubscription();
      } else {
        this.updateMultiProductTriggerVal(product.applicationName);
      }
      this.setTemplateCondt(product.applicationName);
    });
  }
  /**
   * onOptionalPlanChange()--> on Selection of optional plan if we need to peform some check or need to add some functionalities based on the selected optional plan then we can perform all such here.
   * @param {any} optionalPlanEvent - This param has the selected optional plan details.
   * @constant event - used to store the selected optionalPlan Details.
   * @constant prop - used to store the selected optionalPlan Details.
   */
  onOptionalPlanChange(optionalPlanEvent: any, product?: any) {
    this.assetDrawerService.getapplicableList = '';
    const { event, prop } = optionalPlanEvent;
    if (event.action !== 'onLoad') {
      this.resetNextBtnValidForm(null);
      this.compatiblePreviousFormData();
      this.lastSelectedBaseAndOption.apllicationName = product?.applicationName;
      if (!this.lastSelectedBaseAndOption?.optionalPlanList?.hasOwnProperty(product?.applicationName)) {
        this.lastSelectedBaseAndOption.optionalPlanList[product.applicationName] = [];
      }
      this.compareOptionalData(
        this.lastSelectedBaseAndOption?.optionalPlanList[product?.applicationName],
        optionalPlanEvent?.event?.currentSelection,
        product?.applicationName
      );
      this.profileBasedAdditionalServices = {
        optionList: {},
        selectedCount: 0,
        optionListPrev: {},
        chkbxValChng: false,
      };
      this.returnBaseAndOptionalId('', product, optionalPlanEvent);

      event?.currentSelection?.forEach((item: any) => {
        this.profileBasedAdditionalServices.optionList[item.serviceName] = true;
      });
      this.selectedAssetSubscriptionFormData.products[product.applicationName].addOns.currentAddons = Object.keys(
        this.profileBasedAdditionalServices.optionList
      );

      this.isCurrentAddOnEmpty =
        this.selectedAssetSubscriptionFormData.products[product?.applicationName]?.addOns?.currentAddons?.length === 0;

      this.selectedAssetSubscriptionFormData.products[product.applicationName].addOns.selectedAddons = Object.keys(
        this.profileBasedAdditionalServices.optionList
      );
      if (isEmpty(this.profileBasedAdditionalServices.optionList)) {
        this.showCATGradePopup = false;
      }
      this.resetCurrentPrepayPlans(product);
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      this.subsData[product.applicationName].prepaySelected = false;
      this.setContractDefaults(product?.applicationName);
      const customerInfo = this.getValidCustomerForAsset();
      if (
        this.subsPricingEligible &&
        (customerInfo?.length === 0 ||
          !customerInfo[0].ucid ||
          findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo[0].ucid]) < 0 ||
          this.b2cCustomers?.b2cSupportedApps?.indexOf(product.applicationName) < 0)
      ) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(product.applicationName, '', '', '', '');
        this.setIsNewSubscription();
      } else {
        this.updateMultiProductTriggerVal();
      }
      if (product?.applicationName === dspConstants?.Worklist?.MINESTAR) {
        this.listAssetProductFamilyFn(product.applicationName);
      }
      this.assetService.setListOfSubsData(this.subsData);
      this.assetService.setListOfProduct({
        listOfProducts: this.listOfProducts,
        listOfBasePlans: this.listOfBasePlans,
        listOfOptionalPlans: this.listOfOptionalPlans,
        listOfSubscribed: this.listOfSubscribed,
        listOfGreyOut: this.greyOutPlan,
      });
      this.addmorePlanTrigger('', true);
      this.assetService.setManageAssetForm(this.manageAssetSubscriptionForm);
      if (!this.addMorePlanClicked) {
        this.additionalServicesChngFn(product?.applicationName);
      }
      // this.additionalServicesChngFn(product?.applicationName);
    }
  }
  /**
   * Handles changes in additional services based on the subscription level.
   *
   * This method checks if the CAT Grade Connectivity option is available
   * and displays the corresponding popup if it is not already shown.
   * It also updates the template conditions based on the provided
   * subscription level.
   *
   * @param {any} subscriptionLevel - The subscription level that triggers the change.
   */
  additionalServicesChngFn(subscriptionLevel: any) {
    if (
      Object.keys(this.profileBasedAdditionalServices.optionList).indexOf(
        dspConstants.Worklist.CAT_GRADE_CONNECTIVITY
      ) !== -1
    ) {
      if (!this.showCATGradePopup) {
        this.showCATGradePopup = true;
        this.openCatGradeConnectivityPopup(this.catGradeConnectivityPopup);
      }
    } else {
      this.showCATGradePopup = false;
    }
    this.setTemplateCondt(subscriptionLevel);
  }
  /**
   * Opens the CAT Grade Connectivity popup modal.
   *
   * This method initializes and displays the modal with specified
   * configurations, including its width and behavior regarding
   * closing and backdrop interaction.
   *
   * @param {any} content - The content to be displayed in the modal.
   */
  openCatGradeConnectivityPopup(content: any) {
    this.catGradeModalRef = this.modal.openModal(content, {
      width: '600px',

      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    // this.showCATGradePopup = false;
  }

  /**
   * Checks if a rate plan exists for the selected service.
   *
   * This method verifies whether a base subscription price is set for
   * the specified service. It retrieves the applicable plans and their
   * prices, returning true if a valid price exists and false otherwise.
   *
   * @param {any} serviceSelected - The name of the selected service to check.
   * @returns {boolean} - Returns true if a rate plan exists; otherwise, false.
   */
  checkIfRatePlanExists(serviceSelected?: any) {
    let baseSubPrice, subscriptionBasePrice;
    if (this.selectedBasePlan[serviceSelected] && this.selectedBasePlan[serviceSelected] != 'None') {
      baseSubPrice = this.listOfProducts
        ?.filter((item: any) => item.applicationName === serviceSelected)[0]
        ?.applicablePlansDTOs.filter(
          (item1: any) => item1.planName === this.selectedBasePlan[serviceSelected]
        )[0].prices;
      if (baseSubPrice !== null && baseSubPrice !== '') {
        subscriptionBasePrice = parseInt(baseSubPrice);
      }
    }
    if (subscriptionBasePrice == null || subscriptionBasePrice == 0 || subscriptionBasePrice == undefined) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Adds a "None" plan option to the list of applicable plans for each product.
   *
   * This method creates a "None" plan object and prepends it to the
   * `applicablePlansDTOs` array of each product in `listOfProducts`,
   * ensuring that the "None" plan is only added if it does not already exist.
   */
  addNoneInEditFlow() {
    let nonePlan = {
      planName: 'None',
      value: 'None',
      orderId: '0',
      isSubscribable: true,
      prices: '',
      baseSubscription: 'None',
      type: 'Base',
    };
    this.listOfProducts?.forEach((element: any, index: any) => {
      if (
        element.applicablePlansDTOs?.length > 0 &&
        !element.applicablePlansDTOs.some((item: any) => item.planName == 'None')
      ) {
        element.applicablePlansDTOs.unshift(nonePlan);
      }
    });
  }
  /**
   * setOptionalPlans()--> this is used to set the optional plans, based on the selected base plan.
   * @param {any} product - This param has the products details fetched from repsonse.
   * @constant selectedValue - used to store the selected basePlan value for which we have to load its optional plans
   * @constant listOfOptionalPlansArray - used to store the related optionalPlan values.
   */
  setOptionalPlans(product: any) {
    const promise: Promise<void> = new Promise<void>((resolve, reject) => {
      let selectedValue = '';
      selectedValue = this.selectedBasePlan[product.applicationName];
      let dynamicPriceEnabledArrayOpt = this.listOfProducts
        ?.filter((item: any) => item.applicationName === product.applicationName)[0]
        ?.applicablePlansDTOs?.filter((item1: any) => item1.planName === selectedValue)[0]
        ?.addonApplicablePlansDTOs?.filter((item2: any) => item2.hasDynamicPricing === true && item2.prices === null)
        ?.map((item3: any) => item3.planName);
      this.getDynamicPricing(dynamicPriceEnabledArrayOpt, 'OptionalPlan', product.applicationName).then(() => {
        this.checkPriceForB2C(product.applicationName);
        const listOfOptionalPlansArray: any = [];
        this.applicableAddOnPlansDetails = this.listOfProducts
          ?.filter((item: any) => item.applicationName === product.applicationName)[0]
          ?.applicablePlansDTOs.filter((item: any) => item.planName === selectedValue)[0]?.addonApplicablePlansDTOs;
        this.applicableAddOnPlansDetails?.forEach((item: any) => {
          listOfOptionalPlansArray.push({
            label: this.showOptionalRatePlan(item, product.applicationName)
              ? item?.planName + ' - ' + item?.prices
              : item?.planName,
            value: this.showOptionalRatePlan(item, product.applicationName)
              ? item?.planName + ' - ' + item?.prices
              : item?.planName,
            isSubscribable: true,
            prices: item?.prices,
            serviceName: item?.planName,
            autoSelect: false,
            disabled: item?.retirePlan,
          });
        });
        this.listOfOptionalPlans[product.applicationName] = listOfOptionalPlansArray;
        if (
          this.listOfOptionalPlans[product.applicationName].length &&
          this.enableAutoSelectAddon?.length &&
          this.enableAutoSelectAddon.indexOf(selectedValue) > -1
        ) {
          let custInfo = { dcnNumber: '', dcnName: '', ucid: '', customerName: '' };
          const validCustForAsset = this.getValidCustomerForAsset();
          if (validCustForAsset?.length == 1) {
            custInfo = {
              dcnNumber: validCustForAsset[0]?.dealerCustNo,
              dcnName: validCustForAsset[0]?.dealerCustName,
              ucid: validCustForAsset[0]?.ucid,
              customerName: validCustForAsset[0]?.ucidName,
            };
          }
          const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
          const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
          this.listOfOptionalPlansToShow = [];
          this.listOfOptionalPlans[product.applicationName]?.forEach((plans: any) =>
            this.listOfOptionalPlansToShow.push(plans.serviceName)
          );
          const input = {
            dealerCode: this.dealer.dealerCode,
            ucid: custInfo?.ucid,
            productName: product.applicationName,
            make: this.rowData.make,
            serialNumber: this.rowData.serialNumber,
            deviceType: this.rowData.deviceType,
            radioSerialNumber: this.rowData.radioSerialNoList,
            cva: false,
            subscriptions: this.listOfOptionalPlansToShow,
          };
          this.loaderService.show();
          this.assetService.getZeroPricedAddons(input).subscribe({
            next: (result: any) => {
              this.loaderService.hide();
              if (result?.length > 0) {
                if (!savedSubscriptionId || (savedSubscriptionId && this.editAccordianCountWithoutChanges > 0)) {
                  this.fetchedAutoAddons = true;
                  this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  this.listOfOptionalPlans[product.applicationName].forEach(function (obj: any) {
                    if (result?.length && result.indexOf(obj.serviceName) != -1) obj.autoSelect = true;
                  });
                  if (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1) {
                    this.autoSelectItem = this.listOfOptionalPlans[product.applicationName].filter(
                      (resObj: any) => resObj.autoSelect
                    );
                    this.setZeroPricingDetails.product = product;
                    this.setFormValueOnCancel('Addon', this.autoSelectItem, 'addOnOptions');
                    const optionalPlanEvents = {
                      prop: 'addOnOptions',
                      event: { action: 'checkbox', currentSelection: this.autoSelectItem },
                    };
                    if (!this.addMorePlanClicked) {
                      this.onOptionalPlanChange(optionalPlanEvents, product);
                    }
                  }
                }
              }
              resolve();
            },
            error: (err: any) => {
              this.loaderService.hide();
              this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              this.fetchedAutoAddons = true;
              this.showToastMessage(this.systemError, 'error');
              resolve();
            },
          });
        } else {
          resolve();
        }
      });
    });
    return promise;
  }
  /**
   * Sets zero pricing for add-ons based on the last selected base plan.
   *
   * This method checks if any auto-select items correspond to the
   * last selected base plan. If found, it collects these items and
   * triggers the necessary updates for the add-on options. It handles
   * the selection and updates the form values to reflect the zero pricing
   * for applicable add-ons.
   */
  setZeroPricingAddon() {
    if (this.autoSelectItem?.length > 0) {
      const itemOfperformancePro: any = [];
      let optionalPlanEvent: any;
      let index = 0;
      if (this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1) {
        index = 0;
      } else if (this.autoSelectItem.some((item) => item.serviceName == this.lastSelectedBaseAndOption.planName)) {
        this.autoSelectItem.forEach((item: any, indexData: any) => {
          if (item.serviceName == this.lastSelectedBaseAndOption.planName) {
            index = indexData + 1;
          }
        });
      }
      if (
        this.autoSelectItem?.length > 0 &&
        (this.autoSelectItem.some((item) => item.serviceName == this.lastSelectedBaseAndOption.planName) ||
          this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1)
      ) {
        this.autoSelectItem.forEach((item: any, indexAddon: any) => {
          if (this.autoSelectItem?.length > index && indexAddon <= index) {
            itemOfperformancePro.push(item);
            optionalPlanEvent = {
              prop: 'addOnOptions',
              event: { action: 'checkbox', currentSelection: itemOfperformancePro },
            };
          }
          if (this.autoSelectItem?.length - 1 == indexAddon && optionalPlanEvent) {
            this.setFormValueOnCancel('Addon', this.autoSelectItem, 'addOnOptions');
            this.onOptionalPlanChange(optionalPlanEvent, this.setZeroPricingDetails.product);
          }
        });
      }
    }
  }
  /**
   * Closes the specified service by collapsing its expandable section.
   *
   * This method sets the expand value for the selected service to false,
   * effectively closing it. Additional functionality related to closing
   * services can be implemented within this method if needed.
   *
   * @param {any} serviceSelected - The service to be closed.
   */
  closeService(serviceSelected: any) {
    this.setExpandValue(serviceSelected, false, true);
    // while closing the product which is in expandable format in select plan screen, if any functionality needs to be added then that can be done here.
  }
  /**
   * b2cValidationCheck() this function is used to decide whethere to perform validation check or not.
   * Updates the selectedFilters object accordingly.
   * @param {boolean} doNoValidateB2C - This is a boolean value true/false. true=> will not perform check false=> performs validation
   */
  b2cValidationCheck(doNoValidateB2C: boolean, appName: any) {
    if (doNoValidateB2C) {
      // B2C validation not required for AddOns
    } else {
      this.checkPriceForB2C(appName);
    }
  }
  /**
   * Checks the digital authorization status of ownership details.
   *
   * This method filters the ownership details to find checked owners.
   * If any owner has declined digital authorization, it displays an
   * error message prompting the user to authorize through the Digital
   * Authorization Tool to enable subscription management.
   */
  checkDatStatus() {
    const ownershipData =
      this.rowData?.ownershipDetails?.filter((owner: any) => {
        return owner.checked;
      }) || [];
    if (!isEmpty(ownershipData) && ownershipData[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== 'AGREE') {
      const messageText = `Customer has declined authorization. In order to enable subscription management, authorize through the  Digital Authorization Tool`;
      this.showToastMessage(messageText, 'error');
    }
  }

  /**
   *
   * @param message - This has the message value that needs to be displayed
   * @param status - This stores the status of the message.
   */
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
      duration: 3000,
      maxMessages: 2,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  /**
   * Retrieves applicable promotions for the specified subscription.
   *
   * This method checks the eligibility for promotions based on the
   * selected subscription and other parameters. It constructs the necessary
   * parameters, makes an API call to fetch promotions, and updates the
   * subscription data accordingly. It also handles the display of
   * promotional values and messages based on the response.
   *
   * @param {any} subs - The product name details for which the promo is being loaded.
   * @param {any} selOpt - The stored values related to the selected options.
   * @param {any} promoValChng - The values related to promo changes.
   * @param {any} isContractPeriodUpdated - Indicates if the contract period has been updated.
   * @param {any} downgradeUpgradeStatus - The status of upgrade or downgrade of plans.
   */
  getApplicablePromo(
    subs?: any,
    selOpt?: any,
    promoValChng?: any,
    isContractPeriodUpdated?: any,
    downgradeUpgradeStatus?: any
  ) {
    //For getting applicable promo for billing enabled privileges
    this.showOtoMessage = false;
    const param: ApplicablePromoParam = {
      key: 'custSubs',
      prevVal: this.getOptedSubs(this.rowData?.customerSubscriptionId),
      prevAddServ: this.profileBasedAdditionalServices?.optionListPrev,
    };
    const selectedSubsServVal = [];
    selectedSubsServVal.push(this.selectedBasePlan[subs]);
    if (!this.subsPricingEligible && !this.isDspAppMock) {
      return;
    }
    this.subsData[subs].contractPeriod.promoParams = {
      subs: subs,
      selOpt: selOpt,
      promoValChng: promoValChng,
    };
    const customerInfo = this.getValidCustomerForAsset();
    if (
      customerInfo?.length == 1 &&
      customerInfo[0].ucid &&
      findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo[0].ucid]) !== -1 &&
      this.b2cCustomers?.b2cSupportedApps?.indexOf(subs) !== -1
    ) {
      this.subsData[subs].templateCheck.prepayDisplayCheck = false;
      this.subsData[subs].promoInfo.showPromoValue = false;
      this.subsData[subs].promoInfo.showPromoLoad = false;
    } else {
      this.subsData[subs].templateCheck.promotionDisplayCheck = true;
      this.subsData[subs].promoInfo.showPromoValue = true;
      this.subsData[subs].promoInfo.showPromoLoad = true;
    }
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR ||
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      if (!isEmpty(this.profileBasedAdditionalServices?.optionList)) {
        Object.keys(this.profileBasedAdditionalServices.optionList).forEach((key: any) => {
          if (this.profileBasedAdditionalServices.optionList[key]) {
            selectedSubsServVal.push(key);
          }
        });
      }
      this.profileBasedAdditionalServices.chkbxValChng =
        this.checkIfAddnlServChanged(param.prevAddServ, this.profileBasedAdditionalServices.optionList) || promoValChng;
    }
    if (this.selectedBasePlan[subs] != 'None') {
      let custInfo = { dcnNumber: '', dcnName: '', ucid: '', customerName: '' };
      if (this.selectedBasePlan[subs] != 'None') {
        const validCustForAsset = this.getValidCustomerForAsset();
        if (validCustForAsset?.length == 1) {
          custInfo = {
            dcnNumber: validCustForAsset[0]?.dealerCustNo,
            dcnName: validCustForAsset[0]?.dealerCustName,
            ucid: validCustForAsset[0]?.ucid,
            customerName: validCustForAsset[0]?.ucidName,
          };
        }
      }
      const params: any = {
        assetSerialNumber: this.rowData?.serialNumber,
        assetMake: this.rowData?.make,
        deviceId: '',
        deviceSerialNumber: this.rowData?.deviceSerialNumer,
        deviceType: this.rowData?.deviceType,
        dcnNumber: custInfo?.dcnNumber,
        dcnName: custInfo?.dcnName,
        ucid: custInfo?.ucid,
        customerName: custInfo?.customerName,
        dealerCode: this.dealer?.dealerCode,
        applicationName: subs,
        siteName: this.subsData[subs]?.siteInfo?.siteVal?.name || '',
        subscriptions: selectedSubsServVal,
      };
      if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
        params.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
      }
      this.subsData[subs].activeContract =
        !this.rowData?.prepay &&
        this.rowData?.contractStartDate &&
        this.rowData?.contractEndDate &&
        downgradeUpgradeStatus != this.subsGradeChangeStatus?.Downgraded;
      this.setContractPeriodForPromotions(params, isContractPeriodUpdated, subs);
      params.prepay = this.subsData[subs]?.prepaySelected;
      const dualModeScope = {
        catalogMapping: this.catalogMapping,
        deviceType: this.rowData?.deviceType,
        selectedService: subs,
        selectedSubscription: this.customerPlan,
      };
      if (this.commModeFactoryService.isDualModeSubFn(dualModeScope)) {
        if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
          if (params?.subscriptions?.indexOf(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED) == -1)
            params?.subscriptions?.push(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED);
        }
        if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.SATELLITE_HOURS) {
          if (params?.subscriptions?.indexOf(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED) == -1)
            params?.subscriptions?.push(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED);
        }
      }
      this.promo.promoRetrieved = false;
      this.promo.promoParams = params;
      this.promoLoader[subs] = true;
      // this.triggerValChange();
      if (this.subsData[subs]?.promoInfo?.showPromoValue) {
        this.subsData[subs].promoInfo.promoValue = [];
        this.isPromotionAPICallCompleted = false;
        this.promotionAPICallData = this.assetService.getPromotions(params).subscribe({
          next: (res: any) => {
            this.isPromotionAPICallCompleted = true;
            this.promoLoader[subs] = false;
            this.subsData[subs].promoInfo.showPromoLoad = true;
            this.promo.promoRetrieved = true;
            // this.triggerValChange();
            this.updateMultiProductTriggerVal(subs);
            if (hasIn(res, 'applyOto') || hasIn(res, 'tudrImpact')) {
              this.subsData[subs].promoInfo.showPromoLoad = false;
              this.subsData[subs].promoInfo.promoValue = res?.subscriptionRatePlan;
              if (
                this.deviceFeaturesInfo[this.rowData.deviceType]?.OTO_CHARGE_APPLICABLE &&
                this.isNonBillableCust &&
                Object.prototype.hasOwnProperty.call(res, 'applyOTO') &&
                Object.prototype.hasOwnProperty.call(res, 'tudrImpact')
              ) {
                if (res?.applyOTO && res?.tudrImpact) {
                  this.otoMessage = this.languageTranslations['Worklist'].WL_OTO_APPLICABLE_MSG;
                  this.showOtoMessage = true;
                } else if (!res?.applyOTO && res?.tudrImpact) {
                  this.otoMessage = this.languageTranslations['Worklist'].WL_OTO_NOT_APPLICABLE_MSG;
                  this.showOtoMessage = true;
                } else {
                  this.showOtoMessage = false;
                }
                this.promo.promoParams.applyOTO = res?.applyOTO;
                this.promo.promoParams.tudrImpact = res?.tudrImpact;
              }
            } else {
              this.showOtoMessage = false;
              this.subsData[subs].promoInfo.promoValue = [];
              this.subsData[subs].promoInfo.showPromoLoad = false;
            }
            this.subsData[subs].isOtoMsgEnabled = this.showOtoMessage;
            this.subsData[subs].otoMessage = this.showOtoMessage ? this.otoMessage : '';
          },
          error: (_err: any) => {
            this.isPromotionAPICallCompleted = true;
            this.promoLoader[subs] = false;
            this.subsData[subs].promoInfo.showPromoLoad = false;
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else {
        this.subsData[subs].promoInfo.showPromoLoad = false;
        this.promo.promoRetrieved = true;
        // this.triggerValChange();
      }
    } else {
      this.subsData[subs].promoInfo.promoValue = [];
      this.subsData[subs].promoInfo.showPromoLoad = false;
      this.promo.promoParams = {};
    }
  }

  /**
   * Retrieves the opted subscription details based on the provided subscription ID.
   *
   * This method checks if a valid subscription ID is given. If so, it
   * retrieves the corresponding base subscription from the service mapping.
   * If no valid ID is found, it returns 'None'.
   *
   * @param {any} subId - The ID of the subscription to look up.
   * @returns {string} - The base subscription if found; otherwise, 'None'.
   */
  getOptedSubs(subId: any) {
    // filter selected subs for model
    if (subId) {
      const dataHldr = this.serviceMapping[subId];
      if (dataHldr) {
        return dataHldr?.baseSubscription;
      } else {
        return 'None';
      }
    } else {
      return 'None';
    }
  }
  /**
   *
   * @param params - This has the Contract Date details.
   * @param isContractPeriodUpdated - If any contract date updated those values are stored here.
   * @param apllicationName - The product name details are stored here.
   */
  setContractPeriodForPromotions(params: any, isContractPeriodUpdated: any, apllicationName?: any) {
    const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.products[apllicationName].addOns;
    if (
      this.subsPricingEligible &&
      (this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId) &&
      !isContractPeriodUpdated
    ) {
      if (
        this.selectedAssetSubscriptionFormData?.products[apllicationName]?.subscription?.currentSubscription
          ?.customerLevel !==
          this.selectedAssetSubscriptionFormData?.products[apllicationName]?.subscription?.previousSubscription
            ?.customerLevel ||
        this.validateAddons(currentAddons, previousddons)
      ) {
        if (this.subsData[apllicationName]?.prepayEligible && !this.subsData[apllicationName]?.activeContract) {
          this.subsData[apllicationName].prepaySelected = false;
          this.setContractDefaults(apllicationName);
          params.contractStartDate = null;
          params.contractEndDate = null;
        } else if (this.subsData[apllicationName]?.activeContract) {
          this.setUpgradedContractPeriod(apllicationName);
          params.contractStartDate = this.dateFormattingService.customContractDateFn(
            this.subsData[apllicationName]?.contractPeriod?.startDate
          );
          params.contractEndDate = this.dateFormattingService.customContractDateFn(
            this.subsData[apllicationName].contractPeriod?.endDate
          );
        }
      } else if (
        (this.subsData[apllicationName]?.prepayEligible && !!this.rowData?.prepay) ||
        this.subsData[apllicationName]?.activeContract
      ) {
        this.setSavedContractPeriod(apllicationName);
        params.contractStartDate = this.dateFormattingService.formatDate(
          new Date(this.rowData?.contractStartDate),
          'YYYY/MM/DD'
        );
        params.contractEndDate = this.dateFormattingService.formatDate(
          new Date(this.rowData?.contractEndDate),
          'YYYY/MM/DD'
        );
      } else {
        this.subsData[apllicationName].prepaySelected = false;
        params.contractStartDate = null;
        params.contractEndDate = null;
      }
    } else if (
      this.subsPricingEligible &&
      this.subsData[apllicationName]?.contractPeriod?.shadowStartDate != '-' &&
      isContractPeriodUpdated
    ) {
      params.contractStartDate = this.dateFormattingService?.customContractDateFn(
        this.subsData[apllicationName]?.contractPeriod?.startDate
      );
      params.contractEndDate = this.dateFormattingService?.customContractDateFn(
        this.subsData[apllicationName]?.contractPeriod?.endDate
      );
    } else {
      this.subsData[apllicationName].prepaySelected = false;
      params.contractStartDate = null;
      params.contractEndDate = null;
    }
  }

  /**
   * Sets the contract period for upgraded subscriptions.
   *
   * This method initializes the start and end dates of the contract period
   * using the current date and the existing contract end date. It also
   * configures the display settings for read-only applications and
   * disables the end date field.
   */
  setUpgradedContractPeriod(appName: any) {
    this.subsData[appName].contractPeriod.startDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
    this.subsData[appName].contractPeriod.endDate = this.dateFormattingService.formatDate(
      new Date(this.rowData?.contractEndDate),
      'MM/DD/YYYY'
    );
    this.subsData[appName].contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(
      new Date(),
      'MM/DD/YYYY'
    );
    // this.subs.contractPeriod.isDisabled = true;
    this.endDateFieldConfig.disabled = true;
    this.subsData[appName].contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
      this.subsData[appName]?.contractPeriod?.startDate
    );
  }
  /**
   *
   * @param deviceType - This has the deviceType Info.
   * @param selectedService - This has the selected Application Name Details.
   * @returns
   */
  getAllSubsInfoFn(deviceType: any, selectedService: any) {
    //assigned this non-view function to scope for inheritance
    const paramInfo: any = {};
    if (!this.rowData.make) {
      this.rowData.make = this.rowData.catMake;
    }
    paramInfo.assetId = this.rowData?.make + '|' + this.rowData?.serialNumber;
    paramInfo.commercialType = deviceType;
    paramInfo.model = this?.rowData?.model || '';
    paramInfo.serviceSubscribed = selectedService;
    paramInfo.prepay = this.subs?.prepaySelected;
    if (this.subsPricingEligible && this.subs?.contractPeriod?.shadowStartDate != '-') {
      const param: ApplicablePromoParam = {
        key: 'custSubs',
        prevVal: this.getOptedSubs(this.rowData?.customerSubscriptionId),
      };
      /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
        if (
          this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
        ) {
          param.key = 'dealerSubs';
          param.prevVal = this.getOptedSubs(this.rowData?.dealerSubscriptionId);
        } else*/
      if (this.fetchSubscriptionLevelMap(param?.key || '') == param?.prevVal && this.rowData?.contractStartDate) {
        paramInfo.contractStartDate = this.updateContractStartDate();
      } else {
        paramInfo.contractStartDate = this.dateFormattingService.customContractDateFn(
          this.subs?.contractPeriod?.startDate
        );
      }
      paramInfo.contractEndDate = this.dateFormattingService.customContractDateFn(this.subs?.contractPeriod?.endDate);
    } else {
      paramInfo.contractStartDate = null;
      paramInfo.contractEndDate = null;
    }
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
        paramInfo.productFamily = this.rowData.productFamily ? this.rowData.productFamily : null;
        paramInfo.siteId = this.customerPlan == 'None' ? null : this.subs?.siteInfo?.siteId || null;
        break;
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        let catSubsTmp_SingleLevel: any[] = [];
        let catDataInfo_SingleLevel: any[] = [];
        let custDataInfo_SingleLevel: any[] = [];
        let dealerDataInfo_SingleLevel: any[] = [];
        catDataInfo_SingleLevel.unshift(this.customerPlan);
        custDataInfo_SingleLevel.unshift(this.customerPlan);
        dealerDataInfo_SingleLevel.unshift(this.customerPlan);
        paramInfo.catLevel = this.customerPlan == 'None' ? [] : catDataInfo_SingleLevel;
        paramInfo.customerLevel = this.customerPlan == 'None' ? [] : custDataInfo_SingleLevel;
        paramInfo.dealerLevel = this.customerPlan == 'None' ? [] : dealerDataInfo_SingleLevel;
        paramInfo.customerPrevLevel = paramInfo.dealerPrevLevel = paramInfo.catPrevLevel = '';
        break;
    }

    return paramInfo;
  }

  /**
   * Retrieves the subscription level mapping based on the specified property.
   *
   * This method returns the appropriate subscription plan (customer, dealer,
   * or category) based on the provided subscription property. If the property
   * does not match any known type, it defaults to returning the customer plan.
   *
   * @param {any} subscriptionProperty - The property indicating which subscription
   *                                     level to retrieve.
   * @returns {any} - The corresponding subscription plan.
   */
  fetchSubscriptionLevelMap(subscriptionProperty: any) {
    switch (subscriptionProperty) {
      case 'custSubs':
        return this.customerPlan;
      case 'dealerSubs':
        return this.dealerPlan;
      case 'catSubs':
        return this.catPlan;
      default:
        return this.customerPlan;
    }
  }

  /**
   * Updates the contract start date based on existing values.
   *
   * This method checks if a contract period start date is set and differs
   * from the row data's contract start date. If so, it formats and returns
   * the new start date; otherwise, it returns the formatted original
   * contract start date.
   *
   * @returns {string} - The formatted contract start date in 'YYYY/MM/DD' format.
   */
  updateContractStartDate() {
    return this.subs?.contractPeriod?.startDate &&
      this.rowData?.contractStartDate != this.subs?.contractPeriod?.startDate
      ? this.dateFormattingService.formatDate(new Date(this.subs.contractPeriod.startDate), 'YYYY/MM/DD')
      : this.dateFormattingService.formatDate(new Date(this.rowData.contractStartDate), 'YYYY/MM/DD');
  }
  /**
   * Sets the asset subscription form data.
   *
   * This method passes the provided form data to the asset service for
   * further processing or storage.
   *
   * @param {AssetSubscriptionFormDataType} formData - The form data values
   *                                                    to be set.
   */
  setAssetSubscriptionFormData(formData: AssetSubscriptionFormDataType) {
    this.assetService.setAssetSubscriptionFormData(formData);
  }

  /**
   * Sets the subscription level category based on the selected product.
   *
   * This method updates the selected category in the subscription level
   * category and the asset subscription form data by checking which
   * category the opted service belongs to.
   *
   * @param {any} serviceOpted - The selected product details for which
   *                              the subscription category is being set.
   */
  setSubscriptionLevelCategory(serviceOpted: any) {
    const subsCategory = this.subsCategory;
    this.subscriptionLevelCategory.selectedCategory = '';
    for (const level in subsCategory) {
      // eslint-disable-next-line no-prototype-builtins
      if (subsCategory?.hasOwnProperty(level) && subsCategory[level]?.indexOf(serviceOpted) != -1) {
        this.subscriptionLevelCategory.selectedCategory = level;
        this.selectedAssetSubscriptionFormData.selectedCategory = level;
        break;
      }
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  /**
   * Sets the saved contract period for the specified application.
   *
   * This method checks if a valid rate plan exists and if the subscription
   * is eligible. If conditions are met, it formats and sets the contract
   * start and end dates, updates the shadow start date, and configures
   * the contract period as disabled. It also handles prepayment selection
   * and updates the asset subscription form data. If conditions are not
   * met, it resets the prepayment selection and sets default contract values.
   *
   * @param {any} applicationName - The name of the application for which
   *                                the contract period is being set.
   */
  setSavedContractPeriod(applicationName?: any) {
    this.isRatePlanExist = this.checkIfRatePlanExists(applicationName);
    if (
      this.subsPricingEligible &&
      this.isRatePlanExist &&
      this.rowData?.contractStartDate &&
      this.rowData?.contractEndDate &&
      (this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId)
    ) {
      this.subsData[applicationName].contractPeriod.startDate = this.dateFormattingService.formatDate(
        new Date(this.rowData?.contractStartDate),
        'YYYY/MM/DD'
      );
      this.subsData[applicationName].contractPeriod.endDate = this.dateFormattingService.formatDate(
        new Date(this.rowData?.contractEndDate),
        'YYYY/MM/DD'
      );
      this.subsData[applicationName].contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(
        new Date(),
        'YYYY/MM/DD'
      );
      this.subsData[applicationName].contractPeriod.isDisabled = true;
      this.endDateFieldConfig = {
        ...this.endDateFieldConfig,
        disabled: true,
      };
      this.subsData[applicationName].contractPeriod.showContractForReadOnlyApp =
        this.setContractEligibilityForReadOnlyApps(this.subsData[applicationName]?.contractPeriod?.startDate);
      this.subsData[applicationName].prepaySelected = !!this.rowData?.prepay;
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData = {
        contractEndDate: this.subsData[applicationName].contractPeriod.endDate,
        contractStartDate: this.subsData[applicationName].contractPeriod.startDate,
        prepaySelected: true,
      };
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    } else {
      this.subsData[applicationName].prepaySelected = false;
      this.setContractDefaults(applicationName);
    }
  }

  /**
   * Determines contract eligibility for read-only applications.
   *
   * This method checks if the selected service is part of the read-only
   * applications and whether the contract start date is set to '-'.
   * If both conditions are met, it indicates that the contract should
   * not be shown for read-only apps.
   *
   * @param {any} contractStartDate - The contract start date details.
   * @returns {boolean} - Returns true if the contract can be shown;
   *                      otherwise, false.
   */
  setContractEligibilityForReadOnlyApps(contractStartDate: any) {
    let showContractForReadOnlyApp = true;
    const parseContractReadonlyApps = this.dspConfig?.contractReadonlyApps
      ? JSON.parse(this.dspConfig?.contractReadonlyApps?.replace(/[‘’']/g, '"'))
      : [];

    if (parseContractReadonlyApps?.indexOf(this.selectedService) != -1 && contractStartDate == '-') {
      showContractForReadOnlyApp = false;
    }
    return showContractForReadOnlyApp;
  }
  /**
   * Sets the template conditions based on the selected application.
   *
   * This method evaluates various conditions related to subscription management
   * and sets flags for different template checks, including prepayment,
   * promotions, contract display, and warnings for specific services. It checks
   * for active features and the existence of rate plans to determine the
   * appropriate settings for the given service.
   *
   * @param {any} serviceSelected - The selected application name details
   *                                for which template conditions are being set.
   */
  setTemplateCondt(serviceSelected?: any) {
    if (this.selectedBasePlan?.hasOwnProperty(dspConstants.Worklist.NEW_VISION_LINK)) {
      this.isRatePlanExist = this.checkIfRatePlanExists(dspConstants.Worklist.NEW_VISION_LINK);
    } else if (this.selectedBasePlan?.hasOwnProperty(dspConstants.Worklist.VISION_LINK)) {
      this.isRatePlanExist = this.checkIfRatePlanExists(dspConstants.Worklist.VISION_LINK);
    }
    const isSubscriptionDisabled =
      this.userActiveFeatures?.indexOf('Subscription Management') == -1 ||
      (this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures?.indexOf('View Only') > -1 ||
          this.userActiveFeatures?.indexOf('Read Only') > -1 ||
          this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
      this.rowData?.suspended ||
      !this.rowData?.enableSubscription;
    // const subscriptionCatalogVal = this.catalogMapping?.[this.rowData?.deviceType][serviceSelected];
    const profile = this.telUserType || dspConstants.Common.CUSTOMER;
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      // this.subsData[serviceSelected].templateCheck.custSubs.disableServiceCheck = isSubscriptionDisabled;
      // this.subsData[serviceSelected].templateCheck.addOns.disableBtnCheck = isSubscriptionDisabled;
      // this.subsData[serviceSelected].templateCheck.addOns.disableAddOnListCheck = !(
      //   subscriptionCatalogVal &&
      //   subscriptionCatalogVal[profile][this.selectedBasePlan] &&
      //   subscriptionCatalogVal[profile][this.selectedBasePlan]['isSubscribable']
      // );
      this.subsData[serviceSelected].templateCheck.prepayDisableCheck =
        this.selectedBasePlan[serviceSelected] == 'None' || this.subsData[serviceSelected]?.contractPeriod?.isDisabled;
      this.subsData[serviceSelected].templateCheck.prepayDisplayCheck =
        this.subsData[serviceSelected]?.prepayEligible &&
        ((this.isRatePlanExist && this.isNonBillableCust && !this.subsData[serviceSelected]?.activeContract) ||
          this.selectedBasePlan[serviceSelected] == 'None' ||
          !this.selectedBasePlan[serviceSelected]);
      this.subsData[serviceSelected].templateCheck.promotionDisplayCheck =
        this.subsPricingEligible &&
        this.selectedBasePlan[serviceSelected] &&
        this.selectedBasePlan[serviceSelected] != 'None' &&
        this.selectedBasePlan[serviceSelected] != '' &&
        this.rowData?.enableSubscription &&
        !this.rowData?.suspended &&
        this.checkSubscriptionChange(serviceSelected);
      this.subsData[serviceSelected].templateCheck.contractDisplayCheck =
        this.subsPricingEligible &&
        this.selectedBasePlan[serviceSelected] != 'None' &&
        this.featuresToggleMap?.contractPeriodSupportedApplications?.indexOf(serviceSelected) != -1 &&
        (this.subsData[serviceSelected]?.activeContract ||
          (this.subsData[serviceSelected]?.prepayEligible && this.subsData[serviceSelected]?.prepaySelected)) &&
        this.isRatePlanExist &&
        this.isNonBillableCust;
      this.subsData[serviceSelected].templateCheck.showCatRfvWarning =
        (serviceSelected?.indexOf(dspConstants.Worklist.CAT_REMOTE_FLEET_VISION) != -1 ||
          serviceSelected?.indexOf(dspConstants.Worklist.MARINE_CONCIERGE_PLUS) != -1) &&
        this.listOfProducts.filter((item: any) => item.applicationName === serviceSelected)[0]?.applicablePlansDTOs
          ?.length === 0 &&
        !this.checkIfFSMExistForService(serviceSelected, this.rowData?.deviceType);
    } else if (
      this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR
    ) {
      this.subsData[serviceSelected].templateCheck.promotionDisplayCheck =
        this.userActiveFeatures.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures.indexOf('View Only') == -1 ||
          this.userActiveFeatures.indexOf('Read Only') == -1 ||
          this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1) &&
        this.subsPricingEligible &&
        this.selectedBasePlan[serviceSelected] &&
        this.selectedBasePlan[serviceSelected] != 'None' &&
        this.selectedBasePlan[serviceSelected] != '' &&
        this.rowData?.enableSubscription &&
        !this.rowData?.suspended &&
        this.checkSubscriptionChange(serviceSelected);
    }
  }
  /**
   * Retrieves the subscription price based on the asset's make.
   *
   * This method checks the provided base subscription price for the asset's
   * make and returns the corresponding price. If a specific price for the
   * make is not found, it falls back to 'Default', 'CAT', or 'NON-CAT' prices
   * in that order. If none are available, it returns undefined.
   *
   * @param {any} baseSubPrice - The base subscription prices for different makes.
   * @returns {number|undefined} - The subscription price for the asset's make, or
   *                               undefined if not found.
   */
  setSubPriceOnMake(baseSubPrice: any) {
    const makeOfAsset = this.rowData.make;
    if (baseSubPrice != null && baseSubPrice[makeOfAsset]) {
      baseSubPrice = parseInt(baseSubPrice[makeOfAsset]);
      return baseSubPrice;
    } else if (baseSubPrice != null && baseSubPrice['Default']) {
      baseSubPrice = parseInt(baseSubPrice['Default']);
      return baseSubPrice;
    } else if (baseSubPrice != null && parseInt(baseSubPrice['CAT']) != 0) {
      baseSubPrice = parseInt(baseSubPrice['CAT']);
      return baseSubPrice;
    } else if (baseSubPrice != null && parseInt(baseSubPrice['NON-CAT']) != 0) {
      baseSubPrice = parseInt(baseSubPrice['NON-CAT']);
      return baseSubPrice;
    }
  }
  /**
   * Checks if the feature message is supported for the selected subscription.
   *
   * This method filters the list of products to find the applicable plans
   * for the selected subscription. It iterates through the plans to determine
   * if any support the feature message, returning true if so; otherwise, false.
   *
   * @param {any} subSelected - The selected subscription details.
   * @param {any} deviceType - The type of device associated with the subscription.
   * @returns {boolean} - Returns true if the feature message is supported;
   *                      otherwise, false.
   */
  checkIfFSMExistForService(subSelected: any, deviceType: any) {
    let isFeatureMsgSupported = false;
    const mapInfo = this.listOfProducts.filter((item: any) => item.applicationName === subSelected)[0]
      ?.applicablePlansDTOs;
    mapInfo?.forEach((item1: any) => {
      if (item1?.isFeatureMsgSupported) {
        isFeatureMsgSupported = true;
      }
    });
    return isFeatureMsgSupported;
  }
  /**
   * Checks if there has been a change in the subscription details for the specified application.
   *
   * This method evaluates whether the current subscription, add-ons, or prepayment option
   * has changed compared to the previous subscription data. It returns true if any changes
   * are detected or if the application is not currently subscribed; otherwise, it returns false.
   *
   * @param {any} appName - The name of the application for which the subscription change is being checked.
   * @returns {boolean} - Returns true if a change in subscription details is detected; otherwise, false.
   */
  checkSubscriptionChange(appName: any) {
    if (
      this.rowData.reportingState === 'Subscribed' &&
      this.listOfSubscribed.some((item: any) => item.applicationName === appName)
    ) {
      const { currentSubscription, previousSubscription } =
        this.selectedAssetSubscriptionFormData.products[appName].subscription;
      const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.products[appName].addOns;
      const { prepaySelected: currentPrepayOption } =
        this.selectedAssetSubscriptionFormData.products[appName].prepay.currentPrepayData;
      const { prepaySelected: previousPrepayOption } =
        this.selectedAssetSubscriptionFormData.products[appName].prepay.previousPrepayData;
      return (
        currentSubscription.customerLevel !== previousSubscription.customerLevel ||
        !isEqual(currentAddons, previousddons) ||
        currentPrepayOption !== previousPrepayOption
      );
    }
    return true;
  }
  /**
   *
   * @param prevOptionList - This has prev addon Details.
   * @param currOptionList - This has current addon Details.
   * @returns
   */
  checkIfAddnlServChanged(prevOptionList: any, currOptionList: any) {
    let isAddnlServChanged = false;
    for (const option in currOptionList) {
      if (
        Object.prototype.hasOwnProperty.call(currOptionList, option) &&
        ((currOptionList[option] && !prevOptionList[option]) || (!currOptionList[option] && prevOptionList[option]))
      ) {
        isAddnlServChanged = true;
        break;
      }
    }
    return isAddnlServChanged;
  }
  /**
   * Sets the flag indicating whether the subscription is new.
   *
   * This method checks feature flags and updates the promotional parameters
   * to indicate if the subscription is new based on the selected subscription
   * level category. It specifically checks for the existence of customer
   * or dealer subscription IDs to determine if it's a new subscription.
   */
  setIsNewSubscription() {
    if (this.dspStoreData?.featureFlagInfo?.CVAEnabled === true) {
      this.promo.promoParams.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
    }
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        this.promo.promoParams.isNewSubscription = !this.rowData?.customerSubscriptionId;
        break;
      /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
        case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
          this.promo.promoParams.isNewSubscription = !this.rowData?.dealerSubscriptionId;
          break;*/
    }
  }
  /**
   * Displays a warning message in a modal for B2C customers.
   *
   * This method opens a semi-modal dialog with the specified content,
   * configuring its dimensions and behavior, such as preventing
   * closing on escape and disabling backdrop clicks.
   *
   * @param {any} content - The message content to be displayed in the modal.
   */
  b2cCustWarningMsg(content: any) {
    this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }
  /**
   * Determines whether to show an optional rate plan based on eligibility criteria.
   *
   * This method checks if the subscription pricing is eligible, if the customer
   * is non-billable, and if the selected plan is not included in the previous
   * add-ons for the specified application. It returns true if all conditions
   * are met and the plan has associated prices; otherwise, it returns false.
   *
   * @param {any} plan - The selected plan details, which may include planName or serviceName.
   * @param {any} appName - The name of the application for which the plan is being considered (optional).
   * @returns {boolean} - Returns true if the optional rate plan should be shown; otherwise, false.
   */
  showOptionalRatePlan(plan: any, appName?: any) {
    let planName = plan?.planName ? plan?.planName : plan?.serviceName;
    return this.subsPricingEligible && this.isNonBillableCust && plan?.prices ? true : false;
  }
  /**
   * Handles changes to the contract end date for the specified application.
   *
   * This method updates the shadow start date if it is set to '-', formats
   * the end date, and marks the contract period as changed. It sets prepayment
   * form data and checks conditions for promotional parameters. If applicable,
   * it triggers the promotional API call and updates the list of subscription
   * data in the asset service.
   *
   * @param {any} applicationName - The name of the application for which the
   *                                contract end date is being changed.
   */
  contractEndDateChange(applicationName?: any) {
    if (this.subsData[applicationName]?.contractPeriod?.shadowStartDate == '-') {
      this.subsData[applicationName].contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(
        new Date(),
        'MM/DD/YYYY'
      );
    }
    this.subsData[applicationName].contractPeriod.endDate = this.dateFormattingService.formatDate(
      this.subsData[applicationName]?.contractPeriod?.endDate,
      'YYYY/MM/DD'
    );
    this.isContractPeriodChanged = true;
    this.setPrepayFormData(applicationName);
    if (
      applicationName !== dspConstants.Worklist.MINESTAR ||
      (applicationName == dspConstants.Worklist.MINESTAR && this.subsData[applicationName].siteInfo.isValidSite)
    ) {
      const promoParams = this.subsData[applicationName]?.contractPeriod?.promoParams;
      if (promoParams && !promoParams?.subs) {
        promoParams.subs = applicationName;
      }
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(promoParams?.subs, promoParams?.selOpt, promoParams?.promoValChng, true);
      this.setIsNewSubscription();
      // this.triggerValChange();
      this.assetService.setListOfSubsData(this.subsData);
    }
  }

  /**
   * Handles changes to the date values for the specified application.
   *
   * This method updates the start or end date of the contract period based
   * on the provided date value. If the end date is changed, it also triggers
   * the necessary handling for the end date change. Finally, it updates
   * the list of subscription data in the asset service.
   *
   * @param {any} dateValue - The selected date values, including the event
   *                          and contract date type.
   * @param {any} applicationName - The name of the application for which
   *                                the date is being changed.
   */
  onDateChange(dateValue: any, applicationName?: any) {
    const { event, contractDateType } = dateValue;
    if (contractDateType === 'contractStartDate') {
      this.subsData[applicationName].contractPeriod.startDate = event;
    } else if (contractDateType === 'contractEndDate') {
      this.subsData[applicationName].contractPeriod.endDate = event;
      this.contractEndDateChange(applicationName);
    }
    this.assetService.setListOfSubsData(this.subsData);
  }
  /**
   * Displays a toast message with the specified details.
   *
   * This method configures and opens a toast message bar at the top center
   * of the specified host container. It allows for displaying a message
   * along with its status and optional link details.
   *
   * @param {string} message - The message to be displayed in the toast.
   * @param {string} status - The status indicating the type of message (e.g., success, error).
   * @param {any} linkDetail - Optional details for a link to be included in the message.
   */
  showDATToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  CheckIfNotSubscribableAsset(serviceSelected: any) {
    const subscriptionDetails = this.listOfBasePlans[serviceSelected].filter(
      (item: any) => item.baseSubscription === this.selectedBasePlan[serviceSelected]
    );
    return !(subscriptionDetails?.length > 0 && subscriptionDetails[0]?.isSubscribable);
  }
  /**
   * Retrieves the form controls for the specified application.
   *
   * This method iterates through the form controls in the 'products' FormArray
   * to find and return the FormGroup associated with the given application name.
   * If no matching application name is found, it returns undefined.
   *
   * @param {any} index - The index of the product in the FormArray (not used in this implementation).
   * @param {any} applicationName - The name of the application for which the controls are being retrieved.
   * @returns {FormGroup|undefined} - The FormGroup corresponding to the application name, or undefined if not found.
   */
  getControls(index: any, applicationName: any) {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    let returnFormData: any;
    Object.keys(data.controls).forEach((key) => {
      if (applicationName == data.controls[parseInt(key)].value.applicationName) {
        returnFormData = data.controls[parseInt(key)] as FormGroup;
      }
    });
    return returnFormData;
  }
  /**
   * Sets error states for the specified application based on validation results.
   *
   * This method updates the error state of the 'mineStarSiteName' control
   * in the form array for the given application. It clears errors if no
   * error is present; otherwise, it sets appropriate error messages based
   * on whether the site was found or not. If an error state is true, it
   * also returns the corresponding form group. Additionally, if the
   * application is 'MINESTAR' and a site name is provided, it updates
   * the control's value.
   *
   * @param {any} isError - Indicates whether there is an error (true/false).
   * @param {any} applicationName - The name of the application for which
   *                                the error state is being set.
   * @param {any} siteName - The name of the site to be patched (optional).
   * @param {any} siteNotFound - Indicates if the site was not found (optional).
   * @returns {FormGroup|undefined} - Returns the FormGroup corresponding to
   *                                   the application if an error is present;
   *                                   otherwise, undefined.
   */
  setError(isError: any, applicationName: any, siteName?: any, siteNotFound?: any) {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    let returnFormData: any;
    Object.keys(data.controls).forEach((key) => {
      if (applicationName == data.controls[parseInt(key)].value.applicationName) {
        if (!isError) {
          (data.controls[parseInt(key)] as FormGroup).controls['mineStarSiteName'].setErrors(null);
        } else {
          if (siteNotFound) {
            (data.controls[parseInt(key)] as FormGroup).controls['mineStarSiteName'].setErrors({
              siteNotFoundError: true,
            });
          } else {
            (data.controls[parseInt(key)] as FormGroup).controls['mineStarSiteName'].setErrors({ incorrect: true });
          }
        }
        if ((isError = 'error')) {
          returnFormData = data.controls[parseInt(key)];
        }
        if (applicationName == dspConstants.Worklist.MINESTAR && siteName) {
          data.controls[parseInt(key)].get('mineStarSiteName')?.patchValue(siteName);
        }
      }
    });
    return returnFormData;
  }
  /**
   * Closes the Cat Grade Connectivity popup.
   *
   * This method invokes the close function on the modal reference to
   * dismiss the Cat Grade Connectivity popup.
   */
  closeCatGradeConnectivityPopup() {
    this.catGradeModalRef.close();
  }
  /**
   * Closes the B2C customer warning message popup.
   *
   * This method closes the modal. If the resetFlag is true, it also sets
   * the isResetService property to true before closing the modal.
   *
   * @param {boolean} resetFlag - Indicates whether to reset the service
   *                              when closing the popup.
   */
  closeB2cCustWarningMsgPopup(resetFlag: boolean) {
    if (!resetFlag) {
      this.modal.close();
    } else {
      this.isResetService = true;
      this.modal.close();
    }
  }
  /**
   * Retrieves and stores the IDs of the base and optional plans for a given product.
   *
   * This method filters the applicable plans for the specified product to find
   * the base plan that matches the provided base plan criteria and stores its
   * details. If an optional plan is specified, it also retrieves the IDs of
   * applicable optional plans based on the selected base plan and updates the
   * corresponding properties in the addmoreButtonRetain object.
   *
   * @param {any} basePlan - The base plan details used for matching.
   * @param {any} product - The product containing applicable plans.
   * @param {any} optionalPlan - Optional additional plan details for filtering (optional).
   */
  returnBaseAndOptionalId(basePlan: any, product: any, optionalPlan?: any) {
    if (product?.applicablePlansDTOs && product?.applicablePlansDTOs?.length > 0) {
      if (optionalPlan) {
        this.addmoreButtonRetain.selectedOptionalPlanID[product.applicationName] = [];
      }
      product.applicablePlansDTOs.filter((item: any) => {
        if (basePlan && item.planName == basePlan?.event?.currentSelection[0]?.baseSubscription) {
          this.addmoreButtonRetain.selectedBasePlanID[product.applicationName] = [
            { id: item.planId, type: item.type, planName: item.planName },
          ];
        }
        if (
          optionalPlan &&
          item.addonApplicablePlansDTOs &&
          item.addonApplicablePlansDTOs.length > 0 &&
          this.selectedBasePlan[product.applicationName] == item.planName
        ) {
          this.addmoreButtonRetain.selectedOptionalPlanID[product.applicationName] = item.addonApplicablePlansDTOs
            .filter((data1: any) =>
              optionalPlan?.event?.currentSelection?.some((data2: any) => data2.serviceName === data1.planName)
            )
            .map((data: any) => {
              return { id: data.planId, type: data.type, planName: data.planName };
            });
        }
      });
    }
    this.assetService.listOfAddmorePlanDetails = this.addmoreButtonRetain;
  }
  /**
   * Resets the validity of the next button form based on the provided products.
   *
   * This method checks if the products array is null. If it is, the form is marked
   * as invalid. If products are provided, it iterates through them to determine
   * if any product has a valid subscription. If no valid subscriptions are found,
   * the form is marked as invalid.
   *
   * @param {null | Array<any>} products - The array of products to validate;
   *                                        can be null.
   */
  resetNextBtnValidForm(products: null | Array<any>) {
    if (products == null) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
      return;
    }

    let hasSubscribedProduct = false;
    const productsJSONData: { [key: string]: any } = products;

    for (const product in productsJSONData) {
      if (productsJSONData.hasOwnProperty(product) && typeof productsJSONData[product] === 'object') {
        const productDetails = productsJSONData[product];
        if (productDetails?.subscription?.currentSubscription?.catLevel !== '') {
          hasSubscribedProduct = true;
          break;
        }
      }
    }

    if (!hasSubscribedProduct) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
  }
  /**
   * Validates if there are changes between the current and previous add-ons.
   *
   * This method checks if the current and previous add-ons are both provided
   * and compares their lengths. If the lengths differ, it indicates a change.
   * If they are of the same length, it sorts both arrays and compares their
   * elements to check for any differences.
   *
   * @param {string[] | null} currentAddons - The current add-ons to validate.
   * @param {string[] | null} previousddons - The previous add-ons for comparison.
   * @returns {boolean} - Returns true if there are changes between the add-ons;
   *                      otherwise, false.
   */
  validateAddons(currentAddons: string[] | null, previousddons: string[] | null): boolean {
    if (!currentAddons || !previousddons) {
      return false;
    }
    if (currentAddons.length !== previousddons.length) {
      return true;
    }
    const sortedCurrent = currentAddons.slice().sort();
    const sortedPrevious = previousddons.slice().sort();
    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i] !== sortedPrevious[i]) {
        return true;
      }
    }
    return false;
  }
  /**
   * Checks if the next button should be enabled in the edit flow for a given application.
   *
   * This method evaluates the subscription status and changes in various properties
   * (such as add-ons, prepay options, site information, and product family) for
   * each product associated with the specified application. It returns true if
   * any relevant changes are detected that warrant enabling the next button;
   * otherwise, it returns false.
   *
   * @param {null | string} applicationName - The name of the application to check
   *                                          (default is null).
   * @returns {boolean} - Returns true if the next button should be enabled;
   *                      otherwise, false.
   */
  checkNextBtnForEditFlow(applicationName: null | string = null): boolean {
    const products: any = this.selectedAssetSubscriptionFormData.products;

    for (const product in products) {
      if (this.listOfSubscribed.some((item: any) => item.applicationName === product)) {
        const isMineStar = product === dspConstants.Worklist.MINESTAR;
        const item = products[product];
        const hasSubscription = item.subscription?.currentSubscription?.catLevel !== '';
        const { currentSubscription, previousSubscription } = item.subscription;
        const { currentAddons, previousddons } = item.addOns;
        const { currentPrepayData, previousPrepayData } = item.prepay;
        const { selectedSiteInfo, previousSiteInfo } = item;
        const { productFamily } = item;
        if (
          (hasSubscription &&
            product != dspConstants.Worklist.MINESTAR &&
            currentSubscription?.catLevel !== previousSubscription?.catLevel) ||
          (hasSubscription &&
            product == dspConstants.Worklist.MINESTAR &&
            currentSubscription?.catLevel !== previousSubscription?.catLevel &&
            this.siteName != '' &&
            !this.checkProductFamilyEnabled(item)) ||
          currentSubscription?.catLevel === 'None'
        ) {
          return true;
        }
        if (
          (currentAddons.length > 0 || this.isCurrentAddOnEmpty) &&
          this.validateAddons(currentAddons, previousddons)
        ) {
          if (this.checkProductFamilyEnabled(item) && productFamily.currentProductFamily !== '') {
            return true;
          } else if (!this.checkProductFamilyEnabled(item)) {
            return true;
          }
        }
        if (product === dspConstants.Worklist.NEW_VISION_LINK) {
          if (
            (previousPrepayData.prepaySelected === false && currentPrepayData.prepaySelected === true) ||
            (previousPrepayData.prepaySelected === true && currentPrepayData.prepaySelected === false)
          ) {
            return true;
          } else if (previousPrepayData.prepaySelected === true && currentPrepayData.prepaySelected === true) {
            if (
              currentPrepayData.contractStartDate !== previousPrepayData.contractStartDate ||
              currentPrepayData.contractEndDate !== previousPrepayData.contractEndDate
            ) {
              return true;
            }
          }
        }
        if (
          isMineStar &&
          selectedSiteInfo?.siteId &&
          selectedSiteInfo.siteName &&
          selectedSiteInfo.siteName !== previousSiteInfo.siteName &&
          this.siteName !== ''
        ) {
          return true;
        }
        if (
          isMineStar &&
          productFamily?.currentProductFamily !== '' &&
          productFamily?.currentProductFamily !== productFamily?.previousProductFamily
        ) {
          return true;
        }
      }
    }

    return false;
  }
  /**
   * Updates the validity of the multi-product subscription form based on the current state.
   *
   * This method checks various conditions for each product in the subscription
   * form data, including the reporting state and subscription levels. It evaluates
   * whether the form is valid for the next step in the edit flow. Specific checks
   * are made for the MineStar product and other subscribed products. The form's
   * validity state is updated accordingly.
   *
   * @param {null | string} applicationName - The name of the application to update
   *                                          the trigger value for (default is null).
   */
  updateMultiProductTriggerVal(applicationName: null | string = null) {
    const products: any = this.selectedAssetSubscriptionFormData.products;
    const mineStarProduct = products[dspConstants.Worklist.MINESTAR];
    let isValidForm = false;

    if (this.listOfSubscribed?.length > 0) {
      isValidForm = this.checkNextBtnForEditFlow(applicationName);
    }
    for (const product in products) {
      if (
        (this.rowData.reportingState === 'Subscribed' &&
          !this.listOfSubscribed.some((item: any) => item.applicationName === product)) ||
        this.rowData.reportingState !== 'Subscribed'
      ) {
        const isMineStar = product === dspConstants.Worklist.MINESTAR;
        const item = products[product];
        const hasSubscription =
          item.subscription?.currentSubscription?.catLevel !== '' &&
          item.subscription?.currentSubscription?.catLevel !== 'None';
        const { productFamily } = item;

        if (isMineStar) {
          const { siteName, siteId } = item.selectedSiteInfo;
          if (siteName !== '' && siteId !== '' && hasSubscription) {
            if (this.checkProductFamilyEnabled(item) && productFamily.currentProductFamily !== '') {
              isValidForm = true;
            } else if (!this.checkProductFamilyEnabled(item)) {
              isValidForm = true;
            }
          }
        } else if (hasSubscription && !isMineStar) {
          if (hasSubscription) {
            isValidForm = true;
          }
        }
      }
    }

    if (
      (mineStarProduct?.subscription?.currentSubscription?.catLevel !== 'None' &&
        mineStarProduct?.subscription?.selectedSubscription?.catLevel !== '' &&
        mineStarProduct?.siteInfo?.siteName === '') ||
      (mineStarProduct?.subscription?.currentSubscription?.catLevel !== 'None' &&
        mineStarProduct?.subscription?.previousSubscription?.catLevel !== '' &&
        mineStarProduct?.siteInfo?.siteName === '') ||
      (mineStarProduct?.subscription?.currentSubscription?.catLevel !== 'None' &&
        this.checkProductFamilyEnabled(mineStarProduct) &&
        mineStarProduct?.productFamily?.currentProductFamily === '' &&
        mineStarProduct?.productFamily?.previousProductFamily === '')
    ) {
      isValidForm = false;
    }
    this.selectedAssetSubscriptionFormData.isValidForm = isValidForm;
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Checks if the product has a matching subscription in the product family list.
   *
   * This method iterates through the product family list data to determine
   * if the current add-ons of the specified product include any subscriptions
   * from the product family. It returns true if a match is found; otherwise, false.
   *
   * @param {any} product - The product to check for matching subscriptions.
   * @returns {boolean} - Returns true if there is a matching subscription;
   *                      otherwise, false.
   */
  checkProductFamilyEnabled(product: any) {
    let hasMatchingSubscription = false;
    for (const key in this.PFamilyListData) {
      const subscription = this.PFamilyListData[key].subscription;
      if (product?.addOns?.currentAddons.includes(subscription)) {
        hasMatchingSubscription = true;
        break;
      }
    }
    return hasMatchingSubscription;
  }

  /**
   * Clones and stores the current state of relevant data for future compatibility.
   *
   * This method creates deep copies of various form-related data structures
   * and stores them in the `previousCompatible` object. This allows for
   * tracking changes and maintaining compatibility with previous selections
   * in the subscription management process.
   */
  compatiblePreviousFormData() {
    this.previousCompatible.optinalPlanList = cloneDeep(this.lastSelectedBaseAndOption.optionalPlanList);
    this.previousCompatible.addmoreButtonRetain = cloneDeep(this.addmoreButtonRetain);
    this.previousCompatible.manageAssetForm = cloneDeep(this.manageAssetSubscriptionForm);
    this.previousCompatible.listOfProducts = cloneDeep(this.listOfProducts);
    this.previousCompatible.listOfBasePlan = cloneDeep(this.listOfBasePlans);
    this.previousCompatible.listOfOptionalPlan = cloneDeep(this.listOfOptionalPlans);
    this.previousCompatible.selectedAssetFormData = cloneDeep(this.selectedAssetSubscriptionFormData);
    this.previousCompatible.subsData = cloneDeep(this.subsData);
    this.previousCompatible.siteName = cloneDeep(this.siteName);
    this.previousCompatible.minstarSiteNameDisabled = cloneDeep(this.minstarSiteNameDisabled);
  }
  /**
   * Restores the previous compatible state of form data.
   *
   * This method sets various properties of the current instance by cloning
   * the relevant data from the `previousCompatible` object. It updates
   * fields such as site name, subscription data, and selected plans based
   * on the previous state, ensuring that the form reflects the last
   * compatible configuration.
   */
  setCompatiblePreviousFormData() {
    // this.manageAssetSubscriptionForm = cloneDeep(this.previousCompatible.manageAssetForm);
    this.minstarSiteNameDisabled = cloneDeep(this.previousCompatible.minstarSiteNameDisabled);
    this.lastSelectedBaseAndOption.optionalPlanList = cloneDeep(this.previousCompatible.optinalPlanList);
    this.addmoreButtonRetain = this.previousCompatible.addmoreButtonRetain;
    this.listOfProducts = cloneDeep(this.previousCompatible.listOfProducts);
    this.listOfBasePlans = cloneDeep(this.previousCompatible.listOfBasePlan);
    this.listOfOptionalPlans = cloneDeep(this.previousCompatible.listOfOptionalPlan);
    this.selectedAssetSubscriptionFormData = cloneDeep(this.previousCompatible.selectedAssetFormData);
    if (
      this.lastSelectedBaseAndOption.type == 'Base' &&
      this.selectedBasePlan.hasOwnProperty(this.lastSelectedBaseAndOption.apllicationName)
    ) {
      if (
        this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName].subscription
          .currentSubscription.catLevel
      ) {
        this.selectedBasePlan[this.lastSelectedBaseAndOption.apllicationName] =
          this.selectedAssetSubscriptionFormData.products[
            this.lastSelectedBaseAndOption.apllicationName
          ].subscription.currentSubscription.catLevel;
      } else if (
        this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName].subscription
          .previousSubscription.catLevel
      ) {
        this.selectedBasePlan[this.lastSelectedBaseAndOption.apllicationName] =
          this.selectedAssetSubscriptionFormData.products[
            this.lastSelectedBaseAndOption.apllicationName
          ].subscription.previousSubscription.catLevel;
      } else {
        delete this.selectedBasePlan[this.lastSelectedBaseAndOption.apllicationName];
      }
    }
    this.siteName = cloneDeep(this.previousCompatible.siteName);
    this.subsData = cloneDeep(this.previousCompatible.subsData);
    this.formAssetFormCustomerAndAddonPatchValue();
  }
  /**
   * Resets selected plan data to 'None' for all products upon cancellation.
   *
   * This method iterates through the selected asset subscription form data
   * and updates relevant properties to reflect a cancellation action. It
   * sets the current and previous subscription levels to 'None', adjusts
   * the list of products and optional plans, and updates the asset service
   * with the modified data. Additionally, it handles specific conditions
   * for certain products like NEW_VISION_LINK and VISION_LINK.
   */
  setNoneOnCancel() {
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let valueData: any = value;
      if (
        valueData.subscription?.currentSubscription?.catLevel ||
        valueData.subscription?.previousSubscription?.catLevel
      ) {
        this.removeSelectedFormData(key);
        if (this.selectedBasePlan.hasOwnProperty(key)) {
          this.selectedBasePlan[key] = 'None';
        }
        this.listOfProducts.forEach((item: any) => {
          if (item.apllicationName === key) {
            item['expand'] = true;
          }
        });
        this.subsData[key] = cloneDeep(this.subs);
        // this.setContractDefaults(key);
        this.listOfOptionalPlans[key] = [];
        this.setFormValueOnConfirm('Base', '', 'customerPlan', key);
        // this.addmoreButtonRetain.selectedProduct.splice(this.addmoreButtonRetain.selectedProduct.indexOf(key), 1);
        if (this.addmoreButtonRetain.selectedBasePlanID.hasOwnProperty(key)) {
          this.addmoreButtonRetain.selectedBasePlanID[key] = [{ id: '', planName: 'None', type: 'Base' }];
        }
        if (this.addmoreButtonRetain.selectedOptionalPlanID.hasOwnProperty(key)) {
          delete this.addmoreButtonRetain.selectedOptionalPlanID[key];
          this.addmoreButtonRetain.selectedOptionalPlanID[key] = [];
        }
        this.ListOfproductOrder();
        if (key === dspConstants.Worklist.NEW_VISION_LINK || key === dspConstants.Worklist.VISION_LINK) {
          this.setIfPrepayIsEligible(key);
          this.setTemplateCondt(key);
        }
        // if (this.addmoreButtonRetain.selectedProduct?.length == 0) {
        //   this.assetService.isCancelAll.complete();
        // }
      }
    }
    this.assetService.setListOfSubsData(this.subsData);
    this.assetService.setListOfProduct({
      listOfProducts: this.listOfProducts,
      listOfBasePlans: this.listOfBasePlans,
      listOfOptionalPlans: this.listOfOptionalPlans,
      listOfSubscribed: this.listOfSubscribed,
      listOfGreyOut: this.greyOutPlan,
    });
    this.assetService.setManageAssetForm(this.manageAssetSubscriptionForm);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.assetService.listOfAddmorePlanDetails = this.addmoreButtonRetain;
  }
  /**
   * Resets the selected form data for a specific application.
   *
   * This method updates the subscription and add-on levels of the specified
   * application to 'None', effectively clearing any current selections. It
   * also restores default values for add-ons, prepayment data, and product
   * family from the product data. Finally, it updates the asset subscription
   * form data to reflect these changes.
   *
   * @param {any} applicationName - The name of the application for which
   *                                the form data is being reset.
   */
  removeSelectedFormData(applicationName: any) {
    this.minstarSiteNameDisabled[applicationName] = 'None';
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.catLevel = 'None';
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.customerLevel =
      'None';
    this.selectedAssetSubscriptionFormData.products[applicationName].subscription.currentSubscription.dealerLevel =
      'None';

    this.selectedAssetSubscriptionFormData.products[applicationName].addOns.currentAddons = cloneDeep(
      this.productData.addOns.currentAddons
    );
    // this.selectedAssetSubscriptionFormData.products[applicationName].selectedSiteInfo = cloneDeep(
    // this.productData.selectedSiteInfo
    // );
    this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData = cloneDeep(
      this.productData.prepay.currentPrepayData
    );
    this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.currentProductFamily = cloneDeep(
      this.productData.productFamily.currentProductFamily
    );
    // this.selectedAssetSubscriptionFormData.products[applicationName].siteInfo = cloneDeep(this.productData.siteInfo);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Removes existing compatible data based on the success or failure messages.
   *
   * This method iterates through the success or failure messages and processes
   * each element to remove or reset associated data for both base plans and
   * add-ons. It updates various properties, including the selected form data,
   * subscription data, and optional plans. Specific conditions are handled for
   * each type of application, ensuring that the state reflects the removal of
   * incompatible data while maintaining necessary defaults.
   */
  removeExistingCompatibleData() {
    this.successOrFailureMessages?.forEach((element: any) => {
      if (element.type == 'Base' && element.applicationName != this.lastSelectedBaseAndOption.apllicationName) {
        if (this.rowData.reportingState === 'Subscribed') {
          this.removeSelectedFormData(element.applicationName);
        } else {
          this.selectedAssetSubscriptionFormData.products[element.applicationName] = cloneDeep(this.productData);
        }
        if (this.selectedBasePlan.hasOwnProperty(element.applicationName)) {
          delete this.selectedBasePlan[element.applicationName];
        }
        this.subsData[element.applicationName] = cloneDeep(this.subs);
        this.setContractDefaults(element.applicationName);
        this.listOfOptionalPlans[element.applicationName] = [];
        this.setFormValueOnConfirm('Base', '', 'customerPlan', element.applicationName);
        this.addmoreButtonRetain.selectedProduct.splice(
          this.addmoreButtonRetain.selectedProduct.indexOf(element.applicationName),
          1
        );
        if (this.addmoreButtonRetain.selectedBasePlanID.hasOwnProperty(element.applicationName)) {
          delete this.addmoreButtonRetain.selectedBasePlanID[element.applicationName];
        }
        if (this.addmoreButtonRetain.selectedOptionalPlanID.hasOwnProperty(element.applicationName)) {
          delete this.addmoreButtonRetain.selectedOptionalPlanID[element.applicationName];
        }
        this.ListOfproductOrder();
        if (
          element.applicationName === dspConstants.Worklist.NEW_VISION_LINK ||
          element.applicationName === dspConstants.Worklist.VISION_LINK
        ) {
          this.setIfPrepayIsEligible(element.applicationName);
          this.setTemplateCondt(element.applicationName);
        }
      }
      if (element.type == 'Addon' && element.applicationName != this.lastSelectedBaseAndOption.apllicationName) {
        if (this.addmoreButtonRetain.selectedOptionalPlanID.hasOwnProperty(element.applicationName)) {
          const indexValue = this.addmoreButtonRetain?.selectedOptionalPlanID[element.applicationName]?.findIndex(
            (elem: any) => elem.planName == element.addon
          );
          this.addmoreButtonRetain.selectedOptionalPlanID[element.applicationName].splice(indexValue, 1);
        }
        this.resetCurrentPrepayPlans(element);
        this.subsData[element.applicationName].prepaySelected = false;
        this.setContractDefaults(element.applicationName);
        this.setTemplateCondt(element.applicationName);
        if (
          Array.isArray(
            this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons
          ) &&
          this.selectedAssetSubscriptionFormData.products[element.applicationName]?.addOns?.currentAddons?.length > 0
        ) {
          // this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons.splice(
          //   this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons.indexOf(
          //     element.addon
          //   ),
          //   1
          // );

          // To avoid array mutation, use slice/toSplice methods instead of splice
          const removedAddon = this.selectedAssetSubscriptionFormData.products[
            element.applicationName
          ].addOns.currentAddons.toSpliced(
            this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons.indexOf(
              element.addon
            ),
            1
          );

          this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons = removedAddon;

          // this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons =
          //   this.selectedAssetSubscriptionFormData.products[element.applicationName].addOns.currentAddons?.filter(
          //     (item: any) => item !== element.addon
          //   );
        }

        let addOnOptionsValue: any;
        const data = this.manageAssetSubscriptionForm.get('products') as FormArray;
        Object.keys(data.controls).forEach((key) => {
          if (data.controls[parseInt(key)].value.applicationName == element.applicationName) {
            addOnOptionsValue = cloneDeep((data.controls[parseInt(key)] as FormGroup).get('addOnOptions'));
          }
        });
        let patchValue: any = [];
        if (addOnOptionsValue?.value?.length > 0) {
          patchValue = addOnOptionsValue.value;
        }
        patchValue.splice(
          patchValue.findIndex((elem: any) => elem.serviceName == element.addon),
          1
        );
        if (
          this.lastSelectedBaseAndOption.optionalPlanList &&
          this.lastSelectedBaseAndOption.optionalPlanList[element.applicationName]
        ) {
          this.lastSelectedBaseAndOption.optionalPlanList[element.applicationName].splice(
            this.lastSelectedBaseAndOption.optionalPlanList[element.applicationName].findIndex(
              (elem: any) => elem.serviceName == element.addon
            ),
            1
          );
        }
        Object.keys(data.controls).forEach((key) => {
          if (data.controls[parseInt(key)].value.applicationName == element.applicationName) {
            (data.controls[parseInt(key)] as FormGroup).get('addOnOptions')?.patchValue(patchValue);
          }
        });
      }
    });
    this.assetService.setManageAssetForm(this.manageAssetSubscriptionForm);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * Compares two sets of optional data to identify missing objects.
   *
   * This method checks if one dataset has service names that are not present
   * in the other. It populates an array with any missing objects and updates
   * the `lastSelectedBaseAndOption` with relevant details if discrepancies
   * are found. The method determines which dataset is larger and sets the
   * appropriate flags based on the comparison.
   *
   * @param {any} data1 - The first set of optional data to compare.
   * @param {any} data2 - The second set of optional data to compare.
   * @param {any} applicationName - The name of the application associated
   *                                with the optional plans.
   */
  compareOptionalData(data1: any, data2: any, applicationName: any) {
    const missingObjects = [];
    let data = '';
    if (data1?.length > data2?.length) {
      const data2ServiceNames = data2.map((obj: any) => obj.serviceName);
      data = 'unchecked';
      for (const obj of data1) {
        if (!data2ServiceNames.includes(obj.serviceName)) {
          missingObjects.push(obj);
        }
      }
    } else {
      const data1ServiceNames = data1?.map((obj: any) => obj?.serviceName);
      data = 'checked';
      for (const obj of data2) {
        if (!data1ServiceNames.includes(obj.serviceName)) {
          missingObjects.push(obj);
        }
      }
    }

    if (missingObjects.length > 0) {
      this.lastSelectedBaseAndOption.optionalPlanList[applicationName] = cloneDeep(data2);
      this.lastSelectedBaseAndOption.planName = missingObjects[missingObjects.length - 1].serviceName;
      this.lastSelectedBaseAndOption.optionalPlan = missingObjects[missingObjects.length - 1].serviceName;
      this.lastSelectedBaseAndOption.type = 'Addon';
      this.lastSelectedBaseAndOption.optionalPlanChecked = data;
      this.lastSelectedBaseAndOption.isReset = false;
    }
  }
  /**
   * Retrieves a list of plan IDs based on selected base and optional plans.
   *
   * This method iterates through the provided base and optional plan IDs,
   * checking for matches based on the specified type and plan name. It
   * collects the corresponding IDs into a result array, considering
   * conditions for both base and optional plans. If no optional plans
   * are available, it adjusts the count accordingly.
   *
   * @param {any} selectedBasePlanID - The selected base plan IDs to evaluate.
   * @param {any} selectedOptionalPlanID - The selected optional plan IDs to evaluate.
   * @param {any} type - The type of plan (e.g., Base or Addon).
   * @param {any} planName - The name of the plan to match against.
   * @param {any} [addmoreProduct] - An optional parameter for additional product
   *                                  information (default is undefined).
   * @returns {any[]} - An array of matching plan IDs.
   */
  getPlanListId(selectedBasePlanID: any, selectedOptionalPlanID: any, type: any, planName: any, addmoreProduct?: any) {
    const result = [];
    let count = 0;
    let applicationName = addmoreProduct
      ? cloneDeep(addmoreProduct)
      : cloneDeep(this.lastSelectedBaseAndOption.apllicationName);
    for (const key1 in selectedBasePlanID) {
      let values = selectedBasePlanID[key1];
      if (count == 0) {
        for (let value of values) {
          if (count == 0 && value.type === type && value.planName === planName) {
            count++;
            if (planName !== 'None' && !this.greyOutPlan?.base?.includes(value.planName)) {
              result.push(value.id);
            }

            break;
          } else {
            if (value?.id && !this.greyOutPlan?.base?.includes(value.planName)) {
              result.push(value.id);
            }
          }
        }
        for (const key2 in selectedOptionalPlanID) {
          if (key1 == key2) {
            const valuesData = selectedOptionalPlanID[key1];
            if (key2 == applicationName && type == 'Addon' && valuesData?.length == 0) {
              count++;
            }
            if (count == 0) {
              for (let i = 0; i < valuesData.length; i++) {
                if (valuesData[i].type === type && !this.greyOutPlan?.addon?.includes(valuesData[i].planName)) {
                  result.push(valuesData[i].id);
                  if (valuesData.length - 1 == i && key1 == applicationName && type == 'Addon') {
                    count++;
                    break;
                  }
                } else {
                  if (!this.greyOutPlan?.addon?.includes(valuesData[i].planName)) {
                    result.push(valuesData[i].id);
                  }
                }
              }
            }
          }
        }
      }
    }
    return result;
  }
  /**
   * Patches the customer and add-on values in the asset subscription form based on the last selected base option.
   *
   * This method iterates through the selected asset subscription form data to update
   * the relevant base and add-on plans. It applies necessary changes to site information
   * and manages the selection of plans based on the current subscription state. The method
   * also handles auto-selection for add-ons and ensures that the site name is set correctly
   * when required.
   */
  formAssetFormCustomerAndAddonPatchValue() {
    this.lastSelectedBaseAndOption;
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let valueData: any = value;
      if (this.lastSelectedBaseAndOption.apllicationName == key) {
        let data = this.listOfProducts.filter((item: any) => {
          if (this.lastSelectedBaseAndOption.apllicationName == item.applicationName) {
            return item;
          }
        });
        if (this.lastSelectedBaseAndOption.type == 'Base') {
          let matchBasePlan: any = [];
          data[0].applicablePlansDTOs.filter((applicablePlan: any) => {
            if (applicablePlan.planName === valueData.subscription.currentSubscription.catLevel) {
              matchBasePlan.push(this.patchValue(applicablePlan, key));
            }
            if (
              this.rowData.reportingState === 'Subscribed' &&
              !valueData.subscription.currentSubscription.catLevel &&
              valueData.subscription.previousSubscription.catLevel &&
              applicablePlan.planName === valueData.subscription.previousSubscription.catLevel
            ) {
              matchBasePlan.push(this.patchValue(applicablePlan, key));
            }
          });
          if (
            this.lastSelectedBaseAndOption.apllicationName == dspConstants.Worklist.MINESTAR &&
            matchBasePlan?.length == 0
          ) {
            this.siteName = '';
            if (this.subsData[key]?.siteInfo?.siteVal?.name) {
              this.subsData[key].siteInfo.siteVal.name = '';
            }
            this.selectedAssetSubscriptionFormData.products[key].selectedSiteInfo.siteName = '';
            this.selectedAssetSubscriptionFormData.products[key].selectedSiteInfo.siteId = '';
            this.selectedAssetSubscriptionFormData.products[key].siteInfo.siteName = '';
            this.selectedAssetSubscriptionFormData.products[key].siteInfo.siteId = '';

            this.setMineStarSiteNameOnInit();
          }
          this.setFormValueOnCancel('base', matchBasePlan, 'customerPlan');
          let addonMatch: any = [];
          if (Array.isArray(valueData.addOns.currentAddons && valueData.addOns.currentAddons?.length > 0)) {
            valueData.addOns.currentAddons?.filter((addons: any) => {
              data[0].applicablePlansDTOs?.filter((basePlan: any) => {
                basePlan.addonApplicablePlansDTOs?.filter((item: any) => {
                  if (
                    item.planName === addons &&
                    (valueData.subscription.currentSubscription.catLevel === basePlan.planName ||
                      valueData.subscription.currentSubscription.customerLevel === basePlan.planName)
                  ) {
                    addonMatch.push(this.patchValue(item, key));
                  }
                });
              });
            });
          }
          this.setFormValueOnCancel('Addon', addonMatch, 'addOnOptions');
        } else if (this.lastSelectedBaseAndOption.type == 'Addon') {
          let addonMatch: any = [];
          if (Array.isArray(valueData.addOns.currentAddons) && valueData.addOns.currentAddons?.length > 0) {
            valueData.addOns.currentAddons?.filter((addons: any) => {
              data[0].applicablePlansDTOs?.filter((basePlan: any) => {
                basePlan.addonApplicablePlansDTOs?.filter((item: any) => {
                  if (
                    item.planName === addons &&
                    (valueData.subscription.currentSubscription.catLevel === basePlan.planName ||
                      valueData.subscription.currentSubscription.customerLevel === basePlan.planName)
                  ) {
                    addonMatch.push(this.patchValue(item, key));
                  }
                });
              });
            });
          }
          this.setFormValueOnCancel('Addon', addonMatch, 'addOnOptions');
        }
      }
    }
    if (
      this.autoSelectItem?.length > 0 &&
      (this.autoSelectItem.some((item) => item.serviceName == this.lastSelectedBaseAndOption.planName) ||
        this.enableAutoSelectAddon.indexOf(this.lastSelectedBaseAndOption.planName) > -1)
    ) {
      let itemOfperformancePro: any = [];
      let optionalPlanEvent: any;
      this.autoSelectItem.forEach((item: any, indexAddon: any) => {
        if (item.serviceName == this.lastSelectedBaseAndOption.planName) {
          this.autoSelectItem?.splice(indexAddon, 1);
        }
      });
      this.autoSelectItem.forEach((item: any, indexAddon: any) => {
        itemOfperformancePro.push(item);
        optionalPlanEvent = {
          prop: 'addOnOptions',
          event: { action: 'checkbox', currentSelection: itemOfperformancePro },
        };
        if (this.autoSelectItem?.length - 1 == indexAddon && itemOfperformancePro?.length > 0 && optionalPlanEvent) {
          this.setFormValueOnCancel('Addon', itemOfperformancePro, 'addOnOptions');
          this.onOptionalPlanChange(optionalPlanEvent, this.setZeroPricingDetails.product);
        }
      });
    }
    if (this.siteName) {
      this.setMineStarSiteNameOnInit();
    }
  }
  /**
   * Creates a patched value object for a given plan.
   *
   * This method constructs an object containing the label and value for
   * a plan, based on whether the plan is an optional rate plan. It includes
   * the plan's pricing information in the label if applicable. The resulting
   * object also indicates whether the plan is subscribable and includes
   * the service name.
   *
   * @param {any} plan - The plan object to be processed.
   * @param {any} [appName] - An optional application name that may influence
   *                          how the value is patched (default is undefined).
   * @returns {Object} - The patched value object containing label, value,
   *                     subscribable status, and service name.
   */
  patchValue(plan: any, appName?: any) {
    let patchValueSet = {
      label: this.showOptionalRatePlan(plan, appName) ? plan?.planName + ' - ' + plan?.prices : plan?.planName,
      value: this.showOptionalRatePlan(plan, appName) ? plan?.planName + ' - ' + plan?.prices : plan?.planName,
      isSubscribable: true,
      serviceName: plan?.planName,
    };
    return patchValueSet;
  }
  /**
   * Updates form control values upon cancellation of a selection.
   *
   * This method iterates through the product controls in the asset subscription
   * form and patches the specified form control type with the provided values.
   * It also handles specific adjustments for the MineStar application, resetting
   * the site name if no base plans are selected and updating the product family
   * accordingly.
   *
   * @param {any} type - The type of action being performed (e.g., Base or Addon).
   * @param {any} patchValueSet - The value set to be patched into the form control.
   * @param {any} formControlType - The name of the form control type to be updated.
   */
  setFormValueOnCancel(type: any, patchValueSet: any, formControlType: any) {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    Object.keys(data?.controls).forEach((key) => {
      if (this.lastSelectedBaseAndOption.apllicationName == data.controls[parseInt(key)].value.applicationName) {
        (data.controls[parseInt(key)] as FormGroup).get(formControlType)?.patchValue(patchValueSet);
        if (this.lastSelectedBaseAndOption.apllicationName == dspConstants.Worklist.MINESTAR) {
          if (
            !this.addmoreButtonRetain.selectedBasePlanID[this.lastSelectedBaseAndOption.apllicationName] &&
            this.addmoreButtonRetain.selectedBasePlanID[this.lastSelectedBaseAndOption.apllicationName]?.length == 0
          ) {
            this.siteName = '';
          }
          (data.controls[parseInt(key)] as FormGroup).get('mineStarSiteName')?.patchValue(this.siteName);
          this.setProductFamilyOnCancel(type, patchValueSet);
        }
      }
    });
  }
  /**
   * Updates the selected product family based on cancellation type.
   *
   * This method modifies the product family selection in the asset subscription
   * form when the cancellation action occurs. It checks if the provided patch
   * values indicate an existing product family for add-ons and updates the
   * corresponding controls accordingly. For base types, it clears the selected
   * product family. The method also validates add-ons if applicable.
   *
   * @param {any} type - The type of product (e.g., 'Addon' or 'base').
   * @param {any} patchValueSet - The set of values that are being patched into the form.
   */
  setProductFamilyOnCancel(type: any, patchValueSet: any) {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    const controls = data?.controls;

    if (type === 'Addon') {
      let isProductFamilyExists =
        patchValueSet.length > 0 &&
        this.productFamily.productFamilyList.length > 0 &&
        patchValueSet.some((patch: { serviceName: any }) =>
          this.productFamily.productFamilyList.some(
            (product: { subscription: any }) => patch.serviceName === product.subscription
          )
        );

      Object.keys(controls).forEach((key) => {
        const control = controls[parseInt(key)] as FormGroup;
        if (!isProductFamilyExists) {
          this.productFamily.selectedAddOnProductFamilyList = [];
          control.get('selectedProductFamily')?.patchValue('');
        } else {
          control
            .get('selectedProductFamily')
            ?.patchValue(
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].productFamily
                .currentProductFamily
            );
        }
      });

      if (
        isProductFamilyExists &&
        !this.validateAddons(
          this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].addOns.currentAddons,
          this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].addOns.previousddons
        )
      ) {
        this.modifiedProductFamily = false;
      }
    } else if (type === 'base') {
      Object.keys(controls).forEach((key) => {
        this.productFamily.selectedAddOnProductFamilyList = [];
        (controls[parseInt(key)] as FormGroup).get('selectedProductFamily')?.patchValue('');
      });
    }
  }

  /**
   * Initializes the MineStar site name in the asset subscription form.
   *
   * This method iterates through the product controls in the asset subscription
   * form and sets the MineStar site name for any control that matches the
   * MineStar application. It updates the corresponding field with the current
   * site name value.
   */
  setMineStarSiteNameOnInit() {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    Object.keys(data.controls).forEach((key) => {
      if (data.controls[parseInt(key)].value.applicationName == dspConstants.Worklist.MINESTAR)
        (data.controls[parseInt(key)] as FormGroup).get('mineStarSiteName')?.patchValue(this.siteName);
    });
  }
  /**
   * Updates form control values upon confirming a selection.
   *
   * This method patches the specified form control type with the provided
   * values based on the type of selection (Base or Addon) and the application
   * name. For base plans, it handles special cases such as resetting add-on
   * options and site names, especially for the MineStar application. For
   * add-ons, it checks the current values and updates accordingly.
   *
   * @param {any} type - The type of action being confirmed (e.g., 'Base' or 'Addon').
   * @param {any} patchValueSet - The value set to be patched into the form control.
   * @param {any} formControlType - The name of the form control type to be updated.
   * @param {any} applicationName - The name of the application associated with the form.
   * @param {any} [addonValue] - An optional value relevant to add-on selections (default is undefined).
   */
  setFormValueOnConfirm(type: any, patchValueSet: any, formControlType: any, applicationName: any, addonValue?: any) {
    const data = this.manageAssetSubscriptionForm?.get('products') as FormArray;
    Object.keys(data?.controls)?.forEach((key) => {
      if (applicationName == data.controls[parseInt(key)].value.applicationName) {
        if (type === 'Base') {
          if (this.rowData.reportingState === 'Subscribed') {
            patchValueSet = [
              {
                label: 'None',
                value: 'None',
                isSubscribable: true,
                serviceName: applicationName,
              },
            ];
          }
          this.listOfOptionalPlans[applicationName] = [];
          (data.controls[parseInt(key)] as FormGroup).get(formControlType)?.patchValue(patchValueSet);

          (data.controls[parseInt(key)] as FormGroup).get('addOnOptions')?.patchValue('');
          this.listOfProducts.filter((item: any) => {
            if (applicationName == item.applicationName) {
              item.reset = false;
            }
          });
          if (applicationName == dspConstants.Worklist.MINESTAR) {
            if (this.rowData.reportingState != 'Subscribed') {
              this.siteName = '';
              (data.controls[parseInt(key)] as FormGroup).get('mineStarSiteName')?.patchValue('');
              (data.controls[parseInt(key)] as FormGroup).get('selectedProductFamily')?.patchValue('');
            }
            this.productFamily.selectedAddOnProductFamilyList = [];
          }
        }
        if (type == 'Addon') {
          let data2 = (data.controls[parseInt(key)] as FormGroup).controls;

          if (data2['addOnOptions'].value && data2['addOnOptions'].value.length <= 1) {
            (data.controls[parseInt(key)] as FormGroup).get(formControlType)?.patchValue(patchValueSet);
          } else if (data2['addOnOptions'].value && data2['addOnOptions'].value.length > 0) {
            // (data.controls[parseInt(key)] as FormGroup).get(formControlType)?.patchValue(patchValueSet);
          }

          // if(((data.controls[parseInt(key)] as FormGroup) controls  as FormGroup).addOnOptions.value)
        }
      }
    });
  }
  /**
   * Lists the asset product family based on the application name.
   *
   * This method populates the product family list from the available
   * product family data and updates the previous add-ons based on the
   * selected asset subscription form data. It also validates add-ons
   * for the specified application name to ensure consistency with the
   * product family selection.
   *
   * @param {any} applicationName - The name of the application for which
   *                                 the product family is being listed.
   */
  listAssetProductFamilyFn(applicationName: any) {
    if (this.PFamilyListData && this.PFamilyListData.length) {
      this.productFamily.productFamilyList = this.PFamilyListData;
      const { previousddons } = this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns;
      if (previousddons?.length > 0) {
        for (const addOn of previousddons) {
          this.profileBasedAdditionalServices.optionListPrev[addOn] = true;
        }
      }
      // if (
      //   this.rowData?.customerSubscriptionId &&
      //   this.rowData?.productFamily &&
      //   this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily == ''
      // ) {
      this.validateAddOnForProductFamily(applicationName);
    }
    // }
  }
  /**
   * Redirects the user to the site page.
   *
   * This method emits a redirect event and navigates the application
   * to the '/sites' URL, facilitating a transition to the site management
   * page.
   */
  redirectToSitePage() {
    this.redirectToSite.emit(true);
    this.router.navigateByUrl('/sites');
  }
  /**
   * Validates the add-ons for the specified product family.
   *
   * This method checks if the current product family is valid using the
   * subscription change service. If valid, it invokes a function to display
   * the asset product family. Otherwise, it resets the product family list
   * for the provided application name.
   *
   * @param {any} applicationName - The name of the application for which
   *                                 the product family validation is being performed.
   */
  validateAddOnForProductFamily(applicationName: any) {
    if (this.subscriptionChangeService.checkValidProductFamily(this)) {
      //scope
      this.showAssetProductFamilyFn(applicationName);
    } else {
      this.resetProductFamilyListFn(applicationName);
    }
  }
  /**
   * Displays the asset product family based on the application name.
   *
   * This method activates the product family and initializes the selected
   * add-on product family list if it's empty or if navigating back. It
   * populates a list of product families and validates add-ons against
   * previous selections. If the add-ons match, it marks the product family
   * as modified; otherwise, it updates the current product family in the
   * form based on row data. The method also handles specific conditions
   * to reset the modified state of the product family accordingly.
   *
   * @param {any} applicationName - The name of the application for which
   *                                 the asset product family is being shown.
   */
  showAssetProductFamilyFn(applicationName: any) {
    this.isProductFamilyActive = true;
    if (this.productFamily.selectedAddOnProductFamilyList?.length == 0 || this.isBackPrevious) {
      this.productFamily.selectedAddOnProductFamilyList = cloneDeep(
        this.productFamily.productFamilyList[0]?.productFamily
      );
      this.listOfProductFamily = [];
      this.productFamily.selectedAddOnProductFamilyList.forEach((val: any) => {
        const Obj: ProductFamilyList = {
          label: val,
          value: val,
        };
        this.listOfProductFamily.push(Obj);
      });
      // this.fetchApplicablePromo(this.selectedService);
    }
    if (
      this.rowData.customerSubscriptionId &&
      this.rowData.productFamily &&
      this.productFamily?.selectedAddOnProductFamilyList?.length > 0
    ) {
      let isAddOnsMatching = this.validateAddons(
        this.selectedAssetSubscriptionFormData.products[applicationName].addOns.currentAddons,
        this.selectedAssetSubscriptionFormData.products[applicationName].addOns.previousddons
      );
      if (isAddOnsMatching) {
        this.modifiedProductFamily = true;
      } else {
        if (this.rowData.productFamily) {
          this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.currentProductFamily =
            this.rowData.productFamily;
          const data = this.manageAssetSubscriptionForm.get('products') as FormArray;
          Object.keys(data.controls).forEach((key) => {
            if (applicationName == data.controls[parseInt(key)].value.applicationName) {
              (data.controls[parseInt(key)] as FormGroup)
                .get('selectedProductFamily')
                ?.patchValue(this.rowData.productFamily);
            }
          });
        }
        if (
          (this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.previousProductFamily !==
            '' &&
            !this.isBackPrevious) ||
          (this.isBackPrevious &&
            this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.previousProductFamily ===
              this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.currentProductFamily &&
            !isAddOnsMatching)
        ) {
          this.modifiedProductFamily = false;
        }
      }
    }
  }
  /**
   * Resets the product family list for the specified application.
   *
   * This method deactivates the product family and clears the selected add-on
   * product family list. It updates the current product family based on the
   * presence of current add-ons, specifically resetting it if certain conditions
   * are met. The method also patches the selected product family field in the
   * form to reflect the reset state.
   *
   * @param {any} applicationName - The name of the application for which
   *                                 the product family list is being reset.
   */
  resetProductFamilyListFn(applicationName: any) {
    this.isProductFamilyActive = false;
    this.productFamily.selectedAddOnProductFamilyList = [];
    const { currentAddons } = this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns;
    if (currentAddons?.length > 0) {
      if (currentAddons.indexOf('Surface Guidance') == -1) {
        this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.currentProductFamily = '';
      }
    } else if (currentAddons?.length == 0 && this.selectedBasePlan[applicationName] != 'None') {
      this.selectedAssetSubscriptionFormData.products[applicationName].productFamily.currentProductFamily = '';
    }

    //
    const data = this.manageAssetSubscriptionForm.get('products') as FormArray;
    Object.keys(data.controls).forEach((key) => {
      if (applicationName == data.controls[parseInt(key)].value.applicationName) {
        (data.controls[parseInt(key)] as FormGroup).get('selectedProductFamily')?.patchValue('');
      }
    });
    this.productFamily.selectedProductFamily = 'None';
  }
  /**
   * Handles the site change event and updates site-related data accordingly.
   *
   * This method checks if the provided site name exists in the listed sites
   * for the specified product. If found, it updates the site information,
   * validates the site, and triggers relevant promotional checks or updates
   * based on the current subscription levels. If the site is not valid, it
   * marks the site as invalid and potentially triggers necessary updates.
   *
   * @param {any} siteName - The name of the site that has been changed.
   * @param {any} product - The product object associated with the site change.
   */
  onSiteChanged(siteName: any, product: any) {
    this.isSiteFound = this.subsData[product.applicationName]?.siteInfo?.listedSites.filter((site: any) => {
      return site.name === siteName;
    });

    if (!this.isSiteFound || (this.isSiteFound && this.isSiteFound.length > 0)) {
      this.subsData[product.applicationName].siteInfo.isValidSite = true;
      this.subsData[product.applicationName].siteInfo.siteId = this.isSiteFound[0].id;
      this.resetNextBtnValidForm(null);
      if (
        this.selectedAssetSubscriptionFormData?.products[product.applicationName]?.subscription?.currentSubscription
          ?.customerLevel !== '' ||
        this.selectedAssetSubscriptionFormData?.products[product.applicationName]?.subscription?.currentSubscription
          ?.catLevel !== ''
      ) {
        if (this.subsPricingEligible) {
          this.getApplicablePromo(product.applicationName);
        } else {
          this.updateMultiProductTriggerVal();
        }
      }
      if (
        this.customerPlan !== 'None' &&
        (!this.rowData.siteVal ||
          (this.rowData.siteVal?.name &&
            this.subsData[product.applicationName].siteInfo.siteVal?.name &&
            this.rowData.siteVal.name != this.subsData[product.applicationName].siteInfo.siteVal.name) ||
          (!this.subsData[product.applicationName].siteInfo.siteVal.name &&
            this.rowData.siteVal.name != this.subsData[product.applicationName].siteInfo.siteVal.name))
      ) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(product.applicationName);
        this.setIsNewSubscription();
        // this.triggerValChange();
      } else {
        // this.triggerValChange('');
      }
    } else {
      this.subsData[product.applicationName].siteInfo.isValidSite = false;
      // this.triggerValChange('');
    }
  }
  /**
   * Handles the blur event for form fields and validates the input.
   *
   * This method checks if the event type is 'blur' and sets error states
   * based on the application's context. If there are no errors and a previous
   * site search exists with results, it marks the form as invalid, potentially
   * indicating that further action is required.
   *
   * @param {any} event - The event object triggered by the blur action.
   * @param {any} [applicationName] - An optional parameter for specifying the
   *                                   application context (default is undefined).
   */
  onBlurEvent(event: any, applicationName?: any) {
    if (event?.type === 'blur') {
      const { errors } = this.setError('error', applicationName);
      if (this.previousSiteSearch !== '' && this.siteResults?.length > 0 && isEmpty(errors)) {
        // this.setError(true, applicationName);
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      }
    }
  }
  /**
   * Handles site name input changes and fetches site details.
   *
   * This method updates the site name based on user input and checks if
   * the input exceeds a certain length. If so, it triggers a search for site
   * details using the provided parameters. If the input is empty, it resets
   * the site information and related states. The method also manages loading
   * indicators and error states as necessary.
   *
   * @param {any} event - The event object containing the input value.
   * @param {any} product - The product object associated with the site search.
   */
  getSiteReqInfo(event: any, product: any) {
    // this.triggerValChange('');
    this.siteName = event?.value || '';
    if (event.value.length > 2 && this.previousSiteSearch !== this.siteName) {
      this.previousSiteSearch = cloneDeep(this.siteName);
      this.siteNotFound = false;
      this.setError('', product.applicationName);
      const siteSearchParam = {
        name: event.value,
        dealerCode: this.dealer.dealerCode,
      };
      if (!this.siteResults.length) this.isSiteNameLoading = true;
      this.fetchSiteDetails(siteSearchParam, event, product);
    } else if (event.value === '') {
      this.setError('', product.applicationName);
      this.previousSiteSearch = '';
      this.isListItemSelected = false;
      this.isSiteNameLoading = false;
      this.selectedAssetSubscriptionFormData.products[product.applicationName].siteInfo = {
        siteId: '',
        siteName: '',
      };
      this.selectedAssetSubscriptionFormData.products[product.applicationName].selectedSiteInfo = {
        siteId: '',
        siteName: '',
      };
      this.setMineStarSiteNameOnInit();
      this.updateMultiProductTriggerVal(product.applicationName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  fetchSiteDetails = debounce((siteSearchParam: any, event: any, product: any) => {
    if (this.siteName === siteSearchParam.name && this.siteName?.length > 2) {
      this.isSiteNameLoading = true;
      this.siteResults = [];
      this.changeDetector.detectChanges();
      this.getSeachTextSiteNames(siteSearchParam, product.applicationName);
    }
  }, 1000);
  /**
   * Fetches and updates site names based on the search parameters.
   *
   * This method initiates a site search overlay and calls the asset service
   * to retrieve site information based on the provided search parameters.
   * It handles the response by updating the site validity, managing loading
   * states, and populating the site list if results are found. If no sites
   * are returned or an error occurs, it sets appropriate error states and
   * displays messages as necessary.
   *
   * @param {any} siteSearchParam - The parameters used for the site search.
   * @param {any} applicationName - The name of the application related to the site search.
   */
  getSeachTextSiteNames(siteSearchParam: any, applicationName: any) {
    const { customer } = this.selectedAssetSubscriptionFormData;
    this.handleSiteSearchOverlay();
    siteSearchParam.ucid = this.selectedAssetSubscriptionFormData?.customer?.ucid;

    this.assetService.getSitesForWorkList(siteSearchParam).subscribe({
      next: (result: any) => {
        this.isSiteNameLoading = false;
        if (result.sites?.length > 0) {
          this.setError('', applicationName);
          this.siteNotFound = false;
          this.subsData[applicationName].siteInfo.isValidSite = 'true';
          this.subsData[applicationName].siteInfo.listedSites = result.sites;
          this.getSitesList(result.sites);
          this.handleSiteSearchInputFocus(siteSearchParam?.name || '');
        } else if (result.sites.length == 0) {
          this.siteNotFound = true;
          this.resetOverlayBox();
        }
      },
      error: (err: any) => {
        this.siteNotFound = true;
        this.isSiteNameLoading = false;
        this.setError(true, applicationName, '', 'true');
        this.resetOverlayBox();
        if (err.status !== 404) {
          this.showToastMessage(this.systemError, 'error');
        }
      },
    });
  }
  /**
   * Processes and stores site search results.
   *
   * This method iterates through the provided site search results and
   * populates the `siteResults` array with formatted site information,
   * including the site ID, label, and value for each site.
   *
   * @param {any} siteSearchResults - The array of site search results to be processed.
   */
  getSitesList(siteSearchResults: any) {
    for (const site of siteSearchResults) {
      this.siteResults.push({
        id: site.id,
        label: site.name,
        value: site.name,
      });
    }
  }
  /**
   * Sets focus on the MineStar site search input field.
   *
   * This method retrieves the MineStar site search input element and sets
   * focus on it after a short delay. It can also set the cursor position
   * within the input field, although the selection range functionality
   * is currently commented out.
   *
   * @param {string} searchText - The text to be potentially used for
   *                              setting the cursor position in the input field.
   */
  handleSiteSearchInputFocus(searchText: string) {
    const mineStarSearchInput = document?.getElementById('minestarSiteSearch') as HTMLInputElement | null;
    if (mineStarSearchInput) {
      setTimeout(() => {
        mineStarSearchInput?.focus();
        // mineStarSearchInput?.setSelectionRange(searchText.length, searchText.length);
      });
    }
  }
  /**
   * Displays the site search overlay if it is currently hidden.
   *
   * This method checks for the presence of the site search overlay and
   * removes the 'display' style property to make it visible if it is
   * currently set to 'none'.
   */
  handleSiteSearchOverlay() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef?.length && overlayRef[0]?.style?.display == 'none') {
      overlayRef[0].style.removeProperty('display');
    }
  }
  /**
   * Handles the selection of a site from the list.
   *
   * This method updates the state based on the selected site, including
   * setting the site name, site ID, and related information in the
   * subscription data. It marks the list item as selected, resets the
   * previous search value, and triggers any necessary updates in the
   * form data. The method also clears the site results and invokes
   * change detection to ensure the UI is updated.
   *
   * @param {any} site - The selected site object containing site details.
   * @param {any} product - The product object associated with the selected site.
   */
  listItemClick(site: any, product: any) {
    const { label, value, id } = site;
    this.isListItemSelected = true;
    this.siteName = value;
    this.siteValue = value;
    this.subsData[product.applicationName].siteInfo.siteVal = { name: label };
    this.selectedAssetSubscriptionFormData.products[product.applicationName].siteInfo = { siteName: label, siteId: id };
    this.selectedAssetSubscriptionFormData.products[product.applicationName].selectedSiteInfo = {
      siteName: label,
      siteId: id,
    };
    this.previousSiteSearch = value;
    this.setError('', product.applicationName, label);
    this.onSiteChanged(this.siteName, product);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.siteResults = [];
    this.changeDetector.detectChanges();
  }
  /**
   * Handles changes to the selected product family.
   *
   * This method updates the current product family in the subscription data
   * based on the user's selection. It resets the validation state of the
   * next button and triggers promotional checks or updates as necessary
   * based on the eligibility for subscription pricing.
   *
   * @param {any} event - The event object containing the new selection data.
   * @param {any} product - The product object associated with the product family change.
   */
  productFamilyChangedFn(event: any, product: any) {
    if (event.currentSelection && event.currentSelection[0]) {
      this.selectedAssetSubscriptionFormData.products[product.applicationName].productFamily.currentProductFamily =
        event.currentSelection[0].label;
      this.resetNextBtnValidForm(null);
      if (this.subsPricingEligible) {
        this.getApplicablePromo(product.applicationName);
      } else {
        this.updateMultiProductTriggerVal();
      }
    }
    // this.triggerValChange();
  }
  /**
   * Hides the site search overlay.
   *
   * This method sets the display property of the site search overlay
   * to 'none', effectively hiding it from view if it exists in the DOM.
   */
  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }
  /**
   * Checks if any product in the list requires selection of plans.
   *
   * This method evaluates the list of products to determine if there
   * are any products that are not currently subscribed. It returns
   * `true` if at least one product is not subscribed, indicating that
   * plan selection is required; otherwise, it returns `false`.
   *
   * @returns {boolean} - True if plan selection is required; false otherwise.
   */
  isSelectPlansRequired() {
    return this.listOfProducts?.some((product: { isSubscribed: any }) => !product?.isSubscribed);
  }
  /**
   * Filters out specific application data from the provided dataset.
   *
   * This method takes an array of data and removes any items that match
   * the application name of the last selected base and option. It returns
   * the filtered dataset, ensuring that the specified application is excluded.
   *
   * @param {any} Data - The array of data to be filtered.
   * @returns {any[]} - The filtered array of data, excluding the specified application name.
   */
  filterPopupData(Data: any) {
    let popupData = [];
    if (Data?.length > 0) {
      popupData = cloneDeep(Data);
      popupData = popupData?.filter(
        (item: any) => item.applicationName !== this.lastSelectedBaseAndOption.apllicationName
      );
    }
    return popupData;
  }

  checkIfProductsAvailable() {
    return this.listOfProducts?.length < 1;
  }
  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }
  ngOnDestroy(): void {
    let applicationName = cloneDeep(this.lastOpenedAccordian);
    this.lastOpenedAccordian = '';
    this.checkOpenedAccordian(applicationName);
  }
  /**
   * Retrieves and checks dealer features related to 'China'.
   *
   * This method constructs a dealer object with the dealer code and
   * requests feature information from the asset drawer service. If the
   * response indicates that the dealer's feature name is 'China', it
   * sets the `isChinaDealer` flag to true. If a 404 error occurs, it
   * sets the flag to false, indicating that the dealer is not recognized
   * as a China dealer.
   */
  getDealerParentAndChildfeatures() {
    const dealerObj = {
      feature: 'China',
      dealerCode: this.dealer?.dealerCode,
    };
    this.assetDrawerService.getDealerParentAndChildfeatures(dealerObj).subscribe({
      next: (res: any) => {
        if (res.featureName === 'China') {
          this.isChinaDealer = true;
        }
      },
      error: (err: any) => {
        if (err.status === 404) {
          this.isChinaDealer = false;
        }
      },
    });
  }

  datClick() {
    window.open(this.datUrl, '_blank');
  }
}
