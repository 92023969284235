import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  Renderer2,
  ElementRef,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { AssetService } from '../../services/asset.service';
import { CcModal, CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { Router } from '@angular/router';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'dsp-next-gen-ui-cancel-all-plans',
  templateUrl: './cancel-all-plans.component.html',
  styleUrls: ['./cancel-all-plans.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelAllPlansComponent implements OnInit, AfterViewInit {
  rowData: any;
  subsPricingEligible!: any;
  selectedAssetSubscriptionFormData!: any;
  cancelReasonResponseValue: any;
  cancelDetails: any = [];
  paymentDetails: any;
  footerMessage: any;
  selectedOption: any;
  globalInstance: any;
  showWarning = false;
  userActiveFeatures: any;
  submitDisable = true;
  isPaymentPreviewMessage = false;

  constructor(
    private assetDrawerService: AssetDrawerService,
    public modal: CcModal,
    private assetService: AssetService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService,
    private store: Store<DSPAppState>,
    public modalRef: CcModalRef<CancelAllPlansComponent>,
    @Inject(CC_MODAL_DATA) public modalData: any,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isPaymentPreviewMessage = dsp?.featureFlagInfo?.isPaymentPreviewMessage
        ? dsp?.featureFlagInfo?.isPaymentPreviewMessage
        : false;
    });
    this.cancelReasonResponseValue = this.modalData.cancelReasonResponse;
    this.rowData = this.modalData.selectedRowDataValue;
    this.subsPricingEligible = this.modalData.subsPricingEligibleValue;
    this.selectedAssetSubscriptionFormData = this.modalData.selectedAssetSubscriptionFormDataValue;
    this.cancelPage();
    if (this.subsPricingEligible || (this.isPaymentPreviewMessage && !this.subsPricingEligible)) {
      this.paymentPage();
    }
  }

  /**
   * This function handles the cancellation page setup.
   * It performs the following steps:
   * 1. Sets the showWarning flag to true.
   * 2. Calls setSelectedCancelReason with the cancelReasonResponseValue to set the selected cancel reason.
   * 3. Filters the cancel reasons using filterCancelReasonFn and assigns the result to cancelDetails.
   *
   * @returns {void}
   */
  cancelPage() {
    this.showWarning = true;
    this.setSelectedCancelReason(this.cancelReasonResponseValue);
    this.cancelDetails = this.filterCancelReasonFn(this.cancelReasonResponseValue);
  }
  /**
   * This function filters the cancel reasons based on the device type.
   * It performs the following steps:
   * 1. Initializes the filteredCancelReason with the provided cancelReasons.
   * 2. Checks if the device type is 'NO_DEVICE' or null:
   *    - If true, filters out reasons that match the Device_Impacted_reason and are not suspended.
   * 3. Returns the filtered cancel reasons.
   *
   * @param {any} cancelReasons - The list of cancel reasons to be filtered.
   * @returns {any} - The filtered list of cancel reasons.
   */
  filterCancelReasonFn(cancelReasons: any) {
    let filteredCancelReason = cancelReasons;
    if (this.rowData?.deviceType == 'NO_DEVICE' || this.rowData?.deviceType == null) {
      filteredCancelReason = cancelReasons?.filter((element: any) => {
        return (
          element.reason.toLowerCase() != dspConstants.Worklist.Device_Impacted_reason.toLowerCase() &&
          !element.isSuspended
        );
      });
    }
    return filteredCancelReason;
  }
  /**
   * This function sets the selected cancel reason based on the provided cancel reason list.
   * It performs the following steps:
   * 1. Destructures cancelOptions from selectedAssetSubscriptionFormData.
   * 2. Checks if cancelReasonList is provided and cancelOptions.reason is not empty.
   * 3. Finds the selected reason from the cancelReasonList that matches cancelOptions.reason.
   * 4. If a matching reason is found:
   *    - Sets the selectedOption to the found reason.
   *    - Marks the cancel reason as selected.
   *    - Enables the submit button.
   *    - Updates the asset subscription form data using assetService.
   *
   * @param {any} cancelReasonList - The list of cancel reasons to search through.
   * @returns {void}
   */
  setSelectedCancelReason(cancelReasonList: any) {
    const { cancelOptions } = this.selectedAssetSubscriptionFormData;
    if (cancelReasonList && cancelOptions?.reason !== '') {
      const selectedReason = cancelReasonList?.find(
        (cancelOption: any) => cancelOption?.reason === cancelOptions?.reason
      );
      if (selectedReason) {
        this.selectedOption = selectedReason?.reason;
        this.selectedAssetSubscriptionFormData.cancelReasonSelected = true;
        this.submitDisable = false;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
    }
  }

  /**
   * This function prepares the payment page data and fetches the payment preview.
   * It performs the following steps:
   * 1. Constructs the getPaymentPage object with relevant data from rowData and selectedAssetSubscriptionFormData.
   * 2. Calls the assetDrawerService to get the payment preview.
   * 3. Subscribes to the response:
   *    - On success, sets the paymentDetails with the response data.
   *    - Formats the footerMessage by replacing HTML tags and modifying the Terms of Sale link.
   *    - Removes the Terms of Sale message if the user does not have the 'TOS' feature active.
   *    - Marks the change detection for check.
   *
   * @returns {void}
   */
  paymentPage() {
    const getPaymentPage = {
      applicationName:
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
          ?.currentPrepayData.prepaySelected ||
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
          ?.previousPrepayData.prepaySelected
          ? dspConstants.Worklist.NEW_VISION_LINK
          : this.rowData?.applicationName,
      assetMake: this.rowData?.make || '',
      assetSerialNumber: this.rowData?.serialNumber || '',
      contractEndDate: '',
      contractStartDate: '',
      customerName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dcnName: this.selectedAssetSubscriptionFormData.customer.dealerCustName,
      dcnNumber: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dealerCancelReason: 'Price',
      dealerCode: this.selectedAssetSubscriptionFormData?.customer?.dealerCode,
      deviceId: '',
      deviceSerialNumber: '',
      deviceType: this.rowData?.deviceType || '',
      isNewSubscription: true,
      prepay: false,
      ucid: this.selectedAssetSubscriptionFormData?.customer?.ucid || '',
    };
    this.assetDrawerService.getPaymentPreview(getPaymentPage).subscribe({
      next: (response: any) => {
        this.paymentDetails = response;
        this.footerMessage = this.paymentDetails?.cancelNotificationMessage
          .split('<br/>')
          .join(' ')
          .replace('href="terms/tos"', 'onclick="termSaleModal()"');
        const TOS_REGEX = new RegExp('When you submitted your order, you agreed to our <a[^>]*>Terms of Sale</a>');
        this.cdr.markForCheck();
        if (
          (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) ||
          this.userActiveFeatures?.indexOf('Contract Visualization') === -1
        ) {
          this.footerMessage = this.footerMessage.replace(TOS_REGEX, '');
        }
      },
    });
  }
  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }
  /**
   * This function handles the selection of a cancel reason.
   * It performs the following steps:
   * 1. Enables the submit button by setting submitDisable to false.
   * 2. Updates the cancelOptions in selectedAssetSubscriptionFormData with the selected option.
   * 3. Marks the cancel reason as selected.
   * 4. Updates the asset subscription form data using assetService.
   *
   * @param {any} option - The selected cancel reason option.
   * @returns {void}
   */
  handleCancelReasonSelection(option: any) {
    this.submitDisable = false;
    this.selectedAssetSubscriptionFormData.cancelOptions = option;
    this.selectedAssetSubscriptionFormData.cancelReasonSelected = true;
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  closeCancelAllPopUp(value: any) {
    if (value) {
      this.modalRef.close(true);
    } else {
      this.modalRef.close(false);
    }
  }
}
