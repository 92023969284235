<div class="cc-grid">
  <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
    <a
      id="create-site-button"
      class="minestar-site-redirect"
      cc-btn-medium
      cc-btn-tertiary
      [setTheme]="true"
      (click)="redirectToSitePage()"
      [ngClass]="
        userActiveFeatures && userActiveFeatures?.toString()?.indexOf('Subscribe Sites') > -1 ? '' : 'disable-site'
      "
      >Click to create New Site</a
    >
  </div>
</div>
<div
  class="cc-grid site-align"
  [formGroup]="manageAssetSubscriptionForm"
  [ngStyle]="{ paddingBottom: subsData?.templateCheck?.promotionDisplayCheck ? '0px' : '20px' }"
>
  <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12 sites-field">
    <div class="service-list-options-label">Select Site</div>
    <cc-input-field
      [labelValue]="'Minimum 3 characters'"
      [inputtype]="'search'"
      [isDense]="true"
      [width]="'300px'"
      [isTheme]="'2.0'"
      [isLeading]="true"
      [value]="siteName"
      (valueChange)="siteName = $event.value; getSiteReqInfo($event)"
      [triggerSearchMinChar]="3"
      [enableSearchOverlay]="true"
      [disabled]="
        (userActiveFeatures && userActiveFeatures?.toString()?.indexOf('Subscription Management') === -1) ||
        (userActiveFeatures?.toString()?.indexOf('Subscription Management') > -1 &&
          (userActiveFeatures?.toString()?.indexOf('View Only') > -1 ||
            userActiveFeatures?.toString()?.indexOf('Read Only') > -1 ||
            userActiveFeatures?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
        isSiteNameLoading ||
        customerPlan == 'None'
      "
    >
      <span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
            fill="black"
          />
        </svg>
      </span>
      <div class="cc-input-search-menu">
        <div *ngIf="isSiteNameLoading" class="no-result load-container">
          <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
        </div>
        <ng-container *ngIf="siteResults && siteResults.length > 0">
          <cc-list [theme]="'secondary'">
            <cc-list-item
              *ngFor="let item of siteResults; let i = index"
              class="list-item-pg"
              (listItemClick)="listItemClick(item)"
              (listItemEnter)="listItemClick(item)"
            >
              <div class="each-item">
                <div class="txt-name">
                  <span class="label" [innerHTML]="item.label | highlightSearch: siteName"></span>
                </div>
              </div>
            </cc-list-item>
          </cc-list>
        </ng-container>
      </div>
      <input
        input-field
        id="minestarSiteSearch"
        #minestarSiteSearch
        class="input-field"
        [value]="siteName"
        autocomplete="off"
        formControlName="mineStarSiteName"
        type="search"
        [isFloating]="false"
        aria-labelledby="Search"
        aria-describedby="Search"
        (valueChange)="siteName = $event.value"
        (blur)="onBlur($event)"
      />
    </cc-input-field>
    <span class="req-text">
      <span *ngIf="formControl['mineStarSiteName']?.errors?.['minlength']"
        >Site Name must contain atleast 3 characters</span
      >
    </span>
    <span class="req-text">
      <span *ngIf="formControl['mineStarSiteName']?.errors?.['siteNotFoundError']"> Site cannot be found </span>
    </span>
  </div>
  <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
    <div class="service-list-options-label">Select Plan</div>
    <cc-dropdown
      [id]="'base-plan-dropdown-medium'"
      class="manage-asset-dropdown"
      name="service-dropdown"
      [label]="'Select Plan'"
      [dataSource]="listOfBasePlans"
      [mobileHeader]="'Asset Service dropdown'"
      [placementPosition]="'bottom'"
      [isTheme]="'2.0'"
      (selectionChange)="onBasePlanChange($event, 'customerLevel')"
      formControlName="customerPlan"
      [selectionOnClose]="true"
      [isReOrder]="false"
      [wrapperMaxWidth]="250"
      [disabled]="
        applicableSubscriptions?.level1?.length === 0 || subsData?.templateCheck?.custSubs?.disableServiceCheck
      "
    >
    </cc-dropdown>
  </div>
  <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
    <div class="service-list-options-label">Optional Plan</div>
    <cc-dropdown
      [id]="'optional-plan-dropdown-medium'"
      class="manage-asset-dropdown"
      name="optional-dropdown"
      [label]="'Select Plan'"
      [dataSource]="listOfOptionalPlans"
      [mobileHeader]="'Asset Service Optional dropdown'"
      [placementPosition]="'bottom'"
      [isTheme]="'2.0'"
      (selectionChange)="onOptionalPlanChange($event, 'addOnOptions')"
      formControlName="addOnOptions"
      [multiple]="true"
      [expanded]="'selected'"
      [isremoveComma]="true"
      [isReOrder]="false"
      [hideSelectAllLabel]="true"
      [scrollToSelected]="true"
      [disabled]="applicableAddlSubscriptions?.length === 0 || subsData?.templateCheck?.custSubs?.disableServiceCheck"
    >
    </cc-dropdown>
  </div>
  <div
    class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12"
    *ngIf="productFamily.selectedAddOnProductFamilyList.length > 0"
  >
    <div class="service-list-options-label">Product Family</div>
    <cc-dropdown
      [id]="'product-family-dropdown-medium'"
      class="manage-asset-dropdown"
      name="service-dropdown"
      [label]="'Select Product Family'"
      [dataSource]="listOfProductFamily"
      [mobileHeader]="'Asset Service dropdown'"
      [placementPosition]="'bottom'"
      [isTheme]="'2.0'"
      (selectionChange)="productFamilyChangedFn($event)"
      formControlName="selectedProductFamily"
      [selectionOnClose]="true"
      [isReOrder]="false"
      [disabled]="!modifiedProductFamily"
    >
    </cc-dropdown>
  </div>
</div>

<ng-container
  *ngIf="subsData?.templateCheck?.promotionDisplayCheck"
  [ngTemplateOutlet]="promotionSection"
></ng-container>

<ng-template #promotionSection>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <section id="promotion-section" *ngIf="subsData?.promoInfo?.showPromoValue">
        <div class="service-list-options-label promo-padding">Promotion(s) Applied</div>
        <div *ngIf="subsData?.promoInfo?.promoValue?.length > 0">
          <!-- Promotion details card -->
          <ng-container *ngFor="let promo of subsData.promoInfo.promoValue">
            <cc-card [isTheme]="'2.0'" [outline]="true">
              <cc-card-content>
                <div class="promo-data-align">
                  <div class="promo-data">{{ promo.name }}</div>
                  <div class="promo-data">-{{ convertNumberFormat(promo.discount) }}</div>
                </div>
              </cc-card-content>
            </cc-card>
          </ng-container>
        </div>
        <div *ngIf="promoLoader">
          <cc-card [isTheme]="'2.0'" [outline]="true">
            <cc-card-content>
              <cc-skeleton-loader [height]="25" [animation]="true" class="promotion-skeleton-loader">
                <svg:rect x="7" y="7" rx="3" ry="3" width="120" height="4" />
                <svg:rect x="378" y="10" rx="5" ry="5" width="65" height="7" />
                <svg:rect x="7" y="16" rx="3" ry="3" width="70" height="4" />
              </cc-skeleton-loader>
            </cc-card-content>
          </cc-card>
        </div>
        <div class="align-no-promo-message" *ngIf="subsData?.promoInfo?.promoValue?.length === 0 && !promoLoader">
          <!-- Promotion not-available detail -->
          <span class="no-promo-label">Not Available</span>
          <cc-icon
            [template]="noPromoTooltipTemplate"
            [cc-tooltip]="''"
            [interactive]="true"
            [appClass]="'-promo-info-tool-tip'"
            class="info-message-icon-size"
            [tooltipPosition]="'bottom'"
            >info-tooltip</cc-icon
          >
        </div>
      </section>
    </div>
  </div>
</ng-template>

<ng-template #noPromoTooltipTemplate>
  <div class="promo-tooltip-body">
    <span
      >Asset does not currently meet eligibility criteria for promotion. If the asset is not on a contract and it
      becomes eligible by the end of the month, it will be re-evaluated prior to your next invoice. Promotions may
      require dealer submission of customer sale date.
      <a
        href="https://catdealer.com"
        target="_blank"
        style="color: #2595ff; text-decoration: underline; font-weight: 400"
        >Click here</a
      >
      for more information.</span
    >
  </div>
</ng-template>
