import {
  Component,
  Input,
  HostBinding,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  input,
} from '@angular/core';
import { ColumnConfig } from '../../needs-review-column-config';
import * as dspConstants from '../../../../../../apps/dsp-ui/src/app/shared/dspConstants';
import { NeedsreviewPayload } from '../../needs-review-interface';
import { NeedsReviewDefaultData } from '../../needs-review-constant';
import { NeedsReviewService } from '../../services/needs-review.service';
import { fetchLoggedInDealerDetails } from '../../../../../../apps/dsp-ui/src/app/shared/shared';
import { forkJoin, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { NeedsReviewSharedService } from '../../services/needs-review-shared.service';
import { isEmpty } from 'lodash-es';
import { DspCommonService } from 'apps/dsp-ui/src/app/services/dsp-common.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { AssetService } from '../../../../../../apps/dsp-ui/src/app/components/assets/services/asset.service';
import {
  getBillDirectCustomers,
  getEquipmentDataUrl,
  getFeatureSupportMapping,
  getPSRUrl,
  getSubscriptionServiceMapping,
  getFeaturesToggleMapping,
  getBillingAccounts,
  getProductFamily,
  getCertifiedCountries,
  setDeviceCatalogueInfo,
} from '../../../../../../apps/dsp-ui/src/app/store/actions/dspcommon.action';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { CommonFilterService } from 'apps/dsp-ui/src/app/services/common-filter.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { TablesettingService } from '../../../../../../apps/dsp-ui/src/app/services/tablesetting.service';

type dealerType = {
  dealerCity: string;
  dealerCode: string;
  dealerCountry: string;
  dealerEDURL: string;
  dealerEmail: string;
  dealerName: string;
  dealerPhone: string;
  dealerState: string;
  dealerStreet: string;
  dealerZipCode: string;
};
@Component({
  selector: 'dsp-next-gen-ui-need-review-table',
  templateUrl: './need-review-table.component.html',
  styleUrls: ['./need-review-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedReviewTableComponent {
  @HostBinding('class') class = 'needsReview-table-container';
  @Input() events!: Observable<any>;
  @Input() searchChange!: Observable<any>;
  @Output() exportSorting = new EventEmitter();
  @Input() selectedQueueReason!: any;

  public eventsSubscription: Subscription = new Subscription();
  @ViewChild('needsReviewTableRef', { static: false }) tableRef: any;
  reviewTableCustomData: any = {
    searchString: '',
  };
  public dealer: dealerType = {
    dealerCity: '',
    dealerCode: '',
    dealerCountry: '',
    dealerEDURL: '',
    dealerEmail: '',
    dealerName: '',
    dealerPhone: '',
    dealerState: '',
    dealerStreet: '',
    dealerZipCode: '',
  };
  public dealerCode = '';
  subcribeNeedsReviewTableConfig = {
    height: window.innerHeight - 240,
    stickyHeader: true,
    expandable: false,
    isSelfFoldable: true,
    isBulkAction: false,
    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    footer: true,
    isPaginationEnable: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [20, 40, 60, 80, 100],
      advanced: false,
      activePage: 1,
      defaultPageSize: 20,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
      totalNumberOfRecords: 0,
    },
    columnConfig: ColumnConfig,
  };
  dismissStatusSubscription!: Subscription;
  assetStatusSubscription!: Subscription;
  assetStatusNew!: Subscription;

  dismissFlowStatus = false;
  needsReviewTableData: any[] = [];
  dspNeedReviewConstants = dspConstants.NeedsReview;
  enableNeedsReviewTable: any = true;
  isTableLoading = true;
  public fetchNeedsReviewAPIPayload: NeedsreviewPayload = NeedsReviewDefaultData;
  sortOperation = false;
  chipsFilters: any = [];
  reviewCount: any;
  nofilterSelected: any = false;
  queueReasonId: any;
  reviewFilterSubscription!: Subscription;
  searchHelpText = '';
  searchValue: any;
  dspStoreData!: any;
  isSuperAdmin!: any;
  cwsId!: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  appliedFilters: any;
  filterApplied: any;
  chipStatus: boolean = false;
  status: any;
  sortingActive: boolean = false;
  sortingDismiss: boolean = false;
  sortOperationActive: any;
  sortByActive: any;
  sortOrderActive: any;
  sortByDismissed: any;
  sortOrderDismissed: any;
  sortOperationDismissed: any;
  isTimeZoneSelectionEnabled: any;
  pageLabel = 'needsReview';
  @Output() pageLimitPayload = new EventEmitter();
  hideDismissedChip = false;
  queueReasonList: any;
  constructor(
    private needsReviewService: NeedsReviewService,
    private renderer: Renderer2,
    private needsReviewSharedService: NeedsReviewSharedService,
    private dspCommonService: DspCommonService,
    private store: Store<DSPAppState>,
    private assetService: AssetService,
    private messageBar: MessageBar,
    private commonFilterService: CommonFilterService,
    private userPreferenceService: UserPreferenceService,
    private tableSettingService: TablesettingService
  ) {}
  ngOnInit(): void {
    this.isTableLoading = true;
    this.dealer = fetchLoggedInDealerDetails();
    this.fetchNeedsReviewAPIPayload.dealerCode = this.dealer.dealerCode;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.queueReasonList = dsp?.dspConfig?.Queue_Reason_List ? JSON.parse(dsp.dspConfig.Queue_Reason_List) : [];
    });
    this.needsReviewService.refreshNeedsReviewTable.subscribe((event: any) => {
      if (event) {
        this.fetchNeedsReview(true); //refreshing table
      }
    });
    //Dismiss Action Event - Refresh Needs Review Table and Table count
    this.onGlobalSearch();
    this.updateColumnConfigCustomData();
    this.dismissStatusSubscription = this.needsReviewService.dismissStatus.subscribe((data: any) => {
      if (data.status) {
        this.dismissFlowStatus = true;
        this.fetchNeedsReview(true);
      }
    });
    // Chips Change Event - Refresh Needs Review Table and Table count

    this.eventsSubscription = this.events?.subscribe((data: any) => {
      this.isTableLoading = true;
      if (data) {
        if (this.nofilterSelected) {
          this.isTableLoading = false;
          this.fetchNeedsReviewAPIPayload.offset = 0;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          this.fetchNeedsReviewAPIPayload.status = 'Active';
        }
        this.queueReasonId = data;
        this.fetchNeedsReviewAPIPayload.offset = 0;
        this.fetchNeedsReviewAPIPayload.dealerQueueReasonId = data;
        this.fetchNeedsReview(true);
        this.enableNeedsReviewTable = true;
      } else {
        this.isTableLoading = false;
        this.needsReviewTableData = [];
        this.fetchNeedsReviewAPIPayload.offset = 0;
        this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
        const reviewCount = {
          dismissedCount: 0,
          activeCount: 0,
          totalCount: 0,
        };
        this.subcribeNeedsReviewTableConfig = {
          ...this.subcribeNeedsReviewTableConfig,
          pagination: this.updatePaginationCount(this.fetchNeedsReviewAPIPayload.status, reviewCount),
        };
        this.setChipCount(reviewCount);
      }
    });
    // Asset Status Action from New to Active - Clicked from Kebab Menu
    this.assetStatusSubscription = this.needsReviewService.changeAssetStatus.subscribe((data: any) => {
      if (data) {
        this.changeAssetStatus(data);
      }
    });
    // After filter applied calling the filterassetsreview and filterassetscountreview API to update the table data
    this.needsReviewSharedService.appliedReviewFilters.subscribe((data: any) => {
      this.fetchNeedsReviewAPIPayload.filter = {};
      if (data.filterApplied) {
        this.filterApplied = true;
        Object.assign(this.fetchNeedsReviewAPIPayload, { filter: data.filterData, offset: 0 });
        this.fetchFilterNeedsReview(true, this.fetchNeedsReviewAPIPayload);
      } else if (!data.filterApplied) {
        this.filterApplied = false;

        const restoreFilter = this.commonFilterService.getItemFromSessionStorage(
          'needsReviewFilters',
          'selectedFIlters',
          'needsReview',
          '',
          this.queueReasonId
        );
        if (
          data.filterData === false ||
          (data.filterData != undefined && Object.keys(data.filterData) && Object.keys(data.filterData).length == 0)
        ) {
          if (!isEmpty(restoreFilter)) {
            this.fetchNeedsReviewAPIPayload.filter = restoreFilter;
            this.fetchFilterNeedsReview(true, this.fetchNeedsReviewAPIPayload);
          } else {
            this.fetchNeedsReviewAPIPayload.filter = {};
            this.fetchNeedsReview(true);
          }
        } else {
          if (!isEmpty(restoreFilter)) {
            this.fetchNeedsReviewAPIPayload.filter = JSON.parse(restoreFilter);
          } else {
            this.fetchNeedsReviewAPIPayload.filter = {};
          }
        }
      }
    });
    if (this.isTimeZoneSelectionEnabled) {
      this.userPreferenceService.dateTimeObservable.subscribe((res: any) => {
        this.updateColumnNames();
      });
    }
    this.store.select('user').subscribe((user) => {
      this.cwsId = user?.user?.cwsId || '';
      this.isSuperAdmin = user?.user?.superAdministratorUser || '';
    });
    // this.store.select('dsp').subscribe((dsp) => {
    //   this.dspStoreData = dsp;
    // });
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      'needsReviewFilters',
      'selectedFIlters',
      'needsReview',
      '',
      this.queueReasonId
    );
    this.appliedFilters = filtersSaved ? filtersSaved : {};
    this.getSubscriptionServiceMapping();
    this.getBillDirectCustomers();
    this.getEDandPSRUrl();
    this.getFeatureSupportMsg();
    this.getFeaturesToggleMapping();
    this.getBillingAccounts();
    this.getDeviceSubscriptionCatalog();
    this.getFeaturesToggleMapping();
    this.getProductFamily();
    this.getCertifiedCountries();
    this.refreshTableAfterSubscriptionUpdate();
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
    this.updateColumnNames();
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }
  updateColumnNames() {
    if (this.isTimeZoneSelectionEnabled) {
      let localTimeZone = this.userPreferenceService.getLocalTimeZone();
      this.subcribeNeedsReviewTableConfig.columnConfig.map((column: any) => {
        if (column.title.includes('Date & Time')) {
          column.title = column.title.split('(')[0] + '(' + localTimeZone + ')';
        }
      });
    }
  }
  /** fetchNeedsReview Method - To invoke Needs Review API and set the table data,count **/
  fetchNeedsReview(callPaginationApi: boolean) {
    this.setAutoHeight();
    this.isTableLoading = true;
    this.needsReviewTableData = [];
    const restoreFilter = this.commonFilterService.getItemFromSessionStorage(
      'needsReviewFilters',
      'selectedFIlters',
      'needsReview',
      '',
      this.queueReasonId
    );
    this.commonFilterService.isFilterSavedEmptySubject.next('true');
    if (!isEmpty(restoreFilter)) {
      this.filterApplied = true;
      this.fetchNeedsReviewAPIPayload.filter = restoreFilter;
      this.fetchFilterNeedsReview(callPaginationApi, this.fetchNeedsReviewAPIPayload);
    } else if (this.needsReviewService.globalSearchText) {
      this.fetchNeedsReviewAPIPayload.filter = {};
      this.fetchSearchNeedsReviewData(callPaginationApi);
    } else {
      this.filterApplied = false;
      this.fetchNeedsReviewAPIPayload.filter = {};
      const fetchNeedsReviewAPIPayloadCount = {
        ...this.fetchNeedsReviewAPIPayload,
        operation: 'fetchCount',
      };
      fetchNeedsReviewAPIPayloadCount.operation = 'fetchCount';
      const fetchNeedsReviewAPIPayload = {
        ...this.fetchNeedsReviewAPIPayload,
        operation: 'fetch',
      };
      fetchNeedsReviewAPIPayload.operation = 'fetch';

      if (callPaginationApi) {
        forkJoin([
          this.needsReviewService.fetchNeedsReviewCount(fetchNeedsReviewAPIPayloadCount),
          this.needsReviewService.fetchNeedsReviewData(fetchNeedsReviewAPIPayload),
        ]).subscribe(([reviewCount, reviewData]) => {
          this.subcribeNeedsReviewTableConfig = {
            ...this.subcribeNeedsReviewTableConfig,
            pagination: this.updatePaginationCount(this.fetchNeedsReviewAPIPayload.status, reviewCount),
          };
          this.setChipCount(reviewCount);
          this.needsReviewTableData = this.nofilterSelected ? [] : reviewData.reviewAssets;
          this.emptyColumnDataCheck();
          this.enableNeedsReviewTable =
            this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
          setTimeout(() => {
            this.adjustTableHeight({
              eventType: 'SCROLL_EVENT',
            });
          }, 50);
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
        });
      } else if (this.dismissFlowStatus) {
        forkJoin([
          this.needsReviewService.fetchNeedsReviewCount(fetchNeedsReviewAPIPayloadCount),
          this.needsReviewService.fetchNeedsReviewData(fetchNeedsReviewAPIPayload),
        ]).subscribe(([reviewCount, reviewData]) => {
          this.setChipCount(reviewCount);
          this.needsReviewTableData = this.nofilterSelected ? [] : reviewData.reviewAssets;
          this.emptyColumnDataCheck();
          this.enableNeedsReviewTable =
            this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
          this.adjustTableHeight({
            eventType: 'SCROLL_EVENT',
          });
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          this.dismissFlowStatus = false;
        });
      } else {
        this.needsReviewService.fetchNeedsReviewData(this.fetchNeedsReviewAPIPayload).subscribe(
          (res) => {
            this.needsReviewTableData = this.nofilterSelected ? [] : res.reviewAssets;
            this.enableNeedsReviewTable =
              this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
            this.emptyColumnDataCheck();
            this.isTableLoading = false;
            this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
            this.adjustTableHeight({
              eventType: 'SCROLL_EVENT',
            });
          },
          (_err: any) => {
            this.showToastMessage(this.systemError, 'error');
            this.isTableLoading = false;
            this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          }
        );
      }
    }
  }
  emptyColumnDataCheck() {
    this.needsReviewTableData = this.needsReviewTableData?.map(function (user: any) {
      return {
        appId: user.appId ? user.appId : '',
        assetId: user.assetId,
        cellularRadioSerialNumber: user.cellularRadioSerialNumber ? user.cellularRadioSerialNumber : '-',
        satelliteRadioType: user.satelliteRadioType ? user.satelliteRadioType : '-',
        cellularRadioType: user.cellularRadioType ? user.cellularRadioType : '-',
        dcnName: user.dcnName,
        dealerCustomerNumber: user.dealerCustomerNumber ? user.dealerCustomerNumber : '-',
        deviceType: user.deviceType ? user.deviceType : '-',
        eventDate: user.eventDate ? user.eventDate : '-',
        guid: user.guid,
        make: user.make,
        model: user.model,
        modelYear: user.modelYear,
        networkManagerSerialNumber: user.networkManagerSerialNumber ? user.networkManagerSerialNumber : '-',
        ownerShipCount: user.ownerShipCount,
        ownerShipType: user.ownerShipType,
        reportingState: user.reportingState,
        satelliteRadioSerialNumber: user.satelliteRadioSerialNumber ? user.satelliteRadioSerialNumber : '-',
        serialNumber: user.serialNumber,
        status: user.status,
        ucid: user.ucid,
        ucidName: user.ucidName,
        digitalAuthorization: user.digitalAuthorization ? user.digitalAuthorization : '',
      };
    });
  }
  updatePaginationCount(status: string, reviewCount: any) {
    const needsReviewCount1 = {
      pageSizeOptions: [20, 40, 60, 80, 100],
      advanced: false,
      activePage: 1,
      defaultPageSize: this.subcribeNeedsReviewTableConfig.pagination.defaultPageSize,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
      totalNumberOfRecords: reviewCount.totalCount,
    };
    if (status == this.dspNeedReviewConstants.Dismissed) {
      needsReviewCount1.totalNumberOfRecords = reviewCount.dismissedCount;
    } else if (status == this.dspNeedReviewConstants.Active) {
      needsReviewCount1.totalNumberOfRecords = reviewCount.activeCount;
    } else {
      needsReviewCount1.totalNumberOfRecords = reviewCount.totalCount;
    }
    return needsReviewCount1;
  }
  /**** @param count Sets the count of Active and Dismissed chips*/
  setChipCount(count: any) {
    if (this.queueReasonList?.indexOf(this.selectedQueueReason) > -1) {
      this.chipsFilters = [
        {
          value: 'Active',
          label: this.dspNeedReviewConstants.Active,
          isChecked: true,
        },
      ];
    } else {
      this.chipsFilters = [
        {
          value: 'Active',
          label: this.dspNeedReviewConstants.Active,
          isChecked: true,
        },
        {
          value: 'Dismissed',
          label: this.dspNeedReviewConstants.Dismissed,
          isChecked: true,
        },
      ];
    }

    this.chipsFilters.forEach((chip: any) => {
      if (chip.label == this.dspNeedReviewConstants.Active) {
        chip.label = chip.label + ' (' + count.activeCount + ') ';
      } else if (chip.label == this.dspNeedReviewConstants.Dismissed) {
        chip.label = chip.label + ' (' + count.dismissedCount + ') ';
      }
    });
  }
  setAutoHeight() {
    const tableRef = this.tableRef?.table?.nativeElement;
    if (this.renderer && tableRef) {
      this.renderer.setStyle(tableRef, 'height', 'auto');
    }
  }
  adjustTableHeight(event: any) {
    if ((event.eventType === 'SCROLL_EVENT' || event.eventType === 'WINDOW_RESIZE') && this.tableRef) {
      const tableRef = this.tableRef?.table?.nativeElement;
      if (this.needsReviewTableData?.length) {
        const adjustHeight = window.innerHeight - 240 + 'px';
        this.renderer.setStyle(tableRef, 'height', adjustHeight);
        this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
      } else {
        this.renderer.setStyle(tableRef, 'height', '124px');
      }
    }
  }
  tableEvents(event: any) {
    if (event.rowdata?.status === 'Active' || event.rowdata?.status === 'New') {
      this.needsReviewService.enableNeedsReviewDismissOption.next(true);
    } else if (event.rowdata?.status === 'Dismissed') {
      this.needsReviewService.enableNeedsReviewDismissOption.next(false);
    }
    if (event.eventType === 'SORTING' && (this.status === 'Active' || this.status == undefined)) {
      this.fetchNeedsReviewAPIPayload.isSortOperation = true;
      this.sortOperation = true;
      if (
        this.fetchNeedsReviewAPIPayload.sortOrder != event?.event ||
        this.fetchNeedsReviewAPIPayload.sortBy != event?.data?.name
      ) {
        this.fetchNeedsReviewAPIPayload.sortBy = event?.data?.name || 'serialNumber';
        this.fetchNeedsReviewAPIPayload.sortOrder = event?.event || 'DESC';
        sessionStorage.setItem('sortByActive', this.fetchNeedsReviewAPIPayload.sortBy);
        sessionStorage.setItem('sortOrderActive', this.fetchNeedsReviewAPIPayload.sortOrder);
        sessionStorage.setItem('sortOperationActive', JSON.stringify(this.sortOperation));
        this.sortingActive = true;
        this.fetchNeedsReview(false);
      }
    } else if (event.eventType === 'SORTING' && this.fetchNeedsReviewAPIPayload.status === 'Dismissed') {
      this.fetchNeedsReviewAPIPayload.isSortOperation = true;
      this.sortOperation = true;
      if (
        this.fetchNeedsReviewAPIPayload.sortOrder != event?.event ||
        this.fetchNeedsReviewAPIPayload.sortBy != event?.data?.name
      ) {
        this.fetchNeedsReviewAPIPayload.sortBy = event?.data?.name || 'serialNumber';
        this.fetchNeedsReviewAPIPayload.sortOrder = event?.event || 'DESC';
        sessionStorage.setItem('sortByDismissed', this.fetchNeedsReviewAPIPayload.sortBy);
        sessionStorage.setItem('sortOrderDismissed', this.fetchNeedsReviewAPIPayload.sortOrder);
        sessionStorage.setItem('sortOperationDismissed', JSON.stringify(this.sortOperation));
        this.sortingDismiss = true;
        this.fetchNeedsReview(false);
      }
    } else if (event.eventType === 'PAGE_CHANGE_EVENT' && this.isPageChanged(event)) {
      if (event?.range?.pageSize !== this.fetchNeedsReviewAPIPayload.limit) {
        this.subcribeNeedsReviewTableConfig.pagination.activePage = 1;
      }
      this.fetchNeedsReviewAPIPayload.offset = event.range?.startIndex || 0;
      this.fetchNeedsReviewAPIPayload.limit = event.range?.pageSize || 20;
      if (this.fetchNeedsReviewAPIPayload.limit != this.subcribeNeedsReviewTableConfig.pagination.defaultPageSize) {
        this.pageLimitPayload.emit(this.fetchNeedsReviewAPIPayload.limit);
        this.tableSettingService.updateTableSettings(
          this.pageLabel,
          this.subcribeNeedsReviewTableConfig.columnConfig,
          this.fetchNeedsReviewAPIPayload.limit
        );
        this.subcribeNeedsReviewTableConfig.pagination.defaultPageSize = this.fetchNeedsReviewAPIPayload.limit;
      }
      this.fetchNeedsReview(false);
    }
  }
  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.fetchNeedsReviewAPIPayload.offset ||
      pageData?.range?.pageSize !== this.fetchNeedsReviewAPIPayload.limit
    );
  }

  // Change Asset Status from New to Action on any Clickable action - Table row click
  changeAssetStatus(event: any) {
    const payload = {
      dealerCode: this.dealer.dealerCode,
      dealerQueueReasonId: this.queueReasonId ? this.queueReasonId : event.queueReasonId,
      make: event.make,
      serialNumber: event.serialNumber,
      guiId: event.guid,
    };
    this.needsReviewService.changeAssetNewStatus(payload).subscribe(
      (res: any) => {
        if (res.status) {
          this.needsReviewService.refreshNeedsReviewCategories.next(true); //refreshing left tab
          this.fetchNeedsReviewAPIPayload.dealerQueueReasonId = payload.dealerQueueReasonId;
          this.fetchNeedsReviewAPIPayload.status = 'Active';
          /**As Karuna suggested, since the fetchneedsreview data is not refreshed from backend side, so adding timeout for the fetchNeedsReview method, only when the asset status is changed from new to active */
          setTimeout(() => {
            this.fetchNeedsReview(true);
          }, 500);
        }
      },
      (error: any) => {
        this.isTableLoading = false;
        this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
      }
    );
  }

  /**** @param event Output from chips-list component when chips selection changes i.e 'active'*/
  onFilterChange(event: any) {
    this.enableNeedsReviewTable = true;
    if (event == 'Active' || event == 'Dismissed') {
      this.chipStatus = true;
    } else {
      this.chipStatus = false;
    }
    this.exportSorting.emit(this.chipStatus);

    this.nofilterSelected = false;
    // event containing filter data differs when in mobile.
    if (event.length == 0) {
      event.stopPropagation();
      // this.nofilterSelected = true;
      // this.needsReviewTableData = [];
      // this.enableNeedsReviewTable = false;
      // this.hidePaginationBar();
      // this.needsReviewService.setStatus('');
      return;
    }
    this.setAutoHeight();
    this.isTableLoading = true;
    this.showPaginationBar();
    this.fetchNeedsReviewAPIPayload.status = event.length == 1 ? event[0] : 'Active';
    this.status = this.fetchNeedsReviewAPIPayload.status;
    this.needsReviewService.setStatus(this.fetchNeedsReviewAPIPayload.status);
    this.needsReviewSharedService.selectedChipStatus.next(this.fetchNeedsReviewAPIPayload.status);
    this.fetchNeedsReviewAPIPayload.offset = 0;
    if ((this.status === 'Active' || this.status == undefined) && !this.sortingActive) {
      this.fetchNeedsReviewAPIPayload.sortBy = 'eventDate';
      this.fetchNeedsReviewAPIPayload.sortOrder = 'desc';
      this.fetchNeedsReviewAPIPayload.isSortOperation = false;
      this.fetchNeedsReview(true);
    } else if (this.status === 'Dismissed' && !this.sortingDismiss) {
      this.fetchNeedsReviewAPIPayload.sortBy = 'eventDate';
      this.fetchNeedsReviewAPIPayload.sortOrder = 'desc';
      this.fetchNeedsReviewAPIPayload.isSortOperation = false;
      this.fetchNeedsReview(true);
    } else if ((this.status === 'Active' || this.status == undefined) && this.sortingActive) {
      this.sortByActive = sessionStorage.getItem('sortByActive');
      this.sortOrderActive = sessionStorage.getItem('sortOrderActive');
      this.sortOperationActive = sessionStorage.getItem('sortOperationActive');

      this.fetchNeedsReviewAPIPayload.sortBy = this.sortByActive;
      this.fetchNeedsReviewAPIPayload.sortOrder = this.sortOrderActive;
      this.fetchNeedsReviewAPIPayload.isSortOperation = this.sortOperationActive;

      this.fetchNeedsReview(true);
    } else if (this.status === 'Dismissed' && this.sortingDismiss) {
      this.sortByDismissed = sessionStorage.getItem('sortByDismissed');
      this.sortOrderDismissed = sessionStorage.getItem('sortOrderDismissed');
      this.sortOperationDismissed = sessionStorage.getItem('sortOperationDismissed');

      this.fetchNeedsReviewAPIPayload.sortBy = this.sortByDismissed;
      this.fetchNeedsReviewAPIPayload.sortOrder = this.sortOrderDismissed;
      this.fetchNeedsReviewAPIPayload.isSortOperation = this.sortOperationDismissed;

      this.fetchNeedsReview(true);
    }
  }
  hidePaginationBar() {
    const overlayRef = document.getElementsByClassName('pag-container') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }
  showPaginationBar() {
    const overlayRef = document.getElementsByClassName('pag-container') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'flex';
    }
  }

  onGlobalSearch() {
    this.searchChange?.subscribe((value) => {
      this.setAutoHeight();
      if (value !== '' && this.searchHelpText !== '') {
        this.searchHelpText = '';
      } else if (value.length > 2) {
        this.searchValue = value.trim();
        this.fetchNeedsReviewAPIPayload.offset = 0;
        this.fetchNeedsReviewAPIPayload.searchText = this.searchValue;
        this.reviewTableCustomData.searchString = this.searchValue;
        this.isTableLoading = true;
        this.needsReviewTableData = [];
      } else if (value === '') {
        Reflect.deleteProperty(this.fetchNeedsReviewAPIPayload, 'searchText');
        this.searchValue = '';
        this.reviewTableCustomData.searchString = '';
      }
    });
  }

  updateColumnConfigCustomData() {
    this.subcribeNeedsReviewTableConfig.columnConfig.forEach((config: any) => {
      config.customData = this.reviewTableCustomData;
    });
  }

  fetchSearchNeedsReviewData(callPaginationApi: boolean) {
    this.setAutoHeight();
    this.isTableLoading = true;
    this.needsReviewTableData = [];
    const searchNeedsReviewAPIPayloadCount = {
      ...this.fetchNeedsReviewAPIPayload,
      operation: 'globalSearchCount',
    };
    searchNeedsReviewAPIPayloadCount.operation = 'globalSearchCount';
    searchNeedsReviewAPIPayloadCount.status = '';
    const searchNeedsReviewAPIPayload = {
      ...this.fetchNeedsReviewAPIPayload,
      operation: 'globalSearch',
    };
    searchNeedsReviewAPIPayload.operation = 'globalSearch';
    if (callPaginationApi) {
      forkJoin([
        this.needsReviewService.getSearchNeedsReviewCount(searchNeedsReviewAPIPayloadCount),
        this.needsReviewService.getSearchNeedsReviewData(searchNeedsReviewAPIPayload),
      ]).subscribe(([searchReviewCount, searchReviewData]) => {
        this.subcribeNeedsReviewTableConfig = {
          ...this.subcribeNeedsReviewTableConfig,
          pagination: this.updatePaginationCount(this.fetchNeedsReviewAPIPayload.status, searchReviewCount),
        };
        this.setChipCount(searchReviewCount);
        this.needsReviewTableData = searchReviewData.reviewAssets;
        this.emptyColumnDataCheck();
        this.adjustTableHeight({
          eventType: 'SCROLL_EVENT',
        });
        this.isTableLoading = false;
        this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData?.length);
      });
    } else if (this.dismissFlowStatus) {
      forkJoin([
        this.needsReviewService.getSearchNeedsReviewCount(searchNeedsReviewAPIPayloadCount),
        this.needsReviewService.getSearchNeedsReviewData(searchNeedsReviewAPIPayload),
      ]).subscribe(([reviewCount, reviewData]) => {
        this.setChipCount(reviewCount);
        this.needsReviewTableData = this.nofilterSelected ? [] : reviewData.reviewAssets;
        this.emptyColumnDataCheck();
        this.enableNeedsReviewTable = this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
        this.adjustTableHeight({
          eventType: 'SCROLL_EVENT',
        });
        this.isTableLoading = false;
        this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
        this.dismissFlowStatus = false;
      });
    } else {
      this.needsReviewService.getSearchNeedsReviewData(searchNeedsReviewAPIPayload).subscribe(
        (res) => {
          this.isTableLoading = false;
          this.needsReviewTableData = res.reviewAssets;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          this.emptyColumnDataCheck();
          this.enableNeedsReviewTable =
            this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
          this.adjustTableHeight({
            eventType: 'SCROLL_EVENT',
          });
        },
        (_err: any) => {
          this.showToastMessage(this.systemError, 'error');
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
        }
      );
    }
  }
  /** Invokes the fetch Filter records for needs review */

  fetchFilterNeedsReview(callPaginationApi: boolean, reviewPayload: any) {
    this.setAutoHeight();
    this.isTableLoading = true;
    this.needsReviewTableData = [];
    // Reflect.deleteProperty(this.fetchNeedsReviewAPIPayload, 'searchText');
    const filterNeedsReviewAPIPayloadCount = {
      ...this.fetchNeedsReviewAPIPayload,
      operation: 'filterCount',
    };
    filterNeedsReviewAPIPayloadCount.operation = 'filterCount';
    const filterNeedsReviewAPIPayload = {
      ...reviewPayload,
      operation: 'filter',
    };
    filterNeedsReviewAPIPayload.operation = 'filter';
    if (callPaginationApi) {
      forkJoin([
        this.needsReviewService.filterNeedsReviewCount(filterNeedsReviewAPIPayloadCount),
        this.needsReviewService.filterAssetsForReview(filterNeedsReviewAPIPayload),
      ]).subscribe(
        ([filterReviewCount, filterReviewData]) => {
          this.subcribeNeedsReviewTableConfig = {
            ...this.subcribeNeedsReviewTableConfig,
            pagination: this.updatePaginationCount(this.fetchNeedsReviewAPIPayload.status, filterReviewCount),
          };
          this.setChipCount(filterReviewCount);
          this.needsReviewTableData = filterReviewData.reviewAssets;
          this.emptyColumnDataCheck();
          // this.enableNeedsReviewTable =
          //   this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
          this.adjustTableHeight({
            eventType: 'SCROLL_EVENT',
          });
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData?.length);
        },
        (error: any) => {
          this.showToastMessage(this.systemError, 'error');
          this.isTableLoading = false;
          this.needsReviewTableData = [];
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          const reviewCount = {
            dismissedCount: 0,
            activeCount: 0,
            totalCount: 0,
          };
          this.subcribeNeedsReviewTableConfig = {
            ...this.subcribeNeedsReviewTableConfig,
            pagination: this.updatePaginationCount(this.fetchNeedsReviewAPIPayload.status, reviewCount),
          };
          this.setChipCount(reviewCount);
        }
      );
    } else {
      this.needsReviewService.filterAssetsForReview(this.fetchNeedsReviewAPIPayload).subscribe(
        (res) => {
          this.needsReviewTableData = this.nofilterSelected ? [] : res.reviewAssets;
          this.enableNeedsReviewTable =
            this.needsReviewTableData && this.needsReviewTableData.length > 0 ? true : false;
          this.emptyColumnDataCheck();
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
          this.adjustTableHeight({
            eventType: 'SCROLL_EVENT',
          });
        },
        (_err: any) => {
          this.showToastMessage(this.systemError, 'error');
          this.isTableLoading = false;
          this.needsReviewSharedService.dataLoaded.next(this.needsReviewTableData.length);
        }
      );
    }
  }

  getSubscriptionServiceMapping() {
    if (isEmpty(this.dspStoreData?.serviceMapping)) {
      this.assetService.getSubscriptionServiceMapping().subscribe({
        next: (result: any) => {
          this.store.dispatch(getSubscriptionServiceMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  refreshTableAfterSubscriptionUpdate() {
    sessionStorage.removeItem('sortByActive');
    sessionStorage.removeItem('sortOrderActive');
    sessionStorage.removeItem('sortOperationActive');

    sessionStorage.removeItem('sortByDismissed');
    sessionStorage.removeItem('sortOrderDismissed');
    sessionStorage.removeItem('sortOperationDismissed');

    this.reviewTableCustomData.refreshTable = () => {
      this.fetchNeedsReview(true);
    };
  }

  getBillDirectCustomers() {
    if (isEmpty(this.dspStoreData?.billDirectCustomers)) {
      this.assetService.getBillDirectCustomers({}).subscribe({
        next: (result: any) => {
          this.store.dispatch(getBillDirectCustomers(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  getProductFamily() {
    if (isEmpty(this.dspStoreData?.productFamily)) {
      this.dspCommonService.getProductFamily().subscribe({
        next: (result: any) => {
          this.store.dispatch(getProductFamily(result));
        },
        error: (err: any) => {},
      });
    }
  }

  getCertifiedCountries() {
    if (isEmpty(this.dspStoreData?.certifiedCountries)) {
      this.dspCommonService.getCertifiedCountries().subscribe({
        next: (result: any) => {
          this.store.dispatch(getCertifiedCountries(result));
        },
        error: (err: any) => {},
      });
    }
  }

  getEDandPSRUrl() {
    if (isEmpty(this.dspStoreData?.edUrl)) {
      this.dspCommonService.getEquipmentDataUrl().subscribe({
        next: (response: any) => {
          const result = {
            data: response,
          };
          this.store.dispatch(getEquipmentDataUrl(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
    if (isEmpty(this.dspStoreData?.psrUrl)) {
      this.dspCommonService.getPSRUrl().subscribe({
        next: (response: any) => {
          this.store.dispatch(getPSRUrl(response));
        },
        error: (_err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  getFeatureSupportMsg() {
    if (isEmpty(this.dspStoreData?.featureSupportMapping)) {
      this.dspCommonService.getFeatureSupportMsg().subscribe({
        next: (result: any) => {
          this.store.dispatch(getFeatureSupportMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  getFeaturesToggleMapping() {
    if (isEmpty(this.dspStoreData?.featuresToggleMapping)) {
      this.assetService.getFeaturesToggleMapping().subscribe({
        next: (result: any) => {
          this.store.dispatch(getFeaturesToggleMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  getDeviceSubscriptionCatalog() {
    const dealer: dealerType = fetchLoggedInDealerDetails();
    if (isEmpty(this.dspStoreData?.deviceCatalog)) {
      this.dspCommonService.getDeviceSubsciptionCatalogue({ dealerCode: dealer.dealerCode }).subscribe({
        next: (result: any) => {
          this.store.dispatch(setDeviceCatalogueInfo(result));
        },
        error: () => {},
      });
    }
  }
  getBillingAccounts() {
    if (isEmpty(this.dspStoreData?.billingAccounts)) {
      this.dspCommonService.getBillingAccounts().subscribe({
        next: (result: any) => {
          const billingAccounts = this.isSuperAdmin
            ? result
            : result?.filter((account: any) => {
                return this.cwsId == account.adminId;
              });

          const response = { accountDetails: billingAccounts };

          this.store.dispatch(getBillingAccounts(response));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  ngOnDestroy() {
    this.reviewFilterSubscription?.unsubscribe();
    this.fetchNeedsReviewAPIPayload.searchText = '';
  }
}
