<div class="select-plan-content">
  <div class="select-plan-body">
    <div class="cc-grid">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <span class="asset-title">ASSET DETAILS</span>
        <span class="asset-title ownership">ASSET OWNERSHIP</span>
      </div>

      <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
        <div class="column1">
          <span class="asset-details-title">Asset S/N:</span>
          <span class="asset-details-value">{{ rowData?.serialNumber }}</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Make/Model:</span>
          <span class="asset-details-value">{{ rowData?.make }}&sol;{{ rowData?.model }}</span>
        </div>
        <div class="column1 dealer-align">
          <span class="asset-details-title">Dealer:</span>
          <span class="asset-details-value" *ngIf="rowData?.dealerName && rowData?.dealerCode">{{
            rowData?.dealerName + ': ' + rowData?.dealerCode
          }}</span>
          <span class="asset-details-value" *ngIf="!(rowData?.dealerName && rowData?.dealerCode) && !showDealerInfo"
            >-</span
          >
          <span class="asset-details-value" *ngIf="!(rowData?.dealerName && rowData?.dealerCode) && showDealerInfo">{{
            this.dealer?.dealerName + ': ' + this.dealer?.dealerCode || '-'
          }}</span>
        </div>
      </div>
      <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4 column-last">
        <div class="column1">
          <span class="asset-details-title">Device Type:</span>
          <span class="asset-details-value">{{ rowData?.deviceType }}</span>
        </div>
        <div class="column1">
          <span class="asset-details-title dataHeight">Device Information:</span>
          <span
            class="asset-details-value"
            [disabled]="this.deviceInfoWithoutTrim?.length > 15 ? false : true"
            [innerHTML]="getDeviceInformation() || '-'"
          ></span>
        </div>
        <div class="column1" *ngIf="!hideBillToPartyOwnership && subsPricingEligible && !hideBillToParty">
          <span class="asset-details-title">Bill to Party:</span>
          <span class="asset-details-value">{{ billToParty || '-' }}</span>
        </div>
        <div class="column1" *ngIf="hideBillToPartyOwnership || (!subsPricingEligible && !hideBillToParty)">
          <span class="asset-details-title">Bill to Party:</span>
          <span class="asset-details-value">-</span>
        </div>
      </div>

      <div
        class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4"
        *ngIf="!hideBillToPartyOwnership"
      >
        <div class="column1">
          <span class="asset-details-title dataHeight">Customer:</span>
          <span
            *ngIf="ownershipData?.ucidName?.length > 0; else else_content"
            class="asset-details-value"
            [disabled]="this.customerInfoWithoutTrim?.length > 20 ? false : true"
            [innerHTML]="dealerCustomerData(ownershipData?.ucid, ownershipData?.ucidName)"
          ></span>
        </div>
        <div class="column1">
          <span class="asset-details-title dataHeight">Dealer Customer:</span>
          <span
            *ngIf="ownershipData?.dealerCustName?.length > 0; else else_content"
            class="asset-details-value"
            [disabled]="this.dealerCustomerInfoWithoutTrim?.length > 20 ? false : true"
            [innerHTML]="dealerCustomerData(ownershipData?.dealerCustNo, ownershipData?.dealerCustName)"
          ></span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Digital Authorization:</span>
          <span class="icon-size" *ngIf="ownershipData; else else_content">
            <cc-icon
              fontSet="icomoon"
              [interactive]="true"
              [tooltipPosition]="'bottom'"
              [appClass]="'-dat-tool-tip'"
              [ngClass]="checkDATStatus()"
              cc-tooltip
              [template]="tooltipTemplateForDAT"
              >{{ updateDATBadge() }}
            </cc-icon>
            <span class="asset-details-value">{{ updateDatStatus() }}</span>
          </span>
        </div>
        <div class="column1">
          <span *ngIf="ownershipData?.cvaDetails" class="asset-details-title">CVA Status:</span>
          <cc-icon
            *ngIf="ownershipData?.cvaDetails?.cvaStatus.toUpperCase() === 'AVAILABLE'"
            cc-tooltip="CVA Status"
            [template]="tooltipTemplateForCva"
            [appClass]="'-cva-tool-tip'"
            [interactive]="true"
            class="icon-CVA-Available11 cva-status-icon cva-color-avail-mp"
          >
          </cc-icon>
          <cc-icon
            *ngIf="ownershipData?.cvaDetails?.cvaStatus.toUpperCase() === 'NOT AVAILABLE'"
            cc-tooltip="CVA Status"
            [tooltipPosition]="'bottom'"
            [interactive]="true"
            [appClass]="'-cva-tool-tip'"
            [template]="tooltipTemplateForCva"
            class="icon-CVA-NA11 cva-status-icon cva-color-na-mp"
          ></cc-icon>
          <span *ngIf="ownershipData?.cvaDetails" class="cva-change">{{
            ownershipData?.cvaDetails?.cvaStatus.toUpperCase()
          }}</span>
          <span class="cva-change" *ngIf="cvaStatus === 'Available'">{{ '- ' + cvaCount }}</span>
        </div>
      </div>
      <div
        class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4"
        *ngIf="hideBillToPartyOwnership"
      >
        <div class="column1">
          <span class="asset-details-title">Customer:</span>
          <span class="asset-details-value">-</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Dealer Customer:</span>
          <span class="asset-details-value">-</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Digital Authorization:</span>
          <span class="asset-details-value">-</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">CVA Status:</span>
          <span class="asset-details-value">-</span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #else_content>{{ '-' }}</ng-template>
<ng-template #tooltipTemplateForDAT>
  <div class="dat-tooltip-header">
    Digital Authorization <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="dat-tooltip-body">
    <span *ngIf="datStatus === 'AGREE'"
      >Customer authorization on file - No further action required. Click to review in
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'DECLINE'"
      >Customer authorization declined - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'UNAVAILABLE'"
      >Customer authorization unavailable - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'SYSTEM ERROR'"
      >Unable to get authorization details - Please retry later or contact Cat Digital support
    </span>
  </div>
</ng-template>
<ng-template #tooltipTemplateForCva>
  <div class="cva-tooltip-header">
    CVA Status
    <cc-icon [appClass]="'-cva-tool-tip'" class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="cva-tooltip-body">
    <span *ngIf="cvaStatus === 'Available'"
      >One or more active CVAs are associated to this asset. These CVAs may or may not be associated to your dealership.
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>
      to review in Cat Foresight</span
    >
    <span *ngIf="cvaStatus === 'Not Available'"
      >There are no active CVAs recorded for this asset.
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>
      to review in Cat Foresight
    </span>
  </div>
</ng-template>
