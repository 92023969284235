import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as dspConstants from '../../../../shared/dspConstants';
import { HomeService } from '../../services/home.service';
import { debounce, isEmpty, isNil } from 'lodash';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { ManagePlanComponent } from '../../../assets/manage-plan/manage-plan.component';
import { ManageAssetSubscriptionComponent } from '../../../assets/manage-asset-subscription/manage-asset-subscription.component';
import { EditAssetSerialNumberComponent } from '../../../assets/asset-drawer/edit-asset-serial-number/edit-asset-serial-number.component';
import { DeviceStatusComponent } from '../../../device-status/device-status.component';
import { ConfigureWifiComponent } from '../../../assets/asset-drawer/configure-wifi/configure-wifi.component';
import { AssetDrawerComponent } from '../../../assets/asset-drawer/asset-drawer.component';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { ManagePendingRequestComponent } from '../../../manage-pending-request/manage-pending-request.component';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { DspCommonService } from 'apps/dsp-ui/src/app/services/dsp-common.service';
@Component({
  selector: 'dsp-next-gen-ui-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit, DoCheck, OnChanges {
  @Input() assetData: any;
  @Output() refreshAssetsData = new EventEmitter();
  isPreviousDisabled: any = true;
  dspWorklistConstants = dspConstants.Worklist;
  optionDetails: any = [];
  optionDetailsArray = [
    { optionName: 'Details', isShow: true },
    { optionName: 'Manage Plan', isShow: true, optionToShow: 'showManagePlan' },
    { optionName: 'Update Registration', isShow: true, optionToShow: 'showUpdateRegistration' },
    { optionName: 'Device Status', isShow: true, optionToShow: 'showDeviceStatusIcon' },
    { optionName: 'Configure Wi-Fi', isShow: true, optionToShow: 'showConfigureWifiIcon' },
  ];
  popOptionArray: any = [];
  isSecondRowLoading = true;
  dspStoreData: any;
  featureSupportMapping: any;
  isMultiProductWorkFlowEnabled = false;
  manageAssetPlanDrawerRef: any;
  editAssetSerialNumber!: any;
  wifiConfigurationRef!: any;
  assetDrawerRef!: any;
  deviceStatus!: any;
  oldObject: any = '';
  nextBtnClick = false;
  dealer: any;
  dealerCode: any;
  isSelfServeSubscription: any;
  isCustomAccessEnabled: any;
  constructor(
    private homeService: HomeService,
    private drawerService: DrawerService,
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    private dspCommonService: DspCommonService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.featureSupportMapping = this.dspStoreData.featureSupportMapping;
      this.isMultiProductWorkFlowEnabled = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
      this.isSelfServeSubscription = this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        ? this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        : false;
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.dealerCode = this.dealer?.dealerCode || 'TD00';
    this.dspCommonService.isCustomAccessEnabled.subscribe((res: any) => {
      this.isCustomAccessEnabled = res;
    });
  }
  ngOnChanges(): void {
    this.assetData.source = 'Home Page - Recently Viewed';
  }
  ngDoCheck(): void {
    if (this.oldObject !== JSON.stringify(this.assetData)) {
      if (
        this.assetData?.hasOwnProperty('showManagePlan') &&
        this.assetData?.hasOwnProperty('showUpdateRegistration') &&
        this.assetData?.hasOwnProperty('showDeviceStatusIcon') &&
        this.assetData?.hasOwnProperty('showConfigureWifiIcon')
      ) {
        this.oldObject = JSON.stringify(this.assetData);
        this.isSecondRowLoading = false;
        this.updateOptionLists();
      }
    }
  }

  hideArrow(element: any) {
    if (this.nextBtnClick) {
      return true;
    } else {
      return this.checkOverflow(element);
    }
  }
  updateOptionLists() {
    this.optionDetailsArray.forEach((option: any, index: any) => {
      option.optionName == 'Manage Plan' ? (option.isShow = this.assetData?.showManagePlan) : false;
      option.optionName == 'Update Registration' ? (option.isShow = this.assetData?.showUpdateRegistration) : false;
      option.optionName == 'Device Status'
        ? (option.isShow = this.assetData?.showDeviceStatusIcon && this.isCustomAccessEnabled)
        : false;
      option.optionName == 'Configure Wi-Fi' ? (option.isShow = this.assetData?.showConfigureWifiIcon) : false;
    });
    this.optionDetails = this.optionDetailsArray.filter((option: any) => {
      return option.isShow == true;
    });
  }
  checkDealerDataLength(serialNumber: any) {
    return serialNumber?.length > 12 ? serialNumber?.slice(0, 12) + '...' : serialNumber;
  }
  getData(data: any) {
    return data;
  }
  next() {
    this.nextBtnClick = true;
    let popItem: any = this.optionDetails.shift();
    this.popOptionArray.unshift(popItem);
  }
  previous() {
    this.optionDetails.unshift(this.popOptionArray[0]);
    this.popOptionArray.shift();
  }
  checkOverflow(element: any) {
    return element?.offsetWidth < element?.scrollWidth - 12;
  }
  validatePrevBtn() {
    if (this.popOptionArray.length === 0) {
      return true;
    }
    return false;
  }
  checkEnginSerialNumberForTrim(serialNumber: any) {
    return serialNumber?.length > 8 ? serialNumber?.slice(0, 7) + '...' : serialNumber;
  }
  checkDeviceTyperForTrim(deviceType: any) {
    return deviceType?.length > 6 ? deviceType?.slice(0, 3) + '...' : deviceType;
  }
  optionClick(option: any, assetData: any) {
    switch (option?.optionName) {
      case 'Details':
        this.openAssetDrawer(assetData);
        break;
      case 'Manage Plan':
        this.openManagePlan(assetData);
        break;
      case 'Update Registration':
        this.openEditAssetSerialNumber(assetData);
        break;
      case 'Device Status':
        this.openDeviceStatus(assetData);
        break;
      case 'Configure Wi-Fi':
        this.openWifiConfiguration(assetData);
        break;
      default:
        break;
    }
  }
  openAssetDrawer(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: rowData,
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.assetDrawerRef.afterClosed().subscribe((response: any) => {
      this.assetDrawerService.getapplicableList = '';
      this.refreshAssetsData.emit(response);
    });
  }
  openManagePlan(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    if (rowData.isCustRequestedSubscription && this.isSelfServeSubscription) {
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePendingRequestComponent, CcDrawerConfig>(
        ManagePendingRequestComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: rowData ? rowData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: true,
          id: 'multiSizeDrawer',
          disableBackdropClick: false,
          drawerSize: CcDrawerSize.LARGE,
        }
      );

      this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
        this.refreshAssetsData.emit(response);
      });
    } else {
      if (this.isMultiProductWorkFlowEnabled) {
        this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePlanComponent, CcDrawerConfig>(
          ManagePlanComponent,
          {
            data: {
              type: 'LANDING',
              selectedAssetDetails: rowData ? rowData : {},
            },
            enableBlackBackDrop: true,
            closeOnEsc: true,
            id: 'multiSizeDrawer',
            disableBackdropClick: false,
            drawerSize: CcDrawerSize.LARGE,
          }
        );
        this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
          this.refreshAssetsData.emit(response);
        });
      } else {
        this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManageAssetSubscriptionComponent, CcDrawerConfig>(
          ManageAssetSubscriptionComponent,
          {
            data: {
              type: 'LANDING',
              selectedAssetDetails: rowData ? rowData : {},
            },
            enableBlackBackDrop: true,
            closeOnEsc: false,
            id: 'multiSizeDrawer',
            disableBackdropClick: true,
            drawerSize: CcDrawerSize.LARGE,
          }
        );
        this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
          this.refreshAssetsData.emit(response);
        });
      }
    }
  }
  openEditAssetSerialNumber(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.editAssetSerialNumber = this.drawerService.openDrawer<EditAssetSerialNumberComponent, CcDrawerConfig>(
      EditAssetSerialNumberComponent,
      {
        data: {
          rowData: rowData,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.editAssetSerialNumber.afterClosed().subscribe((response: any) => {
      this.refreshAssetsData.emit(response);
    });
  }
  openDeviceStatus(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.deviceStatus = this.drawerService.openDrawer<DeviceStatusComponent, CcDrawerConfig>(DeviceStatusComponent, {
      data: { type: 'LANDING', assetInfo: rowData },
      enableBlackBackDrop: true,
      id: 'deviceStatusDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.deviceStatus.afterClosed().subscribe((response: any) => {
      this.refreshAssetsData.emit(response);
    });
  }
  openWifiConfiguration(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.wifiConfigurationRef = this.drawerService.openDrawer<ConfigureWifiComponent, CcDrawerConfig>(
      ConfigureWifiComponent,
      {
        data: {
          titleNames: 'Configure Wifi',
          data: rowData,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.wifiConfigurationRef.afterClosed().subscribe((response: any) => {
      this.refreshAssetsData.emit(response);
    });
  }
  saveRecentlyViewAssets(rowData: any) {
    let payload = {
      make: rowData?.make,
      serialNumber: rowData?.serialNumber,
      loggedInDealerCode: this.dealerCode,
      assetId: rowData?.assetId,
    };
    this.homeService.saveRecentlyViewAssets(payload).subscribe({
      next: (response: any) => {},
      error: (err: any) => {},
    });
  }
}
