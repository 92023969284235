import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty } from 'lodash-es';
import { AssetService } from '../../services/asset.service';
import { dealerType } from '../../../../models/shared.interface';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../../shared/shared';
import * as dspConstants from '../../../../shared/dspConstants';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { LoaderService } from '../../../../services/loader.service';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { DcnListComponent } from '../dcn-list/dcn-list.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dsp-next-gen-ui-select-ownership',
  templateUrl: './select-ownership.component.html',
  styleUrls: ['./select-ownership.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectOwnershipComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() launchType: any;
  @Input() hasPartialOwnershipData: any;
  @Output() retrieveSyncedOwnership = new EventEmitter();
  @Input() syncEvents!: Observable<void>;
  dealer!: dealerType;
  syncEventsSubscription!: Subscription;
  assetOwners: any = [];
  datToastMsgConfig: any[] = [];
  edUrl = '';
  datUrl = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  ownershipSystemError = dspConstants.Common.OWNERSHIP_SYSTEM_ERROR;
  noActiveSyncError = dspConstants.Common.NO_ACTIVE_SYNC_ERROR;
  selectedOwner!: any;
  dspConfig: any;
  dcnListDrawerRef: any;
  replaceEDUrl!: any;
  dspStoreData!: any;
  enableOwnershipSync!: any;
  userActiveFeatures!: any;
  isCatGradeControlAllDealer: any;
  constructor(
    private messageBar: MessageBar,
    private assetService: AssetService,
    private loaderService: LoaderService,
    public assetDrawerService: AssetDrawerService,
    private drawerService: DrawerService,
    public changeDetector: ChangeDetectorRef,
    public store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.enableOwnershipSync = this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        ? this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.edUrl = dsp.edUrl?.data;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspConfig = dsp.dspConfig || {};
      this.replaceEDUrl = this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        ? this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        : false;
      this.isCatGradeControlAllDealer = this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        ? this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        : false;
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.selectedAssetSubscriptionFormData.isValidForm = false;

    this.syncEventsSubscription = this.syncEvents?.subscribe(() => this.setAssetOwnershipData());
    this.setAssetOwnershipData();
  }
  enableSyncOwnership() {
    return this.assetService.validateOwnershipSync(this.userActiveFeatures, this.rowData, this.enableOwnershipSync);
  }

  initiateSync() {
    const ownershipSyncPayload = {
      assetIds: this.rowData.assetId,
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.initOwnershipSync(ownershipSyncPayload).subscribe({
      next: (result: any) => {
        this.rowData.ownershipDetails = [];
        this.retrieveSyncedOwnership.emit();
      },
      error: (err: any) => {
        if (err.status == 409) {
          this.showToastMessage(this.noActiveSyncError, 'info', undefined, true);
        } else {
          this.showToastMessage(this.ownershipSystemError, 'error', undefined, true);
        }
      },
    });
  }

  setAssetOwnershipData() {
    if (!isEmpty(this.rowData?.ownershipDetails) && !this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.assetOwners.forEach((owner: any) => {
        owner.disabled = false;
      });
      if (
        this.launchType === 'cat-grade-control' &&
        !this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck &&
        !this.hasPartialOwnershipData
      ) {
        this.rowData.ownershipDetails[0].checked = true;
        this.selectedAssetSubscriptionFormData.customer = this.assetOwners[0];
        this.checkDatStatus(this.assetOwners[0]);
      }
      // this.rowData.ownershipDetails[0].checked = true;
    } else if (!isEmpty(this.rowData?.ownershipDetails) && this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.selectedAssetSubscriptionFormData.customer = this.selectedOwner[0];
      this.checkDatStatus(this.selectedOwner[0]);
    }
    // added below if block to disable next if there is no ownership data
    else if (isEmpty(this.rowData?.ownershipDetails)) {
      this.selectedAssetSubscriptionFormData.customer = {};
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    else if (!isEmpty(this.rowData?.ownershipDetails) && this.rowData?.isNoneSelected) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.selectedAssetSubscriptionFormData.customer = 'None';
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    } else if (isEmpty(this.rowData?.ownershipDetails)) {
      this.rowData.isNoneSelected = true;
      this.selectedAssetSubscriptionFormData.customer = 'None';
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    }
    */
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  isOwnerSelected() {
    this.selectedOwner = this.rowData.ownershipDetails.filter((owner: any) => {
      return owner.checked;
    });

    return !isEmpty(this.selectedOwner) ? true : false;
  }
  valueChange(data: any) {
    this.messageBar.dismiss();
    const ownerChecked = cloneDeep(data?.checked);
    if (data && data !== 'None') {
      this.assetOwners.map((owner: any) => {
        if (data.ownershipId === owner.ownershipId && !ownerChecked) {
          owner.checked = true;
        } else {
          owner.checked = false;
        }
      });
      // this.rowData.isNoneSelected = false;
      if (!ownerChecked) {
        this.selectedAssetSubscriptionFormData.customer = data;
        if (this.checkDealerIsNotEmpty(data)) {
          this.checkDatStatus(data);
        } else {
          if (
            this.launchType === 'cat-grade-control' &&
            this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck
          ) {
            this.fetchDCNList(data);
          } else if (this.launchType !== 'cat-grade-control') {
            this.fetchDCNList(data);
          } else {
            this.checkDatStatus(data);
          }
        }
      } else {
        this.selectedAssetSubscriptionFormData.customer = {};
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      }
      this.resetAssetFormData();
    }
    /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    else if (data === 'None') {
      if (this.rowData?.isNoneSelected) {
        this.rowData.isNoneSelected = false;
        this.selectedAssetSubscriptionFormData.customer = {};
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      } else {
        this.rowData.isNoneSelected = true;
        this.assetOwners.map((owner: any) => {
          owner.checked = false;
        });
        this.selectedAssetSubscriptionFormData.customer = 'None';
        this.selectedAssetSubscriptionFormData.isValidForm = true;
      }
    }
    */
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  resetAssetFormData() {
    this.selectedAssetSubscriptionFormData.service.currentService = '';
    this.selectedAssetSubscriptionFormData.service.previousService = '';
    this.selectedAssetSubscriptionFormData.service.selectedService = '';
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.catLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.dealerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.previousSubscription.customerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.previousSubscription.dealerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.previousSubscription.catLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.customerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.dealerLevel = '';
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.catLevel = '';
    this.selectedAssetSubscriptionFormData.addOns.currentAddons = [];
    this.selectedAssetSubscriptionFormData.addOns.previousddons = [];
    this.selectedAssetSubscriptionFormData.addOns.selectedAddons = [];
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = false;
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractStartDate = '';
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractEndDate = '';
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected = false;
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractStartDate = '';
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractEndDate = '';
    this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData.prepaySelected = false;
    this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData.contractStartDate = '';
    this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData.contractEndDate = '';
    this.selectedAssetSubscriptionFormData.catBillingData.billingGroupId = '';
    this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily = '';
    this.selectedAssetSubscriptionFormData.productFamily.previousProductFamily = '';
    this.selectedAssetSubscriptionFormData.selectedCategory = '';
    this.selectedAssetSubscriptionFormData.reportingState = '';
    this.selectedAssetSubscriptionFormData.newPaymentDetails = '';
    this.selectedAssetSubscriptionFormData.siteInfo.siteId = '';
    this.selectedAssetSubscriptionFormData.siteInfo.siteName = '';
    this.selectedAssetSubscriptionFormData.cancelOptions = '';
    this.selectedAssetSubscriptionFormData.expandedService = '';
  }
  fetchDCNList(owner: any) {
    this.assetDrawerService.getDCNListInfo(owner).subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.openDCNListDrawer(owner, response);
        } else {
          owner.disabled = true;
          owner.checked = false;
          const messageText = dspConstants.Common.DCN_ERROR;
          this.showToastMessage(messageText, 'error', undefined, true);
          this.checkDatStatus(owner);
        }
      },
      error: (_err: any) => {
        const messageText = dspConstants.Common.DCN_ERROR;
        this.showToastMessage(messageText, 'error', undefined, true);
        this.checkDatStatus(owner);
      },
    });
  }
  openDCNListDrawer(owner: any, response: any) {
    this.dcnListDrawerRef = this.drawerService.openDrawer<DcnListComponent, CcDrawerConfig>(DcnListComponent, {
      data: {
        headerType: 'Select Dealer Customer',
        dcnList: response,
        owner: owner,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.dcnListDrawerRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.assetOwners.forEach((owner: any) => {
          if (owner.ownershipId === res.ownershipId) {
            owner.dealerCustNo = res.dealerCustNo;
            owner.dealerCustName = res.dealerCustName;
            owner.dealerCode = this.dealer.dealerCode;
          }
        });
      } else {
        if (this.dcnListDrawerRef.config.data.owner) {
          this.dcnListDrawerRef.config.data.owner.checked = false;
        }
      }

      this.checkDatStatus(owner);
    });
  }
  checkDealerDataLength(dealerCustNo: any, dealerCustName: any) {
    if (!isEmpty(dealerCustNo && dealerCustName)) {
      let dealerData = dealerCustNo + ' - ' + dealerCustName;
      if (dealerData.length >= 27) {
        dealerData = dealerData.slice(0, 26) + '...';
      }
      return 'Dealer Customer: ' + dealerData;
    } else {
      return;
    }
  }
  checkDealerIsNotEmpty(owner: any) {
    if (!isEmpty(owner.dealerCustNo && owner.dealerCustName)) return true;
    else return false;
  }
  checkCustomerDataLength(customerCode: any, customerName: any) {
    let customerData = customerCode + ' - ' + customerName;
    if (customerData.length >= 33) {
      customerData = customerData.slice(0, 32) + '...';
    }
    return customerData;
  }
  checkDatStatus(data: any) {
    if (data?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE') {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
      this.showToastMessage(messageText, 'error', linkDetail);
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    } else if (isEmpty(data?.dealerCustNo && data?.dealerCustName) && this.launchType !== 'cat-grade-control') {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else if (
      isEmpty(data?.dealerCustNo && data?.dealerCustName) &&
      this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck &&
      this.launchType === 'cat-grade-control'
    ) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    }
  }

  showToastMessage(message: string, status: string, linkDetail: any, autoCloseNeeded?: boolean) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
    };

    if (autoCloseNeeded) {
      config.duration = 3000;
    }
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  setAssetSubscriptionFormData(formData: AssetSubscriptionFormDataType) {
    this.assetService.setAssetSubscriptionFormData(formData);
  }

  ngOnDestroy() {
    this.syncEventsSubscription?.unsubscribe();
  }
}
