<div class="payment-select">
  <dsp-next-gen-ui-manage-asset-details
    [rowData]="rowData"
    [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
    [showDealerInfo]="true"
    [subsPricingEligible]="subsPricingEligible"
    [hideBillToParty]="true"
  ></dsp-next-gen-ui-manage-asset-details>
  <div *ngIf="loader">
    <div class="spin-align">
      <div class="spinner"></div>
    </div>
    <div class="spin-text">
      <span>One moment. We are calculating your<br />plan charges...</span>
    </div>
  </div>
  <div *ngIf="!loader" id="paymentPreview">
    <div *ngIf="rowData.reportingState === 'Subscribed'">
      <div class="section-line">
        <span class="section-heading">
          CURRENT
          <!--Padding is optional-->
        </span>
      </div>
      <br />
      <cc-accordion>
        <cc-expandable [expanded]="isExpandPlansInEditSubscription">
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ selectedAssetSubscriptionFormData.service.previousService }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row" *ngIf="hideBillToParty">
                <div class="Cell">
                  <span class="asset-details-title"> Bill to Party: </span
                  ><span class="asset-details-value">{{ currentPaymentDetails.billToParty || '-' }}</span>
                </div>
              </div>
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>
                <div class="CellHead" *ngIf="previousProductFamily">
                  <div class="headerCell">Product Family</div>
                </div>
                <div class="CellHead">
                  <div
                    class="headerCell"
                    [class.align-promotion-header]="!isPreviousProductVision === true"
                    [class.align-text]="!isPreviousProductVision === true"
                  >
                    Promotion Expiration Date
                  </div>
                </div>
                <div class="CellHead" *ngIf="isPreviousProductVision">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead" *ngIf="isPreviousProductVision">
                  <div class="headerCell">Contract End Date</div>
                </div>
                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>
              <div
                class="Row"
                *ngFor="let details of previousPaymentDetails?.current?.products.slice(0, 2) || []; let i = index"
              >
                <div class="Cell">
                  <div class="total-plan">{{ details.name }}</div>
                </div>
                <div class="Cell" *ngIf="previousProductFamily && i === 1">
                  <div class="total-plan1">{{ previousProductFamily }}</div>
                </div>
                <div
                  class="Cell"
                  [class.align-text]="!isPreviousProductVision === true"
                  [class.align-promotion-text]="!isPreviousProductVision === true && details.promotionEndDate"
                >
                  <div>{{ getConvertedDate(details.promotionEndDate) }}</div>
                </div>
                <div class="Cell" *ngIf="isPreviousProductVision">
                  <div class="total-plan">{{ getConvertedDate(details.contractStartDate) }}</div>
                </div>
                <div class="Cell" *ngIf="isPreviousProductVision">
                  <div class="total-plan">{{ getConvertedDate(details.contractEndDate) }}</div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-plan">
                    {{ displayAmount(details.amount) }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="previousPaymentDetails?.current?.products.length > 2" class="Cell">
              <a class="link total-plan-link" (click)="openDrawer(previousPaymentDetails, 'previousPayment')">
                See more
              </a>
            </div>
            <div class="Table" *ngIf="previousPaymentDetails?.current?.promotions">
              <div class="Row promotion-apply" *ngFor="let details of previousPaymentDetails?.current?.promotions">
                <div class="Cell">
                  <div class="total-plan">{{ details?.name }}</div>
                </div>
                <div
                  class="Cell"
                  [class.align-text]="!isPreviousProductVision === true"
                  [class.align-promotion-text]="!isPreviousProductVision === true && details.promotionEndDate"
                >
                  <div [ngClass]="previousProductFamily !== '' ? 'align-date' : 'total-plan'">
                    {{ getConvertedDate(details?.promotionEndDate) }}
                  </div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-plan">
                    {{ displayAmount(details.amount) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="Table" *ngIf="previousPaymentDetails?.current">
              <div class="Row cancel-plan-total">
                <div class="Cell">
                  <div class="est-total">Estimated Total</div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-price">
                    {{ displayAmount(previousPaymentDetails?.current?.monthlyRateAfterDiscount) }}
                    <span
                      *ngIf="
                        !previousPaymentDetails.prepay &&
                        displayAmount(previousPaymentDetails?.current?.monthlyRateAfterDiscount) !== '-'
                      "
                    >
                      (Monthly)
                    </span>
                    <span
                      *ngIf="
                        previousPaymentDetails.prepay &&
                        displayAmount(previousPaymentDetails?.current?.monthlyRateAfterDiscount) !== '-'
                      "
                    >
                      (Prepaid)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
    <div>
      <div class="section-line" *ngIf="rowData.reportingState === 'Subscribed'">
        <span class="section-heading heading-new">
          NEW
          <!--Padding is optional-->
        </span>
      </div>
      <br />
      <cc-accordion>
        <cc-expandable [expanded]="isExpandPlansInEditSubscription">
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ selectedAssetSubscriptionFormData.service.currentService }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row" *ngIf="hideBillToParty">
                <div class="Cell">
                  <span class="asset-details-title">Bill to Party: </span
                  ><span class="asset-details-value">{{ currentPaymentDetails?.billToParty || '-' }}</span>
                </div>
              </div>
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>
                <div class="CellHead" *ngIf="currentProductFamily">
                  <div class="headerCell">Product Family</div>
                </div>
                <div class="CellHead">
                  <div
                    class="headerCell"
                    [class.align-promotion-header]="!isPreviousProductVision === true"
                    [class.align-text]="!isPreviousProductVision === true"
                  >
                    Promotion Expiration Date
                  </div>
                </div>
                <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract End Date</div>
                </div>
                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>
              <div
                class="Row"
                *ngFor="let details of currentPaymentDetails?.new?.products.slice(0, 2) || []; let i = index"
              >
                <div class="Cell">
                  <div class="total-plan">{{ details.name }}</div>
                </div>
                <div class="Cell" *ngIf="currentProductFamily && i === 1">
                  <div class="total-plan1">{{ currentProductFamily }}</div>
                </div>

                <div
                  class="Cell"
                  [class.align-text]="!isPreviousProductVision === true"
                  [class.align-promotion-text]="!isPreviousProductVision === true && details.promotionEndDate"
                >
                  <div>{{ getConvertedDate(details.promotionEndDate) }}</div>
                </div>
                <div class="Cell" *ngIf="isCurrentProductVision">
                  <div class="total-plan">{{ getConvertedDate(details.contractStartDate) }}</div>
                </div>
                <div class="Cell" *ngIf="isCurrentProductVision">
                  <div class="total-plan">{{ getConvertedDate(details.contractEndDate) }}</div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-plan">
                    {{ displayAmount(details.amount) }}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="currentPaymentDetails?.new?.products.length > 2" class="Cell">
              <a class="link total-plan-link" (click)="openDrawer(currentPaymentDetails, 'currentPayment')">
                See more
              </a>
            </div>
            <div class="Table" *ngIf="currentPaymentDetails?.new?.promotions">
              <div class="Row promotion-apply" *ngFor="let details of currentPaymentDetails?.new?.promotions">
                <div class="Cell">
                  <div class="total-plan">{{ details?.name }}</div>
                </div>
                <div
                  class="Cell"
                  [class.align-text]="!isCurrentProductVision === true"
                  [class.align-promotion-text]="!isCurrentProductVision === true && details.promotionEndDate"
                >
                  <div div [ngClass]="currentProductFamily !== '' ? 'align-date' : 'total-plan'">
                    {{ getConvertedDate(details?.promotionEndDate) }}
                  </div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-plan">
                    {{ displayAmount(details.amount) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="Table" *ngIf="currentPaymentDetails?.new">
              <div class="Row cancel-plan-total">
                <div class="Cell">
                  <div class="est-total">Estimated Total</div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-price">
                    {{ displayAmount(currentPaymentDetails?.new?.monthlyRateAfterDiscount) }}
                    <span
                      *ngIf="
                        !currentPaymentDetails.prepay &&
                        displayAmount(currentPaymentDetails?.new?.monthlyRateAfterDiscount) !== '-'
                      "
                    >
                      (Monthly)
                    </span>
                    <span
                      *ngIf="
                        currentPaymentDetails.prepay &&
                        displayAmount(currentPaymentDetails?.new?.monthlyRateAfterDiscount) !== '-'
                      "
                    >
                      (Prepaid)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
    <div class="cc-grid payment-footer-container">
      <div
        class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 payment-preview-footer-message"
      >
        <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
        <div id="footer-message" class="colorChange" [innerHTML]="footerMsg || ''"></div>
      </div>
    </div>
  </div>
</div>
