import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, switchMap, of, catchError, throwError, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../store/state/dsp.state';
import { loadUserMenuRequestAction } from '../store/actions/user-info.actions';
import { SharedService } from '../shared.service';
import { isEmpty } from 'lodash-es';
// eslint-disable-next-line @typescript-eslint/no-var-requires

type customerSearchParam = {
  dealerCode: string;
  searchText: string;
};

@Injectable({
  providedIn: 'root',
})
export class DspCommonService {
  isEnableForwardSlashInPID = false;
  backToOnline: BehaviorSubject<boolean>;
  isCustomAccessEnabled: any;

  rootURL = 'https://dspservice-int.azurewebsites.net';

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  constructor(
    private http: HttpClient,
    private store: Store<DSPAppState>,
    private sharedService: SharedService
  ) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.backToOnline = new BehaviorSubject<boolean>(false);
    this.store.select('dsp').subscribe((dsp) => {
      this.isEnableForwardSlashInPID = dsp?.featureFlagInfo?.isEnableForwardSlashInPID
        ? dsp?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
    });
    this.isCustomAccessEnabled = new BehaviorSubject<boolean>(true);
  }
  getUser(): Observable<any> {
    return this.http.get(this.rootURL + '/user/getUserDetails').pipe(
      map((user) => user || []),
      catchError((error) => throwError(() => error))
    );
  }

  getDealer(body: any): Observable<any> {
    return this.http.post(this.rootURL + '/dealer/getDealers', body).pipe(
      map((dealer) => dealer || []),
      catchError((error) => throwError(() => error))
    );
  }
  getRoleMenus() {
    return this.http.get(this.rootURL + '/user/getSideNavMenu');
    // return this.http.get('../assets/connectioncheck.json');
  }
  updateUserPreference(menuId: number, payLoad: any) {
    return this.http.post(`${this.rootURL}/userpreferences/saveLandingMenuId/${menuId}`, payLoad);
  }
  saveUserPreference(payLoad: any) {
    return this.http.post(`${this.rootURL}/userpreferences/saveUserpreferences`, payLoad);
  }
  getCustomers(queryParam: customerSearchParam) {
    return this.http.get(this.rootURL + '/org/customerDtls', { params: queryParam });
  }

  checkCWSID(body: any) {
    return this.http.post(this.rootURL + '/site/getContactSearch', body);
  }

  initialUserAndDealerDetail(): Observable<any> {
    return this.getUser().pipe(
      switchMap((userResp) => {
        const loginDealer = 'TD00'; // need user type API result
        const isDealer = userResp['telUserType'] && userResp['telUserType'].indexOf('Dealer') == 0;
        const initDealer = isDealer ? userResp.dealerCode : loginDealer;
        //pushing the administering dealer code to GA data layer
        if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
          this.sharedService.updateDataLayer({ dealerCode: userResp.dealerCode, updateUserDetails: '', siteId: 'DSP' });
        }
        const body = {
          cwsid: userResp.cwsId,
          dealerCode: initDealer,
          dealerName: initDealer,
          offSet: 25,
          resultLimit: 25,
          sortField: 'dealerName',
          sortOrder: 'ASC',
          doNotCallApi: false,
        };
        if (!sessionStorage['dealer'] || userResp.dealerCode !== 'TD00') {
          return this.getDealer(body).pipe(
            map((dealerResp) => {
              sessionStorage['dealer'] = JSON.stringify(dealerResp.dealer[0]);
              this.store.dispatch(loadUserMenuRequestAction());
              return {
                ...userResp,
              };
            })
          );
        } else {
          this.store.dispatch(loadUserMenuRequestAction());
          return of(userResp);
        }
      })
    );
  }
  getMineStar(queryParams: any) {
    return this.http.get(this.rootURL + '/servicecatalog/getMinestarServiceDetails', {
      params: queryParams,
    });
  }
  validateSite(payLoad: any) {
    return this.http.post(this.rootURL + '/site/validateSite', payLoad);
  }

  deleteSite(siteId: any) {
    return this.http.delete(this.rootURL + '/site/deleteSite/' + siteId);
  }

  getCancelReason(payload: any) {
    return this.http.get(this.rootURL + '/site/subscription/cancelReason', {
      params: payload,
    });
  }
  getSiteDetails(payLoad: any) {
    return this.http.post(this.rootURL + '/org/plans', payLoad);
  }

  saveSiteDetail(payLoad: any) {
    const headers = new HttpHeaders();
    return this.http.post(this.rootURL + '/site/saveSite', payLoad, {
      headers: headers,
      responseType: 'text',
    });
  }

  createSiteSubscription(payLoad: any) {
    const headers = new HttpHeaders();
    return this.http.post(this.rootURL + '/site/createSiteSubscription', payLoad, {
      headers: headers,
      responseType: 'text',
    });
  }

  getBillDirectCustomers(payLoad: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/billToDirectCustomers?dealerCode=' + payLoad.dealerCode, {});
  }

  getBillingAccounts() {
    return this.http.get(this.rootURL + '/user/getMyBillingAccounts');
  }

  getPaymentPreview(payLoad: any) {
    return this.http.post(this.rootURL + '/zuoraPreview/getPaymentPreview', payLoad);
  }

  getDeviceSubsciptionCatalogue(queryParams: any) {
    return this.http.get(this.rootURL + '/subscribe/devicesubscriptioncatalogmap', {
      params: queryParams,
    });
  }

  getDeviceFeaturesInfomation() {
    return this.http.get(this.rootURL + '/assetdata/getDeviceFeaturesInformation');
  }

  getUserActiveFeatures(queryParams: any) {
    return this.http.get(this.rootURL + '/user/features/active', {
      params: queryParams,
    });
  }

  getDeviceStatusForCustomAccess(queryParams: any) {
    return this.http.get(this.rootURL + '/user/isDeviceStatusForCustomAccess', {
      params: queryParams,
    });
  }

  getEquipmentDataUrl() {
    return this.http.get(this.rootURL + '/equipmentdata/getEDUrl', { responseType: 'text' });
  }

  getPSRUrl() {
    return this.http.get(this.rootURL + '/remoteflash/getPSRUrl');
  }

  getDSPConfig(queryParams: any) {
    return this.http.get(this.rootURL + '/admin/getDSPConfig', {
      params: queryParams,
    });
  }

  getFeatureSupportMsg() {
    return this.http.get(this.rootURL + '/subscribe/featuresupportmsg');
  }

  getLanguageTranslations(payload: any) {
    return this.http.post(this.rootURL + '/translations/getLanguageTranslations', payload);
  }

  getProductFamily() {
    return this.http.get(this.rootURL + '/equipments/getProductFamily');
  }
  getCertifiedCountries() {
    return this.http.get(this.rootURL + '/unsuspend/certifiedCountries');
  }

  getFeatureDealerConfiguration() {
    return this.http.get(this.rootURL + '/user/dealer/feature/configure', { params: { feature: 'eRouteSubs' } });
  }

  getDeviceStatus(queryParams: any) {
    return this.isEnableForwardSlashInPID
      ? this.http.get(this.rootURL + '/device/deviceStatus/v2', { params: queryParams })
      : this.http.get(this.rootURL + '/device/deviceStatus/' + queryParams);
  }

  getLastMessage(queryParams: any) {
    return this.isEnableForwardSlashInPID
      ? this.http.get(this.rootURL + '/device/lastSMHMessageDetails/v2', { params: queryParams })
      : this.http.get(this.rootURL + '/device/lastSMHMessageDetails/' + queryParams);
  }

  requestSmhMessage(queryParams: any) {
    return this.isEnableForwardSlashInPID
      ? this.http.get(this.rootURL + '/device/requestSMHMessage/v2', { params: queryParams })
      : this.http.get(this.rootURL + '/device/requestSMHMessage/' + queryParams);
  }

  getInterimUserStatus(params: any) {
    return this.http.get(this.rootURL + '/user/getInterimUserStatus', { params });
  }
  getEula() {
    return this.http.get(this.rootURL + '/user/eula');
  }

  getUserInfo() {
    return this.http.get(this.rootURL + '/user/');
  }

  getFeatureFlagInfo() {
    return this.http.get(this.rootURL + '/featureflag/featureflagnames/?isActive=true');
  }
  acceptEula(payload: any) {
    const reqBody = { appName: 'Dealer Services Portal' };
    return this.http.post(this.rootURL + '/user/acceptEula', payload);
  }

  getMultiProductFeatureFlagInfo(dealerCode: any) {
    return this.http.get(this.rootURL + `/featureflag/multiproduct/access/${dealerCode}`);
  }

  removeDateFiltersFromSessionStorage() {
    const sessionKeys = Object.keys(sessionStorage);
    const dateFilterKeys = sessionKeys?.filter((key) => key.endsWith('dateFilters'));
    dateFilterKeys?.forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }

  getMonthString(dateString: any) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const date = new Date(dateString);
    const monthIndex = date.getMonth(); // 0 for January, 1 for February, etc.

    return months[monthIndex];
  }
}
