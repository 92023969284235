import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-quick-link-card',
  templateUrl: './quick-link-card.component.html',
  styleUrls: ['./quick-link-card.component.scss'],
})
export class QuickLinkCardComponent {
  @Input() quickLink: any;
  emptyTemplate!: TemplateRef<any>;

  parseTitleName(title: any) {
    title = title ? title.trim() : title;
    if (title && title.length > 30) {
      return `${title.substring(0, 30)}...`;
    } else {
      return title;
    }
  }

  openQuickLink(item: any) {
    const check = item.url.split('://');
    if (check.length == 2) window.open(item.url);
    else window.open('http://' + item.url);
  }
}
