import { Injectable } from '@angular/core';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../shared/dspConstants';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../store/state/dsp.state';
import { Subject } from 'rxjs';
import { DATA_LAYER_OBJECT } from '../google-analyatics.interface';
import { SharedService } from '../shared.service';
import { fetchLoggedInDealerDetails } from '../shared/shared';
@Injectable({
  providedIn: 'root',
})
export class TablesettingService {
  rootURL = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  dspStoreData!: any;
  isSaveTableSettings = '';
  private assetSubject = new Subject<any>();
  assetObservable = this.assetSubject.asObservable();

  private sitesSubject = new Subject<any>();
  sitesObservable = this.sitesSubject.asObservable();

  private userAdminSubject = new Subject<any>();
  userAdminObservable = this.userAdminSubject.asObservable();

  private accountSubject = new Subject<any>();
  accountObservable = this.accountSubject.asObservable();

  private billingSubject = new Subject<any>();
  billingObservable = this.billingSubject.asObservable();

  private needsReviewSubject = new Subject<any>();
  needsReviewObservable = this.needsReviewSubject.asObservable();
  dealer: any;

  constructor(
    private messageBar: MessageBar,
    private http: HttpClient,
    private store: Store<DSPAppState>,
    private sharedService: SharedService
  ) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isSaveTableSettings = this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        : false;
    });
    this.dealer = fetchLoggedInDealerDetails();
  }

  callComponentMethod(module: any) {
    switch (module) {
      case 'assets':
        this.assetSubject.next(module);
        break;
      case 'accounts':
        this.accountSubject.next(module);
        break;
      case 'sites':
        this.sitesSubject.next(module);
        break;
      case 'billing':
        this.billingSubject.next(module);
        break;
      case 'users':
        this.userAdminSubject.next(module);
        break;
      case 'needsReview':
        this.needsReviewSubject.next(module);
        break;
      default:
        break;
    }
  }

  getTableSettings(module: any, columnConfigData: any) {
    if (this.isSaveTableSettings) {
      let tableData: any;
      this.http.get(this.rootURL + `/userpreferences/tablesettings/${module}`).subscribe({
        next: (result: any) => {
          tableData = JSON.parse(result.filterValues);
          columnConfigData.pagination.defaultPageSize = JSON.parse(result.recordLimit);
          this.mapData(tableData, columnConfigData, module);
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  mapData(tableData: any, columnConfigData: any, module: any) {
    tableData.map((columnData: any) => {
      columnConfigData.columnConfig.map((data: any) => {
        if (columnData.columnName === data.name) {
          data.isVisible = columnData.isVisible;
          data.isFrozen = columnData.isFrozen;
          data.sortOrder = columnData.sortOrder;
        }
      });
    });
    columnConfigData.columnConfig.sort((a: any, b: any) => {
      return a.sortOrder - b.sortOrder;
    });
    this.callComponentMethod(module);
    return columnConfigData;
  }
  updateTableSettings(module: any, updatedTableConfig: any, paginationLimit?: any) {
    let selectedColumns: any = '';
    if (this.isSaveTableSettings) {
      let payload = { module: module, filterValues: '', recordLimit: paginationLimit };
      let filterValues: any = [];
      updatedTableConfig.map((column: any) => {
        let newColumn: any = {};
        newColumn.sortOrder = column.priority;
        newColumn.isFrozen = column.isFrozen;
        newColumn.isVisible = column.isVisible;
        newColumn.columnName = column.name;
        filterValues.push(newColumn);
        if (newColumn.isVisible) {
          selectedColumns = selectedColumns + ' ' + column.title + ' | ';
        }
      });
      selectedColumns = selectedColumns.substring(0, selectedColumns.length - 2);
      payload.filterValues = JSON.stringify(filterValues);

      this.http.post(this.rootURL + `/userpreferences/updatetablesettings/${module}`, payload).subscribe({
        next: (result: any) => {
          if (module === 'needsReview' || module === 'assets') {
            this.setDataLayer('Table Settings Updated', selectedColumns, 'success', '', module);
          }
        },
        error: (err: any) => {
          if (module === 'needsReview' || module === 'assets') {
            this.setDataLayer('Table Settings Updated', selectedColumns, 'failed', this.systemError, module);
          }
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  setDataLayer(formName: any, formContent: any, status: any, errorReason: any, module: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: formName,
      formContent: formContent,
      formContent2: '',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: errorReason,
      formLocation: module === 'needsReview' ? 'Needs Review Page' : 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
}
