<div
  class="ql-container"
  id="listItem-{{ quickLink.id }}"
  [tooltipPosition]="'top'"
  cc-tooltip
  [template]= "quickLink.title.length > 30 ? template : emptyTemplate"
> 
  <div class="ql-icon-wrapper">
    <cc-icon fontSet="icomoon" class="ql-icon">quick-link</cc-icon>
  </div>
  <span class="ql-card" 
    ><a (click)="openQuickLink(quickLink)">{{ parseTitleName(quickLink.title) }}</a></span
  >
  <ng-template #template>
    <span>{{ quickLink.title }}</span>
  </ng-template>
</div>