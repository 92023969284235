import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import {
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
  CcModalRef,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetService } from '../assets/services/asset.service';
import * as dspConstants from '../../shared/dspConstants';
import { HomeService } from './services/home.service';
import { getAdvanceSearchPayload } from './home.interface';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
import { AssetDrawerComponent } from '../assets/asset-drawer/asset-drawer.component';
import { isEmpty } from 'lodash-es';
import { DspCommonService } from '../../services/dsp-common.service';
import {
  getBillDirectCustomers,
  getCertifiedCountries,
  getEquipmentDataUrl,
  getFeatureSupportMapping,
  getProductFamily,
  getPSRUrl,
  getSubscriptionServiceMapping,
} from '../../store/actions/dspcommon.action';
import { AssetDrawerService } from '../../services/asset-drawer.service';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { getFeaturesToggleMapping } from '../../store/actions/dspcommon.action';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  dspStoreData!: any;
  rowData: any;
  assetDrawerRef!: any;
  featureSupportMapping: any;
  userActiveFeatures: any;
  hideAdvanceSearch = false;
  isTableLoading = false;
  toggleSearch = false;
  advanceSearchModalRef!: CcModalRef<any, any>;
  @ViewChild('homeSearchBox') homeSearchBox!: ElementRef;
  @ViewChild('advance_search_template') advanceSearchModal: any;
  formGroupAssetSearch!: FormGroup;
  searchFormGroup!: FormGroup;
  userName: any;
  fullSearchTxt = '';
  txt = '';
  noResultInAdvanceSearch = '';
  enableSearch = false;
  isDataLoading = false;
  searchDataLoaded = false;
  mobileHeader = 'Search User';
  searchHelpText = '';
  searchValue: FormControl = new FormControl('', {
    validators: [],
    updateOn: 'change', //blur
  });
  loggedInUser!: any;
  prevSearchMain: any;
  searchString: any = '';
  assetSearchText = '';
  make = '';
  makeSearchText = '';
  serialNumber = '';
  prevSearchMake = '';
  makeLoader = false;
  isMakeNotFound: any = false;
  makecodes: any;
  isListItemSelected = false;
  noDeviceSearch: any;
  dealer: any;
  dealerCode: any;
  showTable = false;
  showRecentlyViewedAssets: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  advanceSearchPayload: getAdvanceSearchPayload = {
    loggedInDealerCode: '',
    make: '',
    serialNumber: '',
  };
  emitSearchEvent: Subject<any> = new Subject();
  featuresToggleMap: any;
  deviceFeaturesInfo: any;
  userDealerCode: any;
  isHomePageQuickLinks: any;
  constructor(
    private fb: FormBuilder,
    private store: Store<DSPAppState>,
    private messageBar: MessageBar,
    private assetService: AssetService,
    public modal: CcModal,
    private homeService: HomeService,
    private drawerService: DrawerService,
    private dspCommonService: DspCommonService,
    private _changeDetectorRef: ChangeDetectorRef,
    private assetDrawerService: AssetDrawerService,
    private loaderService: LoaderService,
    private needsReviewService: NeedsReviewService,
    private sharedService: SharedService
  ) {}
  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.dealerCode = this.dealer.dealerCode;
    this.formGroupAssetSearch = this.fb.group({
      assetGlobalSearch: [''],
      nodeviceSearchRadio: [''],
      make: [this.make, [Validators.required, Validators.minLength(3), Validators.nullValidator]],
      serialNum: [this.serialNumber, [Validators.required, Validators.minLength(3), Validators.nullValidator]],
    });
    this.store.select('user').subscribe((user) => {
      this.userDealerCode = user.user?.dealerCode || '';
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.loggedInUser = (dsp && dsp?.userDetails) || {};
      this.featureSupportMapping = dsp.featureSupportMapping;
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.showRecentlyViewedAssets = this.dspStoreData?.featureFlagInfo?.showRecentlyViewedAssets
        ? this.dspStoreData?.featureFlagInfo?.showRecentlyViewedAssets
        : false;
      this.isHomePageQuickLinks = this.dspStoreData?.featureFlagInfo?.isHomePageQuickLinks
        ? this.dspStoreData?.featureFlagInfo?.isHomePageQuickLinks
        : false;
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
    });

    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP Home Page',
      });
    }

    this.getFeatureSupportMsg();
    this.getEDandPSRUrl();
    this.getSubscriptionServiceMapping();
    this.getFeaturesToggleMapping();
    this.getBillDirectCustomers();
    this.getProductFamily();
    this.getCertifiedCountries();
    this.needsReviewService.enableNeedsReviewDismissOption.next(false);
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.value.length || 0;
      this.homeSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }
  displayErrorMsg() {
    const errMsg = 'You have entered less than 3 characters. Please update and search again.';
    this.showToastMessage(errMsg, 'error');
  }
  onSearchChange(event: any) {
    if (event.value.trim().length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value.length > 2 && this.prevSearchMain != this.searchValue?.value) {
      this.searchValue.setValue(event.value);
    } else if (event.value === '') {
      this.showTable = false;
      this.searchValue.setValue('');
      this.searchString = '';
      this.fullSearchTxt = '';
    }

    this.prevSearchMain = this.searchValue?.value;
  }
  getSearchDetails(details: boolean) {
    if (!this.isTableLoading) {
      this.fullSearchTxt = this.searchValue.value;
      this.toggleSearch = !this.toggleSearch;
      this.noResultInAdvanceSearch = '';
      this.showTable = true;
    }
  }
  greetingFunction() {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      return 'Good Morning';
    } else if (hour >= 12 && hour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      appOverlayClass: 'home-search-overlay',
      hostSelectorId: '',
      duration: 1000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  openAdvancedSearch() {
    this.assetDrawerService.getapplicableList = '';
    this.advanceSearchModalRef = this.modal.openModal(this.advanceSearchModal, {
      type: 'semi-modal',
      isAutoHeightModalContent: true,
      disableBackdropClick: true,
      width: '570px',
      height: '569px',
    });
  }
  isSkeletonLoading(event: any) {
    this.isTableLoading = event;
    this.hideAdvanceSearch = event;
  }
  resetTextBoxes() {
    this.make = this.prevSearchMake = '';
    this.serialNumber = '';
    this.formGroupAssetSearch.controls['make'].reset();
    this.formGroupAssetSearch.controls['make'].setErrors(null);
    this.formGroupAssetSearch.controls['serialNum'].reset();
    this.formGroupAssetSearch.controls['serialNum'].setErrors(null);
  }
  makeValueChange(event: any) {
    this.make = event?.value;
    this.formGroupAssetSearch.patchValue({ make: event.value });
    if (this.prevSearchMake != this.make) {
      if (event.value && event.value.length > 2 && event.value != this.makeSearchText) {
        this.isMakeNotFound = false;
        this.makeSearchText = event.value;
        this.enableOverlayBox();
        this.makeLoader = true;
        this.makecodes = [];
        const queryParams = { eq_makeCode: this.make, eq_makeName: this.make };
        this.assetService.getMakeCode(queryParams).subscribe({
          next: (result: any) => {
            this.makecodes = result;
            if (result.length == 0) {
              this.isMakeNotFound = true;
              this.formGroupAssetSearch.controls['make'].setErrors({ 'make-not-found': true });
              this.resetOverlayBox();
            }
            this.makeLoader = false;
          },
          error: (err: any) => {
            this.makeLoader = false;
            this.resetOverlayBox();
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else if (event.value == '') {
        this.isListItemSelected = false;
      }
      if (event.value === this.makeSearchText && this.isMakeNotFound) {
        this.formGroupAssetSearch.controls['make'].setErrors({ 'make-not-found': true });
        setTimeout(() => {
          this.resetOverlayBox();
        }, 500);
      }
    }
  }
  listItemClick(index: number) {
    this.isListItemSelected = true;
    this.make = this.makecodes[index]['makeCode'] + '/' + this.makecodes[index]['makeName'];
    this.prevSearchMake = this.make;
    this.formGroupAssetSearch.patchValue({ make: this.make || '' });
    this.formGroupAssetSearch.value.make = this.make;
    this.formGroupAssetSearch.controls['make'].setValue(this.make);
  }
  serialNumChange(event: any) {
    this.serialNumber = event.value;
    this.formGroupAssetSearch.patchValue({ serialNum: event.value });
  }
  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }

  enableOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.display = 'block';
    }
  }

  handleAdvanceDeviceSearch() {
    this.advanceSearchPayload = {
      loggedInDealerCode: this.dealerCode,
      make: this.make.split('/')[0],
      serialNumber: this.serialNumber.trim(),
    };
    this.homeService.advancedAssetSearch(this.advanceSearchPayload).subscribe({
      next: (result: any) => {
        this.loaderService.show();
        this.noResultInAdvanceSearch = 'false';
        if (result?.serialNumber) {
          this.rowData = result;
          this.checkValidOwnership();
        } else {
          this.noResultInAdvanceSearch = 'true';
          this.homeService.setDataLayer('failed', 'No Results Found', 'Home Page - Advanced Search');
          this.loaderService.hide();
        }
      },
      error: (err: any) => {
        this.noResultInAdvanceSearch = 'true';
        this.loaderService.hide();
        if (err.status === 500) {
          this.homeService.setDataLayer('failed', this.systemError, 'Home Page - Advanced Search');
          this.showToastMessage(this.systemError, 'error');
        }
      },
    });
    this.closeModel();
  }

  getSupportedServices() {
    const supportedServicesPayload = { guiID: this.rowData?.appId || '' };
    this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
      next: (result: any) => {
        let data: any[] = [];
        if (result?.length > 0) {
          this.rowData.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
          result?.forEach((val: any) => {
            if (this.featureSupportMapping && this.featureSupportMapping[val]) {
              data = data.concat(this.featureSupportMapping[val]);
            }
          });
        }
        this.rowData.getSupportedServicesList = data;
        this.homeService.validateWiFiConfiguration(this.rowData);
        this.openAssetDrawer();
      },
      error: (err: any) => {
        this.homeService.validateWiFiConfiguration(this.rowData);
        this.openAssetDrawer();
      },
    });
  }

  onCancel() {
    this.closeModel();
  }
  closeModel() {
    this.advanceSearchModalRef?.close();
    this.resetTextBoxes();
  }
  openAssetDrawer() {
    this.loaderService.hide();
    this.saveRecentlyViewAssets(this.rowData);
    this.rowData.source = 'Home Page - Advanced Search';
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: this.rowData,
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }
  checkValidOwnership() {
    let payload = {};
    if (this.rowData?.assetId) {
      payload = {
        loggedInDealerCode: this.dealerCode,
        assetIds: [this.rowData?.assetId],
      };
    } else {
      payload = {
        loggedInDealerCode: this.dealerCode,
        assetIds: [this.rowData?.assetId],
        make: this.rowData.make,
        serialNumber: this.rowData.serialNumber,
      };
    }

    this.homeService.checkValidOwnership(payload).subscribe({
      next: (res: any) => {
        const validOwnershipRes = this.rowData?.assetId ? res[this.rowData?.assetId] : res['0'];
        this.rowData.isHomeSearch = true;
        this.rowData.isMaskRequired = !validOwnershipRes.enableManagePlan;
        this.rowData.enableManagePlan = validOwnershipRes.enableManagePlan ? true : false;
        this.rowData.hasValidOwnership = validOwnershipRes.hasValidOwnership ? true : false;
        this.getSupportedServices();
      },
      error: (err: any) => {
        this.getSupportedServices();
      },
    });
  }
  getFeatureSupportMsg() {
    if (isEmpty(this.dspStoreData?.featureSupportMapping)) {
      this.dspCommonService.getFeatureSupportMsg().subscribe({
        next: (result: any) => {
          this.store.dispatch(getFeatureSupportMapping(result));
          this.featureSupportMapping = result;
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  getEDandPSRUrl() {
    if (isEmpty(this.dspStoreData?.edUrl)) {
      this.dspCommonService.getEquipmentDataUrl().subscribe({
        next: (response: any) => {
          const result = {
            data: response,
          };
          this.store.dispatch(getEquipmentDataUrl(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
    if (isEmpty(this.dspStoreData?.psrUrl)) {
      this.dspCommonService.getPSRUrl().subscribe({
        next: (response: any) => {
          this.store.dispatch(getPSRUrl(response));
        },
        error: (_err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  getSubscriptionServiceMapping() {
    if (isEmpty(this.dspStoreData?.serviceMapping)) {
      this.assetService.getSubscriptionServiceMapping().subscribe({
        next: (result: any) => {
          this.store.dispatch(getSubscriptionServiceMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  saveRecentlyViewAssets(rowData: any) {
    let payload = {
      make: rowData?.make,
      serialNumber: rowData?.serialNumber,
      loggedInDealerCode: this.dealerCode,
      assetId: rowData?.assetId,
    };
    this.homeService.saveRecentlyViewAssets(payload).subscribe({
      next: (response: any) => {},
      error: (err: any) => {},
    });
  }
  getFeaturesToggleMapping() {
    if (isEmpty(this.dspStoreData?.featuresToggleMapping)) {
      this.assetService.getFeaturesToggleMapping().subscribe({
        next: (result: any) => {
          this.store.dispatch(getFeaturesToggleMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  getBillDirectCustomers() {
    if (isEmpty(this.dspStoreData?.billDirectCustomers)) {
      this.assetService.getBillDirectCustomers({}).subscribe({
        next: (result: any) => {
          this.store.dispatch(getBillDirectCustomers(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  getProductFamily() {
    if (isEmpty(this.dspStoreData?.productFamily)) {
      this.dspCommonService.getProductFamily().subscribe({
        next: (result: any) => {
          this.store.dispatch(getProductFamily(result));
        },
        error: (err: any) => {},
      });
    }
  }

  getCertifiedCountries() {
    if (isEmpty(this.dspStoreData?.certifiedCountries)) {
      this.dspCommonService.getCertifiedCountries().subscribe({
        next: (result: any) => {
          this.store.dispatch(getCertifiedCountries(result));
        },
        error: (err: any) => {},
      });
    }
  }
}
