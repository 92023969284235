<div class="configure-access-drawer cc-drawer">
  <div class="cc-drawer__header">
    <svg
      class="cc-drawer__leadingIcon"
      (click)="closeDrawer(false)"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 5.89258H1"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.3961L1 5.89139L6 1.38672"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="cc-drawer__ellipsis filters-title"> Configure Access </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <div class="drawer-title-cls">Dealer Services Portal (Web & Mobile)</div>
    <div class="roleSection">
      <h3>SELECT ACCESS:</h3>
      <div>
        <div class="chipContainer">
          <span
            class="chipCls"
            *ngFor="let option of roleArray; let i = index"
            [cc-chip]="option.roleName"
            type="check"
            [radio]="true"
            [state]="radioStates[i]"
            [ngClass]="radioStates[i] === 'active' ? 'chip-field' : ''"
            (selected)="changeSelected($event, option.roleName, option.id, i)"
          ></span>
        </div>
      </div>
    </div>
    <div *ngIf="roleValue !== 'None'">
      <div class="roleDisplaySection">
        <ng-container *ngFor="let features of featureData; let isLast = last">
          <div
            [ngClass]="features.children && features.children.length === 0 ? (isLast ? '' : 'horizontalAlignCls') : ''"
          >
            <cc-checkbox
              class="checkbox-checked parentHighlightCls"
              *ngIf="!features.editable"
              [checked]="selectedFeature[features.label]"
              [indeterminate]="isAllSelected(features)"
              [disabled]="features.label === 'Subscription Management' || !features.editable"
              >{{ features.displayLabel }}</cc-checkbox
            >
          </div>

          <div
            class="roleSpaceCls"
            [ngClass]="
              features.children && features.children.length === 0 && roleValue?.toUpperCase() !== 'CUSTOM ACCESS'
                ? 'leftAlignCls'
                : ''
            "
          >
            <div
              [ngClass]="
                features.children && features.children.length === 0 && roleValue?.toUpperCase() === 'CUSTOM ACCESS'
                  ? !isLast
                    ? 'customAccessCls'
                    : 'removeSeggeregation'
                  : ''
              "
            >
              <label [ngClass]="roleValue?.toUpperCase() === 'CUSTOM ACCESS' ? 'customParentCls' : ''">
                <cc-checkbox
                  class="checkbox-checked"
                  [(ngModel)]="selectedFeature[features.label]"
                  [checked]="selectedFeature[features.label]"
                  *ngIf="features.editable"
                  [disabled]="
                    (roleValue?.toUpperCase() === 'CUSTOM ACCESS' &&
                      features.label === 'Subscription Management' &&
                      disableSubscription) ||
                    (features.editable ? false : features.type === 'single' && !selectedFeature[features.label])
                  "
                  (toggleChange)="toggle($event, features)"
                >
                  <span class="dummy">{{ features.displayLabel }}</span></cc-checkbox
                >
              </label>
            </div>
            <div
              [ngClass]="
                features.children && features.children.length
                  ? roleValue?.toUpperCase() !== 'CUSTOM ACCESS'
                    ? 'roleDisplayBlockForOtherAccess'
                    : 'roleDisplayBlock'
                  : 'horizontalRowCls'
              "
              *ngIf="features.children && features.children.length"
            >
              <ng-container *ngFor="let subfeatures of features.children">
                <span
                  *ngIf="
                    !subfeatures ||
                    (features.type === 'multi' &&
                      subfeatures.type === 'multi' &&
                      roleValue?.toUpperCase() !== 'CUSTOM ACCESS')
                  "
                >
                  <cc-icon *ngIf="selectedFeature[subfeatures.label]" class="tick-icon">tick</cc-icon>
                  <cc-icon *ngIf="!selectedFeature[subfeatures.label]" class="cross-icon">cross</cc-icon>
                  <span [ngClass]="!selectedFeature[subfeatures.label] ? 'iconStyle1' : ''">
                    {{ subfeatures.displayLabel }}
                  </span>
                </span>

                <cc-checkbox
                  class="checkbox-checked"
                  [checked]="selectedFeature[subfeatures.label]"
                  [(ngModel)]="selectedFeature[subfeatures.label]"
                  *ngIf="
                    !subfeatures ||
                    (features.type === 'multi' &&
                      subfeatures.type === 'multi' &&
                      roleValue?.toUpperCase() === 'CUSTOM ACCESS')
                  "
                  [disabled]="
                    features.editable
                      ? false
                      : (!selectedFeature[subfeatures.label] && features.active) || !features.editable
                  "
                  (toggleChange)="subfeaturesValueChange($event, subfeatures, features)"
                >
                  <span> {{ subfeatures.displayLabel }} </span></cc-checkbox
                >

                <span
                  *ngIf="
                    roleValue?.toUpperCase() !== 'CUSTOM ACCESS' &&
                    subfeatures &&
                    features.type === 'single' &&
                    subfeatures.type === 'multi'
                  "
                >
                  <cc-icon *ngIf="subfeatures.active" class="tick-icon">tick</cc-icon>
                  <cc-icon *ngIf="!subfeatures.active" class="cross-icon">cross</cc-icon>
                  <span [ngClass]="!subfeatures.active ? 'iconStyle1' : 'iconStyle2'">
                    {{ subfeatures.displayLabel }}</span
                  >
                </span>
              </ng-container>
              <cc-radio-group
                (valueChange)="toggleRadio({ label: $event.value })"
                class="radio-group"
                *ngIf="roleValue?.toUpperCase() === 'CUSTOM ACCESS' && features.type === 'single'"
              >
                <cc-radio-button
                  *ngFor="let subfeatures of features.children"
                  [checked]="selectedFeature[subfeatures.label]"
                  [value]="subfeatures.label"
                  [disabled]="!subfeatures.editable"
                >
                  {{ subfeatures.displayLabel }}
                </cc-radio-button>
              </cc-radio-group>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="notesSection">
        <div class="info-icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 0C3.581 0 0 3.582 0 8C0 12.418 3.581 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0Z"
              fill="#1991EB"
            ></path>
            <path
              d="M8 5C8.552 5 9 4.552 9 4C9 3.448 8.552 3 8 3C7.448 3 7 3.448 7 4C7 4.552 7.448 5 8 5Z"
              fill="white"
            ></path>
            <rect x="7" y="6" width="2" height="6" rx="1" fill="white"></rect>
          </svg>
        </div>
        <div class="notesTxt">Manage Assets will be supported for the user both in DSP Web and Mobile</div>
      </div>
    </div>
    <div *ngIf="roleValue === 'None'">
      <div class="no-results-found">
        <img src="../../../assets/images/no_results_found.png" alt="no results found" />
        <span>User does not have access to Dealer Services Portal (Web & Mobile)</span>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button id="cc-drawer-cancelBtn" cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer(false)">
      Cancel
    </button>
    <button
      id="cc-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="enableApplyButton()"
      (click)="onApplyChanges()"
    >
      Apply
    </button>
  </div>
</div>
