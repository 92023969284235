import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'dsp-next-gen-ui-license-eligiblity',
  templateUrl: './license-eligiblity.component.html',
  styleUrls: ['./license-eligiblity.component.scss'],
})
export class LicenseEligiblityComponent {
  licenseEligiblityForm!: FormGroup;
  licenseData: any = [];
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;
  licenseEligiblityStatus = '';
  isNoLicenseUpgradable = true;
  dspStoreData: any;
  isLicenseMappingEnabled: any;
  selectedLicenseType: any;
  isBackBtnClciked = false;
  selectedLicenseBack: any;
  selectedLicensePartNumber: any;
  filteredPreviewData: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private assetDrawerService: AssetDrawerService,
    private store: Store<DSPAppState>,
    public assetService: AssetService
  ) {}

  ngOnInit() {
    this.rowData = this.value ? this.value : '';
    this.config = this.columnWithHeader ? this.columnWithHeader : '';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isLicenseMappingEnabled = this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        : false;
    });
  }

  ngAfterViewInit() {
    this.assetDrawerService.isBackBtnClicked.subscribe((data: any) => {
      this.isBackBtnClciked = data;
    });

    this.licenseEligiblityForm = this.formBuilder.group({
      licenseEligiblity: [''],
    });

    this.assetDrawerService.filteredPaymentPreviewData.subscribe((data: any) => {
      if (data) {
        this.filteredPreviewData = data;
      }
    });

    if (this.isLicenseMappingEnabled) {
      this.assetDrawerService.licenseEligiblityInfo?.subscribe((data: any) => {
        const eligiblityMap = data.reduce((acc: any, elm: any) => {
          const serialNumber = Object.keys(elm)[0];
          elm[serialNumber].forEach((element: any) => {
            const index = element?.current?.index;
            const mapKey = `${serialNumber}-${index}`;
            if (!acc[mapKey]) {
              acc[mapKey] = [];
            }
            acc[mapKey].push(element);
          });
          return acc;
        }, {});
        const mapKey = `${this.rowData.serialNumber}-${this.rowData.index}`;
        const eligiblityItems = eligiblityMap[mapKey] || [];
        if (eligiblityItems) {
          if (eligiblityItems.length > 1) {
            const sortedArr = eligiblityItems.sort((a: any, b: any) => {
              if (a.next && b.next) {
                return a.next.displayOrder - b.next.displayOrder;
              } else if (a.next) {
                return -1;
              } else if (b.next) {
                return 0;
              } else {
                return 0;
              }
            });
            sortedArr.map((item: any) => {
              const itemObj = {
                label: item.next.description,
                value: item.next.licensePartNumber,
                disabled: !item.next.available,
              };
              if (item.next && item.next.description !== null && !item.next.dealerExclusion) {
                if (this.rowData.licenseStatus === 'PENDING') {
                  this.licenseEligiblityStatus = '-';
                  this.isNoLicenseUpgradable = true;
                } else {
                  this.licenseData.push(itemObj);
                  if (this.isBackBtnClciked) {
                    this.assetDrawerService.selectedLicenseEligiblityStatus.subscribe((license: any) => {
                      const selectedItems = license;
                      selectedItems.map((item: any) => {
                        if (item.serialNumber === this.rowData.serialNumber && item.index === this.rowData.index) {
                          this.licenseEligiblityForm.controls['licenseEligiblity'].setValue(
                            item.nextUpgradableLicenseDesc
                          );
                        }
                      });
                    });
                  }
                  this.isNoLicenseUpgradable = false;
                }
              } else {
                if (
                  (item.next.description === null || item.next.description === '' || item.next.description) &&
                  item.next.dealerExclusion
                ) {
                  this.licenseEligiblityStatus = 'No Eligible Upgrade';
                  this.isNoLicenseUpgradable = true;
                } else {
                  if (
                    (item.next.description === null || item.next.description === '') &&
                    this.rowData.currentLicenseDesc &&
                    this.rowData.licenseStatus !== 'PENDING'
                  ) {
                    this.licenseEligiblityStatus = 'No Upgrade Available';
                    this.isNoLicenseUpgradable = true;
                  } else if (
                    (this.rowData.currentLicenseDesc === null || this.rowData.currentLicenseDesc === '') &&
                    this.rowData.licenseStatus !== 'PENDING'
                  ) {
                    this.licenseEligiblityStatus = 'Not Upgradable';
                    this.isNoLicenseUpgradable = true;
                  }
                  if (this.rowData.licenseStatus === 'PENDING') {
                    this.licenseEligiblityStatus = '-';
                    this.isNoLicenseUpgradable = true;
                  }
                }
              }
            });
          } else {
            eligiblityItems?.forEach((item: any) => {
              if (item.next && item.next.description !== null) {
                this.licenseEligiblityStatus = item.next.description;
                this.isNoLicenseUpgradable = true;
                this.filteredPreviewData = this.filteredPreviewData.map((elm: any) => {
                  if (elm.serialNumber === this.rowData.serialNumber && elm.index === this.rowData.index) {
                    return {
                      ...elm,
                      noMultiLicenseOption: true,
                    };
                  }

                  return elm;
                });
                this.assetDrawerService.filteredPaymentPreviewData.next(this.filteredPreviewData);
              } else {
                if ((item.next.description === null || item.next.description === '') && item.next.dealerExclusion) {
                  this.licenseEligiblityStatus = 'No Eligible Upgrade';
                  this.isNoLicenseUpgradable = true;
                } else {
                  if (
                    (this.rowData.techLevel === '-' || this.rowData.techLevel === '') &&
                    this.rowData.type === 'Grade Control ECM'
                  ) {
                    this.licenseEligiblityStatus = 'Not Upgradable';
                    this.isNoLicenseUpgradable = true;
                  } else if (
                    (item.next.description === null || item.next.description === '') &&
                    this.rowData.currentLicenseDesc !== '-' &&
                    this.rowData.licenseStatus !== 'PENDING'
                  ) {
                    this.licenseEligiblityStatus = 'No Upgrade Available';
                    this.isNoLicenseUpgradable = true;
                  } else if (
                    (this.rowData.currentLicenseDesc === null ||
                      this.rowData.currentLicenseDesc === '' ||
                      this.rowData.currentLicenseDesc === '-') &&
                    this.rowData.licenseStatus !== 'PENDING'
                  ) {
                    this.licenseEligiblityStatus = 'Not Upgradable';
                    this.isNoLicenseUpgradable = true;
                  }
                  if (this.rowData.licenseStatus === 'PENDING') {
                    this.licenseEligiblityStatus = '-';
                    this.isNoLicenseUpgradable = true;
                  }
                }
              }
            });
          }
        } else {
          eligiblityItems?.forEach((item: any) => {
            if (item.next && item.next.description !== null) {
              this.licenseEligiblityStatus = item.next.description;
              this.isNoLicenseUpgradable = true;
              this.filteredPreviewData = this.filteredPreviewData.map((elm: any) => {
                if (elm.serialNumber === this.rowData.serialNumber && elm.index === this.rowData.index) {
                  return {
                    ...elm,
                    noMultiLicenseOption: true,
                  };
                }

                return elm;
              });
              this.assetDrawerService.filteredPaymentPreviewData.next(this.filteredPreviewData);
            } else {
              if ((item.next.description === null || item.next.description === '') && item.next.dealerExclusion) {
                this.licenseEligiblityStatus = 'No Eligible Upgrade';
                this.isNoLicenseUpgradable = true;
              } else {
                if (
                  (item.next.description === null || item.next.description === '') &&
                  this.rowData.currentLicenseDesc &&
                  this.rowData.licenseStatus !== 'PENDING'
                ) {
                  this.licenseEligiblityStatus = 'No Upgrade Available';
                  this.isNoLicenseUpgradable = true;
                } else if (
                  (this.rowData.currentLicenseDesc === null || this.rowData.currentLicenseDesc === '') &&
                  this.rowData.licenseStatus !== 'PENDING'
                ) {
                  this.licenseEligiblityStatus = 'Not Upgradable';
                  this.isNoLicenseUpgradable = true;
                }
                if (this.rowData.licenseStatus === 'PENDING') {
                  this.licenseEligiblityStatus = '-';
                  this.isNoLicenseUpgradable = true;
                }
              }
            }
          });
        }
      });
    }

    if (!this.isNoLicenseUpgradable) {
      if (
        this.licenseEligiblityForm.controls['licenseEligiblity'].value !== null &&
        this.licenseEligiblityForm.controls['licenseEligiblity'].value !== ''
      ) {
        this.assetDrawerService.setMultiLicenseEnabled(true);
      } else {
        this.assetDrawerService.setMultiLicenseEnabled(false);
      }
    } else {
      this.assetDrawerService.setMultiLicenseEnabled(false);
    }
  }

  eligiblityDropdownChange(event: any, rowData: any) {
    if (event.action !== 'close') {
      this.assetDrawerService.setMultiLicenseEnabled(true);
      this.selectedLicenseType = event?.currentSelection[0]?.label || '';
      this.selectedLicensePartNumber = event?.currentSelection[0]?.value || '';
      this.filteredPreviewData = this.filteredPreviewData.map((elm: any) => {
        if (elm.serialNumber === this.rowData.serialNumber && elm.index === this.rowData.index) {
          return {
            ...elm,
            nextUpgradableLicenseDesc: this.selectedLicenseType,
            nextUpgradableLicensePartNum: this.selectedLicensePartNumber,
            selectedMultiLicense: true,
          };
        }

        return elm;
      });
      this.assetDrawerService.filteredPaymentPreviewData.next(this.filteredPreviewData);
    }
  }
}
