import { Component, HostListener, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetService } from '../../services/asset.service';
import { COUNTRY } from './country-code';
import * as dspConstants from '../../../../shared/dspConstants';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { HttpClient } from '@angular/common/http';
import { FilterAssetsV2Payload } from '../asset-drawer-interface';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { DspCommonService } from '../../../../services/dsp-common.service';
import { isEmpty } from 'lodash-es';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { PopupData } from '../../../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../../../common-confirmation-popup/common-confirmation-popup.component';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
@Component({
  selector: 'dsp-next-gen-ui-configure-wifi',
  templateUrl: './configure-wifi.component.html',
  styleUrls: ['./configure-wifi.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureWifiComponent implements OnInit {
  configureWiFiFormGroup: FormGroup = new FormGroup({});
  configDetails: any;
  params: any;
  wiFiConfigs: any;
  isTheme = '2.0';
  rowData: any;
  enableDisconnectBtn = false;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  statusMessage = '';
  currentStatus: any;
  public locationData = COUNTRY;
  public modalRef: any;
  disableFormField = false;
  disableAccessPoint = false;
  dealer: any;
  isCommonPopup = false;
  configurationStatus = [
    {
      statusCode: '0',
      statusMessage: 'Wifi setting not supported by device',
      colorCode: 'orange',
    },
    {
      statusCode: '1',
      statusMessage: 'Wifi configured successfully',
      colorCode: 'green',
    },
    {
      statusCode: '2',
      statusMessage: 'Wi-Fi configuration failed - Incorrect Password',
      colorCode: 'red',
      subStatusMessage: 'Disconnect and reconfigure',
    },
    {
      statusCode: '3',
      statusMessage: 'Not all wifi parameters specified',
      colorCode: 'orange',
    },
    {
      statusCode: '4',
      statusMessage: 'Wi-Fi configuration failed - SSID not found',
      colorCode: 'red',
      subStatusMessage: 'Disconnect and reconfigure',
    },
    {
      statusCode: '5',
      statusMessage: 'Failed (Added recently by our team)',
      colorCode: 'red',
    },
    {
      statusCode: '-1',
      statusMessage: 'Configuration queued successfully ',
      subStatusMessage: 'Pending',
      colorCode: 'blue',
    },
    {
      statusCode: '-2',
      statusMessage: 'Wi-Fi Configuration not found',
      colorCode: 'orange',
    },
    {
      statusCode: '-3',
      statusMessage: 'Disconnect command queued successfully ',
      subStatusMessage: 'Pending',
      colorCode: 'blue',
    },
  ];
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  queueReasonSubscription: any;
  selectedQueueReason: any;
  @ViewChild('disconnectWifiRef') disconnectWifiModal!: TemplateRef<any>;
  launchType: any;
  source: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      titleNames: string;
      data: any;
      type: any;
    },
    public dspCommonService: DspCommonService,
    private formBuilder: FormBuilder,
    private assetService: AssetService,
    private assetDrawerService: AssetDrawerService,
    private bannerNotification: MessageBar,
    public dialogRef: CcDrawerRef<ConfigureWifiComponent, CcDrawerConfig>,
    public modal: CcModal,
    private http: HttpClient,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private sharedService: SharedService,
    private needsReviewService: NeedsReviewService
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp: any) => {
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
    });
    this.rowData = this.header.data ? this.header.data : {};
    this.source = this.rowData ? this.rowData.source : '';
    this.launchType = this.header.type ? this.header.type : 'LANDING';
    this.configureWiFiFormGroup = this.formBuilder.group({
      ssid: ['', Validators.required],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(8)],
          updateOn: 'change', //blur
        },
      ],
      country_code: ['USA', Validators.required],
      security: [''],
      type: ['', Validators.required],
      status: [''],
    });
    this.configureWiFiFormGroup.patchValue({ country_code: 'United States', type: 'CLIENT_WITH_DHCP' });
    this.getMetaData();
    if (this.rowData?.deviceType === 'PL542') {
      this.disableAccessPoint = true;
    }
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
    });
    this.dealer = fetchLoggedInDealerDetails();
  }

  ToAccessPoint(event: any) {
    if (event.value === 'CLIENT_WITH_DHCP') {
      this.configureWiFiFormGroup?.get('password')?.addValidators(Validators.required);
      this.configureWiFiFormGroup?.get('password')?.updateValueAndValidity();
    } else {
      this.configureWiFiFormGroup?.get('password')?.clearValidators();
      this.configureWiFiFormGroup?.get('password')?.setValue('');
    }
  }
  getMetaData() {
    this.assetService.getMetaData().subscribe({
      next: (result: any) => {
        this.configDetails = result;
        this.getConfiguration();
      },
      error: (err: any) => {},
    });
  }

  setFilterAssetsPayload() {
    if (this.rowData.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.rowData?.make || '',
        serialNumber: this.rowData?.serialNumber || '',
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.rowData?.make || '',
        serialNumber: this.rowData?.serialNumber || '',
        flag: false,
      };
    }
  }

  getConfiguration() {
    const inputData = {
      serialNumber: this.rowData.serialNumber,
      make: this.rowData.make,
    };
    this.assetService.getWiFiConfigurationData(inputData).subscribe({
      next: (result: any) => {
        this.enableDisconnectBtn = false;
        this.disableFormField = false;
        this.wiFiConfigs = result;
        this.statusMessage = this.wiFiConfigs.status;
        if (result.country_code) {
          const countryValue = this.locationData.find((item) => item.value === result.country_code)?.label;
          this.configureWiFiFormGroup.patchValue({ country_code: countryValue });
        }
        if (this.statusMessage == 'Default Status') {
          this.getCurrentStatus({ status: '-2' });
        }
        if (result?.status) {
          switch (result.status) {
            case 'Pending':
              this.getCurrentStatus({ status: '-1' });
              this.enableDisconnectBtn = true;
              this.disableFormField = true;
              break;
            case 'SSID not found':
              this.getCurrentStatus({ status: '4' });
              this.enableDisconnectBtn = true;
              this.disableFormField = true;
              break;
            case 'Internal Error':
              this.getCurrentStatus({ status: '2' });
              this.enableDisconnectBtn = true;
              this.disableFormField = true;
              break;
            case 'Success':
              this.getCurrentStatus({ status: '1' });
              this.enableDisconnectBtn = true;
              this.disableFormField = true;
              break;
            case 'Pending Unbinding':
              this.getCurrentStatus({ status: '-3' });
          }
        }
        if (this.wiFiConfigs && this.wiFiConfigs.wifiConfigResponse?.length > 0) {
          this.configureWiFiFormGroup.patchValue(this.wiFiConfigs?.wifiConfigResponse[0]);
        }
      },
      error: (err: any) => {},
    });
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    let assetData: any;
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.rowData.macAddress = result.macAddress;
        }
      },
    });
  }

  refreshConnection() {
    this.disableFormField = true;
    this.getMetaData();
    this.configureWiFiFormGroup.patchValue({ country_code: 'United States', type: 'CLIENT_WITH_DHCP' });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onDropdownChange({ currentSelection }: any, prop: string) {
    this.configureWiFiFormGroup.patchValue({
      [prop]: currentSelection,
    });
  }

  connectWiFi() {
    const input = {
      make: this.rowData.make,
      serialNumber: this.rowData.serialNumber,
      macAddress: this.rowData.macAddress,
      channel: 11,
      country_code: this.configureWiFiFormGroup.value.country_code[0].value
        ? this.configureWiFiFormGroup.value.country_code[0].value
        : 'USA',
      certType: '',
      wifiConfigRequest: [
        {
          ssid: this.configureWiFiFormGroup.value.ssid,
          password: btoa(this.configureWiFiFormGroup.value.password),
          interface_config_type: this.configureWiFiFormGroup.value.type,
          security: this.configDetails.find((item: any) => this.configureWiFiFormGroup.value.type === item.configType)
            ?.securityMethods[0].securityType,
          metric: 30,
        },
      ],
    };

    this.assetService.insertConfiguration(input).subscribe({
      next: (resp: any) => {
        this.enableDisconnectBtn = false;
        this.disableFormField = false;
        this.setDataLayer('success', '');
        if (
          (resp.status && (resp.status === '-1' || resp.status === '1')) ||
          (resp.status_code && (resp.status_code === '-1' || resp.status_code === '1'))
        ) {
          this.enableDisconnectBtn = true;
          this.disableFormField = true;
        }
        this.getCurrentStatus(resp);
      },

      error: (_err: any) => {
        const linkDetail = [
          {
            label: 'Cat Digital Support',
            fn: () => {
              window.open(
                'https://cat-crmi.secure.force.com/CatConnectCase/?_ga=2.117467593.170777017.1674835347-192150537.1661439604'
              );
            },
          },
        ];
        this.setDataLayer('failed', this.systemError);
        this.showToastMessage(`Something went wrong! Please try again or contact`, 'error', linkDetail);
      },
    });
  }
  setDataLayer(status: any, failedReason: any) {
    let isNeedsReview =
      this.rowData?.status === this.dspNeedsReviewConstants.Active ||
      this.rowData?.status === this.dspNeedsReviewConstants.New;
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Configure Wi-Fi',
      formContent: this.rowData?.deviceType,
      formContent2: isNeedsReview ? this.selectedQueueReason : '',
      formAdditionalContent:
        this.dealer?.dealerCode + (this.launchType == 'DRAWER' ? '' : ' | Asset Action Menu Clicked'),
      formStatus: status,
      formFieldCausingError: failedReason,
      formLocation: this.source,
    };
    this.sharedService.updateDataLayer(object);
  }
  showToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'configure-wifi',
      appOverlayClass: 'configure-wifi-overlay',
    };
    this.bannerNotification.open(message, status, linkDetail, config, true);
  }

  disconnectWiFi() {
    this.modal.close();
    const input = {
      make: this.rowData.make,
      serialNumber: this.rowData.serialNumber,
      macAddress: this.rowData.macAddress,
      channel: 11,
      country_code: this.configureWiFiFormGroup.value.country_code.value,
      wifiConfigRequest: [
        {
          ssid: this.configureWiFiFormGroup.value.ssid,
          password: '',
          interface_config_type: this.configureWiFiFormGroup.value.type,
          metric: 30,
        },
      ],
    };

    this.assetService.deleteConfiguration(input).subscribe(
      (resp: any) => {
        this.enableDisconnectBtn = false;
        this.disableFormField = false;
        this.configureWiFiFormGroup.reset();
        if (resp.status_code == 0) {
          this.currentStatus = { colorCode: 'blue', subStatusMessage: 'Pending', statusCode: 'Pending' };
          this.statusMessage = 'Disconnect command queued successfully ';
        }
      },
      (_err: any) => {
        this.showToastMessage(this.systemError, 'error', undefined);
      }
    );
  }

  openModal(content: any) {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.CONFIGURE_WIFI_CLOSE_CONFIRMATION___BODY_LINE1],
        showCrossMark: false,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.CONFIRM,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
      };

      this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '520px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.modalRef.afterClosed().subscribe((res: any) => {
        if (res === dspConstants.PopupButtons.CONFIRM) {
          this.disconnectWiFi();
        }
      });
    } else {
      this.modalRef = this.modal.openModal(content, {
        width: '520px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
    }
  }

  clear() {
    this.modal.close();
  }

  getCurrentStatus(resp: any) {
    this.currentStatus = this.configurationStatus.find((status) => status.statusCode === resp.status);
    this.statusMessage = this.currentStatus ? this.currentStatus.statusMessage : 'Default Status';
  }
}
