import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  ITableSettingsPanelConfig,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { debounce } from 'lodash-es';
import { Observable, Subject, Subscription } from 'rxjs';
import { NEEDSREVIEWOVERLAYDATA, OVERLAYDATA } from '../../needs-review-constant';
import { NeedsReviewSharedService } from '../../services/needs-review-shared.service';
import { NeedsReviewService } from '../../services/needs-review.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { CommonFilterService } from 'apps/dsp-ui/src/app/services/common-filter.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import * as dspConstants from '../../../../../../apps/dsp-ui/src/app/shared/dspConstants';
import { NeedReviewTableComponent } from '../need-review-table/need-review-table.component';
import { TablesettingService } from 'apps/dsp-ui/src/app/services/tablesetting.service';
import { AssetDatComponent } from 'apps/dsp-ui/src/app/components/assets/asset-table-details/asset-dat/asset-dat.component';
import { TableSettingsCloseConfirmationComponent } from 'apps/dsp-ui/src/app/components/table-settings-close-confirmation/table-settings-close-confirmation.component';
import { CommonConfirmationPopupComponent } from 'apps/dsp-ui/src/app/components/common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from 'apps/dsp-ui/src/app/models/popup.interface';
import { Router } from '@angular/router';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-needs-review-main',
  templateUrl: './needs-review-main.component.html',
  styleUrls: ['./needs-review-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedsReviewMainComponent implements OnInit {
  @ViewChildren(NeedReviewTableComponent) needsReviewTableComponent!: QueryList<NeedReviewTableComponent>;
  queueReasonId: any;
  queueReasonLabel: any;
  @ViewChild('needsReviewSearchBox') needsReviewSearchBox!: ElementRef;
  eventsSubject: Subject<void> = new Subject<void>();
  searchChange: Subject<void> = new Subject<void>();
  customData = {
    searchString: '',
  };
  listItems = [
    {
      label: 'Download Excel',
      selected: true,
    },
    {
      label: 'Download CSV',
    },
  ];
  searchHelpText = '';
  width = '280px';

  searchValue: FormControl = new FormControl('', {
    validators: [Validators.minLength(3)],
    updateOn: 'change', //blur
  });

  mobileHeader = 'Search User';
  isNewFiltersEnabled = false;
  showLoader = false;
  overlayData = OVERLAYDATA;
  needsreviewoverlaydata = NEEDSREVIEWOVERLAYDATA;
  filterContentsRef: any;
  filterResponse: any[] = [];
  savedFilters: any;
  downloadedFileName: string = '';
  needsReviewCoumnList: any;
  fileType: any;
  downloadPopUpModalRef: any;
  modal: any;
  downloadOptionType: any;
  appliedFilters: any;
  dealer: any;
  selectedChip: any;
  disableHeaderContent: boolean = true;
  isDataLoading = true;
  prevSearchMain: any;
  sortBy: any;
  isSortOperation: any;
  sortOrder: any;
  enableSearch = false;
  searchDataLoaded = false;
  exportSort: any;
  dspStoreData: any;
  chipStatus: any;
  isXLDownloadEnabled: any;
  sortByActive: any;
  sortOrderActive: any;
  sortOperationActive: any;
  sortByDismissed: any;
  sortOrderDismissed: any;
  sortOperationDismissed: any;
  isSettingsOutSideClicked = false;
  pageLabel = 'needsReview';
  columnConfig = {
    searchString: '',
  };
  isTimeZoneSelectionEnabled: any;
  settingsPanelConfig = {
    settingsPanelHeader: 'Table Settings',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  tableSettingsPanelConfig!: ITableSettings;
  tableSettingsConfig: ITableSettingsPanelConfig = {
    features: [],
    visibilityToggle: false,
  };
  settingDrawerRef: any;
  isSaveTableSettings = '';
  observable!: Subscription;
  isNeedsReviewTableSettingEnabled = false;
  modalRef: any;
  isTableSettingsApply = false;
  isTableSettingClose = false;
  isCloseEvent = false;
  outSideClick = false;
  defaultColumns = ['serialNumber', 'eventDate', 'ucid', 'ucidName', 'dealerCustomerNumber', 'dcnName', 'deviceType'];
  pageLimit: any;
  queueReasonSubscription: any;
  selectedQueueReason: any;
  isCommonPopup = false;
  userDealerCode: any;
  constructor(
    private drawerService: DrawerService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private needsReviewSharedService: NeedsReviewSharedService,
    public needsReviewService: NeedsReviewService,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private commonFilterService: CommonFilterService,
    private userPreferenceService: UserPreferenceService,
    private tableSettingService: TablesettingService,
    private cdr: ChangeDetectorRef,
    public ccModal: CcModal,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getFIlterDetails();
    this.dealer = fetchLoggedInDealerDetails();
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      '',
      this.queueReasonId
    );
    this.appliedFilters = filtersSaved ? filtersSaved : {};
    this.showLoader = this.needsReviewSharedService.showLoader;
    this.needsReviewSharedService.downloadCompleted.subscribe((data) => {
      this.showLoader = data as boolean;
    });
    this.store.select('user').subscribe((user) => {
      this.userDealerCode = user?.user?.dealerCode || '';
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.isSaveTableSettings = this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        : false;
      this.isXLDownloadEnabled = this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        ? this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        : false;
      this.isNeedsReviewTableSettingEnabled = this.dspStoreData?.featureFlagInfo?.isNeedsReviewTableSettingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isNeedsReviewTableSettingEnabled
        : false;
      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
    });
    this.needsReviewSharedService.selectedChipStatus.subscribe((data) => {
      this.isDataLoading = true;
      this.selectedChip = data;
    });
    this.needsReviewSharedService.dataLoaded.subscribe((dataCount) => {
      this.isDataLoading = false;
      if (Number(dataCount) > 0) {
        this.disableHeaderContent = false;
      } else {
        this.disableHeaderContent = true;
      }
    });

    // if(this.chipStatus === 'Active'){
    // this.needsReviewSharedService.sortingValuesExportActive.subscribe((data: any) => {
    //   if (data) {
    //     this.sortBy = data.sortBy;
    //     this.sortOrder = data.sortOrder;
    //     this.exportSort = data.sortOperation;
    //   }
    // });
    // }
    // if(this.chipStatus === 'Dismissed'){
    // this.needsReviewSharedService.sortingValuesExport.subscribe((data: any) => {
    //   if (data) {
    //     this.sortBy = data.sortBy;
    //     this.sortOrder = data.sortOrder;
    //     this.exportSort = data.sortOperation;
    //   }
    // });
    // }
    this.commonFilterService.isFilterSavedEmptySubject.next('true');
    if (this.isTimeZoneSelectionEnabled) {
      this.userPreferenceService.dateTimeObservable.subscribe((res: any) => {
        this.createSettingsPanelConfig();
      });
    }
    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP Needs Review Page',
      });
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsCommonService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsCommonService.isAllowedNavigation();
  }
  ngAfterViewInit() {
    if (this.needsReviewTableComponent) {
      this.observable = this.tableSettingService.needsReviewObservable.subscribe((res) => {
        this.createSettingsPanelConfig();
        let promise = new Promise((resolve) => {
          this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.map((column: any) => {
            if (column.isVisible) {
              this.setDisplayColumns(column);
              switch (column.title) {
                case 'serialNumber':
                  break;
                case 'eventDate':
                case 'ucid':
                  break;
                default:
                  break;
              }
              if (
                this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.indexOf(column.name) ===
                  -1 &&
                this.defaultColumns.indexOf(column.name) === -1
              ) {
                this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.push(column.name);
              }
            }
          });
          resolve(this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload);
        });
        // promise.then((value) => {
        //   this.needsReviewTableComponent.first.fetchNeedsReview(true);
        // });
      });
      this.fetchSettingDetails();
      // this.checkColumnRetaintion();

      this.showDATColumn();
      this.cdr.detectChanges();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
  }

  showDATColumn() {
    if (this.isNeedsReviewTableSettingEnabled === true) {
      const obj = {
        name: 'digitalAuthorization',
        title: 'Digital Authorization',
        width: 180,
        isAlwaysMandatory: false,
        isVisible: false,
        isFrozen: false,
        sortingEnable: true,
        ascendingSort: false,
        sortWithOverlay: true,
        overlayData: OVERLAYDATA,
        minWidth: 180,
        component: AssetDatComponent,
      };

      if (!this.isItemExists('name', 'digitalAuthorization')) {
        this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.push(obj);
      }
    }
  }

  isItemExists(propertyName: string, value: any): boolean {
    return this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.some(
      (item: any) => item[propertyName] === value
    );
  }

  setDisplayColumns(data: any) {
    if (
      data.isVisible &&
      this.defaultColumns.indexOf(data.name) === -1 &&
      this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.indexOf(data.name) === -1
    ) {
      this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.push(data.name);
    }
  }
  setDataLayer(formName: any, formContent: any, formContent2: any, status: any, errorReason: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: formName,
      formContent: formContent,
      formContent2: formContent2,
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: errorReason,
      formLocation: 'Needs Review Page',
    };
    this.sharedService.updateDataLayer(object);
  }
  checkColumnRetaintion() {
    let restoreColumn: any;
    if (this.isSaveTableSettings) {
      restoreColumn = sessionStorage.getItem('tableSettings');
      restoreColumn = JSON.parse(restoreColumn);
      restoreColumn = restoreColumn?.needsReview;
    } else {
      restoreColumn = sessionStorage.getItem('needsReviewColumn');
      restoreColumn = JSON.parse(restoreColumn);
    }
    if (restoreColumn) {
      const columnArray = restoreColumn;
      columnArray.map((columnData: any) => {
        this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.map((data: any) => {
          if (columnData.name === data.name) {
            data.isVisible = columnData.isVisible;
            data.isFrozen = columnData.isFrozen;
            data.sortOrder = columnData.sortOrder;
          }
        });
      });
      this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.sort((a: any, b: any) => {
        return a.sortOrder - b.sortOrder;
      });
      this.createSettingsPanelConfig();
      this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.map((column: any) => {
        if (column.isVisible) {
          if (
            this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.indexOf(column.name) ===
              -1 &&
            this.defaultColumns.indexOf(column.name) === -1
          ) {
            this.needsReviewTableComponent.first.fetchNeedsReviewAPIPayload.displayColumns?.push(column.name);
          }
        }
      });
      this.needsReviewTableComponent.first.fetchNeedsReview(true);
    }
  }

  onCickEvent(event: any) {
    if (event.target.value === '') {
      this.searchHelpText = 'Search Asset/ Engine/ Device/ Radio S/N/ Customer(Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }
  onFilterChange(event: any) {
    this.chipStatus = event;
  }
  onBlurEvent(event: any) {
    if (event.type === 'blur') this.searchHelpText = '';
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.value.length || 0;
      this.needsReviewSearchBox.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  clearSearch() {
    this.searchValue.setValue('');
    this.needsReviewSearchBox.nativeElement.value = '';
    this.needsReviewSearchBox.nativeElement.focus();
    this.needsReviewSearchBox.nativeElement.blur();
  }

  onNeedsReviewSearchChange(event: any) {
    if (event.value.trim().length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value !== '' && this.searchHelpText !== '') {
      this.searchHelpText = '';
    }
    if (event.value.trim().length > 2 && this.prevSearchMain != this.searchValue?.value) {
      this.needsReviewService.selectedQueueReason = null;
      this.savedFilters = {};
      this.searchValue.setValue(event.value);
    } else if (event.value === '') {
      if (!this.isDataLoading) {
        this.searchHelpText = 'Find Asset / Engine / Device / Radio S/N / Customer(Min 3 Char)';
      } else {
        this.searchHelpText = '';
      }
      this.searchValue.setValue('');
      if (this.searchDataLoaded) {
        this.getReviewSearch();
        this.searchDataLoaded = false;
      }
    }
    this.prevSearchMain = this.searchValue?.value;
  }

  fetchSettingDetails() {
    this.tableSettingService.getTableSettings(
      'needsReview',
      this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig
    );
  }
  pageLimitPayload(data: any) {
    this.pageLimit = data;
  }
  getReviewSearch(isSearch?: any) {
    if (this.searchValue?.value.length > 2) {
      this.isDataLoading = true;
    }
    this.searchChange.next(this.searchValue.value);
  }

  onListItemClick(value?: any) {
    const item = value.split(' ');
    this.fileType = item[1];
    this.uploadFile(this.fileType);
  }
  onClickExport() {
    this.uploadFile(this.fileType);
  }

  downloadOptionClick(event: any) {
    this.downloadOptionType = event.value;
  }

  uploadFile(type: string) {
    this.sortByActive = sessionStorage.getItem('sortByActive');
    this.sortOrderActive = sessionStorage.getItem('sortOrderActive');
    this.sortOperationActive = sessionStorage.getItem('sortOperationActive');
    this.sortByDismissed = sessionStorage.getItem('sortByDismissed');
    this.sortOrderDismissed = sessionStorage.getItem('sortOrderDismissed');
    this.sortOperationDismissed = sessionStorage.getItem('sortOperationDismissed');
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      '',
      this.queueReasonId
    );
    this.appliedFilters = filtersSaved ? filtersSaved : {};
    if (this.selectedChip == undefined) {
      this.selectedChip = 'Active';
    }

    if (this.isXLDownloadEnabled) {
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        dealerQueueReasonId: this.queueReasonId,
        operation: 'exportExcel',
        filter: this.appliedFilters ? this.appliedFilters : {},
        status: this.selectedChip ? this.selectedChip : 'Active',
        searchText: this.searchValue?.value ? this.searchValue.value.trim() : '',
        sortByActive: this.sortByActive ? this.sortByActive : 'eventDate',
        sortByDismissed: this.sortByDismissed ? this.sortByDismissed : 'eventDate',
        sortOrderActive: this.sortOrderActive ? this.sortOrderActive : 'DESC',
        sortOrderDismissed: this.sortOrderDismissed ? this.sortOrderDismissed : 'DESC',
        isSortOperationActive: this.sortOperationActive ? true : false,
        isSortOperationDismissed: this.sortOperationDismissed ? true : false,
      };

      switch (type) {
        case 'Excel':
          this.downloadedFileName = 'assetPopulation.xlsx';
          inputData.operation = 'exportExcel';
          this.exportAssetData(inputData);
          break;
        case 'CSV':
          this.downloadedFileName = 'assetPopulation.csv';
          inputData.operation = 'exportCsv';
          this.exportAssetData(inputData);
          break;
        default:
          break;
      }
    } else if (this.isXLDownloadEnabled === false) {
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        dealerQueueReasonId: this.queueReasonId,
        operation: 'exportExcel',
        filter: this.appliedFilters ? this.appliedFilters : {},
        status: this.selectedChip ? this.selectedChip : 'Active',
        searchText: this.searchDataLoaded ? this.searchValue.value.trim() : '',
        sortByActive: this.sortByActive ? this.sortByActive : 'eventDate',
        sortByDismissed: this.sortByDismissed ? this.sortByDismissed : 'eventDate',
        sortOrderActive: this.sortOrderActive ? this.sortOrderActive : 'DESC',
        sortOrderDismissed: this.sortOrderDismissed ? this.sortOrderDismissed : 'DESC',
        isSortOperationActive: this.sortOperationActive ? true : false,
        isSortOperationDismissed: this.sortOperationDismissed ? true : false,
      };

      //this.downloadedFileName = 'assetPopulation.csv';
      inputData.operation = 'exportCsv';
      this.exportAssetData(inputData);
      // switch (type) {
      //   case 'Excel':
      //     this.downloadedFileName = 'assetPopulation.xlsx';
      //     inputData.operation = 'exportExcel';
      //     this.exportAssetData(inputData);
      //     break;
      //   case 'CSV':
      //     this.downloadedFileName = 'assetPopulation.csv';
      //     inputData.operation = 'exportCsv';
      //     this.exportAssetData(inputData);
      //     break;
      //   default:
      //     break;
      // }
    }
  }

  exportAssetData(data: any) {
    this.showLoader = true;
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.needsReviewSharedService.showLoader = true;
    this.needsReviewService.exportReviewData(data).subscribe(
      (resp: any) => {
        this.getExportStatus(resp.exportID);
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.needsReviewSharedService.showLoader = false;
        this.needsReviewSharedService.downloadCompleted.next(false);
      }
    );
  }

  getExportStatus(id: string) {
    const formContent2 = this.searchValue?.value ? 'Global Search Done : ' + this.searchValue.value.trim() : '';
    this.needsReviewService.getReviewDataExportStatus(id).subscribe(
      (resp: any) => {
        if (resp.status === 'Completed') {
          const fileId = resp.fileId;
          this.setDataLayer('Needs Review Export', this.selectedQueueReason, formContent2, 'success', '');
          this.downloadfile(fileId, this.queueReasonLabel);
        } else if (resp.status === 'Pending') {
          setTimeout(() => {
            this.getExportStatus(id);
          }, 5000);
        }
      },
      () => {
        this.setDataLayer('Needs Review Export', this.selectedQueueReason, formContent2, 'failed', 'Download failed');
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.needsReviewSharedService.showLoader = false;
        this.needsReviewSharedService.downloadCompleted.next(false);
      }
    );
  }

  downloadfile(id: string, label: string) {
    this.needsReviewService.downloadReviewData(id, label).subscribe(
      (downloadResp: any) => {
        const contentDisposition = downloadResp.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].split('"').join('');
        this.createDefaultAnchorElement(downloadResp.body, filename);

        this.showLoader = false;
        this.needsReviewSharedService.showLoader = false;
        this.needsReviewSharedService.downloadCompleted.next(false);
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.needsReviewSharedService.showLoader = false;
        this.needsReviewSharedService.downloadCompleted.next(false);
      }
    );
  }

  createDefaultAnchorElement(data: any, filename?: any) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const csvUrl = URL.createObjectURL(data);
    a.href = csvUrl;
    a.download = filename;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  /** Getting the queueReasonId emitted from left tab and passing as input to table component */
  getDealerQueueReason(evt: any) {
    this.isDataLoading = true;
    this.queueReasonId = evt.id;
    this.queueReasonLabel = evt.label;
    this.getFIlterDetails();
    if (evt.isQueueReasonSelected) {
      sessionStorage.removeItem('reviewDateFilters');
      this.needsReviewService.selectedQueueReason = null;
      this.savedFilters = {};
    }
    this.eventsSubject.next(this.queueReasonId);
    this.commonFilterService.isFilterSavedEmptySubject.next(this.queueReasonId);
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  dataLoaded() {
    this.searchDataLoaded = true;
  }
  loadChangeEmit(res: any) {
    if (this.isTimeZoneSelectionEnabled) {
      let timeColummns = ['eventDate'];
      let objKeys = Object.keys(res);
      let dateArray: any = [];
      timeColummns.map((columnName: any) => {
        if (objKeys.includes(columnName)) {
          let convertedDate = [
            this.userPreferenceService.convertTimeToSelectedZone(res[columnName][0], false),
            this.userPreferenceService.convertTimeToSelectedZone(res[columnName][1], false),
          ];

          convertedDate.map((date: any) => {
            date = date.replace(',', '');
            var year = new Date(date).getFullYear();
            var month = new Date(date).getMonth() + 1;
            var day = new Date(date).getDate();
            var formattedDate = year + '-' + month + '-' + day + ' ' + date?.split(' ')[1];
            dateArray.push(formattedDate);
          });
          res[columnName] = dateArray;
        }
      });
    }
    this.getFIlterDetails();
    if (this.appliedFilters && Object.keys(this.appliedFilters)?.length > 0) {
      if (res) {
        this.isDataLoading = true;
      }
    }
    if (res === false) {
      this.isDataLoading = true;
    }

    this.appliedFilters = res;

    if (res && Object.keys(res) && Object.keys(res).length > 0) {
      this.isDataLoading = true;
      this.needsReviewSharedService.appliedReviewFilters.next({ filterData: res, filterApplied: true });
    } else {
      this.needsReviewSharedService.appliedReviewFilters.next({ filterData: res, filterApplied: false });
    }
  }
  getcolumnConfig() {
    this.columnConfig.searchString = this.searchValue?.value;
    return this.columnConfig;
  }
  getFIlterDetails() {
    this.commonFilterService.getFilterColumn(this.pageLabel).subscribe((response: any) => {
      this.needsReviewCoumnList = response;
    });
  }
  createSettingsPanelConfig() {
    if (this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig) {
      const trimmedTableConfig = this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig.columnConfig.map(
        (config: any) => {
          return {
            title: config.title ? config.title : '',
            name: config.name ? config.name : '',
            isFrozen: config.isFrozen ? config.isFrozen : false,
            isVisible: config.isVisible ? config.isVisible : false,
            isAlwaysMandatory: config.isAlwaysMandatory ? config.isAlwaysMandatory : false,
            disableFrozen: false,
          };
        }
      );
      this.tableSettingsPanelConfig = {
        features: trimmedTableConfig,
        visibilityToggle: false,
        ITableSettingsPanelTooltip: {
          columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
        },
      };
    }
  }
  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      const originalConfig: any = {};
      Object.assign(originalConfig, this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig);
      const updatedTableConfig: any = [];
      event.features.forEach((config: any, index: number) => {
        const data = originalConfig.columnConfig.find((dataObj: { name: string }) => config.name === dataObj.name);
        if (data) {
          data.isVisible = config.isVisible;
          data.isFrozen = config.isFrozen;
          data.priority = index + 1;
          this.setDisplayColumns(data);
          switch (data.title) {
            case 'serialNumber':
              break;
            case 'eventDate':
            case 'ucid':
              break;
            default:
              break;
          }
        }
        updatedTableConfig.push(data);
      });
      originalConfig.columnConfig = updatedTableConfig;
      this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig = { ...originalConfig };
      this.tableSettingService.updateTableSettings('needsReview', updatedTableConfig, this.pageLimit);
      sessionStorage.setItem('needsReviewColumn', JSON.stringify(updatedTableConfig));
      this.needsReviewTableComponent.first.updateColumnConfigCustomData();
      this.createSettingsPanelConfig();
      this.cdr.detectChanges();
    }
    this.isCloseEvent = false;
  }

  findSortColumnName(updatedTableConfig: any, sortColumn: string) {
    if (updatedTableConfig.findIndex((item: any) => item.name == sortColumn && item.isVisible == true) == -1) {
      return true;
    } else {
      return false;
    }
  }

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }
  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };

  clearFilters() {
    sessionStorage.removeItem('reviewFilters');
    this.commonFilterService.removeItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'filtersDetails',
      this.pageLabel,
      '',
      this.queueReasonId
    );
    this.commonFilterService.removeItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      '',
      this.queueReasonId
    );
    sessionStorage.removeItem('reviewDateFilters');
    this.commonFilterService.isFilterSavedEmptySubject.next('true');
  }

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalConfig: any = {};
    let isConfirmationPopupNeeded = false;
    Object.assign(originalConfig, this.needsReviewTableComponent.first.subcribeNeedsReviewTableConfig);
    for (let i = 0; event.features?.length > i; i++) {
      const data = originalConfig.columnConfig.find(
        (dataObj: { name: string }) => event?.features[i]?.name === dataObj?.name
      );
      const indexOfOriginalColumnConfig = originalConfig.columnConfig.indexOf(data);
      if (data) {
        if (typeof data.isFrozen === 'undefined') data.isFrozen = false;
        if (
          data.isVisible !== event.features[i].isVisible ||
          data.isFrozen !== event.features[i].isFrozen ||
          indexOfOriginalColumnConfig !== i
        ) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
        showCrossMark: true,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
      };
      this.modalRef = this.ccModal?.openModal(CommonConfirmationPopupComponent, {
        width: '534px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef?.afterClosed()?.subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CROSS) {
          this.isCloseEvent = false;
        }
      });
    } else {
      this.modalRef = this.ccModal?.openModal(TableSettingsCloseConfirmationComponent, {
        width: '534px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === false) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else {
          this.isCloseEvent = false;
        }
      });
    }
  }
}
