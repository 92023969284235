import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TableConfig } from '@cat-digital-workspace/shared-ui-core/cc-table';
import { CcMenuDataSource } from '@cat-digital-workspace/shared-ui-core/contextual-menu';
import {
  CcDrawerRef,
  CC_DRAWER_DATA,
  DrawerService,
  CcDrawerConfig,
  CcDrawerSize,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { SitesService } from '../../../../services/sites.service';
import { siteData } from '../../sites-interface';
import { SitesComponent } from '../../sites.component';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { AssetDrawerComponent } from '../../../assets/asset-drawer/asset-drawer.component';
import * as dspConstants from '../../../../shared/dspConstants';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { PlanDetailsDrawerComponent } from '../../../assets/asset-table-details/plan-details-drawer/plan-details-drawer.component';

@Component({
  selector: 'dsp-next-gen-ui-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteDetailsComponent implements OnInit {
  overlayData = [
    {
      name: 'asc',
      title: 'Sort A to Z',
    },
    {
      name: 'dsc',
      title: 'Sort Z to A',
    },
  ];
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA) public data: siteData,
    private sidePanelRef: CcDrawerRef<SitesComponent>,
    private siteService: SitesService,
    private store: Store<DSPAppState>,
    private drawerService: DrawerService,
    private assetDrawerService: AssetDrawerService,
    private needsReviewService: NeedsReviewService
  ) {}
  subcribeAssetTableConfig: TableConfig = {
    stickyHeader: true,
    expandable: false,
    height: 500,
    isSelfFoldable: false,
    isBulkAction: false,
    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    columnConfig: [
      {
        name: 'serialNumber',
        title: 'Asset S/N',
        formControl: 'span',
      },
      {
        name: 'productPlan',
        title: 'Product | Plan',
        formControl: 'span',
      },
      {
        name: 'deviceType',
        title: 'Device Type',
        formControl: 'span',
      },
      {
        name: 'actions',
        title: 'Actions',
        formControl: 'span',
      },
    ],
  };

  subscribeAssetsData: any;
  subscribeMapData: any;
  additionalServices: any[] = [];
  activeStatus = false;
  inactiveStatus = false;
  statusText: any;
  isTableLoading = true;
  assetDrawerRef: any;
  featureSupportMapping: any;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  subscriptionUpdated = false;
  planDetailsRef: any;
  isMultiProductWorkFlow = false;
  columnData = {
    name: 'application',
  };
  dspStoreData: any;
  assetActionRowData: Array<CcMenuDataSource> = [
    {
      listItemName: 'Update',
    },
  ];
  ngOnInit(): void {
    this.getActiveStatus();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.subscribeMapData = dsp?.serviceMapping || {};
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.featureSupportMapping = dsp.featureSupportMapping;
      this.isMultiProductWorkFlow = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
    });

    this.getAssetInformation(this.data);
  }

  getActiveStatus() {
    if (this.data.status === 'Active') {
      this.activeStatus = true;
      this.inactiveStatus = false;
      this.statusText = 'Active';
    } else if (this.data.status === 'Cancelled') {
      this.inactiveStatus = true;
      this.activeStatus = false;
      this.statusText = 'Inactive';
    }
  }

  getAssetInformation(siteDataParam: siteData) {
    this.isTableLoading = true;
    const body = { dealerCode: siteDataParam.dealerCode, siteId: siteDataParam.guid };
    this.siteService.getAssetInfo(body).subscribe({
      next: (data) => {
        this.isTableLoading = false;
        this.subscribeAssetsData = data;
        if (this.subscribeAssetsData.length > 0) {
          this.subscribeAssetsData.filter((obj: any) => {
            obj['subscription'] = this.subscribeMapData[obj?.catSubscriptionId]?.subscriptionLevel || '';
            obj['applicationName'] = this.subscribeMapData[obj?.catSubscriptionId]?.appName || '';
            obj['reportingState'] = dspConstants.Worklist.SUBSCRIBED;
            obj['getSupportedServicesList'] = this.getSupportedServices(obj);
          });
        }
        if (this.subscriptionUpdated) {
          this.data.assetCount = this.subscribeAssetsData.length;
          this.data = { ...this.data };
        }
      },
      error: (_err) => {
        this.isTableLoading = true;
      },
    });
  }
  getSupportedServices(assetData: any) {
    const supportedServicesPayload = { guiID: assetData?.appId || '' };
    this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
      next: (result: any) => {
        let data: any[] = [];
        if (result?.length > 0) {
          assetData.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
          result?.forEach((val: any) => {
            if (this.featureSupportMapping && this.featureSupportMapping[val]) {
              data = data.concat(this.featureSupportMapping[val]);
            }
          });
        }
        assetData.getSupportedServicesList = data;
        assetData.showConfigureWifiIcon = this.isWifiConfigurationNeeded(assetData);
        return assetData;
      },
      error: (err: any) => {
        assetData.getSupportedServicesList = [];
        assetData.showConfigureWifiIcon = this.isWifiConfigurationNeeded(assetData);
        return assetData;
      },
    });
  }

  isWifiConfigurationNeeded(assetData: any) {
    if (
      assetData?.reportingState != dspConstants.Worklist.SUSPENDED &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Wifi') > -1 &&
      this.getWifiConfigurationType(assetData) !== '' &&
      !assetData.isMaskRequired
    ) {
      return true;
    } else {
      return false;
    }
  }
  getWifiConfigurationType(assetData: any) {
    const featureSupportMsg = assetData.getSupportedServicesList ? assetData.getSupportedServicesList : [];
    const wifiSimultaneous = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_SIMULTANEOUS) > -1;
    const wifiHotSpot = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_HOTSPOT) > -1;
    const wifiInfrastructure = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_INFRASTRUCTURE) > -1;

    if (wifiSimultaneous && wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_AND_HOTSPOT;
    } else if (wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_OR_HOTSPOT;
    } else if (wifiInfrastructure && !wifiHotSpot && !wifiSimultaneous) {
      return dspConstants.Worklist.WIFI_CLIENT;
    }

    return '';
  }

  tableEvents(event: any) {
    if (event.eventType === 'SORTING' && event?.event !== 'filterby') {
      const data = JSON.parse(JSON.stringify(this.subscribeAssetsData));
      this.subscribeAssetsData = data.sort(this.compareValues(event?.data?.name, event?.event));
    }

    if (event.eventType === 'ROWEDITABLE' && event.item.listItemName === 'Update') {
      this.needsReviewService.enableNeedsReviewDismissOption.next(false);
      const assetDetails =
        this.subscribeAssetsData && this.subscribeAssetsData[event.index] ? this.subscribeAssetsData[event.index] : {};
      this.openAssetDrawer(assetDetails);
    }
  }

  openAssetDrawer(assetDetails: any) {
    assetDetails.source = 'Manage Site - Site Details page';
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: assetDetails,
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.assetDrawerRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        this.subscriptionUpdated = true;
        this.getAssetInformation(this.data);
      }
    });
  }

  compareValues(key: any, order = 'ASC') {
    return function (a: any, b: any) {
      if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'DESC' ? comparison * -1 : comparison;
    };
  }

  openDrawerInMultiSize(rowdata: any) {
    this.planDetailsRef = this.drawerService.openDrawer<PlanDetailsDrawerComponent, CcDrawerConfig>(
      PlanDetailsDrawerComponent,
      {
        data: {
          title: 'Product | Plan',
          columnData: this.columnData,
          rowData: rowdata,
          isMultiProductWorkFlow: this.isMultiProductWorkFlow,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.planDetailsRef.afterClosed().subscribe((_res: any) => {});
  }

  closeModal() {
    this.sidePanelRef.close(this.subscriptionUpdated);
  }
}
