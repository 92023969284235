import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CcModalRef, CcModal, MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdatePIDService } from '../../services/update-pid.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { PopupData } from '../../../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../../../common-confirmation-popup/common-confirmation-popup.component';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { getAssetDataSubscribedPlansEditResponse } from '../../../../store/actions/dspcommon.action';
interface searchMakeType {
  label: string;
  value: any;
}
@Component({
  selector: 'dsp-next-gen-ui-edit-asset-serial-number',
  templateUrl: './edit-asset-serial-number.component.html',
  styleUrls: ['./edit-asset-serial-number.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditAssetSerialNumberComponent implements OnInit {
  dspUserRoleConstants = dspConstants;
  @ViewChild('confirmationPopUpTemplateRef') downloadPopUpModal: any;
  downloadPopUpModalRef!: CcModalRef<any, any>;
  @ViewChild('customerSearchBox') customerSearchInput!: ElementRef;
  assetEditableData: any;
  public assetId: any;
  public assetSNo = true;
  public newAssetId: any;
  public serialNumber: any;
  public dealercode: any;
  isTheme = '2.0';
  public editAssetForm!: FormGroup;
  public searchByCode: any;
  public serialNumberError: any;
  public errorMessageProd: any = '';
  public onSaveErrorMessage: any;
  public currentMake: any;
  public equipment: any;
  public makeError: any;
  public remotingerrorMessageProd: any;
  public validMake: any = true;
  public loadingEditSerialNo = false;
  productIdChanged = false;
  newSerialNoClicked = true;
  dealerInfo: any;
  assetDetails: any;
  makeValue: any = '';
  previousMakeSearch: any = '';
  serialNumberErrorMessageProd = '';
  errorMessageFlag = false;
  makeNotFound = false;
  makeCodeLoader = false;
  searchMakeValue: searchMakeType[] = [];
  serialNoListObj: any;
  newSerialNo: any;
  dealer: any;
  newSn: any;
  newSerialNumber: any;
  disableNonMakeSubmit = true;
  loadingproductID: any = false;
  makeChanged: any = false;
  isOpenedFromDrawer: any;
  candidateProductIdList: any = [];
  userRole!: string | null;
  barcode: any = '';
  scanType = '';
  showLoader = false;
  enableFields = true;
  serialNumList: any;
  isEnableForwardSlashInPID = false;
  defaultEntry = 'MANUAL_ENTRY';
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  manualIcon = {
    icon: 'assets/icons/manual.svg',
    iconSelected: 'assets/icons/manual-selected.svg',
    iconDisabled: 'assets/icons/manual.svg',
  };
  scanIcon = {
    icon: 'assets/icons/scan.svg',
    iconSelected: 'assets/icons/scan-selected.svg',
    iconDisabled: 'assets/icons/scan.svg',
  };
  radioStates = ['active', 'default'];
  readOnly = false;
  searchListValue: any = [];
  makeInfo: any = [];
  makeInfoList: any = [];
  scanRestrictOnManual = 'false';
  dspConfig: any;
  isEnableOnlyMakeFor3PDATA = false;
  modalContentData = '';
  dspStoreData!: any;
  isEnableProductMsgUpdate = false;
  loadedManualIcon: any;
  loadedScanIcon: any;
  isCATMakeEditable: any;
  launchType: any;
  storedData: any;
  source: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer(false);
  }
  isCommonPopup = false;
  modalRef: any;
  queueReasonSubscription: any;
  selectedQueueReason: any;
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      rowData: any;
      isDrawer: any;
    },

    // private fb: FormBuilder,
    public dialogRef: CcDrawerRef<EditAssetSerialNumberComponent>,
    public formBuilder: FormBuilder,
    public assetDrawerService: AssetDrawerService,
    public messageBar: MessageBar,
    public modal: CcModal,
    public assetService: AssetService,
    private updatePIDService: UpdatePIDService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<DSPAppState>,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService,
    private needsReviewService: NeedsReviewService
  ) {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.dspConfig = dsp.dspConfig;
      this.scanRestrictOnManual = this.dspStoreData?.featureFlagInfo?.scanRestrictOnManual
        ? this.dspStoreData?.featureFlagInfo?.scanRestrictOnManual
        : false;
      this.isEnableProductMsgUpdate = this.dspStoreData?.featureFlagInfo?.enableProductMsgUpdate
        ? this.dspStoreData?.featureFlagInfo?.enableProductMsgUpdate
        : false;
      this.isCATMakeEditable = this.dspStoreData?.featureFlagInfo?.isCATMakeEditable
        ? this.dspStoreData?.featureFlagInfo?.isCATMakeEditable
        : false;
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
      this.isEnableForwardSlashInPID = this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        ? this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
    });
  }

  ngOnInit(): void {
    this.loadedManualIcon = this.manualIcon;
    this.loadedScanIcon = this.scanIcon;
    this.userRole = this.dspUtilsCommonService.getValue('userRole');
    this.assetEditableData = this.header.rowData;
    this.source = this.assetEditableData.source;
    this.launchType = this.header.isDrawer ? 'DRAWER' : 'LANDING';
    this.isOpenedFromDrawer = this.header.isDrawer ? this.header.isDrawer : false;
    this.assetId = this.assetEditableData?.assetId;
    if (this.assetId) {
      this.assetSNo = true;
    }
    this.makeValue =
      this.assetEditableData?.deviceType === 'PL161' &&
      this.userRole === this.dspUserRoleConstants.Common.DSP_FACTORY_USER
        ? 'CW1'
        : this.assetEditableData?.make;
    this.currentMake = {
      id: this.assetEditableData?.make,
      value:
        this.assetEditableData?.deviceType === 'PL161' &&
        this.userRole === this.dspUserRoleConstants.Common.DSP_FACTORY_USER
          ? 'CW1'
          : this.assetEditableData?.make,
    };
    this.equipment = {
      productId: this.assetEditableData.serialNumber,
    };
    this.dealer = fetchLoggedInDealerDetails();
    this.editAssetForm = this.formBuilder.group({
      // (this.newAssetId = new FormControl('')),
      // (this.searchByCode = new FormControl('')),
      newAssetId: ['', [Validators.required, Validators.minLength(3)]],
    });
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.storedData = this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        ? this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        : {};
    });
    this.getCandidateId();
    this.getDealerInformation(this.dealer.dealerCode);
    this.isPIDEnabledFor3PDATA();
  }

  isPIDEnabledFor3PDATA() {
    if (this.isCATMakeEditable) {
      if (
        this.assetEditableData.deviceType === '3PDATA' &&
        (this.userRole === this.dspUserRoleConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
          this.userRole === this.dspUserRoleConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED ||
          this.userRole === this.dspUserRoleConstants.Common.DSP_FACTORY_USER ||
          this.userRole === this.dspUserRoleConstants.Common.DSP_CUSTOM_ACCESS ||
          this.userRole === this.dspUserRoleConstants.Common.DSP_ADMINISTRATOR ||
          this.userRole === this.dspUserRoleConstants.Common.USER_ADMINISTRATOR ||
          this.userRole === this.dspUserRoleConstants.Common.SUPER_ADMIN)
      )
        this.isEnableOnlyMakeFor3PDATA = true;
    }
  }

  toggleRadioGroup(selected: any, i: any, selectedEntry: any) {
    this.radioStates.forEach((item, index) => {
      this.radioStates[index] = i === index && selected ? 'active' : 'default';
    });
    if (selectedEntry === 'SCAN_ENTRY') {
      this.defaultEntry = 'SCAN_ENTRY';
      this.scanType = 'SCAN_CODE';
      this.scanCode();
      this.enableFields = false;
    } else {
      this.defaultEntry = 'MANUAL_ENTRY';
      this.scanType = '';
      this.enableFields = true;
      this.readOnly = false;
    }
  }

  scanCode() {
    this.showLoader = true;
    this.scanType = 'SCAN_CODE';
    this.defaultEntry = this.scanType;
    this.barcode = '';
    const txtScanInput = document.getElementById('editTableScanInput') as HTMLInputElement | null;
    txtScanInput?.focus();
    this.changeDetector.detectChanges();
  }

  onKeyEdit(event: any) {
    if (this.scanType === 'SCAN_CODE') {
      if (event?.target?.tagName === 'INPUT') {
        return;
      }
      if (event.ctrlKey || event.which === 9 || event.which === 16) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (event.char == '\n' || event.which === 9 || event.which == 13) {
        this.barcode = this.barcode.replace(/\s/g, '');
        if (this.barcode.length >= 16 && this.barcode.length <= 17 && this.barcode.toUpperCase().indexOf('CAT') == 0) {
          this.barcode = this.barcode.substr(9, 8);
        } else if (
          this.barcode.indexOf('[30P]') >= 0 &&
          this.barcode.indexOf('[S]') >= 0 &&
          this.barcode.indexOf('CAT') == -1
        ) {
          let status = this.barcode.indexOf('30P');
          status = eval(status) + 4;
          this.barcode = this.barcode.slice(status);
          this.barcode = this.barcode.substring(0, this.barcode.indexOf('[')).replace(',', '');
        }
        this.barcode = this.barcode && this.barcode.slice(-2) == 'GS' ? this.barcode.slice(0, -2) : this.barcode;

        this.editAssetForm.controls['newAssetId'].setValue(this.barcode);
        this.showLoader = false;
        this.enableFields = true;
        this.newSerialNo = true;
        this.readOnly = true;
        this.barcode = '';
      } else {
        this.barcode = this.barcode + event.key;
      }
    }
  }

  get formControl() {
    return this.editAssetForm.controls;
  }
  setTextLength() {
    const productIdInputText = document.getElementById('productIdInputText') as any;
    if (this.currentMake.value.makeCode != undefined) {
      if (this.currentMake.value.makeCode == 'CAT') {
        productIdInputText.maxLength = 8;
        productIdInputText.value = productIdInputText.value.substr(0, 8);
        this.equipment.productId = productIdInputText.value;
        this.loadingEditSerialNo = false;
        this.errorMessageProd = undefined;
      } else {
        productIdInputText.maxLength = 17;
        this.errorMessageProd = undefined;
      }
    } else {
      if (this.currentMake.value == 'CAT') {
        productIdInputText.maxLength = 8;
        productIdInputText.value = productIdInputText.value.substr(0, 8);
        this.equipment.productId = productIdInputText.value;
        this.loadingEditSerialNo = false;
        this.errorMessageProd = undefined;
      } else {
        productIdInputText.maxLength = 17;
        this.errorMessageProd = undefined;
      }
    }
  }
  getCandidateId() {
    const payloadData =
      (this.currentMake?.value !== this.assetEditableData?.make
        ? this.assetEditableData?.make
        : this.currentMake?.value) +
      '|' +
      this.equipment?.productId;
    this.assetService
      .getCandidateProductId({
        serialNumber: payloadData,
      })
      .subscribe((res: any) => {
        this.assetDetails = res;
        if (this.assetDetails.candidateProductIds !== null && this.assetDetails.candidateProductIds.length > 0) {
          const defaultSerial = this.assetDetails?.candidateProductIds.some(
            (value: any) => value?.serialNumber === this.assetEditableData?.serialNumber
          );
          if (!defaultSerial) {
            const candidateIdObj = {
              make: this.assetEditableData?.make,
              serialNumber: this.assetEditableData?.serialNumber,
            };
            this.candidateProductIdList.push(candidateIdObj);
          }
          this.candidateProductIdList.push(...this.assetDetails.candidateProductIds);
        } else {
          const candidateIdObj = {
            make: this.assetEditableData.make,
            serialNumber: this.assetEditableData.serialNumber,
          };
          this.candidateProductIdList.push(candidateIdObj);
        }

        this.serialNoListObj = res.obj || {};
        this.serialNoListObj.bigID = this.assetEditableData.appId;

        const uniqueCandidateProductIds: {
          make: any;
          serialNumber: any;
        }[] = [];
        if (this.serialNoListObj.candidateProductIds && this.serialNoListObj.candidateProductIds.length > 0) {
          this.serialNoListObj.candidateProductIds.forEach((elem: any, _index: any, _self: any) => {
            const currElem = uniqueCandidateProductIds.filter(
              (filteredData: any) => filteredData.serialNumber == elem.serialNumber
            );
            if (!currElem || (currElem && currElem.length == 0)) {
              uniqueCandidateProductIds.push({
                make: this.equipment.productId == elem.serialNumber ? this.currentMake.value : elem.make,
                serialNumber: elem.serialNumber,
              });
            }
          });
        } else {
          uniqueCandidateProductIds.push({
            make: this.currentMake.value,
            serialNumber: this.equipment.productId,
          });
        }
        this.serialNoListObj.candidateProductIds = uniqueCandidateProductIds;
      });
  }
  getUniqueValues(copySerialNo: any) {
    const unique = copySerialNo.filter(
      (obj: any, index: any) =>
        copySerialNo.findIndex((item: any) => item.make === obj.make && item.serialNumber === obj.serialNumber) ===
        index
    );
    this.candidateProductIdList = unique;
  }
  closeDrawer(data: any) {
    this.dialogRef.close(data);
  }
  hideSerialNo(serialNo: any) {
    this.productIdChanged = this.assetEditableData.serialNumber == serialNo ? false : true;
    this.validateProductIdandMake();
    this.errorMessageProd = '';
    this.newSerialNo = false;
    this.newSerialNoClicked = false;
    this.equipment.productId = serialNo;
  }
  assetTransfer(response: any) {
    const dataParams = {
      request: response,
    };
    this.assetService.assetTransferAPI(dataParams).subscribe({
      next: (res: any) => {
        if (res.obj) {
          this.loadingEditSerialNo = false;
          let newMakeCodeFinal;
          res.obj.newSerialNumber = this.equipment.productId;
          if (this.currentMake.value.makeCode != undefined) {
            newMakeCodeFinal = this.currentMake.value.makeCode;
          } else {
            newMakeCodeFinal = this.currentMake.value;
          }
          res.obj.newMakeCode = newMakeCodeFinal;
          this.loadingproductID = true;
        }
      },
      error: (_err: any) => {
        this.loadingEditSerialNo = false;
      },
    });
  }
  newSerialNoVal() {
    this.errorMessageProd = '';
    this.productIdChanged = false;
    this.newSerialNo = true;
    this.newSerialNoClicked = true;
    this.equipment.productId = '';
  }
  checkNewSerialNo() {
    if (this.serialNoListObj && this.serialNoListObj.candidateProductIds.length > 1) {
      const filterResult = this.serialNoListObj.candidateProductIds.filter((elem: any) => {
        return elem.serialNumber === this.equipment.productId;
      });
      return filterResult.length === 0 ? true : false;
    } else {
      return false;
    }
  }
  getDealerInformation(dealerCode: any) {
    this.assetService.getDealerInformation(dealerCode).subscribe((response: any) => {
      this.dealerInfo = response;
      if (this.dealerInfo && this.dealerInfo.dealerMakeCodeList && this.dealerInfo.dealerMakeCodeList.dealerMakeCode) {
        const dealerData = this.dealerInfo.dealerMakeCodeList.dealerMakeCode.filter((item: any) =>
          item.catMakeCode.toLowerCase().indexOf(this.makeValue.toLowerCase() === 0)
        );
        if (dealerData.length) {
          this.makeInfo = dealerData.map((a: any) => {
            return {
              label: a.catMakeCode,
            };
          });
        }
      }
    });
  }

  onCickEvent(event: any) {}

  onBlurEvent(event: any) {}

  onFocusEvent() {
    setTimeout(() => {
      const textLength = this.makeValue?.length || 0;
      this.customerSearchInput?.nativeElement.setSelectionRange(textLength, textLength);
    }, 100);
  }
  searchByMakeCodeName(event: any) {
    if (event.value !== '' && !this.makeChanged) {
      this.makeValue = event.value;
      this.validMake = true;
      this.makeCodeLoader = true;
      this.previousMakeSearch = this.makeValue;
      this.makeNotFound = false;
      if (event.value && event.value.length >= 1) {
        this.searchMakeValue = [];
        if (
          this.dealerInfo &&
          this.dealerInfo.dealerMakeCodeList &&
          this.dealerInfo.dealerMakeCodeList.dealerMakeCode
        ) {
          const searchList = this.dealerInfo.dealerMakeCodeList.dealerMakeCode.filter(
            (filteredList: any) =>
              filteredList.catMakeCode.toLowerCase().indexOf(event.value.toLowerCase()) == 0 ||
              filteredList.catMakeCodeDesc.toLowerCase().indexOf(event.value.toLowerCase()) == 0
          );
          if (searchList.length) {
            this.searchMakeValue = searchList.map((item: any) => {
              return {
                label: item.catMakeCode + ' - ' + item.catMakeCodeDesc,
                value: item,
              };
            });
            this.makeNotFound = false;
            this.enableOverlayBox();
          } else {
            this.resetOverlayBox();
          }
        }
        if (
          (this.dealerInfo &&
            this.dealerInfo.dealerMakeCodeList &&
            this.dealerInfo.dealerMakeCodeList.dealerMakeCode.length == 0) ||
          event.value.length == 0
        ) {
          this.updateNoResults();
        }
        this.makeCodeLoader = false;
      }
    } else if (event.value === '') {
      this.makeCodeLoader = true;
      this.makeChanged = false;
      this.makeNotFound = true;
      this.validMake = false;
    }
  }
  updateNoResults() {
    this.makeNotFound = true;
    this.makeChanged = false;
    this.validMake = false;
    this.resetOverlayBox();
  }
  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }

  enableOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.display = 'block';
    }
  }

  listItemClick(index: number) {
    this.makeValue = this.searchMakeValue[index]['label'];
    this.validMake = true;
    this.makeNotFound = false;
    this.errorMessageProd = '';
    this.makeChanged = this.assetEditableData.make != this.searchMakeValue[index]['value'].catMakeCode;
    this.currentMake.value = this.searchMakeValue[index]['value'].catMakeCode;
    this.currentMake.id = this.searchMakeValue[index]['value'].catMakeCode;

    this.validateProductIdandMake();
  }

  updateAsssetByCandidateId(data: any) {
    this.downloadPopUpModalRef?.close();
    this.equipment.productId = this.equipment.productId.toUpperCase();
    if (
      this.equipment.productId == this.assetEditableData.serialNumber &&
      ((this.currentMake.value.makeCode != undefined &&
        this.currentMake.value.makeCode == this.assetEditableData.make) ||
        (typeof this.currentMake.value == 'string' && this.currentMake.value == this.assetEditableData.make))
    ) {
      this.equipment.serialNumber = this.equipment.productId;
      this.equipment.make = this.currentMake.id;
      this.loadingEditSerialNo = false;
      this.onSaveErrorMessage = true;
      this.serialNumberError = true;
      this.errorMessageProd =
        'The Product ID already exists. Please contact Cat Digital Support for further assistance.';
    } else {
      let sn;
      if (this.currentMake.value.makeCode != undefined) {
        sn = this.currentMake.value.makeCode + '|' + this.equipment.productId;
      } else {
        let newMake = this.makeValue;
        newMake = newMake.split(' -').length ? this.makeValue.split(' -')[0] : this.currentMake.value;
        this.newSn = this.newSerialNo ? this.formControl['newAssetId'].value : this.equipment.productId;
        sn = newMake + '|' + this.newSn;
        this.updatePIDService.updateSerialNo(this.newSn, newMake);
      }
      let dataParams: any;
      if (data) {
        dataParams = {
          serialNumber: sn,
          bigId: this.serialNoListObj?.bigID,
          assetId: this.assetId,
          isHarvesterFeed: false,
          dealerConsent: data.dealerConsent,
        };
      } else {
        dataParams = {
          serialNumber: sn,
          bigId: this.serialNoListObj?.bigID,
          assetId: this.assetId,
          isHarvesterFeed: false,
        };
      }
      if (this.errorMessageProd.length == 0) {
        this.assetService.updateAssetProductId(dataParams).subscribe({
          next: (res: any) => {
            this.setDataLayer(dataParams, 'success', '');
            if (!res.messageToUser) {
              const overlayCls = this.isOpenedFromDrawer ? 'drawerOverlayCls' : '';
              if (
                this.isCATMakeEditable &&
                this.assetEditableData.deviceType === '3PDATA' &&
                (this.userRole === this.dspUserRoleConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
                  this.userRole === this.dspUserRoleConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED ||
                  this.userRole === this.dspUserRoleConstants.Common.DSP_FACTORY_USER ||
                  this.userRole === this.dspUserRoleConstants.Common.DSP_CUSTOM_ACCESS ||
                  this.userRole === this.dspUserRoleConstants.Common.DSP_ADMINISTRATOR ||
                  this.userRole === this.dspUserRoleConstants.Common.USER_ADMINISTRATOR ||
                  this.userRole === this.dspUserRoleConstants.Common.SUPER_ADMIN)
              ) {
                this.showToastMessage('Asset updated successfully', 'success', overlayCls);
              } else {
                this.showToastMessage('Asset S/N updated successfully', 'success', overlayCls);
              }
              delete this.storedData[this.assetId];
              this.store.dispatch(getAssetDataSubscribedPlansEditResponse(this.storedData));
              this.closeDrawer({
                updatedSerialNumber: dataParams.serialNumber,
              });
            } else {
              const resList = ['CONFIRM_USER_ACTION', 'LEGACY_TO_NEWGEN_CHANGE'];
              if (resList.indexOf(res.responseType) > -1 && res.responseType == resList[0]) {
                this.loadingEditSerialNo = true;
                this.modalContentData = res?.messageToUser;
                if (this.isCommonPopup) {
                  const popupData: PopupData = {
                    title: dspConstants.Common.CONFIRMATION_POPUP_TITLE,
                    messageArray: [this.modalContentData],
                    showCrossMark: false,
                    showPrimaryButton: true,
                    showSecondaryButton: true,
                    primaryButtonText: dspConstants.PopupButtons.SUBMIT,
                    secondaryButtonText: dspConstants.PopupButtons.CANCEL,
                  };

                  this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
                    width: '518px',
                    type: 'semi-modal',
                    data: popupData,
                    closeOnEsc: false,
                    disableBackdropClick: true,
                    isAutoHeightModalContent: true,
                  });

                  this.modalRef.afterClosed().subscribe((res: any) => {
                    if (res === dspConstants.PopupButtons.SUBMIT) {
                      this.handleSubmit();
                    }
                  });
                } else {
                  this.downloadPopUpModalRef = this.modal.openModal(this.downloadPopUpModal, {
                    width: '518px',
                    type: 'semi-modal',
                    isAutoHeightModalContent: true,
                  });
                }
              } else {
                this.assetTransfer(res.transferRequest);
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            const overlayCls = this.isOpenedFromDrawer ? 'drawerOverlayCls' : '';
            if (error.status == 409) {
              this.showToastMessage(
                'This action could not be completed because you do not own this asset',
                'error',
                overlayCls
              );
              this.setDataLayer(
                dataParams,
                'failed',
                'This action could not be completed because you do not own this asset'
              );
            } else {
              this.showToastMessage('Unable to update asset', 'error', overlayCls);
              this.setDataLayer(dataParams, 'failed', 'Unable to update asset');
            }
            this.closeDrawer(false);
          },
        });
      }
    }
  }
  setDataLayer(data: any, status: any, failedReason: any) {
    let isNeedsReview =
      this.assetEditableData?.status === this.dspNeedsReviewConstants.Active ||
      this.assetEditableData?.status === this.dspNeedsReviewConstants.New;
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Update Registration',
      formContent:
        data.serialNumber.split('|')[0] +
        ' | ' +
        data.serialNumber.split('|')[1] +
        ' | ' +
        this.assetEditableData?.deviceType,
      formContent2: isNeedsReview ? this.selectedQueueReason : '',
      formAdditionalContent:
        this.dealer?.dealerCode +
        ' | ' +
        this.assetEditableData.make +
        ' | ' +
        this.assetEditableData.serialNumber +
        (this.launchType == 'DRAWER' ? '' : ' | Asset Action Menu Clicked'),
      formStatus: status,
      formFieldCausingError: failedReason,
      formLocation: this.source,
    };
    this.sharedService.updateDataLayer(object);
  }
  showToastMessage(message: string, status: string, overlayClass: string) {
    const configObj: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      appOverlayClass: overlayClass,
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, configObj, true);
  }

  makeErrorText() {
    this.onSaveErrorMessage = false;
    this.errorMessageProd = 'Please select a valid Make';
    this.makeError = true;
    this.serialNumberError = false;
    this.newSerialNoClicked = !this.productIdChanged;
  }

  validateProductIdandMake() {
    this.formControl['newAssetId']?.setValue(this.formControl['newAssetId']?.value?.toUpperCase());
    const serialNumber = this.newSerialNo ? this.formControl['newAssetId'].value : this.equipment.productId;
    this.newSerialNumber = serialNumber;
    if (this.newSerialNumber.length >= 3) {
      this.disableNonMakeSubmit = false;
    }
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    const regex1 = new RegExp('^[A-Z0-9]+$');
    const regex2 = new RegExp('^[0-9]*$');

    const regex3 = this.isEnableForwardSlashInPID
      ? new RegExp('^(?!(\\s|-|/))[-a-zA-Z0-9\\s/]*[^\\s-/]$')
      : new RegExp('^(?!(\\s|-))[-a-zA-Z0-9\\s]*[^\\s-]$');
    const regex_end = new RegExp('.*[a-zA-Z0-9]$');
    // this.errorMessageProd = '';

    this.makeInfoList = [];
    if (this.makeInfo && this.makeInfo.length > 0) {
      this.makeInfo.forEach((res: any) => {
        this.makeInfoList.push(res.label);
      });
    }

    if (this.makeInfo && this.makeInfo.length > 0) {
      if (this.makeValue.includes('-')) {
        const splitted = this.makeValue.split('-');
        if (!this.makeInfoList.includes(splitted[0].trim())) {
          this.makeErrorText();
          return true;
        }
      } else {
        if (!this.makeInfoList.includes(this.makeValue)) {
          this.makeErrorText();
          return true;
        }
      }
    }

    if (!this.validMake && this.makeNotFound) {
      this.makeErrorText();
      return true;
    }

    if (
      this.currentMake == null ||
      this.currentMake == undefined ||
      this.currentMake.id == null ||
      this.currentMake.id == undefined ||
      this.currentMake.id.length == 0 ||
      this.currentMake.value == null ||
      this.currentMake.value == undefined ||
      this.currentMake.value.length == 0
    ) {
      this.makeErrorText();
      return true;
    }

    if (serialNumber == null || serialNumber == undefined || serialNumber == '') {
      this.onSaveErrorMessage = false;
      this.errorMessageProd = 'Please select a valid Asset S/N or Enter a new Asset S/N';
      this.makeError = false;
      this.serialNumberError = true;
      this.newSerialNoClicked = !this.productIdChanged;
      return true;
    }
    this.serialNumList = [];
    this.candidateProductIdList.forEach((res: any) => {
      this.serialNumList.push(res.serialNumber.toLowerCase());
    });

    if (
      (this.assetEditableData.make !== undefined && this.assetEditableData.make.toLowerCase()) ===
        (typeof this.currentMake.value == 'string' &&
          this.currentMake.value !== undefined &&
          this.currentMake.value.toLowerCase()) &&
      this.assetEditableData.serialNumber.toLowerCase() === serialNumber.toLowerCase()
    ) {
      this.onSaveErrorMessage = false;
      this.errorMessageFlag = true;
      this.errorMessageProd = 'The Asset S/N is already existing';
      this.serialNumberError = true;
      return true;
    }

    if (
      (this.currentMake.value.makeCode && serialNumber?.currentMake?.value?.makeCode.toLowerCase() == 'cat') ||
      (serialNumber.currentMake &&
        serialNumber.currentMake.value &&
        typeof serialNumber.currentMake.value == 'string' &&
        serialNumber.currentMake.value.toLowerCase() == 'cat') ||
      (!regex.test(serialNumber.substring(0, 3)) && this.currentMake.value.toLowerCase() == 'cat') ||
      (serialNumber.length > 3 &&
        !regex2.test(serialNumber.substring(3, 8)) &&
        this.currentMake.value.toLowerCase() == 'cat')
    ) {
      this.remotingerrorMessageProd = '';
      this.onSaveErrorMessage = false;
      this.errorMessageFlag = true;
      this.newSerialNoClicked = !this.productIdChanged;
      this.errorMessageProd =
        'Asset S/N with a Cat make should start with 3 alphanumeric characters followed by 5 numbers.';
      this.makeError = false;
      this.serialNumberError = true;
      return true;
    }

    if (
      (this.currentMake.value == 'CAT' && regex.test(serialNumber.substring(0, 3)) && serialNumber.length < 8) ||
      (this.currentMake.value == 'CAT' && serialNumber.length > 8)
    ) {
      this.onSaveErrorMessage = false;
      this.newSerialNoClicked = !this.productIdChanged;
      this.errorMessageProd = 'A CAT asset S/N must be 8 characters long.';
      this.makeError = false;
      this.serialNumberError = true;
      return true;
    }

    if (this.isEnableForwardSlashInPID) {
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        serialNumber.indexOf('\\') > 0
      ) {
        this.onSaveErrorMessage = false;
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space, / and - are allowed, but not at the start or end.';
        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;

        return true;
      }
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        (serialNumber.length > 17 || serialNumber.length < 1 || !regex3.test(serialNumber))
      ) {
        this.onSaveErrorMessage = false;
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space, / and - are allowed, but not at the start or end.';
        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;
        return true;
      }
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        !regex_end.test(serialNumber)
      ) {
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space, / and - are allowed, but not at the start or end.';
        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;
        return true;
      }
    } else {
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        serialNumber.indexOf('\\') > 0
      ) {
        this.onSaveErrorMessage = false;
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space and - are allowed, but not at the start or end.';
        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;

        return true;
      }
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        (serialNumber.length > 17 || serialNumber.length < 1 || !regex3.test(serialNumber))
      ) {
        this.onSaveErrorMessage = false;
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space and - are allowed, but not at the start or end.';

        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;
        return true;
      }
      if (
        ((this.currentMake.value.makeCode != undefined && this.currentMake.value.makeCode.toLowerCase() != 'cat') ||
          (typeof this.currentMake.value == 'string' && this.currentMake.value.toLowerCase() != 'cat')) &&
        !regex_end.test(serialNumber)
      ) {
        this.errorMessageProd =
          'Asset S/N with non Cat make must be 17 characters or less, where space and - are allowed, but not at the start or end.';
        this.makeError = false;
        this.serialNumberError = true;
        this.newSerialNoClicked = !this.productIdChanged;
        return true;
      }
    }

    this.makeError = false;
    this.serialNumberError = false;

    if (this.onSaveErrorMessage) {
      return true;
    }

    this.errorMessageProd = '';
    this.newSerialNoClicked = false;
    return false;
  }

  isSubmitButtonDisabled() {
    return (
      (!this.productIdChanged &&
        !this.makeChanged &&
        this.currentMake.value === this.assetEditableData.make &&
        !this.newSerialNo) ||
      (this.errorMessageProd !== null && this.errorMessageProd !== undefined && this.errorMessageProd.length > 0) ||
      (this.currentMake.value !== 'CAT' && this.newSerialNumber.length < 3) ||
      this.loadingEditSerialNo
    );
  }

  onSubmit() {
    this.updateAsssetByCandidateId('');
  }
  closeDownloadPopupModal() {
    this.downloadPopUpModalRef?.close();
  }
  onCancel() {
    this.closeDownloadPopupModal();
  }
  handleSubmit() {
    const dataParams = {
      dealerConsent: true,
    };
    this.updateAsssetByCandidateId(dataParams);
  }
}
