<div class="ql-container">
  <div class="ql-header">
    <span class="header-text">QUICK LINKS</span>
    <div class="control-panel__item">
      <span class="settings-icon" cc-tooltip="Settings" (click)="openSettingDrawer(settingPanelDesktop)">
        <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.1907 11.125H18.6252V8.875H16.19C16.0332 8.22475 15.7767 7.23925 15.4377 6.6835L17.1597 4.96225L15.038 2.8405L13.3152 4.5625C12.7602 4.2235 11.7755 3.96775 11.1252 3.811V1.375H8.87524V3.811C8.22499 3.96775 7.23949 4.2235 6.68449 4.5625L4.96174 2.8405L2.83999 4.96225L4.56274 6.68425C4.22374 7.23925 3.96724 8.22475 3.80974 8.875H1.37524V11.125H3.80974C3.96724 11.7752 4.22374 12.7607 4.56274 13.3157L2.83999 15.0385L4.96174 17.1595L6.68374 15.4368C7.23949 15.7773 8.22499 16.033 8.87524 16.1905V18.625H11.1252V16.1905C11.7755 16.033 12.761 15.7765 13.3152 15.4375L15.0387 17.1595L17.1597 15.0385L15.4377 13.3157C15.7767 12.7607 16.0332 11.7752 16.1907 11.125Z"
            stroke="#000000"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.0002 13.375C11.8642 13.375 13.3752 11.864 13.3752 10C13.3752 8.13604 11.8642 6.625 10.0002 6.625C8.13628 6.625 6.62524 8.13604 6.62524 10C6.62524 11.864 8.13628 13.375 10.0002 13.375Z"
            stroke="#000000"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <ng-template #settingPanelDesktop>
        <div class="control-panel__item-options">
          <cat-digital-workspace-table-settings
            [cancel]="closeSettingDrawer"
            [setTableSettings]="tableSettingsPanelConfig"
            [isDisableFrozenTab]="false"
            [isApply]="isTableSettingsApply"
            [removeMinColumnreq]="true"
            [isClose]="isTableSettingClose"
            (closeTableSettingsEvent)="tableSettingsClose($event)"
            (closeTableDataEvent)="closeTableSettingsData($event)"
            [isDrawer]="true"
            [closeOniconClick]="false"
            [settingsPanelConfig]="settingsPanelConfig"
            (saveTableSettingsEvent)="saveSettings($event)"
            [isOutsideClick]="isSettingsOutSideClicked"
            (outsideClickDataEvent)="outsideClickDataEvent($event)"
            [isFrozendragdisabled]="true"
            [numberOfColumnsSelected]="8"
          >
          </cat-digital-workspace-table-settings>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="ql-body">
    <div class="quick-link-card" *ngFor="let quickLink of quickLinks">
      <div class="quick-link-card" *ngIf="quickLink.isSelected">
        <dsp-next-gen-ui-quick-link-card [quickLink]="quickLink"></dsp-next-gen-ui-quick-link-card>
      </div>
    </div>
  </div>
</div>
