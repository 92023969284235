import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { PlanDetailsDrawerComponent } from '../plan-details-drawer/plan-details-drawer.component';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { Subscription } from 'rxjs';
import * as dspConstants from '../../../../shared/dspConstants';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-asset-product-plan',
  templateUrl: './asset-product-plan.component.html',
  styleUrls: ['./asset-product-plan.component.scss'],
})
export class AssetProductPlanComponent implements OnInit {
  @ViewChild('siteSearchBox') siteSearchBox!: ElementRef;
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  searchValue = '';
  searchHelpText = '';
  mobileHeader = 'Search User';
  planDetailsRef: any;
  queueReasonId: any;
  dspStoreData!: any;
  isMultiProductWorkFlow = false;
  queueReasonSubscription!: Subscription;
  dspNeedReviewConstants = dspConstants.NeedsReview;
  isShowSeeMore = false;
  arr: any;
  constructor(
    private drawerService: DrawerService,
    private needsReviewService: NeedsReviewService,
    private assetDrawerService: AssetDrawerService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isMultiProductWorkFlow = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
    });

    this.rowData = this.value;

    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.queueReasonId = data.dealerQueueReasonId;
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }
  onSiteSearchChange(_event: any) {}
  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }
  onCickEvent(event: any) {
    if (event?.target?.value === '') {
      this.searchHelpText = 'Find Site name/Ownership/Product|Plan (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }
  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.length || 0;
      this.siteSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }
  openDrawerInMultiSize() {
    /**Added Asset status change from New to Active on click of connection check**/
    if (this.rowData.status == this.dspNeedReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.rowData, this.queueReasonId);
    }
    /**/
    this.planDetailsRef = this.drawerService.openDrawer<PlanDetailsDrawerComponent, CcDrawerConfig>(
      PlanDetailsDrawerComponent,
      {
        data: {
          title:
            this.columnWithHeader.name === 'ucidName' ||
            this.columnWithHeader.name === 'dcnName' ||
            this.columnWithHeader.name === 'ucid' ||
            this.columnWithHeader.name === 'dealerCustomerNumber'
              ? 'Ownership'
              : this.columnWithHeader.title,
          columnData: this.columnWithHeader,
          rowData: this.rowData,
          isMultiProductWorkFlow: this.isMultiProductWorkFlow,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.planDetailsRef.afterClosed().subscribe((_res: any) => {});
  }

  getColumnData() {
    switch (this.columnWithHeader.name) {
      case 'application': {
        const newAppname =
          this.rowData.applicationName != '-'
            ? this.rowData.applicationPlan
              ? this.rowData.applicationName + ' | ' + this.rowData.applicationPlan
              : this.rowData.applicationName
            : '-';
        return this.rowData && this.rowData.applicationName ? newAppname : '-';
      }
      case 'ucidName':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'ucid':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'dcnName':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'dealerCustomerNumber':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'dealerSubscription':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'customerSubscription':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name].trim()
          : '-';
      case 'promotionName':
        return this.rowData && this.rowData[this.columnWithHeader.name]
          ? this.rowData[this.columnWithHeader.name]?.split('|')[0]
          : '-';
    }
  }

  getColumnToolTipData() {
    let promoName = '';
    switch (this.columnWithHeader.name) {
      case 'application': {
        const newAppname =
          this.rowData.applicationName != '-'
            ? this.rowData.applicationName + ' | ' + this.rowData.applicationPlan
            : '';
        return this.rowData && this.rowData.applicationName ? newAppname : '';
      }
      case 'ucidName':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'dealerCustomerNumber':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'dcnName':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'ucid':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'dealerSubscription':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'customerSubscription':
        return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
      case 'promotionName':
        if (this.rowData && this.rowData.promotionName) {
          promoName = this.rowData.promotionName;
          promoName = promoName?.split('!').join('      ');
          promoName = promoName?.split(',').join('      ');
        }
        return promoName;
    }
  }
  checkIfEmpty() {
    if (
      this.columnWithHeader.name === 'application' &&
      this.rowData['applicationName'] &&
      this.rowData.applicationName != '-' &&
      this.rowData.applicationPlan
    ) {
      return true;
    } else if (this.rowData[this.columnWithHeader.name]) {
      if (
        ['ucid', 'ucidName', 'dcnName', 'dealerCustomerNumber'].includes(this.columnWithHeader.name) &&
        (this.rowData['reportingState'] === 'Subscribed' || this.rowData['ownerShipCount'] <= 1)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  checkIsShowMultiProductSeeMore() {
    if (this.rowData?.subscribedProducts && this.rowData?.subscribedProducts >= 2) {
      this.isShowSeeMore = true;
    }

    if (this.isMultiProductWorkFlow && this.isShowSeeMore) {
      return true;
    } else {
      return false;
    }
  }
}
