<div class="enter-reason">
  <div class="cc-grid">
    <div class="{{ ccGrid.GRID_TWELVE }}">
      <div class="enter-reason-label">
        <span>Enter Reason for Decline</span>
      </div>
      <div class="columnSize">
        <cc-input-field
          id="declineReason_id"
          [disabled]="false"
          [labelValue]="'Reason'"
          [width]="'100%'"
          [value]="''"
          [maxlength]="1000"
          [value]="declineReason"
          [isTheme]="'2.0'"
        >
          <textarea
            id="declineReason_text"
            autosize
            [(ngModel)]="declineReason"
            [isFloating]="false"
            [labelValue]="'Reason'"
            class="textarea-field"
            rows="2"
            [value]="declineReason"
            [maxlength]="1000"
            (valueChange)="onTextChange()"
          ></textarea>
        </cc-input-field>
      </div>
      <div class="decline-area">
        <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
        <span class="decline-message">The reason entered will be shared with the customer.</span>
      </div>
    </div>
  </div>
</div>
