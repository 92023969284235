/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import { forkJoin, fromEvent, merge, of, Subscription } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';
import { loggedInUser } from './store/selectors/user-info.selector';
import { DSPAppState } from './store/state/dsp.state';
import { appRoutes } from './app.routes';
import { loadUserRequestAction, loadUserMenuRequestAction } from './store/actions/user-info.actions';
import { OrganizationComponent } from './components/UserSetting/organization/organization.component';
import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {
  getDeviceFeaturesInfo,
  userActiveFeatures,
  getDSPConfig,
  getLanguageTranslations,
  getDealerSubFeature,
  getUserDetails,
  getMinestarServiceDetails,
  setDeviceCatalogueInfo,
  getFeatureFlagInfo,
  getMultiProductFeatureFlagInfo,
  getListOfProducts,
} from './store/actions/dspcommon.action';
import { DspCommonService } from './services/dsp-common.service';
import { dealerType } from './models/shared.interface';
import { fetchLoggedInDealerDetails, fetchdecodedAccessToken } from './shared/shared';
import { environment } from '../environments/environment';
import { isEmpty } from 'lodash-es';
import { DealerSubfeatureService } from './services/dealerSubfeature.service';
import * as dspConstants from '../app/shared/dspConstants';
import { SitesService } from './services/sites.service';
import { OneTrustService } from './services/one-trust/one-trust.service';
import { CcPopover } from '@cat-digital-workspace/shared-ui-core/cc-popover';
import { DspUtilsCommonService } from './services/dsp-utils-common.service';
import { WhatfixScriptService } from './services/whatfix-script.service';
import { SharedService } from './shared.service';
import { UserPreferenceService } from './user-preference.service';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth/auth.service';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { ConfigurationService } from './services/configuration.service';
import { NotificationPopupComponent } from './components/configuration/user-notification/notification-popup/notification-popup.component';
import { AssetService } from './components/assets/services/asset.service';
import { CcMenu } from '@cat-digital-workspace/shared-ui-core/contextual-menu';
import { AssetDrawerService } from './services/asset-drawer.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface NavbarState {
  isMenuEnabled: boolean;
}
@Component({
  selector: 'dsp-next-gen-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends ComponentStore<NavbarState> implements OnInit, AfterViewInit, OnDestroy {
  title = 'DEALER SERVICES PORTAL';
  public user: any;
  @ViewChild(CcPopover) popoverDirective?: CcPopover;
  @ViewChild('ccmenu') ccmenu?: CcMenu;
  isApplicationDataLoading = false;
  isWhatFixHelpEnabled = false;
  isFetchApiDataLoading = false;
  isNavBarEnabled = true;
  isShowSidenav = false;
  backOnline: boolean = false;
  isSideNavOpen = false;
  today: Date = new Date();
  todayTime?: string;
  shortName: any;
  showError = false;
  selectedPath = '';
  logoUrl = '';
  isDefaultFound = false;
  isMenuEnabled$ = this.select((state) => state.isMenuEnabled);
  dspStoreData!: any;
  footerEventEmitterValue: any;
  userRole: any;
  isCatUser = true;

  disableTopNavToggle = false;
  @ViewChild('topNavMenu') topNavMenu?: ElementRef;
  @ViewChild('sideNavComp', { static: false }) myDiv?: ElementRef;

  dealerCode = 'TD00';
  isMenu$ = fromEvent(document, 'click').pipe(
    tap((event: any) => {
      const container = document.getElementsByClassName('linked-accounts')[0];
      if (
        (event.target.className &&
          typeof event.target.className === 'string' &&
          event.target.className.indexOf('profile-button-click') === -1 &&
          event.target.className.indexOf('change-account-label') === -1 &&
          container &&
          !container.contains(event.target)) ||
        (typeof event.target.className === 'string' &&
          event.target.className.indexOf('profile-button-click') === -1 &&
          event.target.className.indexOf('dealer-info') === -1 &&
          !container) ||
        (typeof event.target.className === 'string' && event.target.className.indexOf('close-change-account') > -1)
      ) {
        this.patchState((_state: any) => ({
          isMenuEnabled: false,
        }));
      }
    })
  );
  //selectedParentNode: any;
  public menuOrderList = [...appRoutes];
  public menuOrderListApi = [];
  enableEroutes = false;
  catrecId: any;
  public subscription: Subscription = new Subscription();
  appInfo: any;
  // subscription: Subscription;
  browserRefresh = false;
  showHelpIcon = true;
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  ishelpIconPopover = false;
  isTimeZoneSelectionEnabled: any;
  isInternetOfflineMsgDisplay: any;
  listOfTimeZones: any;
  formattedTimeZones: any;
  defaultTimeZone: any;
  isGetTimeZoneCalled = false;
  isNumberConventionEnabled: any;
  selectedNumberFormat: any;
  defaultNumberFormat: any;
  isTimeFormatEnabled: any;
  isMultiProductWorkFlow = false;
  isDisableManualDeviceRegTab = false;
  defaultTimeFormat: any;
  defaultDateFormat: any;
  make: any;
  serialNumber: any;
  isCcatSearch = false;
  isDeviceStatusEnableCustomAccess = false;

  constructor(
    private modal: CcModal,
    public router: Router,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private dealerService: DealerSubfeatureService,
    private siteService: SitesService,
    private oneTrustService: OneTrustService,
    private whatfixScriptService: WhatfixScriptService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private authService: AuthService,
    private oAuthService: OAuthService,
    private messageBar: MessageBar,
    private loaderService: LoaderService,
    private configurationService: ConfigurationService,
    private assetService: AssetService,
    private assetDrawerService: AssetDrawerService
  ) {
    super({
      isMenuEnabled: false,
    });
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (event.navigationTrigger === 'popstate') {
          const userRole = this.dspUtilsCommonService.getValue('userRole');
          if (isEmpty(userRole) && !['/eula'].includes(this.router.url)) {
            this.router.navigate(['denied']);
          } else if (
            userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
            userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
            userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
          )
            this.router.navigate(['dft']);
          else if (userRole === dspConstants.Common.DSP_FACTORY_USER) this.router.navigate(['factoryuser']);
        }
      }
    });
    this.routeEvent(this.router);
    this.isFetchApiDataLoading = true;
  }

  initializeFeatureFlags() {
    this.dspCommonService.getFeatureFlagInfo().subscribe({
      next: (res: any) => {
        this.store.dispatch(getFeatureFlagInfo(res));
        this.isInternetOfflineMsgDisplay = res?.isInternetOfflineMsgDisplay ? res?.isInternetOfflineMsgDisplay : false;
        this.isTimeZoneSelectionEnabled = res?.isTimeZoneSelectionEnabled ? res?.isTimeZoneSelectionEnabled : false;
        this.isNumberConventionEnabled = res?.isNumberConventionEnabled ? res?.isNumberConventionEnabled : false;
        this.isTimeFormatEnabled = res?.isTimeFormatEnabled ? res.isTimeFormatEnabled : false;
        this.isMultiProductWorkFlow = res?.isMultiProductWorkFlow ? res.isMultiProductWorkFlow : false;
        this.isDisableManualDeviceRegTab = res?.isDisableManualDeviceRegTab ? res?.isDisableManualDeviceRegTab : false;
        this.isDeviceStatusEnableCustomAccess = res?.isDeviceStatusEnableCustomAccess
          ? res.isDeviceStatusEnableCustomAccess
          : false;
        this.initializeMultiProductFeatureFlags();
      },
    });
  }

  initializeMultiProductFeatureFlags() {
    if (this.isMultiProductWorkFlow) {
      this.dspCommonService.getMultiProductFeatureFlagInfo(this.dealerCode).subscribe({
        next: (res: any) => {
          const result = {
            data: res,
          };
          this.store.dispatch(getMultiProductFeatureFlagInfo(result));
        },
      });
    }
  }
  ngOnInit(): void {
    if ((window as any).dataLayer?.filter((element: any) => element.event === 'b2cloaded').length === 0) {
      const decodedAccessToken = fetchdecodedAccessToken();
      forkJoin([
        this.sha256(decodedAccessToken?.email_address?.toLowerCase()),
        this.sha256(decodedAccessToken?.catloginid?.toLowerCase()),
      ]).subscribe(([resHem, resLoginId]) => {
        ((window as any).dataLayer = (window as any).dataLayer || []).push({
          event: 'b2cloaded',
          companyID: decodedAccessToken?.ucid?.toLowerCase() || '',
          HEM: resHem,
          loginID: resLoginId,
          catRecId: decodedAccessToken?.catrecid?.toLowerCase() || '',
          affClass: decodedAccessToken?.catafltnclass?.toLowerCase() || '',
          affCode: decodedAccessToken?.catafltncode,
          affiliationOrgCode: decodedAccessToken?.catafltnorgcode?.toLowerCase() || '',
          affiliationOrgName: decodedAccessToken?.company?.toLowerCase() || '',
          B2CdealerCode: decodedAccessToken?.catafltnorgcode?.toLowerCase() || '',
          topLvlOrgCode: decodedAccessToken?.cattoplevelorgcode || '',
          B2CObjectID: decodedAccessToken?.aud || '',
          B2CUserType: decodedAccessToken?.catafltnclass?.toLowerCase() || '',
        });
      });
    }
    if (window.localStorage.getItem('access_token')) {
      this.initializeFeatureFlags();
      this.getFeatureDealerConfiguration();
      this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
      forkJoin([this.dspCommonService.getEula(), this.dspCommonService.getUserInfo()]).subscribe(([responseEula]) => {
        this.appInfo = responseEula;
        if (
          !this.appInfo.isEulaAccepted &&
          !['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)
        ) {
          sessionStorage.removeItem('tableSettings');
          this.router.navigate(['eula']);
        } else {
          // environment.redirectURL.b2c ? '' : this.checkNetworkStatus();
          this.checkNetworkStatus();
          const footerNavLink = this.sharedService.getNavigationValue();
          this.getDeviceSubscriptionCatalog();
          // // After changing the dealer enabling the eroutes based on the available dealers
          this.dealerService.updatedDealer.subscribe((res) => {
            /** This footerNavLink condition check is when click on legal,privacy and TOS its redirecting to
             * corrresponding page but the dashboarding page is refreshing again. To prevent the dashboard refresh added that condition */
            if (res && footerNavLink !== 'enableFooterComponent') {
              this.orderingMenu();
            }
          });
          // /** This footerNavLink condition check is when click on legal,privacy and TOS its redirecting to
          //  * corrresponding page but the dashboarding page is refreshing again. To prevent the dashboard refresh added that condition */
          if (footerNavLink !== 'enableFooterComponent') {
            this.todayTime = this.getTimeAsUTC();
            this.getTimeAsUTC();
            this.fetchDeviceFeaturesInformation();
            this.getListOfProducts();
            this.getLanguageTranslations();
            this.store.select('dsp').subscribe((dsp) => {
              this.dspStoreData = dsp;
              this.isWhatFixHelpEnabled = this.dspStoreData?.featureFlagInfo?.isWhatFixHelpEnabled
                ? this.dspStoreData?.featureFlagInfo?.isWhatFixHelpEnabled
                : false;
              this.isCcatSearch = this.dspStoreData?.featureFlagInfo?.isCcatSearch
                ? this.dspStoreData?.featureFlagInfo?.isCcatSearch
                : false;
            });
            this.store.dispatch(loadUserRequestAction());
            this.orderingMenu();
            this.isMenu$.subscribe();
          }
          if (footerNavLink === 'enableFooterComponent') {
            if (!['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)) {
              this.isFetchApiDataLoading = true;
              this.getFeatureDealerConfiguration();
              this.dspCommonService.getRoleMenus().subscribe({
                next: (responseList) => {
                  this.enableEroutesFeature();
                  this.setDefaultMenu(responseList);
                  this.menuOrderList = this.sortMenuOrder(responseList);
                  this.dspUtilsCommonService.setValue(JSON.stringify(this.menuOrderList), 'menuList');
                  this.dspCommonService.getUser().subscribe({
                    next: (response) => {
                      this.user = response;
                      this.userRole = this.user?.roleName;
                      this.updateDefaultPreferences();
                      this.dspUtilsCommonService.setValue(this.userRole, 'userRole');
                      if (this.user?.telUserType == dspConstants.Common.DEALER) {
                        this.isCatUser = false;
                      }
                      if (
                        this.user?.roleName === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
                        this.user?.roleName === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
                        this.user?.roleName === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
                      ) {
                        this.isShowSidenav = false;
                        this.isSideNavOpen = true;
                      } else if (this.user?.roleName === dspConstants.Common.DSP_FACTORY_USER) {
                        this.isShowSidenav = false;
                        if (!['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url))
                          this.router.navigate(['factoryuser']);
                      } else {
                        this.isShowSidenav = true;
                        this.isSideNavOpen = true;
                      }
                      this.getShortName(response);
                      this.store.dispatch(loadUserRequestAction());
                      this.loggedInUser();
                    },
                  });
                  this.isFetchApiDataLoading = false;
                },
              });
            } else {
              this.disableTopNavToggle = false;
              this.isFetchApiDataLoading = false;
            }
          }
        }
      });
      this.getReleaseDetails();
    }
  }
  getDeviceSubscriptionCatalog() {
    if (isEmpty(this.dspStoreData?.deviceCatalog)) {
      this.dspCommonService.getDeviceSubsciptionCatalogue({ dealerCode: this.dealerCode }).subscribe({
        next: (result: any) => {
          this.store.dispatch(setDeviceCatalogueInfo(result));
        },
        error: (error) => {
          console.log('Error occurred while retrieving device catalog info.');
        },
      });
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  getReleaseDetails() {
    this.configurationService.getReleaseDetails().subscribe({
      next: (result: any) => {
        if (!isEmpty(result?.releaseNotesModelResponse) && result?.showpopup) {
          const releaseNotes = result.releaseNotesModelResponse;

          if (releaseNotes.customTitle === dspConstants.Configuration.DEALER_SERVICES_PORTAL_NEW_RELEASE_TYPE) {
            const clickHereIndex = releaseNotes.customMessage.toLowerCase().indexOf('click here');
            if (clickHereIndex > -1 && releaseNotes.releasenoteLink.length > 10) {
              releaseNotes.customMessage = releaseNotes.customMessage.substr(0, clickHereIndex);
              releaseNotes.trustAsHtml = false;
            }
          } else if (releaseNotes.customTitle === dspConstants.Configuration.SCHEDULED_MAINTENANCE_TYPE) {
            let Message = releaseNotes.customMessage;
            Message = Message.replace('(Start Date/Time)', '<b>(' + releaseNotes.messageStartDate + ')</b>');
            Message = Message.replace('(End Date/Time)', '<b>(' + releaseNotes.messageEndDate + ')</b>');
            releaseNotes.customMessage = Message;
            releaseNotes.trustAsHtml = true;
          }
          let modalRef: any;
          setTimeout(() => {
            modalRef = this.modal.openModal(NotificationPopupComponent, {
              data: releaseNotes,
              type: 'semi-modal',
              width: '512px',
              isAutoHeightModalContent: true,
              hasBackdrop: true,
              closeOnEsc: true,
            });
          }, 5000);
        }
      },
      error: (err: any) => {},
    });
  }
  updateDefaultPreferences() {
    if (this.isTimeZoneSelectionEnabled) {
      this.defaultTimeZone = !isEmpty(this.user?.timeZone) ? this.user?.timeZone : dspConstants.Common.DEFAULT_TIMEZONE;
      this.userPreferenceService.updateDefaultTimeZone(this.defaultTimeZone);
      const timeZones = this.userPreferenceService.getTimeZonesValues();
      timeZones.map((timeZone: any) => {
        if (timeZone.value === this.defaultTimeZone) {
          this.userPreferenceService.updateSelectedTimeZone([timeZone]);
        }
      });
    }
    if (this.isNumberConventionEnabled) {
      this.defaultNumberFormat = !isEmpty(this.user?.numberFormat)
        ? this.user?.numberFormat
        : dspConstants.Common.DEFAULT_NUMBER_FORMAT;
      this.userPreferenceService.updateNumberFormat(this.defaultNumberFormat);
    }
    if (this.isTimeFormatEnabled) {
      this.defaultTimeFormat = !isEmpty(this.user?.timeFormat)
        ? this.user?.timeFormat
        : dspConstants.Common.DEFAULT_TIME_FORMAT;
      this.defaultDateFormat = !isEmpty(this.user?.dateFormat)
        ? this.user?.dateFormat
        : dspConstants.Common.DEFAULT_DATE_FORMAT;
      this.userPreferenceService.updateTimeFormat(this.defaultTimeFormat);
      this.userPreferenceService.updateDateformat(this.defaultDateFormat);
    }
  }
  loggedInUser() {
    this.store.pipe(select(loggedInUser)).subscribe((result: any) => {
      if (result?.user?.roleName) {
        if (!this.isApplicationDataLoading) {
          this.fetchApplicationData(result.user);
        }
      }
    });
  }
  private setHelpPopoverCondition(): void {
    const activeGroups = this.oneTrustService.getOneTrustActiveGroups();
    this.getFunctionalCookiesStatus(activeGroups);

    this.oneTrustService.OnConsentChanged().subscribe((consent) => {
      this.getFunctionalCookiesStatus(consent);
    });
  }

  private getFunctionalCookiesStatus(consentGroup: string): void {
    if (consentGroup.includes('C0003')) {
      (window as any)._wfx_cat_userRole = this.user?.roleName;
      this.ishelpIconPopover = false;
      const rootEnv = environment.env;
      const scriptPath = '.\\assets\\whatfix-scripts\\' + rootEnv + '-snippet.js';
      this.whatfixScriptService.loadScript(scriptPath);
    } else {
      this.ishelpIconPopover = true;
    }
  }
  public openCookieSettings(): void {
    const cookieDrawerLink = document.getElementsByClassName('ot-sdk-show-settings');
    if (cookieDrawerLink && cookieDrawerLink.length) (cookieDrawerLink[0] as HTMLElement).click();
    this.popoverDirective?.hide();
    this.ccmenu?.hide();
  }
  private rejectOneTrustIfFlaggedOff(): void {
    this.oneTrustService.onLoad().subscribe(() => {
      this.oneTrustService.RejectAll();
    });
  }
  getTimeZones() {
    this.userPreferenceService.getTimeZones().subscribe({
      next: (response: any) => {
        this.listOfTimeZones = response;
        const catTimeZones = this.userPreferenceService.generateCatTimeZones(this.listOfTimeZones);
        const tdDisplays: any = [];
        catTimeZones.forEach((catTimeZone: any, index: any) => {
          const tzAbbr = format(parseISO(catTimeZone.tzInstance), 'zzz', {
            timeZone: catTimeZone.timeZoneName,
          });
          const tzOffset = format(parseISO(catTimeZone.tzInstance), 'xx', {
            timeZone: catTimeZone.timeZoneName,
          });
          const tzLocalTime = tzAbbr?.includes('+')
            ? tzAbbr?.split('+')[0]
            : tzAbbr?.includes('-')
              ? tzAbbr?.split('-')[0]
              : tzAbbr;
          let tzCatTimeZone = catTimeZone.timeZoneValue;
          // if (catTimeZone.timeZoneValue.includes('UTC+') || catTimeZone.timeZoneValue.includes('UTC-')) {
          //   tzCatTimeZone = catTimeZone.timeZoneValue?.split('UTC');
          //   tzCatTimeZone = tzCatTimeZone[0] + tzCatTimeZone[1]?.slice(3)?.trim();
          // }
          const tzDisplay = `(UTC${tzOffset.substring(0, 3)}:${tzOffset.substring(3)}) ${tzAbbr} (${tzCatTimeZone.replace(/_/g, '')})`;
          tdDisplays.push({
            value: this.listOfTimeZones[index].key,
            label: tzDisplay,
            localTime: tzAbbr,
          });
        });
        this.formattedTimeZones = tdDisplays;
        this.userPreferenceService.setTimeZones(this.formattedTimeZones);
        this.updateDefaultPreferences();
      },
      error: (err: any) => {
        console.log('Error occurred while retrieving timeZone info.');
      },
    });
  }

  checkNetworkStatus() {
    let messageBarRef: any;
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        const config: MessageBarConfig = {
          hostType: 'overlay',
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 3000,
          appOverlayClass: 'connection-error',
          maxMessages: window.location.href.includes('deviceRegistration') ? 3 : 0,
        };

        this.dspCommonService.backToOnline.subscribe((res: any) => {
          if (res) {
            this.backOnline = res;
          } else {
            this.backOnline = false;
          }
        });
        if (status && this.backOnline) {
          if (this.isInternetOfflineMsgDisplay) {
            messageBarRef.dismiss();
            document
              .querySelectorAll('.cdk-overlay-pane')
              .forEach((element) => element.classList.remove('connection-error'));
            delete config['appOverlayClass'];
            this.messageBar.open('You are back online ', 'success', undefined, config, true);
            this.loaderService.hide();
            this.dspCommonService.backToOnline.next(false);
          }
          // this.oAuthService.loadDiscoveryDocumentAndTryLogin();
        } else if (!status && !this.backOnline) {
          delete config['duration'];
          this.dspCommonService.backToOnline.next(true);
          if (this.isInternetOfflineMsgDisplay) {
            document.querySelectorAll('.cdk-overlay-pane').forEach((element) => {
              if (
                !element.classList.contains('cc-message-overlay-wrapper') &&
                !element.classList.contains('cc-menu') &&
                !element.classList.contains('cc-dropdown-overlay-wrapper') &&
                !element.classList.contains('cc-modal-panel') &&
                !element.classList.contains('cc-container-overlay-wrapper')
              )
                element.classList.add('connection-error');
            });
            messageBarRef = this.messageBar.open(
              "We've lost our connection to you. Trying to reconnect... ",
              'error',
              undefined,
              config,
              false
            );
            this.loaderService.hide();
          }
        }
        this.networkStatus = status;
      });
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('isNewWindowOpened') && sessionStorage.getItem('isNewWindowOpened') != null) {
      const reURL = sessionStorage.getItem('isNewWindowOpened') as string;
      if (['/legalModal', '/privacyModal', '/termSaleModal'].includes(reURL)) {
        this.router.navigate([reURL]);
      }
    }
    setTimeout(() => {
      this.setHelpPopoverCondition();
      this.menuOrderList.filter((element: any) => element.pathName === 'manage')[0].activeClass = 'manage-closed';
    }, 7000);
  }
  toLowerCase(str: any) {
    return str?.toLowerCase();
  }
  sha256 = async (message: any) => {
    // hash the message
    const hashBuffer = await crypto?.subtle?.digest('SHA-256', new TextEncoder().encode(message));
    // convert bytes to hex string
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  };
  orderingMenu() {
    this.store.pipe(select(loggedInUser)).subscribe((result: any) => {
      if (result.user?.roleName) {
        if (!this.isApplicationDataLoading) {
          this.fetchApplicationData(result.user);
          if (result.user.roleName === dspConstants.Common.DSP_CUSTOM_ACCESS && this.isDeviceStatusEnableCustomAccess) {
            this.getDeviceStatusForCustomAccess(result.user);
          }
        }
      }
      const res = result.user;
      this.userRole = res.roleName;
      this.thirdPartyApiCall();

      this.dspUtilsCommonService.setValue(this.userRole, 'userRole');
      if (res.telUserType == dspConstants.Common.DEALER) {
        this.isCatUser = false;
      }
      if (!['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)) {
        if (
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
        ) {
          this.isShowSidenav = false;
          this.isSideNavOpen = true;
          this.showHelpIcon = true;
          this.router.navigate(['dft']);
        } else if (this.userRole === dspConstants.Common.DSP_FACTORY_USER) {
          this.isShowSidenav = false;
          this.isSideNavOpen = true;
          this.showHelpIcon = false;
          this.router.navigate(['factoryuser']);
        } else if (result.menu.length && this.userRole) {
          this.isShowSidenav = true;
          this.isSideNavOpen = true;
          this.enableEroutesFeature();
          this.setDefaultMenu(result.menu);
          this.menuOrderListApi = this.sortMenuOrder(result.menu);

          if (!this.isDefaultFound) {
            this.isDefaultFound = true;
            result.menu[0].isDefault = true;
            this.selectedPath = result.menu[0].path;
            this.logoUrl = result.menu[0].path;
            if (
              this.userRole.toLocaleLowerCase() === dspConstants.Common.DSP_CUSTOM_ACCESS.toLocaleLowerCase() &&
              result.menu[0].children[0]
            ) {
              this.isDefaultFound = true;
              result.menu[0].children[0].isDefault = true;
              this.selectedPath = result.menu[0].children[0].path;
              this.logoUrl = result.menu[0].children[0].path;
            }
          }
          this.menuOrderList = this.sortMenuOrder(result.menu);
          sessionStorage.setItem('menuList', JSON.stringify(this.menuOrderList));

          this.dspUtilsCommonService.setValue(JSON.stringify(this.menuOrderList), 'menuList');
        }
      }
      this.getShortName(res);
      this.user = res;
      this.store.dispatch(getUserDetails(this.user));
      if (!isEmpty(this.user) && !this.isGetTimeZoneCalled) {
        this.isGetTimeZoneCalled = true;
        this.getTimeZones();
      }
    });
  }
  thirdPartyApiCall(): void {
    if (this.isCcatSearch) {
      let thirdPartyApi = window.localStorage.getItem('thirdPartyApi');
      const queryString = thirdPartyApi?.split('?')[1]; // Get the part after '?'
      if (queryString) {
        const params = queryString.split('&'); // Split parameters by '&'
        params.forEach((param: any) => {
          const [key, value] = param.split('='); // Split key and value by '='
          if (key === 'make') {
            this.make = decodeURIComponent(value);
          } else if (key === 'serialNumber') {
            this.serialNumber = decodeURIComponent(value);
          }
        });
      }

      if (this.make && this.serialNumber && this.userRole) {
        this.assetService.setThirdPartySerialNumber(this.serialNumber);
        this.assetService.setThirdPartymake(this.make);
        if (
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
          this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
        ) {
          this.router.navigate(['/dft']);
        }
        if (this.userRole === dspConstants.Common.DSP_ADMINISTRATOR) {
          this.router.navigate(['/asset']);
        }
        if (this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS) {
          this.router.navigate(['/asset']);
        }
      }
    }
  }

  enableEroutesFeature() {
    this.enableEroutes = false;
    let dealerSubfeatureList: any;
    this.store.select('dsp').subscribe((dsp) => {
      dealerSubfeatureList = dsp.dealerSubfeature || [];
      delete dealerSubfeatureList.type;
      dealerSubfeatureList = Object.values(dealerSubfeatureList);
    });
    dealerSubfeatureList.forEach((dealer: any) => {
      if (dealer['dealerCode'] === this.dealerCode && dealer['subFeatures'].length > 0) {
        this.enableEroutes = true;
      }
    });
  }
  showOrganization() {
    if (
      this.userRole == dspConstants.Common.DSP_FACTORY_USER ||
      ((this.userRole == dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
        this.userRole == dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
        this.userRole == dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED) &&
        !this.isCatUser)
    ) {
      return false;
    }
    return true;
  }
  routeEvent(router: Router) {
    router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((_e: any) => {
      const path = window.location.pathname.split('/');
      this.selectedPath = path[path.length - 1];
      this.removeActiveHighlight(this.menuOrderList, this.selectedPath);
    });
  }
  removeActiveHighlight(menuArr: any, selectedPath: string) {
    menuArr.map((item: any) => {
      if (item.activeClass === 'active-highlight') item.activeClass = '';
      if (selectedPath === item.pathName) item.activeClass = 'active-highlight';

      if (item.children && item.children.length > 0) {
        this.removeActiveHighlight(item.children, selectedPath);
      }
    });
  }
  fetchApplicationData(userData: any) {
    this.isApplicationDataLoading = true;

    if (isEmpty(this.dspStoreData?.userActiveFeatures)) {
      const userActiveFeaturesReqObj = {
        catrecId: '',
        roleName: userData.roleName,
        appName: 'Dealer Services Portal',
        profileName: userData.telUserType,
        dealerCode: this.dealerCode,
      };
      this.dspCommonService.getUserActiveFeatures(userActiveFeaturesReqObj).subscribe({
        next: (response) => {
          this.isApplicationDataLoading = false;
          this.store.dispatch(userActiveFeatures(response));
        },
        error: (error) => {
          this.isApplicationDataLoading = false;
          console.log('Error occurred while retrieving User Active Features.');
        },
      });
    }
    if (isEmpty(this.dspStoreData?.dspConfig)) {
      this.dspCommonService.getDSPConfig({ dealerCode: this.dealerCode }).subscribe({
        next: (response: any) => {
          this.store.dispatch(getDSPConfig(response));
          /*** switch the targeted dealers to interim DSP url */
          if (response.isInterIMValidation === 'true') {
            this.store.pipe(select(loggedInUser)).subscribe((result: any) => {
              if (result?.user?.federationIdentifier) {
                this.catrecId = result.user.federationIdentifier;
                const params = { catrecId: this.catrecId };
                this.dspCommonService.getInterimUserStatus(params).subscribe(
                  (res: any) => {
                    this.isFetchApiDataLoading = false;
                    if (!res.isActive) {
                      this.router.navigate(['denied']);
                    }
                  },
                  (error) => {
                    this.router.navigate(['denied']);
                  }
                );
              }
            });
          } else {
            this.isFetchApiDataLoading = false;
          }
        },
      });
    }
  }

  getDeviceStatusForCustomAccess(userData: any) {
    this.isApplicationDataLoading = true;
    if (isEmpty(this.dspStoreData?.userActiveFeatures)) {
      const deviceStatusReqObj = {
        catrecId: '',
        roleName: userData.roleName,
        appName: 'Dealer Services Portal',
        profileName: userData.telUserType,
        dealerCode: this.dealerCode,
      };
      this.dspCommonService.getDeviceStatusForCustomAccess(deviceStatusReqObj).subscribe({
        next: (response) => {
          this.dspCommonService.isCustomAccessEnabled.next(response);
        },
        error: (error) => {},
      });
    }
  }

  fetchDeviceFeaturesInformation() {
    if (isEmpty(this.dspStoreData?.deviceFeaturesInfo)) {
      this.dspCommonService.getDeviceFeaturesInfomation().subscribe({
        next: (response: any) => {
          //this.isFetchApiDataLoading = false;
          this.store.dispatch(getDeviceFeaturesInfo(response));
        },
        error: (error: any) => {
          this.isFetchApiDataLoading = false;
        },
      });
    }
  }

  getListOfProducts() {
    this.assetDrawerService.getListOfProduct().subscribe({
      next: (result: any) => {
        // this.selectedRowData.products = result?.products || [];
        if (result?.products?.length) {
          this.store.dispatch(getListOfProducts(result));
        }
      },
      error: (err: any) => {},
    });
  }

  getMinestarServiceDetails() {
    if (isEmpty(this.dspStoreData?.minestarServiceDetails)) {
      this.siteService
        .getMineStar({
          organization: this.dealerCode,
          organizationType: 'Dealer',
        })
        .subscribe((res) => {
          this.store.dispatch(getMinestarServiceDetails(res));
        });
    }
  }

  setDefaultMenu(menu: any) {
    for (const menuObj of menu) {
      if (menuObj.isDefault && !['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)) {
        if (this.router.url === '/') {
          this.isDefaultFound = true;
          this.logoUrl = menuObj.path;
          this.selectedPath = menuObj.path;
          //this.selectedParentNode.path = `/${menuObj.path}`;
          //this.selectedParentNode.pathName = menuObj.path;
          menuObj.activeClass = 'active-highlight';
        }
        //this.router.navigate([menuObj.path]);
        return;
      } else if (menuObj.children && menuObj.children.length) {
        //this.selectedParentNode = menuObj;
        this.setDefaultMenu(menuObj.children);
      } else if (['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)) {
        this.disableTopNavToggle = false;
      }
    }
  }
  navToggle(_flag: boolean) {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
  menuChange({ event }: any) {
    if (
      event.target?.className.indexOf('change-account-label') === -1 ||
      event.currentTarget?.className.indexOf('change-account-label') === -1
    ) {
      this.patchState((state) => ({
        isMenuEnabled: !state.isMenuEnabled,
      }));
    }
  }
  showMenu() {
    this.patchState((state) => ({
      isMenuEnabled: !state.isMenuEnabled,
    }));
  }

  signOut() {
    this.authService.logoutSession();
  }
  getTimeAsUTC() {
    const todayDate = new Date().toUTCString();
    const timeofDay = todayDate.substring(17, 26);
    return timeofDay;
  }
  fullName(user: any) {
    const nameArray = [user.firstName, user.lastName];
    return nameArray.join(' ');
  }
  getShortName(user: any) {
    (window as any)._wfx_cat_userIdentifier = user.federationIdentifier;
    (window as any)._wfx_cat_partyType = user.telUserType;
    (window as any)._wfx_cat_userRole = user.roleName;
    this.shortName = this.fullName(user)
      .split(' ')
      .map((n, i) => {
        if (i <= 1) {
          return n[0];
        }
        return '';
      })
      .join('');
  }
  navLinkClicked(node: any) {
    this.router.navigate([node.currentNode.pathName]);
    this.selectedPath = node.currentNode.pathName;
  }
  navLinkExpanded(node: any) {
    if (node.currentNode.pathName === 'asset') {
      this.router.navigate([node.currentNode.pathName]);
      this.selectedPath = node.currentNode.path;
    }
    if (node.currentNode.pathName === 'manage') {
      if (node.currentNodeOpen) node.currentNode.activeClass = 'navlink-expanded manage-opened';
      else node.currentNode.activeClass = 'manage-closed';
    }
  }
  OrganizationAction() {
    const modalRef = this.modal.openModal(OrganizationComponent, {
      type: 'semi-modal',
      width: '512px',

      data: {
        title: 'Organization',
        isDealer: !this.isCatUser,
      },
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
      disableBackdropClick: true,
    });
    modalRef.afterClosed().subscribe((_result: any) => {});
  }
  userProfile() {
    const modalRef = this.modal.openModal(UserProfileComponent, {
      type: 'semi-modal',
      width: '512px',

      data: {
        title: 'Profile',
        shortName: this.shortName,
        userInfo: this.user,
        landingPages: this.menuOrderListApi,
      },
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
      disableBackdropClick: true,
    });
    modalRef.afterClosed().subscribe((result: any) => {});
  }
  userPreference() {
    this.dspCommonService.getRoleMenus().subscribe({
      next: (responseList) => {
        const modalRef = this.modal.openModal(UserPreferenceComponent, {
          type: this.isTimeZoneSelectionEnabled === true ? 'full-modal' : 'semi-modal',
          width: this.isTimeZoneSelectionEnabled === true ? '100%' : '512px',
          backdropClass: 'cc-modal-full-window-backdrop',
          panelClass: 'cc-modal-full-window',
          data: {
            title: 'User Preferences',
            shortName: this.shortName,
            userInfo: this.user,
            listOfTimeZones: this.userPreferenceService.getTimeZonesValues(),
            landingPages: responseList,
          },
          hasBackdrop: true,
          closeOnEsc: false,
          hideHeader: false,
          hideFooter: false,
          disableMargin: this.isTimeZoneSelectionEnabled === true ? false : true,
          disableBackdropClick: true,
        });
        modalRef.afterClosed().subscribe((result: any) => {
          this.selectedPath = result;
          if (this.selectedPath === '/asset' && result) {
            const element: HTMLElement = document.getElementsByClassName('navitem')[2] as HTMLElement;
            element?.click();
          }
        });
      },
    });
  }
  sortMenuOrder(menuArr: any) {
    let menuOrder = this.sortOrder(menuArr);
    menuOrder = menuOrder.map((item: any) => {
      item.islazyLoaded = true;
      item.id = item.path;
      if (item.disabled) {
        item.disabled = false;
      }
      if (item.path === 'deviceRegistration') item.path = `/asset/${item.path}`;
      if (!item.path.startsWith('/')) item.path = `/${item.path}`;

      if (
        (this.selectedPath === 'asset' && item.pathName === 'manage') ||
        (this.selectedPath === 'asset' && item.pathName === 'asset')
      )
        item.activeClass = 'active-highlight';
      if (item.children && item.children.length > 0) {
        item.children = this.sortMenuOrder(item.children);
        // Hardcoded values for Manage device registration menu will removed once added in Api.
        item.children.forEach((menuItem: any) => {
          if (menuItem.name === 'Assets' && !this.enableEroutes) {
            menuItem.children = [];
          } else if (menuItem.name === 'Assets' && this.enableEroutes) {
            menuItem.children = [
              {
                name: 'Manual Device Registration',
                pathName: 'asset/deviceRegistration',
                component: 'typeof ManageDeviceRegistrationComponent',
                isDefault: false,
                path: '/asset/deviceRegistration',
                iconText: '',
                fontSet: 'icomoon',
                activeClass: '',
                level: 2,
                maxLength: 20,
                disabled: false,
                menuId: 1,
                displayOrder: 1,
                children: [],
                id: 'deviceRegistration',
              },
            ];
            if (this.isDisableManualDeviceRegTab) {
              menuItem.children = [];
            }
          }
        });
      }

      return item;
    });
    return menuOrder;
  }
  sortOrder(menuArr: any) {
    const sortArr = JSON.parse(JSON.stringify(menuArr));
    sortArr.sort((itemA: any, itemB: any) => {
      return itemA.displayOrder - itemB.displayOrder;
    });
    return sortArr;
  }
  onLogoClick() {
    const href = window.location.href;
    if (href.includes('legalModal') || href.includes('privacyModal') || href.includes('termSaleModal')) {
      return;
    }
    if (
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
    ) {
      this.isShowSidenav = false;
      this.isSideNavOpen = true;
      sessionStorage.removeItem('dateFilters');
      sessionStorage.removeItem('selectedFIlters');
      sessionStorage.removeItem('filtersDetails');
      this.dspCommonService.removeDateFiltersFromSessionStorage();

      this.router.navigate(['dft']);
    } else if (this.userRole === dspConstants.Common.DSP_FACTORY_USER) {
      this.isShowSidenav = false;
      this.isSideNavOpen = true;
      sessionStorage.removeItem('selectedFIlters');
      sessionStorage.removeItem('filtersDetails');
      this.dspCommonService.removeDateFiltersFromSessionStorage();
      this.router.navigate(['factoryuser']);
    } else {
      this.isShowSidenav = true;
      this.isSideNavOpen = true;
      this.router.navigate([this.logoUrl === 'manage' ? '/' : this.logoUrl]);
    }
  }

  getLanguageTranslations() {
    if (isEmpty(this.dspStoreData?.languageTranslations)) {
      const languageTranslationPayload = {
        pageNameList: [
          'AssetPopulation',
          'CatLevelServices',
          'Common',
          'CustomerWorklist',
          'Dashboard',
          'FactoryUser',
          'Minestar',
          'Shared',
          'WifiConfig',
          'Worklist',
        ],
        language: 'en',
      };
      let languageTranslationData = {};
      this.dspCommonService.getLanguageTranslations(languageTranslationPayload).subscribe({
        next: (result: any) => {
          languageTranslationData = result ? result : {};
          this.store.dispatch(getLanguageTranslations(languageTranslationData));
        },
        error: (error) => {
          this.store.dispatch(getLanguageTranslations(languageTranslationData));
          console.log('Error in fetching language translation');
        },
      });
    }
  }

  getFeatureDealerConfiguration() {
    let delaerSubFeatureList;
    this.dspCommonService.getFeatureDealerConfiguration().subscribe({
      next: (result: any) => {
        delaerSubFeatureList = result ? result : [];
        this.store.dispatch(getDealerSubFeature(delaerSubFeatureList));
        this.enableEroutesFeature();
        this.menuOrderList = this.sortMenuOrder(this.menuOrderList);
      },
    });
  }
  userPrefRoleCheck() {
    if (this.isTimeZoneSelectionEnabled) {
      return this.userRole !== dspConstants.Common.USER_ADMINISTRATOR;
    } else {
      return (
        this.userRole !== dspConstants.Common.DSP_FACTORY_USER &&
        this.userRole !== dspConstants.Common.DEALER_FIELD_TECHNICIAN &&
        this.userRole !== dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC &&
        this.userRole !== dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
      );
    }
  }
  handleEllipsis() {
    let clipboardText = window.getSelection()?.toString();
    if (typeof this.selectedPath === 'string' && this.selectedPath.includes('deviceRegistration')) {
      return 'Manual Device Registration';
    } else {
      return clipboardText;
    }
  }
  helpCenterNavigate() {
    this.ccmenu?.hide();
    window.open(environment.redirectURL.helpDocUrl, '_blank');
  }
  onHelpIconClick(close: boolean) {
    if (!close) {
      this.ccmenu?.hide();
    }
  }
}
