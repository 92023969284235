import { forEach } from '@angular-devkit/schematics';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { isEqual, cloneDeep } from 'lodash-es';
import { HomeService } from '../services/home.service';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import * as dspConstants from '../../../shared/dspConstants';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { AssetService } from '../../assets/services/asset.service';
@Component({
  selector: 'dsp-next-gen-ui-home-recently-viewed',
  templateUrl: './home-recently-viewed.component.html',
  styleUrls: ['./home-recently-viewed.component.scss'],
})
export class HomeRecentlyViewedComponent implements OnInit {
  popOptionArray: any = [];
  index: any = 3;
  assetDetailsResponse: any;
  assetDetails: any = [];
  dealer: any;
  dealerCode: any;
  isSecondRowLoading = false;
  assetIds: number[] = [];
  featureSupportMapping: any;
  dspStoreData: any;
  queueReasonList: any;
  isRvAssets = false;
  constructor(
    private homeService: HomeService,
    private messageBar: MessageBar,
    private assetDrawerService: AssetDrawerService,
    private store: Store<DSPAppState>,
    private assetService: AssetService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.featureSupportMapping = this.dspStoreData.featureSupportMapping;
      this.queueReasonList = dsp.dspConfig?.Queue_Reason_List ? JSON.parse(dsp.dspConfig.Queue_Reason_List) : [];
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.dealerCode = this.dealer?.dealerCode || 'TD00';
    this.getRecentlyViewAssets();
  }
  refreshAssetsData() {
    this.isRvAssets = false;
    this.getRecentlyViewAssets();
  }
  getRecentlyViewAssets() {
    this.assetDetailsResponse = [];
    this.assetDetails = [];
    this.popOptionArray = [];
    this.index = 3;
    this.homeService.getRecentlyViewAssets({ loggedinDealerCode: this.dealerCode }).subscribe({
      next: (assets: any) => {
        this.isRvAssets = true;
        this.assetDetailsResponse = cloneDeep(assets);
        this.getAssetsApplicableMenus();
        this.assetDetailsResponse.forEach((data: any, i: any) => {
          data.appId = data?.assetGuid;
          if (i <= 2) {
            this.assetDetails.push(data);
          }
        });
      },
      error: (err: any) => {
        this.assetDetailsResponse = [];
        this.assetDetails = [];
        this.isRvAssets = true;
      },
    });
  }

  getAssetsApplicableMenus() {
    this.isSecondRowLoading = true;
    if (this.assetDetailsResponse.length > 0) {
      this.assetIds = this.assetDetailsResponse?.map((asset: { assetId: any }) => asset.assetId);
      const payload = {
        loggedInDealerCode: this.dealerCode,
        assetIds: this.assetIds,
      };
      this.homeService.checkValidOwnership(payload).subscribe({
        next: (result: any) => {
          this.assetDetailsResponse?.forEach((asset: any) => {
            const assetId = asset.assetId.toString();
            if (result[assetId]) {
              this.getSupportedServices(asset, result[assetId]);
              this.fetchDealerDetails(asset, assetId);
            }
          });
        },
        error: (_err: any) => {
          const messageText = 'There was an error processing your request.Please contact Cat Digital Support';
          this.showToastMessage(messageText, 'error');
        },
      });
    }
  }
  getSupportedServices(asset: any, validOwnershipRes: any) {
    const supportedServicesPayload = { guiID: asset?.appId || '' };
    this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
      next: (result: any) => {
        let data: any[] = [];
        if (result?.length > 0) {
          asset.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
          result?.forEach((val: any) => {
            if (this.featureSupportMapping && this.featureSupportMapping[val]) {
              data = data.concat(this.featureSupportMapping[val]);
            }
          });
        }
        asset.getSupportedServicesList = data;
        this.homeService.getScrollableMenu(asset, validOwnershipRes);
      },
      error: (err: any) => {
        this.homeService.getScrollableMenu(asset, validOwnershipRes);
      },
    });
  }
  fetchDealerDetails(asset: any, assetId: any) {
    const dealerInfoPayload = {
      assetIds: [assetId] || [],
      dealerCode: this.dealer?.dealerCode || '',
    };
    this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
      next: (result: any) => {
        if (result) {
          asset.dealerInfo = result;
          asset.isCustRequestedSubscription =
            this.assetService.checkIfReasonListExists(this.queueReasonList, result[assetId]) || false;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  validatePrevBtn() {
    if (this.popOptionArray.length === 0) {
      return true;
    }
    return false;
  }
  validateNextBtn() {
    if (this.assetDetailsResponse.length <= 3 || this.assetDetailsResponse.length === this.index) {
      return true;
    }
    return false;
  }
  previous() {
    this.index--;
    this.assetDetails.unshift(this.popOptionArray[0]);
    this.assetDetails.pop();
    this.popOptionArray.shift();
  }
  next() {
    this.assetDetails.push(this.assetDetailsResponse[this.index]);
    this.index++;
    let popItem: any = this.assetDetails.shift();
    this.popOptionArray.unshift(popItem);
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
