<div class="payment-select">
  <dsp-next-gen-ui-asset-details-card
    [rowData]="rowData"
    [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
    [showDealerInfo]="true"
    [subsPricingEligible]="subsPricingEligible"
    [hideBillToParty]="true"
  ></dsp-next-gen-ui-asset-details-card>
  <div *ngIf="loader">
    <div class="spin-align">
      <div class="spinner"></div>
    </div>
    <div class="spin-text">
      <span>One moment. We are calculating your<br />plan charges...</span>
    </div>
  </div>

  <div *ngIf="!loader" id="paymentPreview">
    <div
      *ngIf="productLength && productLength > 1"
      class="advance-search-wrapper"
      (click)="toggle(expandDropdown); (expandDropdown ? true : false)"
    >
      <label class="adv-search-label">{{ accordionLabel }}</label>
      <div>
        <span [class]="accordionLabel === 'Collapse All' ? 'pointer up' : 'pointer down'"></span>
      </div>
    </div>
    <br />
    <div *ngIf="rowData.reportingState === 'Subscribed'">
      <div class="section-line">
        <span class="section-heading">
          CURRENT
          <!--Padding is optional-->
        </span>
      </div>
      <br />
      <div *ngFor="let product of keys('current')">
        <cc-accordion>
          <cc-expandable
            [expanded]="this.expanDropDown.previous[product]"
            (opened)="closeService(product, 'current', 'open')"
            (closed)="closeService(product, 'current', 'close')"
          >
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">
                  {{ this.selectedAssetSubscriptionFormData.products[product].service.previousService }}
                </span>
              </div>
            </cc-expandable-header>
            <div class="rowTbl">
              <div class="Table">
                <div class="Row" *ngIf="hideBillToParty">
                  <div class="Cell">
                    <span class="asset-details-title"> Bill to Party: </span>
                    <span class="asset-details-value">{{ currentPaymentDetails[product]?.billToParty || '-' }}</span>
                  </div>
                </div>
                <div class="Row rowHead">
                  <div class="CellHead">
                    <div class="headerCell">Plan</div>
                  </div>
                  <div class="CellHead" *ngIf="previousProductFamily[product]">
                    <div class="headerCell">Product Family</div>
                  </div>
                  <div class="CellHead">
                    <div
                      class="headerCell"
                      [class.align-promotion-header]="!isPreviousProductVision[product] === true"
                      [class.align-text]="!isPreviousProductVision[product] === true"
                    >
                      Promotion Expiration Date
                    </div>
                  </div>
                  <div class="CellHead" *ngIf="isPreviousProductVision[product]">
                    <div class="headerCell">Contract Start Date</div>
                  </div>
                  <div class="CellHead" *ngIf="isPreviousProductVision[product]">
                    <div class="headerCell">Contract End Date</div>
                  </div>
                  <div class="CellHead priceCell">
                    <div class="headerCell">Price</div>
                  </div>
                </div>
                <div
                  class="Row"
                  *ngFor="
                    let details of previousPaymentDetails[product]?.current?.products.slice(0, 2) || [];
                    let i = index
                  "
                >
                  <div class="Cell">
                    <div class="total-plan">{{ details.name }}</div>
                  </div>
                  <div class="Cell" *ngIf="previousProductFamily[product] && i === 1">
                    <div class="total-plan1">{{ previousProductFamily[product] }}</div>
                  </div>
                  <div
                    class="Cell"
                    [class.align-text]="!isPreviousProductVision[product] === true"
                    [class.align-promotion-text]="
                      !isPreviousProductVision[product] === true && details.promotionEndDate
                    "
                  >
                    <div>{{ getConvertedDate(details.promotionEndDate) }}</div>
                  </div>
                  <div class="Cell" *ngIf="isPreviousProductVision[product]">
                    <div class="total-plan">{{ getConvertedDate(details.contractStartDate) }}</div>
                  </div>
                  <div class="Cell" *ngIf="isPreviousProductVision[product]">
                    <div class="total-plan">{{ getConvertedDate(details.contractEndDate) }}</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">
                      {{ displayAmount(details.amount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="previousPaymentDetails[product]?.current?.products.length > 2" class="Cell">
                <a
                  class="link total-plan-link"
                  (click)="openDrawer(previousPaymentDetails[product], 'previousPayment', product)"
                >
                  See more
                </a>
              </div>
              <div class="Table" *ngIf="previousPaymentDetails[product]?.current?.promotions">
                <div
                  class="Row promotion-apply"
                  *ngFor="let details of previousPaymentDetails[product]?.current?.promotions"
                >
                  <div class="Cell">
                    <div class="total-plan">{{ details?.name }}</div>
                  </div>
                  <div
                    class="Cell"
                    [class.align-text]="!isPreviousProductVision[product] === true"
                    [class.align-promotion-text]="
                      !isPreviousProductVision[product] === true && details.promotionEndDate
                    "
                  >
                    <div [ngClass]="previousProductFamily[product] !== '' ? 'align-date' : 'total-plan'">
                      {{ getConvertedDate(details?.promotionEndDate) }}
                    </div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">
                      {{ displayAmount(details.amount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="Table" *ngIf="previousPaymentDetails[product]?.current">
                <div class="Row cancel-plan-total">
                  <div class="Cell">
                    <div class="est-total">Estimated Total</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-price">
                      {{ displayAmount(previousPaymentDetails[product]?.current?.monthlyRateAfterDiscount) }}
                      <span
                        *ngIf="
                          !previousPaymentDetails[product].prepay &&
                          displayAmount(previousPaymentDetails[product]?.current?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Monthly)
                      </span>
                      <span
                        *ngIf="
                          previousPaymentDetails[product].prepay &&
                          displayAmount(previousPaymentDetails[product]?.current?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Prepaid)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </cc-expandable>
        </cc-accordion>
      </div>
    </div>
    <div>
      <div class="section-line" *ngIf="rowData.reportingState === 'Subscribed'">
        <span class="section-heading heading-new">
          NEW
          <!--Padding is optional-->
        </span>
      </div>
      <br />
      <div *ngFor="let product of keys('new')">
        <cc-accordion>
          <cc-expandable
            [expanded]="this.expanDropDown.new[product]"
            (opened)="closeService(product, 'new', 'open')"
            (closed)="closeService(product, 'new', 'close')"
          >
            <cc-expandable-header>
              <div style="display: flex">
                <div
                  class="expandable-header-title cc-col cc-lg-col--11 cc-md-col--11 cc-xl-col--11 cc-sm-col--11 cc-xs-col--11"
                >
                  <span class="title-display">
                    {{ this.selectedAssetSubscriptionFormData.products[product].service.currentService }}</span
                  >
                </div>
                <div
                  style="margin: 0px !important; align-content: center"
                  class="cc-col cc-lg-col--1 cc-md-col--1 cc-xl-col--1 cc-sm-col--1 cc-xs-col--1"
                >
                  <cc-status-indicator
                    [type]="'rectangle'"
                    [statusType]="'success'"
                    *ngIf="this.rowData?.reportingState == 'Subscribed' && addNewOrUpdate(product)"
                  >
                    <span class="status-update">{{ addNewOrUpdate(product) }}</span>
                  </cc-status-indicator>
                </div>
              </div>
            </cc-expandable-header>
            <div class="rowTbl">
              <div class="Table">
                <div class="Row" *ngIf="hideBillToParty">
                  <div class="Cell">
                    <span class="asset-details-title"> Bill to Party: </span
                    ><span class="asset-details-value">{{ currentPaymentDetails[product]?.billToParty || '-' }}</span>
                  </div>
                </div>
                <div class="Row rowHead">
                  <div class="CellHead">
                    <div class="headerCell">Plan</div>
                  </div>
                  <div class="CellHead" *ngIf="currentProductFamily[product]">
                    <div class="headerCell">Product Family</div>
                  </div>
                  <div class="CellHead">
                    <div
                      class="headerCell"
                      [class.align-promotion-header]="!isPreviousProductVision[product] === true"
                      [class.align-text]="!isPreviousProductVision[product] === true"
                    >
                      Promotion Expiration Date
                    </div>
                  </div>
                  <div class="CellHead" *ngIf="isCurrentProductVision[product]">
                    <div class="headerCell">Contract Start Date</div>
                  </div>
                  <div class="CellHead" *ngIf="isCurrentProductVision[product]">
                    <div class="headerCell">Contract End Date</div>
                  </div>
                  <div class="CellHead priceCell">
                    <div class="headerCell">Price</div>
                  </div>
                </div>
                <div
                  class="Row"
                  *ngFor="let details of currentPaymentDetails[product]?.new?.products.slice(0, 2) || []; let i = index"
                >
                  <div class="Cell">
                    <div class="total-plan">{{ details.name }}</div>
                  </div>
                  <div class="Cell" *ngIf="currentProductFamily[product] && i === 1">
                    <div class="total-plan1">{{ currentProductFamily[product] }}</div>
                  </div>

                  <div
                    class="Cell"
                    [class.align-text]="!isPreviousProductVision[product] === true"
                    [class.align-promotion-text]="
                      !isPreviousProductVision[product] === true && details.promotionEndDate
                    "
                  >
                    <div>{{ getConvertedDate(details.promotionEndDate) }}</div>
                  </div>
                  <div class="Cell" *ngIf="isCurrentProductVision[product]">
                    <div class="total-plan">{{ getConvertedDate(details.contractStartDate) }}</div>
                  </div>
                  <div class="Cell" *ngIf="isCurrentProductVision[product]">
                    <div class="total-plan">{{ getConvertedDate(details.contractEndDate) }}</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">
                      {{ displayAmount(details.amount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="currentPaymentDetails[product]?.new?.products.length > 2" class="Cell">
                <a
                  class="link total-plan-link"
                  (click)="openDrawer(currentPaymentDetails[product], 'currentPayment', product)"
                >
                  See more
                </a>
              </div>
              <div class="Table" *ngIf="currentPaymentDetails[product]?.new?.promotions">
                <div
                  class="Row promotion-apply"
                  *ngFor="let details of currentPaymentDetails[product]?.new?.promotions"
                >
                  <div class="Cell">
                    <div class="total-plan">{{ details?.name }}</div>
                  </div>
                  <div
                    class="Cell"
                    [class.align-text]="!isCurrentProductVision[product] === true"
                    [class.align-promotion-text]="!isCurrentProductVision[product] === true && details.promotionEndDate"
                  >
                    <div div [ngClass]="currentProductFamily[product] !== '' ? 'align-date' : 'total-plan'">
                      {{ getConvertedDate(details?.promotionEndDate) }}
                    </div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">
                      {{ displayAmount(details.amount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="Table" *ngIf="currentPaymentDetails[product]?.new">
                <div class="Row cancel-plan-total">
                  <div class="Cell">
                    <div class="est-total">Estimated Total</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-price">
                      {{ displayAmount(currentPaymentDetails[product]?.new?.monthlyRateAfterDiscount) }}
                      <span
                        *ngIf="
                          !currentPaymentDetails[product].prepay &&
                          displayAmount(currentPaymentDetails[product]?.new?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Monthly)
                      </span>
                      <span
                        *ngIf="
                          currentPaymentDetails[product].prepay &&
                          displayAmount(currentPaymentDetails[product]?.new?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Prepaid)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cc-grid payment-footer-container" *ngIf="addNewOrUpdate(product)">
              <div
                class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 payment-preview-footer-message"
              >
                <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
                <div id="footer-message" class="colorChange" [innerHTML]="footerMsg[product] || ''"></div>
              </div>
            </div>
          </cc-expandable>
        </cc-accordion>
      </div>
    </div>
  </div>
</div>
