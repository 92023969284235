import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import {
  MessageBar,
  MessageBarConfig,
  DrawerService,
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
} from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { PaymentPreviewPayload } from '../../asset-interface';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { AssetService } from '../../services/asset.service';
import { AssetSubscriptionFormDataType, PrepayContractInfo } from '../../../../models/assets.interface';
import { PlanPaymentComponent } from '../../manage-asset-subscription/payment-preview/plan-payment/plan-payment.component';
import { cloneDeep, isEmpty } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TermsofsaleModalComponent } from '../../../footer/termsofsale-modal/termsofsale-modal.component';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Router } from '@angular/router';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-payment-preview-menu',
  templateUrl: './payment-preview-menu.component.html',
  styleUrls: ['./payment-preview-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPreviewMenuComponent implements OnInit, AfterViewInit {
  @Input() rowData: any;
  @Input() selectedAssetDetails: any;
  @Input() selectedAssetSubscriptionFormData!: any;
  @Input() dynamicTrackerData: any;
  @Input() currentPageIndex = 0;
  @Input() subsPricingEligible: any;
  // @Output() updateTrackerIndexFor = new EventEmitter();
  @Output() updateTrackerIndex = new EventEmitter();
  ownershipData!: any;
  expandDropdown = true;
  dealer: any;
  expanDropDown: any = {
    new: {},
    previous: {},
  };
  appName: any;
  serviceMapping: any;
  paymentDetails: any;
  loader = false;
  currentPaymentDetails: any = {};
  previousPaymentDetails: any = {};
  planPayment: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isCurrentProductVision: any = {};
  isPreviousProductVision: any = {};
  previousSubscription: any = {};
  currentSubscription: any = {};
  currentProductFamily: any = {};
  previousProductFamily: any = {};
  productLength: any;
  productFamily: any;
  footerMsg: any = {};
  globalInstance: any;
  subscription!: Subscription;
  paymentPreviewAPICallData!: Subscription;
  isPaymentPreviewAPICompleted = false;
  isExpandPlansInEditSubscription = true;
  userActiveFeatures: any;
  newSelectedAssetSubscriptionFormData: any = {};
  isTimeFormatEnabled: any;
  accordionLabel: any;
  seeMoreOverlayBasicDetails: any;
  dspStoreData: any;
  hideBillToParty: boolean = false;
  constructor(
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar,
    public assetService: AssetService,
    private drawerService: DrawerService,
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    if (this.rowData) {
      this.seeMoreOverlayBasicDetails = {
        make: this.rowData?.make ? this.rowData?.make : '',
        model: this.rowData?.model ? this.rowData?.model : '',
        modelYear: this.rowData?.manufacturingYear ? this.rowData?.manufacturingYear : '',
        serialNumber: this.rowData?.serialNumber ? this.rowData?.serialNumber : '',
        engineSerialNumber: this.rowData?.engineSerialNo ? this.rowData?.engineSerialNo : '',
        reportingState: this.rowData.reportingState ? this.rowData.reportingState : '',
      };
    }

    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (
        productObj.subscription.currentSubscription.catLevel ||
        productObj.subscription.previousSubscription.catLevel
      ) {
        this.newSelectedAssetSubscriptionFormData[key] = value;

        this.productLength = Object.keys(this.newSelectedAssetSubscriptionFormData).length;

        if (
          productObj.subscription.currentSubscription.catLevel !== 'None' &&
          ((productObj.subscription.currentSubscription.catLevel &&
            !productObj.subscription.previousSubscription.catLevel) ||
            (!productObj.subscription.currentSubscription.catLevel &&
              productObj.subscription.previousSubscription.catLevel) ||
            (productObj.subscription.currentSubscription.catLevel &&
              productObj.subscription.previousSubscription.catLevel))
        ) {
          this.expanDropDown.new[key] = true;
        }
        if (productObj.subscription.previousSubscription.catLevel) {
          this.expanDropDown.previous[key] = true;
        }
      }
    }
    this.accordionLabel = 'Collapse All';

    this.dealer = fetchLoggedInDealerDetails();
    this.subscription = this.assetService.backButtonNotification.subscribe((res: any) => {
      if (res?.isBackButtonPressed) {
        if (this.paymentPreviewAPICallData && !this.isPaymentPreviewAPICompleted) {
          this.paymentPreviewAPICallData.unsubscribe();
        }
      }
    });

    this.store.select('dsp').subscribe((dsp) => {
      this.serviceMapping = dsp.serviceMapping || {};
      this.isExpandPlansInEditSubscription = dsp?.featureFlagInfo?.isExpandPlansInEditSubscription
        ? dsp?.featureFlagInfo?.isExpandPlansInEditSubscription
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
    });

    this.selectedAssetSubscriptionFormData.isValidForm = false;
    for (const [key, value] of Object.entries(this.newSelectedAssetSubscriptionFormData)) {
      let productObj: any = value;
      if (this.selectedAssetSubscriptionFormData.products[key]?.addOns?.currentAddons?.length >= 1) {
        this.currentProductFamily[key] =
          this.selectedAssetSubscriptionFormData?.products[key]?.productFamily?.currentProductFamily || '';
      }
      if (this.selectedAssetSubscriptionFormData.products[key]?.addOns?.previousddons?.length >= 1) {
        this.previousProductFamily[key] =
          this.selectedAssetSubscriptionFormData?.products[key]?.productFamily?.previousProductFamily || '';
      }

      this.previousSubscription[key] =
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.previousSubscription?.customerLevel ||
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.previousSubscription?.dealerLevel ||
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.previousSubscription?.catLevel;
      this.currentSubscription[key] =
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.currentSubscription?.customerLevel ||
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.currentSubscription?.dealerLevel ||
        this.selectedAssetSubscriptionFormData?.products[key]?.subscription?.currentSubscription?.catLevel;
      if (
        (this.selectedAssetSubscriptionFormData?.products[key]?.service?.previousService == 'New VisionLink' ||
          this.selectedAssetSubscriptionFormData?.products[key]?.service?.previousService == 'VisionLink') &&
        this.selectedAssetSubscriptionFormData?.products[key]?.prepay?.previousPrepayData?.prepaySelected == true
      ) {
        this.isPreviousProductVision[key] = true;
      }
      if (
        (this.selectedAssetSubscriptionFormData?.products[key]?.service?.currentService == 'New VisionLink' ||
          this.selectedAssetSubscriptionFormData?.products[key]?.service?.currentService == 'VisionLink') &&
        this.selectedAssetSubscriptionFormData?.products[key]?.prepay?.currentPrepayData?.prepaySelected == true
      ) {
        this.isCurrentProductVision[key] = true;
      }
      this.mapOwnershipData();
      const getPaymentPage: PaymentPreviewPayload = {
        assetMake: this.rowData?.make || '',
        assetSerialNumber: this.rowData?.serialNumber || '',
        deviceId: '',
        deviceSerialNumber: this.rowData?.deviceSerialNumer || '',
        deviceType: this.rowData?.deviceType || '',
        dealerCode: this.rowData?.dealerCode || this.dealer?.dealerCode,
        ucid: this.selectedAssetSubscriptionFormData?.customer?.ucid || '',
        customerName: this.selectedAssetSubscriptionFormData?.customer?.ucidName || '',
        dcnName: this.selectedAssetSubscriptionFormData?.customer?.dealerCustName || '',
        dcnNumber: this.selectedAssetSubscriptionFormData?.customer?.dealerCustNo || '',
        contractEndDate:
          this.selectedAssetSubscriptionFormData?.products[key]?.prepay?.currentPrepayData?.contractEndDate || '',
        contractStartDate:
          this.selectedAssetSubscriptionFormData?.products[key]?.prepay?.currentPrepayData?.contractStartDate || '',
        applicationName: '', //from form data
        siteName: this.selectedAssetSubscriptionFormData?.products[key]?.siteInfo?.siteName || '',
        subscriptions: [], //from form data
        cva: this.rowData?.cvaInfo?.cvaStatus == 'Available' ? true : false,
        prepay: this.selectedAssetSubscriptionFormData?.products[key]?.prepay?.currentPrepayData?.prepaySelected,
      };
      if (
        this.selectedAssetSubscriptionFormData?.products[key]?.reportingState == 'Subscribed' &&
        this.previousSubscription[key] != ''
      ) {
        const getPreviousPaymentPayload = Object.assign({}, getPaymentPage);
        this.getPreviousPaymentInfo(getPreviousPaymentPayload, key);
      }
      if (
        (productObj?.subscription?.currentSubscription?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.greyOutPlan?.base?.includes(
            productObj?.subscription?.currentSubscription?.catLevel
          )) ||
        (!productObj?.subscription?.currentSubscription?.catLevel &&
          productObj?.subscription?.previousSubscription?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.greyOutPlan?.base?.includes(
            productObj?.subscription?.previousSubscription?.catLevel
          ))
      ) {
        const getPaymentPayload = Object.assign({}, getPaymentPage);
        this.getCurrentPaymentInfo(getPaymentPayload, key);
      }
    }
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.hideBillToParty = this.dspStoreData?.featureFlagInfo?.hideBillToParty
        ? this.dspStoreData?.featureFlagInfo?.hideBillToParty
        : false;
    });
  }
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
  /**
   * Determines whether to add a new entry or update an existing one based on changes.
   *
   * This method evaluates the current and previous data for a specified
   * application to decide if an update is necessary. It checks for changes
   * in contract end dates, site names, subscription levels, add-ons, and
   * prepay selections. Depending on the conditions met, it returns a
   * string indicating whether the data is 'Updated', 'New', or an empty string.
   *
   * @param {any} applicationName - The name of the application to evaluate.
   * @returns {string} - 'Updated' if changes are detected, 'New' if it's a new
   *                     subscription, or an empty string if no action is needed.
   */
  addNewOrUpdate(applicationName: any) {
    let dataReturn = '';
    const item = this.selectedAssetSubscriptionFormData.products[applicationName];
    const { currentPrepayData, previousPrepayData } = item.prepay;
    const { selectedSiteInfo, previousSiteInfo } = item;
    let isContractEndData = false;
    if (applicationName === dspConstants.Worklist.NEW_VISION_LINK && currentPrepayData?.prepay) {
      if (
        (currentPrepayData.contractEndDate && !previousPrepayData.contractEndDate) ||
        currentPrepayData.contractEndDate !== previousPrepayData.contractEndDate
      ) {
        isContractEndData = true;
      }
    }
    let isSiteName = false;
    if (
      applicationName == dspConstants.Worklist.MINESTAR &&
      selectedSiteInfo.siteName &&
      selectedSiteInfo.siteName !== previousSiteInfo.siteName
    ) {
      isSiteName = true;
    }
    if (
      (this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.currentSubscription?.catLevel &&
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.currentSubscription
          ?.catLevel !=
          this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.previousSubscription
            ?.catLevel &&
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.previousSubscription
          ?.catLevel) ||
      this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns?.currentAddons?.length !=
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns?.previousddons?.length ||
      isSiteName ||
      isContractEndData ||
      this.selectedAssetSubscriptionFormData?.products[applicationName]?.productFamily?.currentProductFamily !=
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.productFamily?.previousProductFamily ||
      !this.areArraysEqual(
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns?.currentAddons,
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.addOns?.previousddons
      ) ||
      (this.selectedAssetSubscriptionFormData?.products[applicationName]?.prepay?.currentPrepayData?.prepaySelected ==
        true &&
        this.selectedAssetSubscriptionFormData?.products[applicationName]?.prepay?.previousPrepayData?.prepaySelected ==
          false)
    ) {
      dataReturn = 'Updated';
    }
    if (
      this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.currentSubscription?.catLevel &&
      !this.selectedAssetSubscriptionFormData?.products[applicationName]?.subscription?.previousSubscription?.catLevel
    ) {
      dataReturn = 'New';
    }
    return dataReturn;
  }
  /**
   * Compares two arrays for equality, considering their contents and order.
   *
   * This function checks if two arrays are equal by sorting and serializing
   * them to JSON strings. It returns true if both arrays contain the same
   * elements in the same order; otherwise, it returns false.
   *
   * @param {any} arr1 - The first array to compare.
   * @param {any} arr2 - The second array to compare.
   * @returns {boolean} - True if the arrays are equal, false otherwise.
   */
  areArraysEqual = (arr1: any, arr2: any) => JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
  /**
   * Initializes event listeners after the component's view has been fully initialized.
   *
   * This method sets up a click event listener on the component's element.
   * When the 'Terms of Sale' text is clicked, it stores a flag in session
   * storage, updates the navigation state, and opens the 'termSaleModal'
   * in a new browser window.
   */
  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }
  /**
   * Toggles the accordion label between 'Collapse All' and 'Expand All'.
   *
   * This method checks the current label and updates it accordingly.
   * If the label is 'Collapse All', it changes it to 'Expand All';
   * otherwise, it sets it to 'Collapse All'.
   *
   * @param {any} label - The current label of the accordion.
   */
  acordionLabelChange(label: any) {
    label === 'Collapse All' ? (this.accordionLabel = 'Expand All') : (this.accordionLabel = 'Collapse All');
  }
  /**
   * Toggles the expansion state of dropdowns and updates the accordion label.
   *
   * This method changes the accordion label based on its current state and
   * toggles the visibility of the dropdown items in both the `new` and
   * `previous` categories. If the dropdown is expanded, it collapses all
   * items; if collapsed, it expands them. The method also updates the
   * `expandDropdown` state.
   *
   * @param {any} expandDropdown - A boolean indicating the current state of
   *                               the dropdown (expanded or collapsed).
   */
  toggle(expandDropdown: any) {
    this.acordionLabelChange(this.accordionLabel);
    for (const [key, value] of Object.entries(this.expanDropDown.new)) {
      if (expandDropdown) {
        this.expanDropDown.new[key] = false;
      } else {
        this.expanDropDown.new[key] = true;
      }
    }
    for (const [key, value] of Object.entries(this.expanDropDown.previous)) {
      if (expandDropdown) {
        this.expanDropDown.previous[key] = false;
      } else {
        this.expanDropDown.previous[key] = true;
      }
    }
    this.expandDropdown = !this.expandDropdown;
  }
  /**
   * Toggles the expansion state of services in the dropdown based on the provided state.
   *
   * This method updates the visibility of specific services based on their
   * type ('new' or 'current') and the accordion's current state ('open' or 'close').
   * It checks if all items are collapsed or expanded to adjust the overall
   * dropdown state and updates the accordion label accordingly.
   *
   * @param {any} product - The product to be toggled in the dropdown.
   * @param {any} type - The type of product, either 'new' or 'current'.
   * @param {any} accordionState - The current state of the accordion, either 'open' or 'close'.
   */
  closeService(product: any, type: any, accordionState: any) {
    if (accordionState === 'open') {
      if (type == 'new') {
        this.expanDropDown.new[product] = true;
      } else if (type == 'current') {
        this.expanDropDown.previous[product] = true;
      }
      if (
        Object.values(this.expanDropDown.new).every((item) => item === false) &&
        Object.values(this.expanDropDown.previous).every((item) => item === false) &&
        !(this.accordionLabel === 'Expand All')
      ) {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
      if (
        Object.values(this.expanDropDown.new).every((item) => item === true) &&
        Object.values(this.expanDropDown.previous).every((item) => item === true) &&
        !(this.accordionLabel === 'Collapse All')
      ) {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
    } else if (accordionState === 'close') {
      if (type == 'new') {
        this.expanDropDown.new[product] = false;
      } else if (type == 'current') {
        this.expanDropDown.previous[product] = false;
      }
      if (
        Object.values(this.expanDropDown.new).every((item) => item === false) &&
        Object.values(this.expanDropDown.previous).every((item) => item === false) &&
        !(this.accordionLabel === 'Expand All')
      ) {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
      if (
        Object.values(this.expanDropDown.new).every((item) => item === true) &&
        Object.values(this.expanDropDown.previous).every((item) => item === true) &&
        !(this.accordionLabel === 'Collapse All')
      ) {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
    }
  }
  /**
   * Prepares and retrieves previous payment information for a specific application.
   *
   * This method populates the provided payload with subscription details,
   * prepayment status, and contract dates based on the previous service data
   * for the given application. It then calls the payment page function to
   * handle the previous payment retrieval.
   *
   * @param {any} getPreviousPaymentPayload - The payload object to be populated with previous payment data.
   * @param {any} applicationName - The name of the application for which to retrieve previous payment information (optional).
   */
  getPreviousPaymentInfo(getPreviousPaymentPayload: any, applicationName?: any) {
    getPreviousPaymentPayload.isViewSubscription = true;
    getPreviousPaymentPayload.subscriptions = [];
    getPreviousPaymentPayload.applicationName =
      this.selectedAssetSubscriptionFormData.products[applicationName].service.previousService;
    getPreviousPaymentPayload.prepay =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.previousPrepayData.prepaySelected;
    getPreviousPaymentPayload.contractEndDate =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.previousPrepayData.contractEndDate;
    getPreviousPaymentPayload.contractStartDate =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.previousPrepayData.contractStartDate;
    this.paymentPage(getPreviousPaymentPayload, 'previousPayment', applicationName);
  }
  /**
   * Prepares and retrieves current payment information for a specific application.
   *
   * This method populates the provided payload with the current subscription details,
   * prepayment status, and formatted contract dates based on the specified application.
   * It then calls the payment page function to handle the current payment retrieval.
   *
   * @param {any} getPaymentPayload - The payload object to be populated with current payment data.
   * @param {any} applicationName - The name of the application for which to retrieve current payment information (optional).
   */

  getCurrentPaymentInfo(getPaymentPayload: any, applicationName?: any) {
    const { prepaySelected: currentPrepayStatus } =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData;
    let allCurrentSubscriptions: any = [];
    allCurrentSubscriptions.push(this.currentSubscription[applicationName]);
    if (this.selectedAssetSubscriptionFormData.products[applicationName]?.addOns?.currentAddons?.length >= 1) {
      allCurrentSubscriptions = allCurrentSubscriptions.concat(
        this.selectedAssetSubscriptionFormData.products[applicationName].addOns.currentAddons
      );
    }
    getPaymentPayload.isNewSubscription = true;
    allCurrentSubscriptions = allCurrentSubscriptions.filter(
      (item: any) => !this.selectedAssetSubscriptionFormData?.greyOutPlan?.addon?.includes(item)
    );
    getPaymentPayload.subscriptions = allCurrentSubscriptions;
    getPaymentPayload.applicationName =
      this.selectedAssetSubscriptionFormData.products[applicationName].service.currentService;
    getPaymentPayload.prepay =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData.prepaySelected;
    getPaymentPayload.contractEndDate = this.formatContractDates(
      'contractEndDate',
      currentPrepayStatus,
      applicationName
    );
    getPaymentPayload.contractStartDate = this.formatContractDates(
      'contractStartDate',
      currentPrepayStatus,
      applicationName
    );
    this.paymentPage(getPaymentPayload, 'currentPayment', applicationName);
  }
  /**
   * Maps the customer data to ownership data if valid.
   *
   * This method checks if the customer data from the asset subscription form
   * is not empty and is not equal to 'None'. If valid, it assigns the
   * customer data to the `ownershipData` property.
   */
  mapOwnershipData() {
    const customer = this.selectedAssetSubscriptionFormData.customer;
    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }
  /**
   * Formats contract dates based on the specified date option and prepayment status.
   *
   * This method retrieves the date corresponding to the provided date option
   * if the prepay flag is true. If the selected date range is not empty, it
   * returns the formatted date; otherwise, it returns null.
   *
   * @param {keyof PrepayContractInfo} dateOption - The key representing the date option to format.
   * @param {boolean} prepayFlag - A flag indicating whether prepayment is applicable.
   * @param {any} applicationName - The name of the application for retrieving the date data.
   * @returns {string | null} - The formatted date string if available, or null if not.
   */
  formatContractDates(dateOption: keyof PrepayContractInfo, prepayFlag: boolean, applicationName: any) {
    if (!prepayFlag) return null;
    const selectedDateRange =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData[dateOption] || '';
    return !isEmpty(selectedDateRange) ? selectedDateRange : null;
  }
  /**
   * Opens a payment drawer with the specified payment details and configuration.
   *
   * This method determines the application name and base plan based on the
   * payment type (previous or current) and initializes the payment drawer
   * with the relevant data. It uses the drawer service to open a
   * PlanPaymentComponent with the provided settings.
   *
   * @param {any} PaymentDetails - The details of the payment to be displayed in the drawer.
   * @param {any} paymentType - The type of payment, either 'previousPayment' or 'currentPayment'.
   * @param {any} product - The product for which the payment is being processed.
   */
  openDrawer(PaymentDetails: any, paymentType: any, product: any) {
    const applicationName =
      paymentType === 'previousPayment'
        ? this.selectedAssetSubscriptionFormData.products[product].service.previousService
        : this.selectedAssetSubscriptionFormData.products[product].service.currentService;
    const basePlan =
      paymentType === 'previousPayment' ? this.previousSubscription[product] : this.currentSubscription[product];
    this.planPayment = this.drawerService.openDrawer<PlanPaymentComponent, CcDrawerConfig>(PlanPaymentComponent, {
      data: {
        rowData: paymentType === 'previousPayment' ? PaymentDetails?.current : PaymentDetails?.new,
        applicationName: applicationName,
        basePlan: basePlan,
        seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
  }
  /**
   * Handles the payment preview process based on the provided payload and payment type.
   *
   * This method initiates a payment preview API call, manages loading states,
   * and updates the relevant payment details based on the response. It also
   * handles both current and previous payment types, updating the form data
   * and footer messages accordingly. In case of an error, it displays a toast message.
   *
   * @param {any} getPaymentPayload - The payload containing payment details to be sent in the API call.
   * @param {any} paymentType - The type of payment, either 'currentPayment' or 'previousPayment'.
   * @param {any} applicationName - The name of the application for which the payment details are being processed.
   */
  paymentPage(getPaymentPayload: any, paymentType: any, applicationName: any) {
    this.loader = true;
    this.isPaymentPreviewAPICompleted = false;
    let data = false;
    if (!this.addNewOrUpdate(applicationName) && paymentType == 'currentPayment') {
      delete getPaymentPayload.isNewSubscription;
      getPaymentPayload.isViewSubscription = true;
      getPaymentPayload.subscriptions = [];
      data = true;
    }
    this.paymentPreviewAPICallData = this.assetDrawerService.getPaymentPreview(getPaymentPayload).subscribe({
      next: (response: any) => {
        this.isPaymentPreviewAPICompleted = true;
        if (response) {
          this.selectedAssetSubscriptionFormData.products[applicationName].newPaymentDetails = response;
          if (paymentType == 'currentPayment') {
            if (this.currentPageIndex === this.dynamicTrackerData?.length - 1) {
              this.updateTrackerIndex.emit();
            }
            if (data) {
              const data = cloneDeep(response);
              data.new = data.current;
              data.current = null;
              this.currentPaymentDetails[applicationName] = data;
            } else {
              this.currentPaymentDetails[applicationName] = response;
            }
            // this.currentPaymentDetails[applicationName] = response;
            this.currentPaymentDetails[applicationName].prepay = getPaymentPayload?.prepay;
            this.footerMsg[applicationName] = this.updateFooterMessage(this.currentPaymentDetails[applicationName]);
            this.loader = false;
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.previousPaymentDetails[applicationName] = response;
            this.previousPaymentDetails[applicationName].prepay = getPaymentPayload?.prepay;
          }
        }
      },
      error: (_err: any) => {
        this.isPaymentPreviewAPICompleted = true;
        this.loader = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  /**
   * Updates the footer message based on the current payment details.
   *
   * This method processes the `currentPaymentDetails` to construct a
   * footer message. It handles cancellation notifications, promotions,
   * and modifies links to be more interactive. If certain conditions
   * are met (e.g., promotions or TOS visibility), it adjusts the message
   * accordingly before returning it.
   *
   * @param {any} currentPaymentDetails - The details of the current payment that may contain footer messages.
   * @returns {string} - The updated footer message or an empty string if there are no relevant messages.
   */
  updateFooterMessage(currentPaymentDetails: any) {
    let footerMsg;
    if (!currentPaymentDetails?.cancelNotificationMessage) footerMsg = '';
    if (currentPaymentDetails?.cancelNotificationMessage) {
      footerMsg = currentPaymentDetails?.cancelNotificationMessage
        .split('<br/>')
        .join(' ')
        .replace('href="terms/tos"', 'onclick="termSaleModal()"')
        .replace(
          '"/assets/dsp_help_document/index.htm#t=BillingOverview.htm"',
          '"' + environment.redirectURL.helpDocUrl + 'article/Can-I-prepay-for-subscriptions?language=en_US"'
        );
    }
    // if promotion is there and promotion not eligible message also coming from API, we should hide the message.
    if (
      currentPaymentDetails?.new?.promotions?.length > 0 &&
      currentPaymentDetails?.cancelNotificationMessage?.indexOf(dspConstants?.Worklist?.PROMOTION_NOT_ELIGIBLE) != -1
    ) {
      footerMsg = currentPaymentDetails.cancelNotificationMessage
        ?.split('<br/>')
        ?.join(' ')
        ?.replace(dspConstants.Worklist.PROMOTION_NOT_ELIGIBLE, '');
    }
    const TOS_REGEX = new RegExp('By submitting your order, you are agreeing to our <a[^>]*>Terms of Sale</a>');
    if (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) {
      footerMsg = footerMsg.replace(TOS_REGEX, '');
    }
    return footerMsg;
  }
  /**
   * Displays a toast message with the specified content and status.
   *
   * This method configures and opens a toast notification using the
   * message bar service. The toast is displayed at the top center of
   * the specified host for a duration of 3000 milliseconds.
   *
   * @param {string} message - The content of the toast message to be displayed.
   * @param {string} status - The status of the message (e.g., 'success', 'error').
   */
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  /**
   *
   * @param amount
   * @returns
   */
  displayAmount(amount: any) {
    return amount === 'N/A' || isEmpty(amount) ? '-' : this.userPreferenceService.convertNumberFormat(amount, true);
  }
  /**
   *
   * @param type
   * @returns
   */
  keys(type: any): Array<string> {
    let returnData: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (
        (type == 'new' &&
          productObj.subscription.currentSubscription.catLevel !== 'None' &&
          (productObj.subscription.currentSubscription.catLevel ||
            productObj.subscription.previousSubscription.catLevel) &&
          productObj?.subscription?.currentSubscription?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.greyOutPlan?.base?.includes(
            productObj?.subscription?.currentSubscription?.catLevel
          )) ||
        (!productObj?.subscription?.currentSubscription?.catLevel &&
          productObj?.subscription?.previousSubscription?.catLevel &&
          !this.selectedAssetSubscriptionFormData?.greyOutPlan?.base?.includes(
            productObj?.subscription?.previousSubscription?.catLevel
          ))
      ) {
        returnData[key] = value;
      }
      if (type == 'current' && productObj.subscription.previousSubscription.catLevel) {
        returnData[key] = value;
      }
    }
    return Object.keys(returnData);
  }
  productListCurrentOrNew(type: any) {}
  getConvertedDate(expirationDate: any) {
    if (this.isTimeFormatEnabled) {
      return !isEmpty(expirationDate) ? this.userPreferenceService.convertDate(expirationDate) : '';
    }
    return expirationDate;
  }
}
