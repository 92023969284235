import { ChangeDetectorRef, Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerConfig, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core';
import { UserAdminService } from '../../services/user-admin.service';
import { CUSTOMACCESS } from '../../user-admin-constant';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'dsp-next-gen-ui-configure-dsp-admin-drawer',
  templateUrl: './configure-dsp-admin-drawer.component.html',
  styleUrls: ['./configure-dsp-admin-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureDspAdminDrawerComponent {
  roleValue: any;
  roleArray: any;
  roleId: any;
  featureData: any;
  activeFeatures: any = {};
  selectedFeature: any = {};
  updatedDspAccessObj: any = {};
  radioStates: any;
  appFeatures: any;
  indeterminate = false;
  defaultFeatures: any = {};
  disableSubscription: boolean = true;
  dspStoreData!: any;
  dspData: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    public dialogRef: CcDrawerRef<ConfigureDspAdminDrawerComponent, CcDrawerConfig>,
    public userAdminService: UserAdminService,
    private cdr: ChangeDetectorRef,
    private store: Store<DSPAppState>,
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      appId: any;
      roleName: any;
      roleId: any;
      catRecId: any;
      profileType: string;
      dealerCode: string;
      affiliationCode: string;
    }
  ) {}
  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspData = dsp;
      this.dspStoreData = dsp.dspConfig;
    });
    const params = {
      appId: this.header.appId,
      profileName: this.header.profileType,
    };
    this.radioStates = ['default', 'default', 'default', 'default', 'default', 'default'];
    this.roleValue = this.header.roleName ? this.header.roleName : '';
    this.roleId = this.header.roleId ? this.header.roleId : '';
    this.userAdminService.getApplicationRoleInfo(params).subscribe((res: any) => {
      //this.roleValue = res && res.roles.length && res.roles[0].roleName;
      // this.roleId = res && res.roles.length && res.roles[0].id;
      this.roleArray = res && res.roles.length && res.roles;
      this.roleArray.forEach((option: any, index: any) => {
        if (option.roleName === this.roleValue) {
          this.radioStates[index] = 'active';
        } else {
          this.radioStates[index] = 'default';
        }
      });
      if (this.roleValue !== 'None') {
        this.getFeaturesByAppProfile();
      }
    });
  }
  closeModal() {
    this.dialogRef.close();
  }
  closeDrawer(val: any) {
    if (val) {
      this.dialogRef.close(this.updatedDspAccessObj);
    } else {
      this.dialogRef.close();
    }
  }
  onApplyChanges() {
    let features = (this.updatedDspAccessObj = {
      roleId: this.roleId,
      roleName: this.roleValue,
      selectedFeatures: Object.keys(this.selectedFeature).filter((key: any) => {
        if (this.selectedFeature[key]) {
          return key;
        }
      }),
      defaultFeatures: Object.keys(this.defaultFeatures),
    });
    this.closeDrawer(true);
  }
  changeSelected(event: any, roleName: any, roleId: any, i: number) {
    this.radioStates.forEach((item: any, index: any) => {
      this.radioStates[index] = i === index && event ? 'active' : 'default';
    });
    if (roleName !== 'None') {
      this.roleArray.forEach((ele: any) => {
        if (ele.roleName == roleName) {
          this.roleId = roleId;
          this.roleValue = roleName;
        }
      });
      this.getFeaturesByAppProfile();
    } else {
      this.roleId = roleId;
      this.roleValue = roleName;
      this.selectedFeature = {};
    }
  }
  valueChange(event: any) {
    if (event.value !== 'None') {
      this.roleArray.forEach((ele: any) => {
        if (ele.roleName == event.value) {
          this.roleId = ele.id;
          this.roleValue = ele.roleName;
        }
      });
      this.getFeaturesByAppProfile();
    }
  }
  getFeaturesByAppProfile() {
    const queryParams = {
      roleId: this.roleId,
      applicationId: this.header.appId,
      profile: this.header.profileType,
      dealerCode: this.header.dealerCode ? this.header.dealerCode : '',
      affiliationCode: this.header?.affiliationCode || '',
      version: 2,
    };
    const params = {
      catrecId: this.header.catRecId,
      roleName: this.roleValue,
      appName: 'Dealer Services Portal',
      profileName: this.header.profileType,
      dealerCode: '',
    };
    this.userAdminService.getAppFeaturesByAppProfile(queryParams).subscribe((result: any) => {
      this.userAdminService.getActiveFeatures(params).subscribe((res: any) => {
        this.featureData = result;
        this.appFeatures = result;
        this.activeFeatures = res.features != null ? res.features : [];
        this.setSelectedFeature(this.featureData, true);
      });
    });
  }

  enableApplyButton() {
    if (this.roleValue?.toUpperCase() == CUSTOMACCESS) {
      let defaultItems = Object.keys(this.defaultFeatures);
      let selectedItems = Object.keys(this.selectedFeature);
      let currentItems = selectedItems.filter((item) => item !== 'undefined' && this.selectedFeature[item] == true);
      return (
        (defaultItems.length === currentItems.length &&
          Object.keys(this.defaultFeatures).every((key) => this.defaultFeatures[key] === this.selectedFeature[key])) ||
        currentItems.length === 0
      );
    } else if (this.header.roleName === this.roleValue) {
      return true;
    } else {
      return false;
    }
  }
  setSelectedFeature(features: any, isResetFlag: any) {
    if (isResetFlag) {
      this.selectedFeature = {};
      this.defaultFeatures = {};
    }
    features.forEach((feature: any) => {
      if (this.activeFeatures.indexOf(feature.label) != -1) {
        this.selectedFeature[feature.label] = true;
      }
      if (feature.children && feature.children.length > 0) {
        this.setSelectedFeature(feature.children, '');
      }
    });
    if (this.roleValue?.toUpperCase() === CUSTOMACCESS) {
      if (
        this.selectedFeature['Device Configuration'] ||
        this.selectedFeature['Asset Configuration'] ||
        this.selectedFeature['Subscribe Sites']
      ) {
        this.disableSubscription = true;
        this.cdr.detectChanges();
      } else if (
        !this.selectedFeature['Device Configuration'] &&
        !this.selectedFeature['Subscribe Sites'] &&
        !this.selectedFeature['Asset Configuration']
      ) {
        this.disableSubscription = false;
        this.cdr.detectChanges();
      }
      if (
        (!this.selectedFeature['Read Only'] || !this.selectedFeature['View Only']) &&
        (!this.selectedFeature['Read and Write'] || !this.selectedFeature['Manage - All Plans']) &&
        !this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)']
      ) {
        this.disableSubscription = true;
        this.cdr.detectChanges();
      } else {
        this.disableSubscription = false;
        this.cdr.detectChanges();
      }
    }
    Object.assign(this.defaultFeatures, this.selectedFeature);
  }
  subfeaturesValueChange(event: any, subFeature: any, features: any) {
    let subFeatures;
    if (event?.checked) {
      this.selectedFeature[features.label] = true;
      const SubscriptionKey = 'Subscription Management';
      if (features.label === 'Device Configuration' || features.label === 'Asset Configuration') {
        if (
          (!this.selectedFeature['Read Only'] || !this.selectedFeature['View Only']) &&
          (!this.selectedFeature['Read and Write'] || !this.selectedFeature['Manage - All Plans']) &&
          !this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)']
        ) {
          this.selectedFeature[SubscriptionKey] = true;
          this.selectedFeature['Read Only'] = true;
          this.selectedFeature['View Only'] = true;
          this.selectedFeature['Read and Write'] = false;
          this.selectedFeature['Manage - All Plans'] = false;
          this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
        }
        this.disableSubscription = true;
        this.cdr.detectChanges();
      }
    } else {
      subFeatures = features.children.filter((item: any) => {
        return this.selectedFeature[item.label];
      });
      this.selectedFeature[features.label] = subFeatures.length > 0 ? true : false;
      if (
        !this.selectedFeature['Device Configuration'] &&
        !this.selectedFeature['Subscribe Sites'] &&
        !this.selectedFeature['Asset Configuration']
      ) {
        this.disableSubscription = false;
        this.cdr.detectChanges();
      }
    }
  }
  toggle(event: any, feature: any) {
    const parentFeature = feature.children && feature.children.length ? feature.children : [];

    this.selectedFeature[feature.label] = event.checked ? true : false;

    if (!this.selectedFeature[feature.label] && parentFeature) {
      let anySelected = false;
      if (parentFeature && parentFeature.children) {
        for (const element of parentFeature.children) {
          const childFeature = element;
          if (this.selectedFeature[childFeature.label]) {
            anySelected = true;
            break;
          }
        }
      }
      if (!anySelected && parentFeature?.label) {
        this.selectedFeature[parentFeature.label] = false;
      }
    }

    if (feature.children && feature.children.length > 0 && feature.type == 'multi') {
      feature.children.forEach((childFeature: any) => {
        this.selectedFeature[childFeature.label] = this.selectedFeature[feature.label] == true ? true : false;
      });
    }

    if (feature.children && feature.children.length > 0 && feature.type == 'single') {
      feature.children.forEach((childFeature: any) => {
        this.selectedFeature[childFeature.label] = false;
      });
    }

    const selectedParentFeature = parentFeature ? parentFeature : feature;
    this.checkIfSubsFeatureSelected();
    if (
      feature.label === 'Subscribe Sites' &&
      (!this.selectedFeature['Read Only'] || !this.selectedFeature['View Only']) &&
      (!this.selectedFeature['Read and Write'] || !this.selectedFeature['Manage - All Plans']) &&
      !this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)']
    ) {
      this.selectedFeature['Subscription Management'] = true;
      this.selectedFeature['Read Only'] = false;
      this.selectedFeature['View Only'] = false;
      this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
      this.selectedFeature['Read and Write'] = true;
      this.selectedFeature['Manage - All Plans'] = true;
      this.disableSubscription = true;
      this.cdr.detectChanges();
    } else if (
      this.selectedFeature['Device Configuration'] ||
      this.selectedFeature['Subscribe Sites'] ||
      this.selectedFeature['Asset Configuration']
    ) {
      this.disableSubscription = true;
      this.cdr.detectChanges();
    } else if (
      !this.selectedFeature['Device Configuration'] &&
      !this.selectedFeature['Subscribe Sites'] &&
      !this.selectedFeature['Asset Configuration']
    ) {
      this.disableSubscription = false;
      this.cdr.detectChanges();
    }
    if (!this.selectedFeature['Subscription Management']) {
      this.disableSubscription = true;
      this.cdr.detectChanges();
    }
  }

  checkIfSubsFeatureSelected() {
    const isReadOnlyFeatureSelected = this.isReadOnlySubsFeatureSelected();
    const isReadWriteFeatureSelected = this.isReadWriteSubsFeatureSelected();
    const SubscriptionKey = 'Subscription Management';

    if (
      isReadOnlyFeatureSelected &&
      !isReadWriteFeatureSelected &&
      (!this.selectedFeature['Read Only'] || !this.selectedFeature['View Only']) &&
      (!this.selectedFeature['Read and Write'] || !this.selectedFeature['Manage - All Plans']) &&
      !this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)']
    ) {
      this.selectedFeature[SubscriptionKey] = true;
      this.selectedFeature['Read Only'] = true;
      this.selectedFeature['View Only'] = true;
      this.selectedFeature['Read and Write'] = false;
      this.selectedFeature['Manage - All Plans'] = false;
      this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
    } else if (isReadWriteFeatureSelected) {
      this.selectedFeature[SubscriptionKey] = true;
      this.selectedFeature['Read Only'] = false;
      this.selectedFeature['View Only'] = false;
      this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
      this.selectedFeature['Read and Write'] = true;
      this.selectedFeature['Manage - All Plans'] = true;
    }
  }

  isReadOnlySubsFeatureSelected() {
    const readOnlyFeatures = ['Asset Configuration', 'Device Configuration'];
    let isFeatureExist = false;
    if (!this.selectedFeature) {
      isFeatureExist = true;
    } else {
      readOnlyFeatures.forEach((feature: any) => {
        if (!isFeatureExist) {
          isFeatureExist = this.selectedFeature[feature];
        }
      });
    }
    return isFeatureExist;
  }

  isReadWriteSubsFeatureSelected() {
    const readWriteFeatures = ['Subscribe Sites'];
    let isFeatureExist = false;
    if (!this.selectedFeature) {
      isFeatureExist = true;
    } else {
      readWriteFeatures.forEach((feature: any) => {
        if (!isFeatureExist) {
          isFeatureExist = this.selectedFeature[feature];
        }
      });
    }
    return isFeatureExist;
  }
  toggleRadio(feature: any) {
    if (!this.selectedFeature['Subscription Management']) {
      this.selectedFeature['Subscription Management'] = true;
    }

    this.selectedFeature[feature.label] = true;
    if (feature.label == 'Read Only' || feature.label == 'View Only') {
      this.selectedFeature['Read Only'] = true;
      this.selectedFeature['Read and Write'] = false;
      this.selectedFeature['Manage - All Plans'] = false;
      this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
    } else if (feature.label == 'Read and Write' || feature.label == 'Manage - All Plans') {
      this.selectedFeature['Read and Write'] = true;
      this.selectedFeature['Read Only'] = false;
      this.selectedFeature['View Only'] = false;
      this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)'] = false;
    } else if (feature.label === 'Manage -  Limited Plans (DSP Mobile Only)') {
      this.selectedFeature['Read and Write'] = false;
      this.selectedFeature['Manage - All Plans'] = false;
      this.selectedFeature['Read Only'] = false;
      this.selectedFeature['View Only'] = false;
    }
    if (
      !this.selectedFeature['Read Only'] &&
      !this.selectedFeature['View Only'] &&
      !this.selectedFeature['Read and Write'] &&
      !this.selectedFeature['Manage - All Plans'] &&
      !this.selectedFeature['Manage -  Limited Plans (DSP Mobile Only)']
    ) {
      this.disableSubscription = true;
      this.cdr.detectChanges();
    } else if (
      !this.selectedFeature['Asset Configuration'] &&
      !this.selectedFeature['Device Configuration'] &&
      !this.selectedFeature['Subscribe Sites']
    ) {
      this.disableSubscription = false;
      this.cdr.detectChanges();
    }
  }

  isAllSelected(feature: any) {
    if (this.roleValue?.toUpperCase() !== CUSTOMACCESS) {
      if (feature.children && feature.children.length > 0 && feature.active) {
        const isAllChecked = feature.children.some((item: any) => {
          return !item.active && item.disabled;
        });
        return (this.indeterminate = isAllChecked);
      }
    }
  }
}
