import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { QuickLinksComponent } from './components/quick-links/quick-links/quick-links.component';
import { CreateNewSiteComponent } from './components/sites/create-new-site/create-new-site.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { LegalModalComponent } from './components/footer/legal-modal/legal-modal.component';
import { PrivacyModalComponent } from './components/footer/privacy-modal/privacy-modal.component';
import { TermModalComponent } from './components/footer/term-modal/term-modal.component';
import { ManageDeviceRegistrationComponent } from './components/assets/./manage-device-registration/manage-device-registration/manage-device-registration.component';
import { AuthGuard } from './shared/auth.guard';
import { ConfirmationPopupGuard } from './shared/confirmation-popup.guard';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EulaComponent } from './eula/eula.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { BillingMainComponent } from 'libs/billing/src/lib/components/billing-main/billing-main.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { SitesComponent } from './components/sites/sites.component';
import { NeedsReviewMainComponent } from 'libs/needs-review/src/lib/components/needs-review-main/needs-review-main.component';
import { UserAdminMainComponent } from 'libs/user-admin/src/lib/components/user-admin-main/user-admin-main.component';
import { AssetsComponent } from './components/assets/assets.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: 'dft',
    loadChildren: () => import('../app/components/dft-user/dft-user.module').then((dft) => dft.DftModule),
  },
  {
    path: 'factoryuser',
    loadChildren: () =>
      import('../app/components/factory-user/factory-user.module').then((factoryuser) => factoryuser.FactoryUserModule),
  },
  {
    path: 'asset',

    loadChildren: () => import('../app/components/assets/assets.module').then((assets) => assets.AssetsModule),

    canActivate: [AuthGuard],
  },
  {
    path: 'accounts',
    canDeactivate: [ConfirmationPopupGuard],
    component: AccountsComponent,
    loadChildren: () =>
      import('../app/components/accounts/accounts.module').then((accounts) => accounts.AccountsModule),
  },
  {
    path: 'sites',
    component: SitesComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('../app/components/sites/sites.module').then((sites) => sites.SitesModule),
  },
  {
    path: 'home',
    component: HomeComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('../app/components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('../app/components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'needsreview',
    component: NeedsReviewMainComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('@dsp-next-gen-ui/needs-review').then((m) => m.NeedsReviewModule),
  },

  {
    path: 'billing',
    component: BillingMainComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('@dsp-next-gen-ui/billing').then((m) => m.BillingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: UserAdminMainComponent,
    canDeactivate: [ConfirmationPopupGuard],
    loadChildren: () => import('@dsp-next-gen-ui/user-admin').then((m) => m.UserAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'usernotification',
    loadChildren: () =>
      import('../app/components/configuration/user-notification/user-notification.module').then(
        (usernotification) => usernotification.UserNotificationModule
      ),
  },
  {
    path: 'userconfiguration',
    loadChildren: () =>
      import('../app/components/configuration/user-configuration/user-configuration.module').then(
        (userconfiguration) => userconfiguration.UserConfigurationModule
      ),
  },
  {
    path: 'dealerconfiguration',
    loadChildren: () =>
      import('../app/components/configuration/dealer-configuration/dealer-configuration.module').then(
        (dealerconfiguration) => dealerconfiguration.DealerConfigurationModule
      ),
  },
  {
    path: 'applicationconfiguration',
    loadChildren: () =>
      import('../app/components/configuration/application-configuration/application-configuration.module').then(
        (applicationconfiguration) => applicationconfiguration.ApplicationConfigurationModule
      ),
  },
  { path: 'create-new-site', component: CreateNewSiteComponent },
  { path: 'quickLinks', component: QuickLinksComponent },
  { path: 'legalModal', component: LegalModalComponent },
  { path: 'privacyModal', component: PrivacyModalComponent },
  { path: 'termSaleModal', component: TermModalComponent },
  { path: 'denied', component: AccessDeniedComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'tos', component: TosComponent },
  { path: 'privacyNotice', component: PrivacyNoticeComponent },
  { path: 'session-expired', component: SessionExpiredComponent },
  { path: 'asset/deviceRegistration', component: ManageDeviceRegistrationComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '*', component: DashboardComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      //relativeLinkResolution: 'legacy',
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
