<div
  [style.height]="reportingState === dspWorklistConstants.SUBSCRIBED ? 'max-content' : '100%'"
  class="cards asset-plan-payment-card"
>
  <cc-card>
    <cc-card-content>
      <div>
        <div class="header">
          <div class="header-text-plan-payment">
            <span class="header-text-alignment">PLAN & PAYMENT DETAILS</span>
            <span class="manage-plan" *ngIf="!customUserCheck()">
              <button
                cc-btn-primary
                cc-btn-medium
                [setTheme]="true"
                [disabled]="
                  reportingState === dspWorklistConstants.SUSPENDED ||
                  this.selectedAssetDetails?.isMaskRequired ||
                  (userActiveFeatures &&
                    userActiveFeatures?.toString()?.indexOf('Subscription Management') > -1 &&
                    (userActiveFeatures?.toString()?.indexOf('Read Only') > -1 ||
                      userActiveFeatures?.toString()?.indexOf('View Only') > -1 ||
                      userActiveFeatures?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1))
                "
                class="manageBtn"
                (click)="managePlanClicked()"
              >
                Manage Plan
              </button>
            </span>
          </div>

          <ng-container *ngIf="showManageIcon">
            <dsp-next-gen-ui-blocked-devices [rowData]="selectedAssetDetails"></dsp-next-gen-ui-blocked-devices>
          </ng-container>

          <div
            *ngIf="
              this.selectedAssetDetails.showPendingRequestChip &&
              !(
                reportingState === dspWorklistConstants.SUSPENDED ||
                this.selectedAssetDetails?.isMaskRequired ||
                (userActiveFeatures &&
                  userActiveFeatures?.toString()?.indexOf('Subscription Management') > -1 &&
                  (userActiveFeatures?.toString()?.indexOf('Read Only') > -1 ||
                    userActiveFeatures?.toString()?.indexOf('View Only') > -1 ||
                    userActiveFeatures?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1))
              )
            "
            class="header-text-pending-request"
          >
            <span
              ><img src="../../../assets/images/info-group.svg" class="info-icon-style info-group" />&nbsp;Pending
              Request</span
            >
          </div>
        </div>
        <div class="asset-drawer-table-container" *ngIf="isViewSubscription">
          <div *ngIf="reportingState === dspWorklistConstants.SUBSCRIBED && this.isMultiProductWorkFlow">
            <div class="bill-to-party-row" *ngIf="!this.selectedAssetDetails?.isMaskRequired && !hideBillToParty">
              <span class="bill-to-party">Bill to Party:</span>&nbsp;<span
                *ngIf="subsPricingEligible && !isShowPriceIfNoBilling"
                class="bill-value"
                >{{ assetPlanPaymentDetails?.billToParty || '-' }}</span
              >
              <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value"> {{ '-' }}</span>
              <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                assetPlanPaymentDetails?.billToParty || '-'
              }}</span>
            </div>
            <div class="payment-details-row">
              <span class="bill-value">Current Plan(s)</span>
            </div>

            <div *ngFor="let plan of this.subscribedPlansJson?.servicesSubscribedDTO">
              <div class="payment-block">
                <div class="payment-details-row2">
                  <span class="product-title">Product: </span
                  ><span class="product-value">{{ plan?.appName || '-' }}</span>
                </div>
                <div class="payment-details-row2" *ngIf="!this.selectedAssetDetails?.isMaskRequired && hideBillToParty">
                  <span class="product-title">Bill to Party: </span>
                  <span
                    class="product-title"
                    *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling"
                    class="bill-value"
                  >
                    {{ '-' }}</span
                  >
                  <span class="product-title" *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                    plan?.billToParty || '-'
                  }}</span>
                </div>
                <ng-container>
                  <div class="payment-details-row2">
                    <span class="product-title"> Plan:</span>&nbsp;
                    <div style="display: inline" *ngFor="let level of plan?.customerLevel">
                      <span
                        class="product-value"
                        cc-tooltip="{{ (level?.planName).length > 32 ? level?.planName : null }}"
                        [tooltipPosition]="'top'"
                        *ngIf="level.type === 'Base'"
                        (copy)="copyEvent($event, level?.planName, 'Plan')"
                        >{{ checkForPlanTrim(level?.planName) || '-' }}</span
                      >
                      <span class="payment-dollar" *ngIf="level?.type === 'Base' && subsPricingEligible">{{
                        displayAmount(level?.amount)
                      }}</span>
                    </div>
                  </div>
                </ng-container>

                <div class="payment-details-row2">
                  <span class="product-title"> Optional Plan(s):</span>&nbsp;
                  <div style="display: inline" *ngFor="let level of plan?.optionalPlans; let i = index">
                    <ng-container
                      *ngIf="getOptionalPlanLength(this.telUserType == 'CAT' ? plan?.catLevel : plan?.dealerLevel) >= 1"
                    >
                      <span
                        class="product-value"
                        cc-tooltip="{{ (level?.planName).length > 32 ? level?.planName : null }}"
                        [tooltipPosition]="'top'"
                        *ngIf="i === 0"
                        (copy)="copyEvent($event, level?.planName, 'Plan')"
                        >{{ checkForPlanTrim(level?.planName) || '-' }}</span
                      >
                      <span class="payment-dollar" *ngIf="subsPricingEligible && i === 0">{{
                        displayAmount(level?.amount)
                      }}</span>
                    </ng-container>
                  </div>
                  <span
                    *ngIf="getOptionalPlanLength(this.telUserType == 'CAT' ? plan?.catLevel : plan?.dealerLevel) === 0"
                  >
                    -
                  </span>
                </div>
                <div
                  *ngIf="getOptionalPlanLength(this.telUserType == 'CAT' ? plan?.catLevel : plan?.dealerLevel) > 1"
                  class="detail-tiles"
                >
                  <a
                    class="link"
                    (click)="
                      openDrawerInMultiSizeForMultiProduct(
                        plan?.appName,
                        this.telUserType == 'CAT' ? plan?.catLevel : plan?.dealerLevel
                      )
                    "
                  >
                    See more
                  </a>
                </div>
              </div>

              <div *ngIf="subscribedPlansJson?.productFamily && minestarCheck(plan)">
                <div class="payment-details-row">
                  <span class="bill-value">Product Family</span><br /><span class="promotion-value">{{
                    subscribedPlansJson?.productFamily || ''
                  }}</span>
                </div>
              </div>

              <div>
                <ng-container
                  *ngIf="
                    (subsPricingEligible &&
                      (!plan?.promotions ||
                        plan?.promotions?.length === 0 ||
                        this.selectedAssetDetails?.isMaskRequired)) ||
                    (subsPricingEligible &&
                      !this.selectedAssetDetails?.isMaskRequired &&
                      !doesPromoExistForAppName(plan?.promotions))
                  "
                >
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">-</span>
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">-</span>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    !isShowPriceIfNoBilling &&
                    !this.selectedAssetDetails?.isMaskRequired &&
                    subsPricingEligible &&
                    doesPromoExistForAppName(plan?.promotions)
                  "
                >
                  <ng-container *ngFor="let promo of plan?.promotions">
                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                        promo?.name || '-'
                      }}</span>
                      <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                    </div>

                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Expiration Date</span><br />
                      <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="
                    isShowPriceIfNoBilling &&
                    !this.selectedAssetDetails?.isMaskRequired &&
                    subsPricingEligible &&
                    doesPromoExistForAppName(plan?.promotions)
                  "
                >
                  <ng-container *ngFor="let promo of plan?.promotions">
                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                        promo?.name || '-'
                      }}</span>
                      <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                    </div>

                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Expiration Date</span><br />
                      <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="
                    isShowPriceIfNoBilling &&
                    !this.selectedAssetDetails?.isMaskRequired &&
                    !subsPricingEligible &&
                    doesPromoExistForAppName(plan?.promotions)
                  "
                >
                  <ng-container *ngFor="let promo of plan?.promotions">
                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                        promo?.name || '-'
                      }}</span>
                      <span class="payment-dollar">{{ '-' }} </span><br />
                    </div>

                    <div class="payment-details-row" *ngIf="promo?.promoName !== null">
                      <span class="bill-value">Promotion Expiration Date</span><br />
                      <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="
                    plan?.prepay &&
                    plan?.customerLevel?.length > 0 &&
                    !isShowPriceIfNoBilling &&
                    subsPricingEligible &&
                    vlCheck(plan?.appName)
                  "
                >
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(plan?.contractStartDate) || '-'
                        : '-'
                    }}</span>
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired ? getConvertedDate(plan?.contractEndDate) || '-' : '-'
                    }}</span>
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? plan?.duration || '-' : '-' }}
                    </span>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    plan?.prepay && plan?.customerLevel?.length > 0 && isShowPriceIfNoBilling && vlCheck(plan?.appName)
                  "
                >
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(plan?.contractStartDate) || '-'
                        : '-'
                    }}</span>
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired ? getConvertedDate(plan?.contractEndDate) || '-' : '-'
                    }}</span>
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? plan?.duration || '-' : '-' }}
                    </span>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="subsPricingEligible" class="payment-block">
                <div class="payment-details-row2">
                  <span class="product-title"> Estimated Total</span>

                  <span class="payment-dollar">
                    {{ displayAmount(plan?.monthlyRateAfterDiscount) }}
                    <span
                      *ngIf="assetPlanPaymentDetails?.current && displayAmount(plan?.monthlyRateAfterDiscount) !== '-'"
                      >{{
                        !this.selectedAssetDetails?.isMaskRequired ? (plan?.prepay ? '(Prepaid)' : '(Monthly)') : ''
                      }}</span
                    >
                  </span>
                </div>
              </div>

              <div class="note-text" *ngIf="!customUserCheck()">
                <div>
                  <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
                </div>
                <div
                  *ngIf="subsPricingEligible && !isPaymentPreviewMessage"
                  id="paymentMessage"
                  [innerHTML]="plan?.paymentMessage || ''"
                ></div>
                <div
                  *ngIf="!subsPricingEligible && !isPaymentPreviewMessage"
                  id="paymentMessage"
                  [innerHTML]="dspWorklistConstants.NON_BILLED_DEALER_MESSAGE"
                ></div>
                <div *ngIf="isPaymentPreviewMessage" id="paymentMessage" [innerHTML]="plan?.paymentMessage || ''"></div>
              </div>
            </div>
          </div>

          <div *ngIf="reportingState === dspWorklistConstants.SUBSCRIBED && !this.isMultiProductWorkFlow">
            <div class="bill-to-party-row" *ngIf="!this.selectedAssetDetails?.isMaskRequired && !hideBillToParty">
              <span class="bill-to-party">Bill to Party:</span>&nbsp;<span
                *ngIf="subsPricingEligible && !isShowPriceIfNoBilling"
                class="bill-value"
                >{{ assetPlanPaymentDetails?.billToParty || '-' }}</span
              >
              <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{ '-' }}</span>
              <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                assetPlanPaymentDetails?.billToParty || '-'
              }}</span>
            </div>
            <div class="payment-details-row"><span class="bill-value">Current Plan(s)</span></div>
            <div class="payment-block">
              <div class="payment-details-row2">
                <span class="product-title"> Product:</span>&nbsp;<span class="product-value">{{
                  applicationName || '-'
                }}</span>
              </div>
              <div class="payment-details-row2" *ngIf="hideBillToParty">
                <span class="product-title">Bill to Party: </span
                ><span *ngIf="subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{
                  selectedAssetDetails?.paymentDetails?.billToParty || '-'
                }}</span>
                <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{ '-' }}</span>
                <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                  selectedAssetDetails?.paymentDetails?.billToParty || '-'
                }}</span>
              </div>
              <ng-container>
                <div class="payment-details-row2">
                  <span class="product-title"> Plan:</span>&nbsp;
                  <ng-container *ngFor="let plan of plans">
                    <span
                      class="product-value"
                      cc-tooltip="{{ (plan?.name).length > 45 ? this.planTooltip : null }}"
                      [tooltipPosition]="'top'"
                      *ngIf="plan.type === 'Base Plan'"
                      (copy)="copyEvent($event, plan?.name, 'Plan')"
                      >{{ plan?.name || '-' }}</span
                    >
                    <span class="payment-dollar" *ngIf="plan.type === 'Base Plan' && subsPricingEligible">{{
                      displayAmount(plan?.amount)
                    }}</span>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Commented as a part of Converting EI/VIMS to Single Level Plans Feature. -->
              <!-- <ng-container *ngIf="multiLevelServices.indexOf(applicationName) !== -1">
                <div class="payment-details-row2">
                  <span class="product-title">Customer Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ custSubsTooltip }}" [tooltipPosition]="'top'">{{
                    custSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">Dealer Plan:</span>&nbsp;
                  <ng-container *ngFor="let plan of plans">
                    <span
                      class="product-value"
                      cc-tooltip="{{ dealerSubsTooltip }}"
                      [tooltipPosition]="'top'"
                      *ngIf="plan.type === 'Base Plan'"
                      >{{ dealerSubs || '-' }}</span
                    ><span class="payment-dollar" *ngIf="plan?.type === 'Base Plan'">{{
                      displayAmount(plan?.amount)
                    }}</span>
                  </ng-container>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">CAT Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ catSubsTooltip }}" [tooltipPosition]="'top'">{{
                    catSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
              </ng-container> -->
              <div class="payment-details-row2">
                <span class="product-title"> Optional Plan(s):</span>&nbsp;
                <ng-container *ngIf="optionalPlans && optionalPlans.length >= 1">
                  <span
                    class="product-value"
                    cc-tooltip="{{ (optionalPlans[0]?.name).length > 32 ? this.opTooltip : null }}"
                    [tooltipPosition]="'top'"
                    (copy)="copyEvent($event, optionalPlans[0]?.name, 'Plan')"
                    >{{ optionalPlans[0]?.name || '-' }}</span
                  >
                  <span class="payment-dollar" *ngIf="subsPricingEligible">{{
                    displayAmount(optionalPlans[0]?.amount)
                  }}</span>
                </ng-container>
                <span *ngIf="optionalPlans && optionalPlans.length === 0"> - </span>
              </div>
              <div *ngIf="optionalPlans && optionalPlans.length >= 2" class="detail-tiles">
                <a class="link" (click)="openDrawerInMultiSize()"> See more </a>
              </div>
            </div>
            <div *ngIf="selectedAssetDetails?.productFamily">
              <div class="payment-details-row">
                <span class="bill-value">Product Family</span><br /><span class="promotion-value">{{
                  selectedAssetDetails?.productFamily || ''
                }}</span>
              </div>
            </div>
            <div>
              <ng-container
                *ngIf="
                  subsPricingEligible &&
                  (!assetPlanPaymentDetails?.current?.promotions ||
                    assetPlanPaymentDetails?.current?.promotions.length === 0 ||
                    this.selectedAssetDetails?.isMaskRequired)
                "
              >
                <div class="payment-details-row">
                  <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">-</span>
                </div>
                <div class="payment-details-row">
                  <span class="bill-value">Promotion Expiration Date</span><br />
                  <span class="promotion-value">-</span>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!isShowPriceIfNoBilling && subsPricingEligible && !this.selectedAssetDetails?.isMaskRequired"
              >
                <ng-container *ngFor="let promo of assetPlanPaymentDetails?.current?.promotions">
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                      promo?.name || '-'
                    }}</span>
                    <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="isShowPriceIfNoBilling && subsPricingEligible && !this.selectedAssetDetails?.isMaskRequired"
              >
                <ng-container *ngFor="let promo of assetPlanPaymentDetails?.current?.promotions">
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                      promo?.name || '-'
                    }}</span>
                    <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="isShowPriceIfNoBilling && !subsPricingEligible && !this.selectedAssetDetails?.isMaskRequired"
              >
                <ng-container *ngFor="let promo of assetPlanPaymentDetails?.current?.promotions">
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                      promo?.name || '-'
                    }}</span>
                    <span class="payment-dollar">{{ '-' }} </span><br />
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let product of assetPlanPaymentDetails?.current?.products">
                <div *ngIf="!isShowPriceIfNoBilling && subsPricingEligible">
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractStartDate) || '-'
                        : '-'
                    }}</span>
                  </div>

                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractEndDate) || '-'
                        : '-'
                    }}</span>
                  </div>
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? product?.duration || '-' : '-' }}
                    </span>
                  </div>
                </div>
                <div *ngIf="isShowPriceIfNoBilling">
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractStartDate) || '-'
                        : '-'
                    }}</span>
                  </div>

                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractEndDate) || '-'
                        : '-'
                    }}</span>
                  </div>
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === plans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? product?.duration || '-' : '-' }}
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="subsPricingEligible" class="payment-block">
              <div class="payment-details-row2">
                <span class="product-title"> Estimated Total</span>

                <span class="payment-dollar">
                  {{ displayAmount(assetPlanPaymentDetails?.current?.monthlyRateAfterDiscount) }}
                  <span
                    *ngIf="
                      assetPlanPaymentDetails?.current &&
                      displayAmount(assetPlanPaymentDetails?.current?.monthlyRateAfterDiscount) !== '-'
                    "
                    >{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? selectedAssetDetails?.prepay
                          ? '(Prepaid)'
                          : '(Monthly)'
                        : ''
                    }}</span
                  >
                </span>
              </div>
            </div>
            <div class="note-text" *ngIf="!customUserCheck()">
              <div>
                <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
              </div>
              <div
                *ngIf="subsPricingEligible && !isPaymentPreviewMessage"
                id="paymentMessage"
                [innerHTML]="paymentMessage || ''"
              ></div>
              <div
                *ngIf="!subsPricingEligible && !isPaymentPreviewMessage"
                id="paymentMessage"
                [innerHTML]="dspWorklistConstants.NON_BILLED_DEALER_MESSAGE"
              ></div>
              <div *ngIf="isPaymentPreviewMessage" id="paymentMessage" [innerHTML]="paymentMessage || ''"></div>
            </div>
          </div>
          <div style="margin-top: 65px !important" *ngIf="reportingState === dspWorklistConstants.UNSUBSCRIBED">
            <img class="no-results-notfound-image" src="..\assets\images\unsubscribed-plan-and-payment.svg" />
            <p class="no-plans-text">No plans have been added</p>
          </div>
          <div style="margin-top: 65px !important" *ngIf="reportingState === dspWorklistConstants.SUSPENDED">
            <img class="no-results-notfound-image" src="..\assets\images\suspended-plan-and-payment.svg" />
            <p class="no-plans-text">
              Transmission suspended
              <cc-icon
                *ngIf="isSuspendedForLocationUnauthorized && showInfoIcon"
                [cc-tooltip]="''"
                [template]="infoTooltipTemplateForLocation"
                [tooltipPosition]="'top'"
                class="icon-size"
                >info-tooltip</cc-icon
              >
              <cc-icon
                *ngIf="!isSuspendedForLocationUnauthorized && showInfoIcon"
                [cc-tooltip]="''"
                [template]="infoTooltipTemplate"
                [tooltipPosition]="'top'"
                class="icon-size"
                >info-tooltip</cc-icon
              >
            </p>
            <div class="unsuspension">
              <span class="btn-unsuspension">
                <button
                  cc-btn-secondary
                  cc-btn-medium-2
                  [setTheme]="true"
                  *ngIf="showunsuspensionBtn"
                  (click)="requestUnsuspension()"
                >
                  Request Unsuspension
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="asset-drawer-table-container" *ngIf="isNewSubscription">
          <div
            *ngIf="
              this.selectedAssetDetails.reportingState === dspWorklistConstants.SUBSCRIBED &&
              (!this.isMultiProductWorkFlow || isSelfServeSubscription)
            "
          >
            <div class="bill-to-party-row" *ngIf="!hideBillToParty">
              <span class="bill-to-party">Bill to Party:</span>&nbsp;<span
                *ngIf="subsPricingEligible && !isShowPriceIfNoBilling"
                class="bill-value"
                >{{ assetPlanPaymentDetails?.billToParty || '-' }}</span
              >
              <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{ '-' }}</span>
              <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                assetPlanPaymentDetails?.billToParty || '-'
              }}</span>
            </div>

            <div class="payment-details-row"><span class="bill-value">Current Plan(s)</span></div>
            <div class="payment-block">
              <div class="payment-details-row2">
                <span class="product-title"> Product:</span>&nbsp;<span class="product-value">{{
                  newApplicationName || '-'
                }}</span>
              </div>
              <div class="bill-to-party-row" *ngIf="hideBillToParty">
                <span class="bill-to-party">Bill to Party:</span>&nbsp;<span
                  *ngIf="subsPricingEligible && !isShowPriceIfNoBilling"
                  class="bill-value"
                  >{{ selectedAssetDetails?.paymentDetails?.billToParty || '-' }}</span
                >
                <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{ '-' }}</span>
                <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                  selectedAssetDetails?.paymentDetails?.billToParty || '-'
                }}</span>
              </div>
              <ng-container>
                <div class="payment-details-row2">
                  <span class="product-title"> Plan:</span>&nbsp;
                  <ng-container *ngFor="let plan of newPlans">
                    <span
                      class="product-value"
                      cc-tooltip="{{ (plan?.name).length > 45 ? this.newPlanTooltip : null }}"
                      [tooltipPosition]="'top'"
                      *ngIf="plan.type === 'Base Plan'"
                      (copy)="copyEvent($event, plan?.name, 'Plan')"
                      >{{ plan?.name || '-' }}</span
                    >
                    <span class="payment-dollar" *ngIf="plan.type === 'Base Plan' && subsPricingEligible">{{
                      displayAmount(plan?.amount)
                    }}</span>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Commented as a part of  EI/VIMS as Single Level Plans Feature. -->
              <!-- <ng-container *ngIf="multiLevelServices.indexOf(newApplicationName) !== -1">
                <div class="payment-details-row2">
                  <span class="product-title">Customer Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ newCustSubsTooltip }}" [tooltipPosition]="'top'">{{
                    newCustSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">Dealer Plan:</span>&nbsp;
                  <ng-container *ngFor="let plan of newPlans">
                    <span
                      class="product-value"
                      cc-tooltip="{{ newDealerSubsTooltip }}"
                      [tooltipPosition]="'top'"
                      *ngIf="plan.type === 'Base Plan'"
                      >{{ newDealerSubs || '-' }}</span
                    ><span class="payment-dollar" *ngIf="plan?.type === 'Base Plan'">{{
                      displayAmount(plan?.amount)
                    }}</span>
                  </ng-container>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">CAT Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ newCatSubsTooltip }}" [tooltipPosition]="'top'">{{
                    newCatSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
              </ng-container> -->
              <div class="payment-details-row2">
                <span class="product-title"> Optional Plan(s):</span>&nbsp;
                <ng-container *ngIf="newOptionalPlans && newOptionalPlans.length >= 1">
                  <span
                    class="product-value"
                    cc-tooltip="{{ (newOptionalPlans[0]?.name).length > 32 ? this.newOpTooltip : null }}"
                    [tooltipPosition]="'top'"
                    (copy)="copyEvent($event, newOptionalPlans[0]?.name, 'Plan')"
                    >{{ newOptionalPlans[0]?.name || '-' }}</span
                  >

                  <span class="payment-dollar" *ngIf="subsPricingEligible">{{
                    displayAmount(newOptionalPlans[0]?.amount)
                  }}</span>
                </ng-container>
                <span *ngIf="newOptionalPlans && newOptionalPlans.length === 0"> - </span>
              </div>
              <div *ngIf="newOptionalPlans && newOptionalPlans.length >= 2" class="detail-tiles">
                <a class="link" (click)="openDrawerInMultiSizeWithNewPlans()"> See more </a>
              </div>
            </div>
            <div *ngIf="selectedAssetDetails?.productFamily">
              <div class="payment-details-row">
                <span class="bill-value">Product Family</span><br /><span class="promotion-value">{{
                  selectedAssetDetails?.productFamily || ''
                }}</span>
              </div>
            </div>
            <div>
              <ng-container
                *ngIf="
                  subsPricingEligible &&
                  (!assetPlanPaymentDetails?.new?.promotions ||
                    assetPlanPaymentDetails?.new?.promotions.length === 0 ||
                    this.selectedAssetDetails?.isMaskRequired)
                "
              >
                <div class="payment-details-row">
                  <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">-</span>
                </div>
                <div class="payment-details-row">
                  <span class="bill-value">Promotion Expiration Date</span><br />
                  <span class="promotion-value">-</span>
                </div>
              </ng-container>

              <ng-container *ngIf="subsPricingEligible && !this.selectedAssetDetails?.isMaskRequired">
                <ng-container *ngFor="let promo of assetPlanPaymentDetails?.new?.promotions">
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                      promo?.name || '-'
                    }}</span>
                    <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngFor="let product of assetPlanPaymentDetails?.new?.products">
                <div *ngIf="subsPricingEligible">
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === newPlans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractStartDate) || '-'
                        : '-'
                    }}</span>
                  </div>

                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === newPlans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired
                        ? getConvertedDate(product?.contractEndDate) || '-'
                        : '-'
                    }}</span>
                  </div>
                  <div
                    *ngIf="selectedAssetDetails?.prepay && product.name === newPlans[0].name"
                    class="payment-details-row"
                  >
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? product?.duration || '-' : '-' }}
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="subsPricingEligible" class="payment-block">
              <div class="payment-details-row2">
                <span class="product-title"> Estimated Total</span>

                <span class="payment-dollar">
                  {{ displayAmount(assetPlanPaymentDetails?.new?.monthlyRateAfterDiscount) }}
                  <span *ngIf="assetPlanPaymentDetails?.new">{{
                    !this.selectedAssetDetails?.isMaskRequired
                      ? selectedAssetDetails?.prepay
                        ? '(Prepaid)'
                        : '(Monthly) '
                      : ''
                  }}</span>
                </span>
              </div>
            </div>
            <div class="note-text" *ngIf="!customUserCheck()">
              <div>
                <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
              </div>
              <div
                *ngIf="subsPricingEligible && !isPaymentPreviewMessage"
                id="paymentMessage"
                [innerHTML]="paymentMessage || ''"
              ></div>
              <div
                *ngIf="!subsPricingEligible && !isPaymentPreviewMessage"
                id="paymentMessage"
                [innerHTML]="dspWorklistConstants.NON_BILLED_DEALER_MESSAGE"
              ></div>
              <div *ngIf="isPaymentPreviewMessage" id="paymentMessage" [innerHTML]="paymentMessage || ''"></div>
            </div>
          </div>
          <div
            *ngIf="
              this.selectedAssetDetails.reportingState === dspWorklistConstants.SUBSCRIBED &&
              this.isMultiProductWorkFlow &&
              !isSelfServeSubscription
            "
          >
            <div class="bill-to-party-row" *ngIf="!hideBillToParty">
              <span class="bill-to-party">Bill to Party:</span>&nbsp;<span
                *ngIf="subsPricingEligible && !isShowPriceIfNoBilling"
                class="bill-value"
                >{{ assetPlanPaymentDetails?.billToParty || '-' }}</span
              >
              <span *ngIf="!subsPricingEligible && !isShowPriceIfNoBilling" class="bill-value">{{ '-' }}</span>
              <span *ngIf="isShowPriceIfNoBilling" class="bill-value">{{
                assetPlanPaymentDetails?.billToParty || '-'
              }}</span>
            </div>
            <div class="payment-details-row"><span class="bill-value">Current Plan(s)</span></div>
            <div *ngFor="let app of newSubscribedPlansJson">
              <div class="payment-block">
                <div class="payment-details-row2">
                  <span class="product-title"> Product:</span>&nbsp;<span class="product-value">{{
                    app.appName || '-'
                  }}</span>
                </div>
                <div class="payment-details-row2" *ngIf="hideBillToParty">
                  <span class="product-title">Bill to Party:</span>&nbsp;<span class="product-value">{{
                    app.billToParty || '-'
                  }}</span>
                </div>
                <ng-container>
                  <div class="payment-details-row2">
                    <span class="product-title"> Plan:</span>&nbsp;
                    <div style="display: inline" *ngFor="let plan of app?.plans">
                      <ng-container>
                        <span
                          class="product-value"
                          cc-tooltip="{{ (plan?.planName).length > 32 ? plan?.planName : null }}"
                          [tooltipPosition]="'top'"
                          *ngIf="plan.type === 'Base'"
                          (copy)="copyEvent($event, plan?.planName, 'Plan')"
                          >{{ checkForPlanTrim(plan?.planName) || '-' }}</span
                        >
                        <span class="payment-dollar" *ngIf="plan?.type === 'Base' && subsPricingEligible">{{
                          displayAmount(plan?.amount)
                        }}</span>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <!-- Commented as a part of  EI/VIMS as Single Level Plans Feature. -->
                <!-- <ng-container *ngIf="multiLevelServices.indexOf(newApplicationName) !== -1">
                <div class="payment-details-row2">
                  <span class="product-title">Customer Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ newCustSubsTooltip }}" [tooltipPosition]="'top'">{{
                    newCustSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">Dealer Plan:</span>&nbsp;
                  <ng-container *ngFor="let plan of newPlans">
                    <span
                      class="product-value"
                      cc-tooltip="{{ newDealerSubsTooltip }}"
                      [tooltipPosition]="'top'"
                      *ngIf="plan.type === 'Base Plan'"
                      >{{ newDealerSubs || '-' }}</span
                    ><span class="payment-dollar" *ngIf="plan?.type === 'Base Plan'">{{
                      displayAmount(plan?.amount)
                    }}</span>
                  </ng-container>
                </div>
                <div class="payment-details-row2">
                  <span class="product-title">CAT Plan:</span>&nbsp;
                  <span class="product-value" cc-tooltip="{{ newCatSubsTooltip }}" [tooltipPosition]="'top'">{{
                    newCatSubs || '-'
                  }}</span>
                  <span class="payment-dollar">{{ '-' }}</span>
                </div>
              </ng-container> -->
                <div class="payment-details-row2">
                  <span class="product-title"> Optional Plan(s):</span>&nbsp;
                  <div style="display: inline" *ngFor="let plan of app?.plans; let i = index">
                    <ng-container *ngIf="getOptionalPlanLength(app?.plans) >= 1">
                      <span
                        class="product-value"
                        cc-tooltip="{{ (plan?.planName).length > 32 ? plan?.planName : null }}"
                        [tooltipPosition]="'top'"
                        *ngIf="plan?.type === 'Addon' && i <= 1"
                        (copy)="copyEvent($event, plan?.planName, 'Plan')"
                        >{{ checkForPlanTrim(plan?.planName) || '-' }}</span
                      >

                      <span class="payment-dollar" *ngIf="subsPricingEligible && plan?.type === 'Addon' && i <= 1">{{
                        displayAmount(plan?.amount)
                      }}</span>
                    </ng-container>
                  </div>
                  <span *ngIf="getOptionalPlanLength(app?.plans) === 0"> - </span>
                </div>
                <div *ngIf="getOptionalPlanLength(app?.plans) > 1" class="detail-tiles">
                  <a class="link" (click)="openDrawerInMultiSizeForMultiProduct(app?.appName, app?.plans)">
                    See more
                  </a>
                </div>
              </div>
              <div *ngIf="selectedAssetDetails?.productFamily && minestarCheck(app)">
                <div class="payment-details-row">
                  <span class="bill-value">Product Family</span><br /><span class="promotion-value">{{
                    selectedAssetDetails?.productFamily || ''
                  }}</span>
                </div>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    (subsPricingEligible &&
                      (!app?.promotions ||
                        app?.promotions.length === 0 ||
                        this.selectedAssetDetails?.isMaskRequired)) ||
                    (subsPricingEligible &&
                      !this.selectedAssetDetails?.isMaskRequired &&
                      !doesPromoExistForAppName(app?.promotions))
                  "
                >
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">-</span>
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Promotion Expiration Date</span><br />
                    <span class="promotion-value">-</span>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="
                    subsPricingEligible &&
                    !this.selectedAssetDetails?.isMaskRequired &&
                    doesPromoExistForAppName(app?.promotions)
                  "
                >
                  <ng-container *ngFor="let promo of app?.promotions">
                    <div class="payment-details-row">
                      <span class="bill-value">Promotion Name</span><br /><span class="promotion-value">{{
                        promo?.name || '-'
                      }}</span>
                      <span class="payment-dollar">{{ displayAmount(promo?.amount) || '-' }} </span><br />
                    </div>
                    <div class="payment-details-row">
                      <span class="bill-value">Promotion Expiration Date</span><br />
                      <span class="promotion-value">{{ getConvertedDate(promo?.promotionEndDate) || '-' }}</span>
                    </div>
                  </ng-container>
                </ng-container>

                <div *ngIf="subsPricingEligible && vlCheck(app?.appName) && app?.prepay">
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Start Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired ? getConvertedDate(app?.contractStartDate) || '-' : '-'
                    }}</span>
                  </div>

                  <div class="payment-details-row">
                    <span class="bill-value">Contract End Date</span><br /><span class="promotion-value">{{
                      !this.selectedAssetDetails?.isMaskRequired ? getConvertedDate(app?.contractEndDate) || '-' : '-'
                    }}</span>
                  </div>
                  <div class="payment-details-row">
                    <span class="bill-value">Contract Duration</span><br /><span class="promotion-value"
                      >{{ !this.selectedAssetDetails?.isMaskRequired ? app?.duration || '-' : '-' }}
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="subsPricingEligible" class="payment-block">
                <div class="payment-details-row2">
                  <span class="product-title"> Estimated Total</span
                  ><span class="payment-dollar" *ngIf="app?.monthlyRateAfterDiscount === 'N/A'"> - </span>
                  <span class="payment-dollar" *ngIf="app?.monthlyRateAfterDiscount !== 'N/A'">
                    {{ !this.selectedAssetDetails?.isMaskRequired ? app?.monthlyRateAfterDiscount : '-' }}
                    <span *ngIf="app?.monthlyRateAfterDiscount">{{
                      !this.selectedAssetDetails?.isMaskRequired ? (app?.prepay ? '(Prepaid)' : '(Monthly) ') : ''
                    }}</span>
                  </span>
                </div>
              </div>
              <div class="note-text" *ngIf="!customUserCheck()">
                <div>
                  <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
                </div>
                <div
                  *ngIf="subsPricingEligible && !isPaymentPreviewMessage"
                  id="paymentMessage"
                  [innerHTML]="app?.paymentMessage || ''"
                ></div>
                <div
                  *ngIf="!subsPricingEligible && !isPaymentPreviewMessage"
                  id="paymentMessage"
                  [innerHTML]="dspWorklistConstants.NON_BILLED_DEALER_MESSAGE"
                ></div>
                <div *ngIf="isPaymentPreviewMessage" id="paymentMessage" [innerHTML]="app?.paymentMessage || ''"></div>
              </div>
            </div>
          </div>
          <div
            style="margin-top: 65px !important"
            *ngIf="this.selectedAssetDetails.reportingState === dspWorklistConstants.UNSUBSCRIBED"
          >
            <img class="no-results-notfound-image" src="..\assets\images\unsubscribed-plan-and-payment.svg" />
            <p class="no-plans-text">No plans have been added</p>
          </div>
          <div
            style="margin-top: 65px !important"
            *ngIf="this.selectedAssetDetails.reportingState === dspWorklistConstants.SUSPENDED"
          >
            <img class="no-results-notfound-image" src="..\assets\images\suspended-plan-and-payment.svg" />
            <p class="no-plans-text">
              Transmission suspended
              <cc-icon [cc-tooltip]="''" [template]="infoTooltipTemplate" [tooltipPosition]="'top'" class="icon-size"
                >info-tooltip</cc-icon
              >
            </p>
            <div class="unsuspension">
              <span class="btn-unsuspension">
                <button
                  cc-btn-secondary
                  cc-btn-medium-2
                  [setTheme]="true"
                  *ngIf="showunsuspensionBtn"
                  (click)="requestUnsuspension()"
                >
                  Request Unsuspension
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </cc-card-content>
  </cc-card>
</div>

<ng-template #infoTooltipTemplateForLocation>
  <p class="info-tooltip">
    Service levels cannot be set for this<br />
    asset because device transmission<br />
    has been suspended.
  </p>
</ng-template>

<ng-template #infoTooltipTemplate>
  <p class="info-tooltip">
    Service levels cannot be set for this<br />
    asset because device transmission<br />
    has been suspended. Please contact<br />
    Product Support to reverse suspension.
  </p>
</ng-template>
