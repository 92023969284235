import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { CcDrawerConfig, DrawerService } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { PopupData } from 'apps/dsp-ui/src/app/models/popup.interface';
import * as dspConstants from 'apps/dsp-ui/src/app/shared/dspConstants';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
import { HomeService } from '../services/home.service';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-home-quick-links',
  templateUrl: './home-quick-links.component.html',
  styleUrls: ['./home-quick-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeQuickLinksComponent implements OnInit {
  systemError = dspConstants.Common.SYSTEM_ERROR;
  settingDrawerRef: any;
  isCloseEvent = false;
  isSettingsOutSideClicked = false;
  isTableSettingClose = false;
  isTableSettingsApply = false;
  tableSettingsPanelConfig!: ITableSettings;
  settingsPanelConfig = {
    settingsPanelHeader: 'Quick Links',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  modalRef: any;
  updatedLinks: any = [];
  constructor(
    public modal: CcModal,
    private homeService: HomeService,
    private drawerService: DrawerService,
    private cdr: ChangeDetectorRef,
    private messageBar: MessageBar
  ) {}
  quickLinks: any;

  ngOnInit(): void {
    this.initialValues();
  }

  initialValues() {
    this.homeService.getQuickLinks().subscribe({
      next: (result: any) => {
        this.quickLinks = result;
        this.createSettingsPanelConfig();
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  createSettingsPanelConfig() {
    const trimmedTableConfig = this.quickLinks?.map((link: any) => ({
      title: link.title
        ? link.title?.split(' ')[0].length > 30
          ? `${link.title.substring(0, 30)}...`
          : link.title
        : '',
      name: link.id ? link.id.toString() : '',
      isDsp2Link: link.isDsp2Link ? link.isDsp2Link : false,
      url: link.url ? link.url : '',
      userId: link.userId ? link.userId : null,
      isVisible: link.isSelected ? link.isSelected : false,
      isAlwaysMandatory: false,
    }));
    this.tableSettingsPanelConfig = {
      features: trimmedTableConfig,
      visibilityToggle: false,
      ITableSettingsPanelTooltip: {
        drawerVisibleTooltip: 'Selection is limited to a maximum of 8 columns',
        columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
        columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
        drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
      },
    };
  }

  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalLinks = this.quickLinks;
    let isConfirmationPopupNeeded = false;
    for (let i = 0; event?.features?.length > i; i++) {
      const data = originalLinks.find((dataObj: { id: any }) => event?.features[i]?.name === dataObj?.id.toString());
      const indexOfOriginalColumnConfig = originalLinks.indexOf(data);
      if (data) {
        if (typeof data.isSelected === 'undefined') data.isSelected = false;
        if (data.isSelected !== event.features[i].isVisible || indexOfOriginalColumnConfig !== i) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    const popupData: PopupData = {
      title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
      messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
      showCrossMark: true,
      showPrimaryButton: true,
      showSecondaryButton: true,
      primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
      secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
    };
    this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
      width: '534px',
      type: 'semi-modal',
      data: popupData,
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.modalRef.afterClosed().subscribe((result: any) => {
      if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
        this.isCloseEvent = false;
        this.isTableSettingsApply = true;
        this.closeSettingDrawer();
        setTimeout(() => {
          this.isTableSettingsApply = false;
        }, 500);
      } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
        this.isCloseEvent = false;
        this.isTableSettingClose = true;
        this.closeSettingDrawer();
        setTimeout(() => {
          this.isTableSettingClose = false;
        }, 500);
      } else if (result === dspConstants.PopupButtons.CROSS) {
        this.isCloseEvent = false;
      }
    });
  }

  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      this.updatedLinks = [];
      const originalLinks = cloneDeep(this.quickLinks);
      event.features.forEach((config, index) => {
        const data = originalLinks.find((dataObj: { id: string }) => config.name === dataObj.id.toString());
        if (data) {
          data.isSelected = config.isVisible;
          data.orderId = index + 1;
        }
        this.updatedLinks.push(data);
      });
      this.homeService.updateQuickLinks(this.updatedLinks).subscribe({
        error: (err: any) => {
          if (err.status === 202) {
            this.quickLinks = this.updatedLinks;
            this.createSettingsPanelConfig();
            this.cdr.detectChanges();
          } else {
            this.showToastMessage(this.systemError, 'error');
          }
        },
      });
    }
    this.isCloseEvent = false;
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
