import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { LicenseInfoComponent } from '../license-info/license-info.component';
import { LicenseInfoHeaderComponent } from '../license-info-header/license-info-header.component';
import { LicenseEligiblityHeaderComponent } from '../license-eligiblity-header/license-eligiblity-header.component';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { catchError, finalize, forkJoin, map, of } from 'rxjs';
import { AssetService } from '../../services/asset.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { LicenseEligiblityComponent } from '../license-eligiblity/license-eligiblity.component';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-request-license',
  templateUrl: './cat-grade-request-license.component.html',
  styleUrls: ['./cat-grade-request-license.component.scss'],
})
export class CatGradeRequestLicenseComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  catGradeLicenseTableData: any = [];
  validateNextBtnTableData: any = [];
  catGradeLicenseTableConfig = {
    columnConfig: [
      {
        name: 'type',
        title: 'Component',
        width: 150,
        formControl: 'span',
      },
      {
        name: 'serialNumber',
        title: 'Serial Number',
        width: 150,
        formControl: 'span',
      },
      {
        name: 'currentLicenseDesc',
        title: 'Current License',
        width: 100,
        formControl: 'span',
      },
      {
        name: 'currentLicenseStatus',
        title: 'License Request Status',
        width: 100,
        component: LicenseInfoComponent,
        headerComponent: LicenseInfoHeaderComponent,
      },
      {
        width: 200,
        name: 'nextUpgradableLicenseDesc',
        title: 'License Eligiblity',
        component: LicenseEligiblityComponent,
        headerComponent: LicenseEligiblityHeaderComponent,
      },
    ],
  };
  componentRes: any;
  loader = false;
  isBackBtnClciked = false;
  validatedLicenseTableData: any;
  techLevel: any;
  dspConfig: any;
  userActiveFeatures: any;
  subsPricingEligible = false;
  productFamilyCode: any;
  dspStoreData!: any;
  isLicenseMappingEnabled: any;
  multiLicenseRes: any;
  isMultiLicenseEnabled: any;
  isShowPriceIfNoBilling = false;
  isDemoEligibleDealer = false;
  dspHelpUrl: any;
  constructor(
    public assetDrawerService: AssetDrawerService,
    private cdr: ChangeDetectorRef,
    public assetService: AssetService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.dspHelpUrl = environment.redirectURL.helpDocUrl + '?language=en_US';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.dspConfig = dsp.dspConfig;
      this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
      this.isLicenseMappingEnabled = this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        : false;
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
    });
    this.assetDrawerService.catGradeEditInfo.subscribe((data: any) => {
      this.componentRes = data.editInfo.filter(
        (item: any) => item.ecmSerialNumber !== null && item.ecmSerialNumber !== '-' && item.ecmSerialNumber !== ''
      );
    });

    this.assetDrawerService.isBackBtnClicked.subscribe((data: any) => {
      this.isBackBtnClciked = data;
    });

    this.assetDrawerService.productFamilyCode.subscribe((data: any) => {
      this.productFamilyCode = data;
    });

    const customerInfo = this.selectedAssetSubscriptionFormData?.customer
      ? this.selectedAssetSubscriptionFormData?.customer
      : {};
    this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    if (!this.subsPricingEligible) {
      this.assetDrawerService.enableNextBtn.next(false);
    } else {
      this.assetDrawerService.enableNextBtn.next(true);
    }
  }

  ngAfterViewInit() {
    this.assetDrawerService.isRefreshBtnClicked.subscribe((data: any) => {
      if (data) {
        const updatedLicenseStatus = this.catGradeLicenseTableData.findIndex(
          (item: any) => item.serialNumber === data.serialNumber
        );
        if (updatedLicenseStatus !== -1) {
          this.catGradeLicenseTableData[updatedLicenseStatus].licenseStatus = data.status.toUpperCase();
          this.assetDrawerService.filteredPaymentPreviewData.next(this.catGradeLicenseTableData);
        }
      }
    });

    this.assetDrawerService.isDemoEligibleDealer.subscribe((data: any) => {
      this.isDemoEligibleDealer = data;
    });

    if (this.selectedAssetSubscriptionFormData?.isValidForm && !this.isBackBtnClciked) {
      const responses: any = [];
      let nextUpgradableLicenseDesc = '';
      const requests = this.componentRes.map((component: any, index: number) => {
        const updatedComponent = {
          ...component,
          productFamily: this.productFamilyCode,
        };
        this.loader = true;
        this.selectedAssetSubscriptionFormData.isValidForm = false;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        return this.assetDrawerService.validateSerialNo(updatedComponent).pipe(
          map((res: any) => {
            if (res.nextUpgradableLicenseDesc !== null && res.nextUpgradableLicenseDesc !== '') {
              nextUpgradableLicenseDesc = res.nextUpgradableLicenseDesc;
            } else if (
              (res.nextUpgradableLicenseDesc === null || res.nextUpgradableLicenseDesc === '') &&
              res.currentLicenseDesc &&
              res.currentLicenseStatus !== 'PENDING'
            ) {
              nextUpgradableLicenseDesc = 'No Upgrade Available';
            } else if (
              (res.currentLicenseDesc === null || res.currentLicenseDesc === '') &&
              res.currentLicenseStatus !== 'PENDING'
            ) {
              nextUpgradableLicenseDesc = 'Not Upgradable';
            }
            if (res.currentLicenseStatus === 'PENDING') {
              nextUpgradableLicenseDesc = '-';
            }
            if (this.techLevel !== null) {
              this.techLevel = res.techLevel;
              sessionStorage.setItem('techLevel', this.techLevel);
            }
            return {
              index,
              id: component.id ? component.id : '',
              type: res.type ? res.type : '-',
              serialNumber: res.serialNumber ? res.serialNumber : '-',
              currentLicenseDesc: res.currentLicenseDesc ? res.currentLicenseDesc : '-',
              licenseStatus: res.currentLicenseStatus ? res.currentLicenseStatus : '-',
              nextUpgradableLicenseDesc: nextUpgradableLicenseDesc,
              nextUpgradableLicensePartNum: res.nextUpgradableLicensePartNum ? res.nextUpgradableLicensePartNum : '-',
              licenseRequested: res.licenseRequested ? res.licenseRequested : '',
              currentLicensePartNum: res.currentLicensePartNum ? res.currentLicensePartNum : '',
              techLevel: res.techLevel ? res.techLevel : '',
              make: this.rowData && this.rowData.make ? this.rowData.make : '',
            };
          }),
          catchError((error: any) => {
            return of({
              index,
              id: component.id ? component.id : '',
              type: component.componentType ? component.componentType : '-',
              serialNumber: component.ecmSerialNumber ? component.ecmSerialNumber : '-',
              currentLicenseDesc: component.currentLicenseDesc ? component.currentLicenseDesc : '-',
              licenseStatus: component.licenseStatus ? component.licenseStatus : '-',
              nextUpgradableLicenseDesc: component.nextUpgradableLicenseDesc
                ? component.nextUpgradableLicenseDesc
                : '-',
              licenseRequested: component.licenseRequested ? component.licenseRequested : '',
              currentLicensePartNum: component.currentLicensePartNum ? component.currentLicensePartNum : '',
              techLevel: component.techLevel ? component.techLevel : '',
              make: this.rowData && this.rowData.make ? this.rowData.make : '',
            });
          })
        );
      });

      forkJoin(...requests)
        .pipe(
          finalize(() => {
            if (!this.isLicenseMappingEnabled) {
              this.loader = false;
            }

            responses[0].sort((a: any, b: any) => a.index - b.index);
            this.catGradeLicenseTableData = responses[0];
            if (this.isLicenseMappingEnabled) {
              const licenseEligibilityReqs = this.catGradeLicenseTableData.map((element: any) => {
                const licensePayload = {
                  componentType: element.type,
                  currentLicensePartNumber: element.currentLicensePartNum,
                  productFamily: this.productFamilyCode,
                  ecmSerialNumber: element.serialNumber,
                  currentLicenseStatus: element.licenseStatus,
                  techLevel: element.techLevel,
                  index: element.index,
                };
                return this.assetDrawerService.getUpgradeLicenseDetails(licensePayload);
              });

              forkJoin(...licenseEligibilityReqs).subscribe({
                next: (responses) => {
                  let licenseEligiblityStatus: any;
                  let currentLicenseStatus: any;
                  this.loader = false;
                  const descriptionMap: { [key: string]: string } = {};
                  const descriptionPartNumberMap: { [key: string]: string } = {};
                  const currentLicenseDescMap: { [key: string]: string } = {};
                  const licenseEligiblityStatusMap: { [key: string]: string } = {};
                  const currentLicenseStatusMap: { [key: string]: string } = {};
                  const indexMap: { [key: string]: string } = {};
                  if (responses && Array.isArray(responses)) {
                    responses.forEach((eligiblity: any) => {
                      const serialNumber = Object.keys(eligiblity)[0];
                      eligiblity[serialNumber].forEach((entry: any) => {
                        const index = entry?.current?.index;
                        const mapKey = `${serialNumber} - ${index}`;
                        if (eligiblity[serialNumber].length == 1) {
                          licenseEligiblityStatus = entry?.next?.description
                            ? entry?.next?.description
                            : 'Not Eligible';
                        } else {
                          licenseEligiblityStatus =
                            entry?.current?.currentLicenseStatus === 'PENDING' ? 'pendingMultiLicense' : 'multiLicense';
                          currentLicenseStatus = entry?.current?.currentLicenseStatus ?? '';
                        }
                        const description =
                          eligiblity[serialNumber].length > 1 ? 'multiLicense' : entry.next?.description;
                        const licensepartNumber = entry?.next?.licensePartNumber || '';
                        const currentLicensedesc = entry?.current?.description || '';

                        descriptionMap[mapKey] = description;
                        descriptionPartNumberMap[mapKey] = licensepartNumber;
                        currentLicenseDescMap[mapKey] = currentLicensedesc;
                        licenseEligiblityStatusMap[mapKey] = licenseEligiblityStatus;
                        currentLicenseStatusMap[mapKey] = currentLicenseStatus;
                      });
                    });
                  }

                  this.catGradeLicenseTableData = this.catGradeLicenseTableData.map((license: any) => {
                    const mapKey = `${license.serialNumber} - ${license.index}`;
                    return {
                      ...license,
                      nextUpgradableLicenseDesc: descriptionMap[mapKey] || license.nextUpgradableLicenseDesc,
                      nextUpgradableLicensePartNum:
                        descriptionPartNumberMap[mapKey] || license.nextUpgradableLicensePartNum,
                      currentLicenseDesc: currentLicenseDescMap[mapKey] || license.currentLicenseDesc,
                      licenseEligiblityStatus: licenseEligiblityStatusMap[mapKey] || license.licenseEligiblityStatus,
                      currentLicenseStatus: currentLicenseStatusMap[mapKey] || license.currentLicenseStatus,
                    };
                  });
                  this.validateNextBtnTableData = this.catGradeLicenseTableData.map((license: any) => {
                    if (licenseEligiblityStatusMap[license.serialNumber]) {
                      return {
                        ...license,
                        licenseEligiblityStatus: licenseEligiblityStatusMap[license.serialNumber],
                        currentLicenseStatus: currentLicenseStatusMap[license.serialNumber],
                      };
                    }
                    return license;
                  });

                  sessionStorage.setItem('validateNextBtnTableData', JSON.stringify(this.validateNextBtnTableData));
                  this.assetDrawerService.filteredPaymentPreviewData.next(this.catGradeLicenseTableData);
                  const result = this.catGradeLicenseTableData.every(
                    (item: any) =>
                      item.nextUpgradableLicenseDesc === 'No Upgrade Available' ||
                      item.nextUpgradableLicenseDesc === 'Not Upgradable' ||
                      item.nextUpgradableLicenseDesc === '-' ||
                      item.nextUpgradableLicenseDesc === null ||
                      item.nextUpgradableLicenseDesc === 'No Eligible Upgrade'
                  );

                  if (result) {
                    // this.selectedAssetSubscriptionFormData.isValidForm = false;
                    this.selectedAssetSubscriptionFormData.enableCloseButton = true;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  } else {
                    this.selectedAssetSubscriptionFormData.enableCloseButton = false;
                    this.selectedAssetSubscriptionFormData.isValidForm = true;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  }
                  if (!this.subsPricingEligible && this.catGradeLicenseTableData) {
                    const result = this.catGradeLicenseTableData.filter(
                      (item: any) =>
                        item.nextUpgradableLicenseDesc !== 'No Upgrade Available' &&
                        item.nextUpgradableLicenseDesc !== 'Not Upgradable' &&
                        item.nextUpgradableLicenseDesc !== 'No Eligible Upgrade' &&
                        item.licenseStatus.toLowerCase() !== 'pending' &&
                        item.nextUpgradableLicenseDesc !== null
                    );
                    this.assetDrawerService.filteredPaymentPreviewData.next(result);
                  }
                  this.assetDrawerService.licenseEligiblityInfo.next(responses);
                  sessionStorage.setItem('multiLicenseEligiblity', JSON.stringify(responses));
                  // sessionStorage.setItem('validatedLicenseTableData', JSON.stringify(this.catGradeLicenseTableData));
                },
                error: (err: any) => {
                  this.loader = false;
                },
              });
            }

            const result = this.catGradeLicenseTableData.every(
              (item: any) =>
                item.nextUpgradableLicenseDesc === 'No Upgrade Available' ||
                item.nextUpgradableLicenseDesc === 'Not Upgradable' ||
                item.nextUpgradableLicenseDesc === '-' ||
                item.nextUpgradableLicenseDesc === 'No Eligible Upgrade'
            );

            const customerInfo = this.selectedAssetSubscriptionFormData?.customer
              ? this.selectedAssetSubscriptionFormData?.customer
              : {};
            this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
            if (!this.isLicenseMappingEnabled) {
              if (result) {
                // this.selectedAssetSubscriptionFormData.isValidForm = false;
                this.selectedAssetSubscriptionFormData.enableCloseButton = true;
                this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              } else {
                this.selectedAssetSubscriptionFormData.isValidForm = true;
                this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              }
            } else {
              this.assetDrawerService.isMultiLicenseEnabled.subscribe((data: any) => {
                const checkLicenseCombinations = (data: any[]) => {
                  const hasMultilicense = data.some((item: any) => item.licenseEligiblityStatus === 'multiLicense');
                  const hasOthervalues = data.some(
                    (item: any) =>
                      item.licenseEligiblityStatus !== 'multiLicense' && item.licenseEligiblityStatus !== 'Not Eligible'
                  );
                  const hasNotEligible = data.some((item: any) => item.licenseEligiblityStatus === 'Not Eligible');
                  const hasPending = data.some(
                    (item: any) =>
                      item.licenseEligiblityStatus === 'pendingMultiLicense' && item.currentLicenseStatus === 'PENDING'
                  );
                  const hasEligible = data.every((item: any) => item.licenseEligiblityStatus === 'Not Eligible');

                  return {
                    hasMultilicense,
                    hasMultiLicenseAndOtherValues: hasMultilicense && hasOthervalues,
                    hasNotEligible,
                    hasPending,
                    hasEligible,
                  };
                };

                const result = checkLicenseCombinations(this.validateNextBtnTableData);

                if (
                  (result.hasMultilicense && result.hasNotEligible && result.hasPending) ||
                  (result.hasMultiLicenseAndOtherValues && result.hasNotEligible) ||
                  result.hasMultiLicenseAndOtherValues ||
                  (result.hasMultiLicenseAndOtherValues && result.hasPending) ||
                  (result.hasMultilicense && result.hasPending) ||
                  (result.hasMultilicense && result.hasNotEligible) ||
                  result.hasMultilicense
                ) {
                  this.selectedAssetSubscriptionFormData.enableCloseButton = false;
                  this.selectedAssetSubscriptionFormData.isValidForm = false;
                  this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                } else if (
                  result.hasPending ||
                  (result.hasPending && result.hasNotEligible) ||
                  (result.hasNotEligible && !result.hasMultilicense) ||
                  (result.hasEligible && !result.hasMultilicense)
                ) {
                  this.selectedAssetSubscriptionFormData.enableCloseButton = true;
                  this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                } else {
                  this.selectedAssetSubscriptionFormData.enableCloseButton = false;
                  this.selectedAssetSubscriptionFormData.isValidForm = true;
                  this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                }

                this.isMultiLicenseEnabled = data;
                if (this.isMultiLicenseEnabled) {
                  this.selectedAssetSubscriptionFormData.isValidForm = true;
                }

                this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              });
            }

            this.assetDrawerService.filteredPaymentPreviewData.next(this.catGradeLicenseTableData);
            if (!this.subsPricingEligible && this.catGradeLicenseTableData) {
              const result = this.catGradeLicenseTableData.filter(
                (item: any) =>
                  item.nextUpgradableLicenseDesc !== 'No Upgrade Available' &&
                  item.nextUpgradableLicenseDesc !== 'Not Upgradable' &&
                  item.nextUpgradableLicenseDesc !== 'No Eligible Upgrade' &&
                  item.licenseStatus.toLowerCase() !== 'pending'
              );
              this.assetDrawerService.filteredPaymentPreviewData.next(result);
            }
          })
        )
        .subscribe((data: any) => {
          if (data) {
            responses.push(data);
          }
        });
    } else if (this.isBackBtnClciked) {
      const nextBtnTableData = sessionStorage.getItem('validateNextBtnTableData');
      this.validateNextBtnTableData = nextBtnTableData ? JSON.parse(nextBtnTableData) : null;
      let validatedLicenseData;
      this.assetDrawerService.filteredPaymentPreviewData.subscribe((data: any) => {
        if (data) {
          validatedLicenseData = data;
        }
      });
      if (this.isLicenseMappingEnabled) {
        this.assetDrawerService.isMultiLicenseEnabled.subscribe((data: any) => {
          const checkLicenseCombinations = (data: any[]) => {
            const hasMultilicense = data?.some((item: any) => item.licenseEligiblityStatus === 'multiLicense');
            const hasOthervalues = data?.some(
              (item: any) =>
                item.licenseEligiblityStatus !== 'multiLicense' && item.licenseEligiblityStatus !== 'Not Eligible'
            );
            const hasNotEligible = data.some((item: any) => item.licenseEligiblityStatus === 'Not Eligible');
            const hasPending = data.some(
              (item: any) =>
                item.licenseEligiblityStatus === 'pendingMultiLicense' && item.currentLicenseStatus === 'PENDING'
            );
            const hasEligible = data.every((item: any) => item.licenseEligiblityStatus === 'Not Eligible');

            return {
              hasMultilicense,
              hasMultiLicenseAndOtherValues: hasMultilicense && hasOthervalues,
              hasNotEligible,
              hasPending,
              hasEligible,
            };
          };

          const result = checkLicenseCombinations(this.validateNextBtnTableData);

          if (
            (result.hasMultilicense && result.hasNotEligible && result.hasPending) ||
            (result.hasMultiLicenseAndOtherValues && result.hasNotEligible) ||
            result.hasMultiLicenseAndOtherValues ||
            (result.hasMultiLicenseAndOtherValues && result.hasPending) ||
            (result.hasMultilicense && result.hasPending) ||
            (result.hasMultilicense && result.hasNotEligible) ||
            result.hasMultilicense
          ) {
            this.selectedAssetSubscriptionFormData.enableCloseButton = false;
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else if (
            result.hasPending ||
            (result.hasPending && result.hasNotEligible) ||
            (result.hasNotEligible && !result.hasMultilicense) ||
            (result.hasEligible && !result.hasMultilicense)
          ) {
            this.selectedAssetSubscriptionFormData.enableCloseButton = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.selectedAssetSubscriptionFormData.enableCloseButton = false;
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          }

          this.isMultiLicenseEnabled = data;
          if (this.isMultiLicenseEnabled) {
            this.selectedAssetSubscriptionFormData.isValidForm = true;
          }

          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        });

        const multiLicenseData = sessionStorage.getItem('multiLicenseEligiblity');
        this.multiLicenseRes = multiLicenseData ? JSON.parse(multiLicenseData) : null;
        this.assetDrawerService.licenseEligiblityInfo.next(this.multiLicenseRes);
      }

      if (validatedLicenseData) {
        this.catGradeLicenseTableData = validatedLicenseData;
        const result = this.catGradeLicenseTableData.every(
          (item: any) =>
            item.nextUpgradableLicenseDesc === 'No Upgrade Available' ||
            item.nextUpgradableLicenseDesc === 'Not Upgradable' ||
            item.nextUpgradableLicenseDesc === 'No Eligible Upgrade'
        );
        const customerInfo = this.selectedAssetSubscriptionFormData?.customer
          ? this.selectedAssetSubscriptionFormData?.customer
          : {};
        this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
        if (result) {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.selectedAssetSubscriptionFormData.enableCloseButton = true;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        } else {
          this.selectedAssetSubscriptionFormData.enableCloseButton = false;
          this.selectedAssetSubscriptionFormData.isValidForm = true;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        }
      }
    }
  }

  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }
}
