import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CcBarChartModule, CcDonutChartModule } from '@cat-digital-workspace/shared-charts';
import { CcMapsModule } from '@cat-digital-workspace/shared-maps';
import {
  CatButtonModule,
  CatIconModule,
  CatPaginationModule,
  CcAccordionModule,
  CcCardModule,
  CcCheckboxModule,
  CcChipModule,
  CcDatepickerModule,
  CcDropdownModule,
  CcInputFieldModule,
  CcListModule,
  CcMenuModule,
  CcMessageModule,
  CcModalModule,
  CcPopoverModule,
  CcProgressTrackerModule,
  CcRadioModule,
  CcSkeletonLoaderModule,
  CcSlideToggleModule,
  CcStatusIndicatorModule,
  CcTableModule,
  CCTabModule,
  CcTooltipModule,
  CcTopNavModule,
  DrawerModule,
  HighlightSearchModule,
  MobileHeaderModule,
  SideNavModule,
  TabsModule,
  CcTimepickerModule,
  CcTimepickerComponent,
  CcFileUploadComponent,
  CcFileUploadModule,
} from '@cat-digital-workspace/shared-ui-core';
import { CcTableSettingsModule } from '@cat-digital-workspace/shared-ui-core/cc-table-settings';
import { AccessDeniedComponent } from '../components/access-denied/access-denied.component';
import { NoResultsFoundComponent } from '../components/no-results-found/no-results-found.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal/confirmation-modal.component';
import { LoaderModule } from '../filters/loader/loader.module';
import { SearchLoaderModule } from '../filters/search-loader/search-loader.module';
import { SpinnerModule } from '../filters/spinner/spinner.module';
import { SpotLoaderModule } from '../filters/spot-loader/spot-loader.module';
import { DateFormatPipe } from './date-format.pipe';
import { TimeFormat } from './date-pipe';
import { InputRestrictionDirective } from './input-restriction.directive';
import { OwnershipFormat } from './ownership-pipe';
import { NumericOnlyDirective } from './numeric-only.directive';
import { CopyDirective } from './copy.directive';
import { CommonMainFiltersIconComponent } from './components/common-main-filters-icon/common-main-filters-icon.component';
import { CommonMainFiltersComponent } from './components/common-main-filters/common-main-filters.component';
import { CommonDateTimeComponent } from './components/common-date-time/common-date-time.component';
import { CommonSubFilterComponent } from './components/common-sub-filter/common-sub-filter.component';
import { CommonShowallFilterComponent } from './components/common-showall-filter/common-showall-filter.component';
import { DisplayTimeZoneComponent } from './components/display-time-zone/display-time-zone.component';

@NgModule({
  declarations: [
    ConfirmationModalComponent,
    TimeFormat,
    AccessDeniedComponent,
    DateFormatPipe,
    OwnershipFormat,
    InputRestrictionDirective,
    NoResultsFoundComponent,
    NumericOnlyDirective,
    CopyDirective,
    CommonMainFiltersIconComponent,
    CommonMainFiltersComponent,
    CommonDateTimeComponent,
    CommonSubFilterComponent,
    CommonShowallFilterComponent,
    DisplayTimeZoneComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HighlightSearchModule,
    SearchLoaderModule,
    CatIconModule,
    CcTooltipModule,
    SpinnerModule,
    CcInputFieldModule,
    CcAccordionModule,
    CcCheckboxModule,
    CcDropdownModule,
    CcListModule,
    CcSkeletonLoaderModule,
    CcCardModule,
    CcDatepickerModule,
    CcRadioModule,
    CcChipModule,
    CcTableSettingsModule,
    CcTableModule,
    CommonModule,
    CcListModule,
    CcPopoverModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CatIconModule,
    CatButtonModule,
    CcTopNavModule,
    CcDropdownModule,
    SideNavModule,
    CcTooltipModule,
    CcAccordionModule,
    CcListModule,
    CcInputFieldModule,
    CcProgressTrackerModule,
    CcModalModule,
    CcCardModule,
    CcChipModule,
    CcMessageModule,
    CcTooltipModule,
    CcMenuModule,
    CcTableModule,
    CatPaginationModule,
    DrawerModule,
    CcTableSettingsModule,
    CCTabModule,
    CcCheckboxModule,
    MobileHeaderModule,
    CcSlideToggleModule,
    TabsModule,
    CcSkeletonLoaderModule,
    HighlightSearchModule,
    LoaderModule,
    SpotLoaderModule,
    CcMapsModule,
    CcDatepickerModule,
    SearchLoaderModule,
    SpinnerModule,
    CcRadioModule,
    CcStatusIndicatorModule,
    CcBarChartModule,
    CcDonutChartModule,
    CcTimepickerModule,
  ],
  exports: [
    CopyDirective,
    CommonMainFiltersIconComponent,
    ConfirmationModalComponent,
    TimeFormat,
    AccessDeniedComponent,
    DateFormatPipe,
    OwnershipFormat,
    InputRestrictionDirective,
    NoResultsFoundComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HighlightSearchModule,
    SearchLoaderModule,
    CatIconModule,
    CcTooltipModule,
    SpinnerModule,
    CcListModule,
    CcInputFieldModule,
    CcAccordionModule,
    CcCheckboxModule,
    CcDropdownModule,
    CcListModule,
    CcSkeletonLoaderModule,
    CcCardModule,
    CcDatepickerModule,
    CcRadioModule,
    CcChipModule,
    CcTableSettingsModule,
    CcTableModule,
    CommonModule,
    CcPopoverModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CatIconModule,
    CatButtonModule,
    CcTopNavModule,
    CcDropdownModule,
    SideNavModule,
    CcTooltipModule,
    CcAccordionModule,
    CcListModule,
    CcInputFieldModule,
    CcProgressTrackerModule,
    CcModalModule,
    CcCardModule,
    CcChipModule,
    CcMessageModule,
    CcTooltipModule,
    CcMenuModule,
    CcTableModule,
    CatPaginationModule,
    DrawerModule,
    CcTableSettingsModule,
    CCTabModule,
    CcCheckboxModule,
    MobileHeaderModule,
    CcSlideToggleModule,
    TabsModule,
    CcSkeletonLoaderModule,
    HighlightSearchModule,
    LoaderModule,
    SpotLoaderModule,
    CcMapsModule,
    CcDatepickerModule,
    SearchLoaderModule,
    SpinnerModule,
    CcRadioModule,
    CcStatusIndicatorModule,
    CcBarChartModule,
    CcDonutChartModule,
    NumericOnlyDirective,
    CommonShowallFilterComponent,
    CcTimepickerComponent,
    CcFileUploadModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
