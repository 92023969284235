import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MessageBar,
  MessageBarConfig,
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetService } from '../../services/asset.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { AssetDrawerComponent } from '../../asset-drawer/asset-drawer.component';
import { isEmpty } from 'lodash-es';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';

@Component({
  selector: 'dsp-next-gen-ui-manage-device-registration',
  templateUrl: './manage-device-registration.component.html',
  styleUrls: ['./manage-device-registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageDeviceRegistrationComponent implements OnInit {
  isTheme = '2.0';
  deviceType: Array<string> = [];
  deviceRegisterForm: FormGroup = new FormGroup({});
  eRoutesData: Array<any> = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;
  disableDeviceSerialNo = true;
  disableEroutes = true;
  assetDrawerRef: any;
  public rowData: any;
  deviceForm: any;
  prevValue = '';
  curValue = '';
  negative = false;
  registerDisabled = true;
  make: any;
  edUrl = '';
  replaceEDUrl!: any;

  constructor(
    public assetService: AssetService,
    private formBuilder: FormBuilder,
    private bannerNotification: MessageBar,
    private drawerService: DrawerService,
    public store: Store<DSPAppState>,
    private router: Router,
    private assetDrawerService: AssetDrawerService
  ) {}

  ngOnInit(): void {
    this.deviceRegisterForm = this.formBuilder.group({
      deviceType: ['', Validators.required],
      deviceSerialNumber: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(16), Validators.pattern('^[a-zA-Z0-9]+$')],
      ],
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.edUrl = dsp.edUrl?.data;
      this.replaceEDUrl = dsp?.featureFlagInfo?.replaceEDUrl ? dsp?.featureFlagInfo?.replaceEDUrl : false;
    });
    this.deviceRegisterForm.removeControl('eroutesEnabled'); // Removing the eroutesEnabled control by default
    this.getErouteMakeCode();
  }

  getErouteMakeCode() {
    this.assetService.getErouteMakeCode().subscribe({
      next: (resp) => {
        this.eRoutesData = resp;
        this.deviceType = resp.map((type: any) => ({
          label: type.deviceType,
          value: type.deviceType,
        }));
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  get formControl() {
    return this.deviceRegisterForm.controls;
  }

  registerDevice() {
    let messageRef: any;
    const config: MessageBarConfig = {
      hostType: 'inline',
      verticalPosition: 'top',
      horizontalPosition: 'left',
      hostSelectorId: 'sel-device-registration',
      appOverlayClass: 'device-registration-msg',
    };

    const deviceData = this.eRoutesData.find(
      (item) => item.deviceType === this.deviceRegisterForm.value.deviceType[0].value
    );
    const input = {
      makeCode: deviceData.makeCode,
      deviceSerialNumber: this.deviceRegisterForm.value.deviceSerialNumber.toUpperCase(),
      deviceType: this.deviceRegisterForm.value.deviceType[0].value,
      defaultMakeCode: deviceData.defaultMakeCode,
      dspVersion: 'v2',
    };

    if (input.deviceType === 'SNM941') {
      Object.assign(input, { eroutesEnabled: this.deviceRegisterForm.value?.eroutesEnabled });
    }
    this.make = input.makeCode;
    this.assetService.saveErouteSubs(input).subscribe({
      next: (resp) => {
        this.deviceForm = this.deviceRegisterForm.value.deviceSerialNumber;
        this.resetForm();
        this.deviceRegisterForm.removeControl('eroutesEnabled');
        const message = resp.Message
          ? resp.Message
          : 'Device has been successfully created. Ensure an ownership record exists and proceed to subscribe. ';
        messageRef = this.bannerNotification.open(
          message,
          'success',
          [
            {
              label: 'Go to Asset Details',
              fn: () => {
                messageRef.dismiss();
                this.openAssetDrawer();
              },
            },
            {
              label: this.replaceEDUrl ? 'Update Ownership' : 'Go to Equipment Data',
              fn: () => {
                window.open(this.edUrl); // As maha suggested since the equipement component data is not developed, so opening the url in new window
              },
            },
          ],
          config,
          true
        );
      },
      error: (err: any) => {
        if (err.status === 409) {
          this.showToastMessage(
            'Device serial number already exists in DSP. Please search for the device using the asset Search option',
            'error'
          );
        } else if (err.status === 500) {
          this.showToastMessage(
            'Device creation was unsuccessful. Please check the entered values or contact support',
            'error'
          );
        }
      },
    });
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'inline',
      verticalPosition: 'top',
      horizontalPosition: 'left',
      hostSelectorId: 'sel-device-registration',
      duration: 3000,
    };
    this.bannerNotification.open(message, status, undefined, config, true);
  }
  resetForm() {
    this.registerDisabled = true;
    this.deviceRegisterForm.reset();
  }

  /** Adding the eroutesEnabled control on change of SNM941 */
  onDropdownChange(event: any) {
    if (event) {
      this.disableDeviceSerialNo = false;
    }
  }

  /** Disabling the eroutes when the device serial is not entered */

  openAssetDrawer() {
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: {
          serialNumber: this.deviceForm,
          make: this.make,
          reportingState: dspConstants.Worklist.UNSUBSCRIBED,
        },
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.assetDrawerRef.afterClosed().subscribe((response: boolean) => {
      this.assetDrawerService.getapplicableList = '';
      if (response) {
      }
    });
  }
  serialNumberChange(event: any) {
    if (event.target?.value?.length < 3) {
      this.registerDisabled = true;
    } else if (event.target?.value?.length > 16) {
      const { errors } = this.deviceRegisterForm.controls['deviceSerialNumber'];
      if (isEmpty(errors)) {
        this.deviceRegisterForm.controls['deviceSerialNumber'].setErrors({ incorrect: true });
      }

      this.negative = true;
      this.registerDisabled = true;
      this.deviceRegisterForm.controls['deviceSerialNumber'].patchValue(
        (event.target?.value as string).slice(0, event.target?.value.length - 1),
        {
          emitEvent: false,
        }
      );
    } else {
      this.negative = false;
      this.registerDisabled = false;
    }
  }
  onFocusEvent() {
    this.negative = false;
  }
  onblurEvent() {
    this.negative = false;
    const { errors } = this.deviceRegisterForm.controls['deviceSerialNumber'];
    if (errors?.['incorrect']) {
      this.deviceRegisterForm.controls['deviceSerialNumber'].setErrors(null);
    }
  }
}
